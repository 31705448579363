import React, { useEffect } from 'react'

import immersify_logo from "../../images/immersify_logo.svg";
import shape_1 from "../../images/shape_1.svg";

import { useParams } from "react-router-dom";
import { DownloadS3File } from '../../Utils/downloadS3File';
import { Encrypt } from '../../Utils/Encrypt';
import { GetAccountInfo } from '../scripts/GetLoginData';

var id_token

export const UniSSO = () => {
    id_token = Object.values(useParams())[0];

    useEffect(() => {
        DownloadS3File(
            "UniSSO/connections_list_live.json",
            gotConnectionsListCallback,
            "com.immersifyeducation.webdev"
        );
    }, []);

    function handleManualRedirect () {
        window.location.href = process.env.REACT_APP_CLIENT_URL + "/Profile"
    }

    return (
        <>
            <section className="container-fluid">
                <div className="row">
                    <div className="col-xl-7 col-md-6 left_block d-none d-md-block">
                        <div className="signup_image">
                            <div className="logo_wrap">
                                <img alt="placeholder" src={immersify_logo} />
                            </div>
                            <div className="shape_block">
                                <div className="shape_1">
                                    <img alt="placeholder" src={shape_1} />
                                </div>
                                <div className="shape_2">
                                    <img alt="placeholder" src={shape_1} />
                                </div>
                            </div>
                            <div className="login_text_block">
                                <h1>LEARN.</h1>
                                <h1>EXPERIENCE.</h1>
                                <h1>CONNECT.</h1>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-5 col-md-6">
                        <div className="signup_form_area">
                            <h3>Please do not refresh </h3>
                            <h2>Redirecting...</h2>
                        </div>
                        <button onClick={handleManualRedirect} >
                            If you have been waiting longer than a few seconds please click here
                        </button>
                    </div>
                </div>
            </section>
        </>
    );
}

function gotConnectionsListCallback (data) {
    const connections = JSON.parse(data).OpenIDConnections
    var connection_id
    const suffix = localStorage.getItem("suffix");
    console.log({suffix})

    connections.forEach(connection => {
        console.log(connection.suffix)
        if (connection.suffix === suffix) {
            connection_id = connection.connectionID
        }
    });
    console.log(connection_id)

    if (connection_id === undefined || connection_id === null || connection_id === "") {
        return
    }


    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
        ConnectionId: connection_id,
        IdToken: id_token,
        TitleId: "29001",
        CreateAccount: true,
    });

    var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
    };

    fetch(
        "https://29001.playfabapi.com/Client/LoginWithOpenIdConnect",
        requestOptions
    )
        .then((response) => response.text())
        .then((result) => {
            GetAccountInfo(result, "uniSSO", getAccountInfoCallback)
        })
        .catch((error) => console.log("error", error));
}

function getAccountInfoCallback (displayName) {

    localStorage.setItem(
        process.env.REACT_APP_PREMIUM_CODE_NAME + "value",
        Encrypt(true)
    );
    localStorage.setItem(
        process.env.REACT_APP_PREMIUM_CODE_NAME + "date",
        Encrypt("Fri Oct 07 2032 22:07:27 GMT+0100 (British Summer Time)")
    );
    localStorage.setItem("activeUntil", "NA");
    localStorage.setItem("interval", "NA");

    if (displayName === "") {
        window.location.href = process.env.REACT_APP_API_URL + "/DisplayName";
    }
    else {
        window.location.href = process.env.REACT_APP_API_URL;
    }
}