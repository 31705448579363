import React, { useState } from "react";
import PropTypes from "prop-types";
import close_button_image from "../../../../../images/close_btn.svg";

var axios = require("axios");

export const PasswordReset = (props) => {
    //handling modal
    const [showPassword, setShowPassword] = useState(false);
    const [password, setPassword] = useState("");
    const [validationMessage, setValidationMessage] = useState("");
    const [passwordConfirmDisabled, setPasswordConfirmDisabled] = useState(false);

    function passwordResetConfirm() {
        const urlParams = new URLSearchParams(window.location.search);
        if (urlParams.has("token")) {
            const token = urlParams.get("token");

            const validPassword = validatePassword(password);
            if (!validPassword) {
                return;
            }
            //do call
            var data = JSON.stringify({
                Token: token,
                Password: password,
            });

            var config = {
                method: "post",
                url: "https://29001.playfabapi.com/Admin/ResetPassword",
                headers: {
                    "X-SecretKey": process.env.REACT_APP_PLAYFAB_SECRET_KEY,
                    "Content-Type": "application/json",
                },
                data: data,
            };

            axios(config)
                .then(function (response) {
                    console.log(response);
                    setPasswordConfirmDisabled(true);
                    setValidationMessage("Password reset successfully!");
                    setTimeout(() => {
                        props.setPasswordResetModalOpen(false);
                    }, 2000);
                })
                .catch(function (error) {
                    console.log(error);
                    setValidationMessage("Something went wrong. Please try again, if the problem persists please contact us.");
                });
        } else {
            console.log("No token found in URL!");
            setValidationMessage("Something went wrong. Please try again, if the problem persists please contact us.");
        }
    }

    function hasNumber(myString) {
        return /\d/.test(myString);
    }

    function validatePassword(password) {
        console.log(password);
        // field is not empty
        if (!password) {
            setValidationMessage("Password cannot be empty");
            console.log("validation fail");
            return false;
        }

        // at least one number
        if (!hasNumber(password)) {
            setValidationMessage("Password must contain at least one number");
            console.log("validation fail");
            return false;
        }

        // at least 8 characters
        if (password.length < 8) {
            setValidationMessage("Password must be at least 8 characters long");
            console.log("validation fail");
            return false;
        }

        // must not contains spaces
        if (password.includes(" ")) {
            setValidationMessage("Password cannot contain spaces");
            console.log("validation fail");
            return false;
        }

        return true;
    }

    return (
        <>
            <div className="modal fade show" style={{ display: props.passwordResetModalOpen ? "block" : "" }}>
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <div>Reset your password</div>
                            <button type="button"></button>
                            <img
                                alt="placeholder"
                                src={close_button_image}
                                className="btn_mod_close cursor-pointer"
                                onClick={() => props.setPasswordResetModalOpen(false)}
                            ></img>
                        </div>
                        <div className="modal-body">
                            <div>
                                <label>Enter new password here:</label>
                                <div className="flex" style={{ alignItems: "center", marginTop: "10px", marginBottom: "10px" }}>
                                    <input
                                        type={showPassword ? "text" : "password"}
                                        className="form-control pwd"
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                    />
                                    <i
                                        className={"cursor-pointer h-8 w-8 fa fa-eye" + (!showPassword ? "-slash" : "")}
                                        onClick={() => setShowPassword(!showPassword)}
                                        style={{ marginLeft: "10px" }}
                                    ></i>
                                </div>
                            </div>
                            <div style={{ height: "40px", fontSize: "10px" }}>{validationMessage}</div>
                            <button className="btn_common" onClick={passwordResetConfirm} disabled={passwordConfirmDisabled}>
                                Confirm
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal-backdrop fade show"></div>
        </>
    );
};

PasswordReset.propTypes = {
    passwordResetModalOpen: PropTypes.bool.isRequired,
    setPasswordResetModalOpen: PropTypes.func.isRequired,
};
