import React from "react";
import PropTypes from "prop-types";

import { GetNextComing } from "./GetNextComing";

import { SideCard } from "../SideCard/SideCard";

export const NextComing = (props) => {

    const id = props.id

    var nextComing = []
    nextComing = GetNextComing(id)

    const SideCards = () => {
        return (nextComing.map((id, index) => (
            <SideCard key={"sideCard" + index} id={id} displayContentCallback={props.displayContentCallback} />
        )))
    }

    return (
        <>
            <div className="content_links">
                <h4>Next Activity</h4>
                {SideCards()}
            </div>
        </>
    );
};

NextComing.propTypes = {
    id: PropTypes.string,
    displayContentCallback: PropTypes.func,
};
