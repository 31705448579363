import ModuleStructure from "../../../../../InfoJSONs/ModuleStructure.json";

export function GetTopicStructure (id) {

    var returnInfo = {"Lessons": [], "Practicals": []}

    ModuleStructure.modules.forEach(module => {
        module.topics.forEach(topic => {
            if (topic.topicID === id) {
                topic.lessonIDs.forEach(lesson => {
                    returnInfo.Lessons.push(lesson)
                });

                returnInfo.Practicals.push(topic.pracID)
            }
        });
    });

    return returnInfo
}