// eslint-disable-next-line
import React from "react";
import PropTypes from "prop-types";

// import '../SCSS/style.css';
import { VMCQFeedbackTemplate } from "./VMCQModelFeedback";

// import zoom from "../images/zoom.png";

// export const AFramePlayer = (props) => {  
//     useEffect(() => {
//         console.log("Use effect (AframePlayer)");
//     }, []);


export const AFramePlayer = (props) => {

    const getModelsForThisPoint = () => {
        let modelsForThisPointList = [];
        props.modelData.forEach(model =>{
            if(model.id == props.id.toString()){
                modelsForThisPointList.push(model);
            }
        });
        return modelsForThisPointList;
    }

    return (
        <div className="anim_video">
            <VMCQFeedbackTemplate
                key={props.id}
                id={props.id}
                modelsForThisPoint={getModelsForThisPoint()}
            />
        </div>
    );
};

AFramePlayer.propTypes = {
    id: PropTypes.number,
    modelData: PropTypes.any
};