import React from "react";
import { Scene } from "aframe-react";

export function SetPoint(pointData, setSliderPoints, currentPoint) {
    const textContent = pointData.textContent;
    const subheadingText = pointData.subheadingText;
    const content = pointData.content;
    localStorage.setItem("currentPoint", currentPoint);

    var points = [];

    const pointsJSX = textContent.map((element, i) => {
        if (currentPoint === 0 && i === 2) {
            return (
                <Scene
                    embedded
                    device-orientation-permission-ui="enabled: false"
                    vr-mode-ui="enabled: false"
                    key="empty-scene"
                ></Scene>
            );
        }
        if (
            i === currentPoint - 1 ||
            i === currentPoint ||
            i === currentPoint + 1
        ) {
            points.push(true);
            return (
                <article key={"sliderPoint" + i} className="lesson_slide">
                    <div className="std_lessons">
                        {content[i] !== undefined && (
                            <div className="std_img_block">
                                <div className="img_box">{content[i]}</div>
                            </div>
                        )}

                        <div className="std_text_block">
                            <h4>{subheadingText[i]}</h4>
                            <p
                                style={{
                                    whiteSpace: "pre-wrap",

                                    overflowWrap: "break-word",
                                }}
                            >
                                {element.text}
                            </p>
                        </div>
                    </div>
                </article>
            );
        } else {
            points.push(false);
            return (
                <></>
                // <article key={"sliderPoint" + i} className="lesson_slide">
                // </article>
            );
        }
    });

    setSliderPoints(pointsJSX);
}
