// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent, setUserProperties } from "firebase/analytics";

const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: "immersify-dental.firebaseapp.com",
    databaseURL: "https://immersify-dental-default-rtdb.firebaseio.com",
    projectId: "immersify-dental",
    storageBucket: "immersify-dental.appspot.com",
    messagingSenderId: "190953993681",
    appId: "1:190953993681:web:83ff25b4e34a6935f7dcf0",
    measurementId: "G-MFH9NZT2R6",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const firebaseAnalytics = getAnalytics(app);
setUserProperties(firebaseAnalytics, {
    playfab_id: window.localStorage.getItem("playfabID")
        ? window.localStorage.getItem("playfabID")
        : "No Playfab ID",
    click_id: "click_id",
    discount_code: "discount_code",
    // immersify_platform: (window.location.href.includes('Landing')?'web_landing':'web_platform'),
    user_email: window.localStorage.getItem("contactEmail")
        ? window.localStorage.getItem("contactEmail")
        : "No Email yet",
});

export const updateUserProperties = (platform) => {
    setUserProperties(firebaseAnalytics, {
        playfab_id: window.localStorage.getItem("playfabID")
            ? window.localStorage.getItem("playfabID")
            : "No Playfab ID",
        click_id: "click_id",
        discount_code: "discount_code",
        immersify_platform: platform,
        user_email: window.localStorage.getItem("contactEmail")
            ? window.localStorage.getItem("contactEmail")
            : "No Email yet",
    });
};

export const firebaseCall = (eventName, parameters) => {
    logEvent(firebaseAnalytics, eventName, parameters);
};
