import React, {useEffect, useState} from 'react';
import {Tab, Tabs} from "react-bootstrap";
import ProductCards from "./ProductCards";
import {changeSubscription} from "../../DisplayPremium/StripeCheckout/CreateStripeCheckout/ChangeSubscription";
import {CheckIfLoggedIn} from "../../../../../Utils/CheckIfLoggedIn";

const PRODUCTS = [
  {
    name: "Free",
    daily_activities: 3,
    features: {
      "No access to the library": false,
      "Limited progress reports": false,
      "No access to proficiency": false
    }

  },
  {
    name: "Bronze",
    price_monthly: "3.50",
    price_yearly: "35.00",
    stripe_price_id_monthly: process.env.REACT_APP_STRIPE_MONTHLY_BRONZE_PID,
    stripe_price_id_yearly: process.env.REACT_APP_STRIPE_YEARLY_BRONZE_PID,
    daily_activities: 6,
    features: {
      "Full access to the library": true,
      "Full progress reports": true,
      "Full Access to proficiency": true
    }
  },
  {
    name: "Silver",
    price_monthly: "4.50",
    price_yearly: "45.00",
    stripe_price_id_monthly: process.env.REACT_APP_STRIPE_MONTHLY_SILVER_PID,
    stripe_price_id_yearly: process.env.REACT_APP_STRIPE_YEARLY_SILVER_PID,
    daily_activities: 10,
    features: {
      "Full access to the library": true,
      "Full progress reports": true,
      "Full Access to proficiency": true
    }
  },
  {
    name: "Gold",
    price_monthly: "6.50",
    price_yearly: "65.00",
    stripe_price_id_monthly: process.env.REACT_APP_STRIPE_MONTHLY_GOLD_PID,
    stripe_price_id_yearly: process.env.REACT_APP_STRIPE_YEARLY_GOLD_PID,
    daily_activities: "Unlimited",
    features: {
      "Full access to the library": true,
      "Full progress reports": true,
      "Full Access to proficiency": true
    }
  }
]

function findProductFromId(id) {
  return PRODUCTS.find(product =>
    (product.stripe_price_id_monthly && product.stripe_price_id_monthly === id)
    ||
    (product.stripe_price_id_yearly && product.stripe_price_id_yearly === id)) || PRODUCTS[0];
}

const NewSubscription = () => {
  const [stripeSubData, setStripeSubData] = useState();
  const activeProduct = stripeSubData ? findProductFromId(stripeSubData.Product) :
    stripeSubData === null ? PRODUCTS[0] : undefined;
  console.log("Active product: ", activeProduct, "Stripe sub data: ", stripeSubData)
  const [selectedProduct, setSelectedProduct] = useState();
  const [selectedPeriod, setSelectedPeriod] = useState("monthly");
  const [hasSubscribedOnOtherPlatform, setHasSubscribedOnOtherPlatform] = useState(false);
  const selectedIsActive = activeProduct && selectedProduct && selectedProduct.name === activeProduct.name;
  const enableSubscribe = activeProduct && selectedProduct && !selectedIsActive && !hasSubscribedOnOtherPlatform;

  useEffect(() => {
    const sessionTicket = CheckIfLoggedIn(true);

    if (sessionTicket === null || sessionTicket === undefined) {
      return false;
    }

    let raw = JSON.stringify({
      PlayFabId: localStorage.getItem("playfabID"),
      Keys: [
        "StripeSubData",
        "AndroidSubData",
        "iOSSubData",
        "OtherSubData"
      ],
      SessionTicket: sessionTicket,
    });

    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");


    let requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(process.env.REACT_APP_SERVER_URL + "/api/getUserData", requestOptions)
      .then((response) => response.text())
      .then((res) => {


        if (res === undefined) {
          console.error("Res is undefined")
        }
        try {
          console.log("Raw: ", res)
          res = JSON.parse(res);

          let subData;

          if (res.AndroidSubData?.Value || res.iOSSubData?.Value || res.OtherSubData?.Value) {
            setHasSubscribedOnOtherPlatform(true)
            subData = res.AndroidSubData?.Value ?? res.iOSSubData?.Value ?? res.OtherSubData?.Value
          }

          if (res.StripeSubData?.Value) {
            subData = res.StripeSubData?.Value
          }


          subData = subData ? JSON.parse(subData) : null
          console.log("Sub data: ", subData)

          setStripeSubData(subData)
          localStorage.setItem("subData", JSON.stringify(subData))

          setSelectedProduct(subData ? findProductFromId(subData.Product) : PRODUCTS[0])
          setSelectedPeriod(subData ? subData.SubscriptionPeriod : "monthly")
        } catch (e) {
          console.error(e)
        }
      })

  }, []);

  const onProductSubscribe = () => {
    const priceId =
      selectedPeriod === "monthly" ? selectedProduct.stripe_price_id_monthly
        : selectedProduct.stripe_price_id_yearly;

    changeSubscription(priceId)
  };

  return (
    <div className={"subscriptions-page"}>

      <Tabs
        activeKey={selectedPeriod}
        onSelect={(k) => setSelectedPeriod(k)}
        id={"subscription-tabs"}
        className={"subscription-tabs"}
        fill
        justify
      >

        <Tab tabClassName={"subscription-tab"} eventKey={"monthly"} title={(
          <span>Monthly <small>(Save up to 20%)</small></span>
        )
        }>
          <ProductCards products={PRODUCTS} period={"monthly"} selectedProduct={selectedProduct}
                        setSelectedProduct={setSelectedProduct} activeProduct={activeProduct}/>
        </Tab>


        <Tab tabClassName={"subscription-tab"} eventKey={"yearly"} title={(
          <span>Annually</span>
        )}>
          <ProductCards products={PRODUCTS} period={"yearly"} selectedProduct={selectedProduct}
                        setSelectedProduct={setSelectedProduct} activeProduct={activeProduct}/>
        </Tab>

      </Tabs>

      <div className={"subscription-actions"}>

        {hasSubscribedOnOtherPlatform && (
          <span className={"subscriptions-actions-wrong-platform-message"}>
            You have previously subscribed on another platform. Please visit that platform to manage your subscription.
          </span>
        )}

        <span>
          {selectedProduct ? (
            <>
              {selectedIsActive ? "Continue " : "Switch to "}
              {selectedProduct.name}
            </>

          ) : (
            <span> Loading... </span>
          )}

        </span>
        <button className={"btn_common"} disabled={!enableSubscribe}
                onClick={onProductSubscribe}>Subscribe now
        </button>

      </div>

    </div>
  );
};

export default NewSubscription;