import React, {useEffect, useState} from "react";
import "../../../../SCSS/style.css";
import "../../../../SCSS/fonts.css";
import PropTypes from "prop-types";
import {ReportsDoc} from "./EditProfile/Reports";
import {QRcodes} from "./EditProfile/QRcodes";
import {EditProfile} from "./EditProfile/EditProfile";
import {PasswordAndSecurity} from "./PasswordAndSecurity/PasswordAndSecurity";
// import {Subscription} from "./Subscription/Subscription";
// import { AcademicArea } from "./AcademicArea/AcademicAreaProfile";
// import {CheckForPremium} from "../../../../Utils/checkForPremium";
import NewSubscription from "./Subscription/new_Subscription";
import {CheckIfLoggedIn} from "../../../../Utils/CheckIfLoggedIn";
import {GetUserData} from "../../../../Utils/getUserData";

export const DisplayProfile = (props) => {
  const initialPath = props.initialPath?.toLowerCase();
  const editProfilePath = "editprofile";
  const passwordAndSecurityPath = "passwordandsecurity";
  const subscriptionPath = "subscription";
  const reportsPath = "reports";
  const QRcodespath = "qrcodes";
  // const academicAreaPath = 'academicarea'

  const [currentPath, setCurrentPath] = useState(initialPath ?? "editprofile");

  const [accessLevel, setAccessLevel] = useState();
  const displayReports = accessLevel === "admin" || accessLevel === "report"

  useEffect(() => {

    GetUserData(["AccessLevel"], (data) => {
      const userData = JSON.parse(data);
      setAccessLevel(userData.AccessLevel.Value);

    })
  }, []);

  const pathContent = (path) => {
    switch (path) {
      case "editprofile":
        return <EditProfile passwordReset={props.passwordReset}/>;
      case "passwordandsecurity":
        return <PasswordAndSecurity/>;
      case "reports":
        if (!displayReports) {
          window.location.href = "/profile/editprofile";
          return
        }
        return <ReportsDoc/>;
      case "subscription":
        return <NewSubscription/>;
      case "qrcodes":
        return <QRcodes/>;
      // case 'academicarea':
      //     setContentToDisplay(<AcademicArea />)
      //     break;
      default:
        return <EditProfile passwordReset={props.passwordReset}/>;
    }
  }

  const [contentToDisplay, setContentToDisplay] = useState(pathContent(currentPath));

  console.log("Checkif logged in")
  if (!CheckIfLoggedIn()) {
    console.log("Not logged in")

    window.location.href = "/login";

  }

  // const [premium, setPremium] = useState(false);

  // useEffect(() => {
  //   CheckForPremium(checkForPremiumCallback, "displayProfile");
  // }, []);

  // function checkForPremiumCallback(data) {
  //   setPremium(data);
  // }


  const handleNavClick = (path) => {
    path = path.toLowerCase();
    setCurrentPath(path);
    setContentToDisplay(pathContent(path));
  };

  return (
    <>
      <section className="container-fluid profile_page">
        <div className="row">
          <div className="col-md-3">
            <div className="left_settings">
              <ul>
                <li className={currentPath === editProfilePath ? "active" : ""}>
                  <button onClick={() => handleNavClick(editProfilePath)}>
                    <i className="fa fa-edit"></i>Account
                  </button>
                </li>
                <li className={currentPath === passwordAndSecurityPath ? "active" : ""}>
                  <button onClick={() => handleNavClick(passwordAndSecurityPath)}>
                    <i className="fa fa-lock"></i>Password & Security
                  </button>
                </li>
                <li className={currentPath === subscriptionPath ? "active" : ""}>
                  <button onClick={() => handleNavClick(subscriptionPath)}>
                    <i className="fa fa-tag"></i>
                    Subscription
                  </button>
                </li>
                {displayReports && (

                  <li className={currentPath === reportsPath ? "active" : ""}>
                    <button onClick={() => handleNavClick(reportsPath)}>
                      <i className="fa fa-file"></i>
                      Reports
                    </button>
                  </li>
                )}

                <li className={currentPath === QRcodespath ? "active" : ""}>
                  <button onClick={() => handleNavClick(QRcodespath)}>
                    <i className="fa fa-qrcode"></i>
                  Content
                  </button>
                </li>

                {/* <li
                                    className={
                                        currentPath === academicAreaPath
                                            ? "active"
                                            : ""
                                    }
                                >
                                    <button
                                        onClick={() =>
                                            handleNavClick(
                                                academicAreaPath
                                            )
                                        }
                                    >
                                        <i className="fa fa-graduation-cap"></i>Academic Area
                                    </button>
                                </li> */}
              </ul>
            </div>
          </div>

          <div className="col-md-9">
            <div className="right_profile">{contentToDisplay}</div>
          </div>
        </div>
      </section>
    </>
  );
};

DisplayProfile.propTypes = {
  passwordReset: PropTypes.bool,
  initialPath: PropTypes.string,
};
