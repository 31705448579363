
import { CheckIfLoggedIn } from "../../../../../../../../Utils/CheckIfLoggedIn";
import { GetUserData } from "../../../../../../../../Utils/getUserData";

var setGotUserData

export function getLessonUserData (topicId, gotUserData, setGotUserDataParam) {

    setGotUserData = setGotUserDataParam

    const loggedIn = CheckIfLoggedIn()

    if (loggedIn === false || loggedIn === undefined) {
        console.warn("user not logged in, cannot get data until logged in");
        return;
    }
    
    if (!gotUserData) {
        // get score / progress from playfab
        GetUserData(['"' + topicId + '"'], gotUserDataCallback);
    }
}

const gotUserDataCallback = (data) => {
    //console.log(data);
    let userData;
    try {
        userData = JSON.parse(data);
        if (userData !== undefined) {
            setGotUserData(true);
            return;
        }
    } catch (error) {
        console.log(error)
        setGotUserData(false)
        return
    }

    // within topic, get value,
    // within value get activity,
    // update progress when quiz question answered,
    // write quiz value back, send to playfab

    
};
