import { Decrypt } from "./Decrypt";

export function CheckIfLoggedIn (returnSessionTicket) {

    var sessionTicket = localStorage.getItem("sessionTicket");

    if (sessionTicket === undefined || sessionTicket === null) {
        const localStorageItem = localStorage.getItem(process.env.REACT_APP_SESSION_TICKET_CODE_NAME);
        if (localStorageItem !== undefined) {
            sessionTicket = Decrypt(localStorageItem)
        }
    }
    
    if (sessionTicket !== null || sessionTicket !== undefined) {
        if (checkSessionTicketValid() === true) {
            if (returnSessionTicket === true) {
                return sessionTicket
            }
            else {
                return true
            }
        }
        else {
            localStorage.clear()
        }
    }
    else {
        return false
    }
}

function checkSessionTicketValid () {
    if (localStorage.getItem("loginTime") !== null) {
        const loginDate = new Date(localStorage.getItem("loginTime"));
        const currentDate = new Date();

        const msBetweenDates = Math.abs(
            loginDate.getTime() - currentDate.getTime()
        );
        const hoursBetweenDates = msBetweenDates / (60 * 60 * 1000);

        if (hoursBetweenDates < 24) {
            /*console.log(
                "login was " + Math.round(hoursBetweenDates) + " hours ago"
            );*/
            return true
        } else {
            //console.log("login was NOT within 24 hours");
            return false
        }
    } else {
        //console.log("no login time found");
        return false
    }
}