
export const HandleValidation = (switchForm, form, callback = () => {}, errorsCallback = () => {}, uniSSO) => {
    let errors = {};
    let isFormValid = false;

    // --- Email ---
    const email = form.email;

    // must not contain spaces
    if (email.includes(" ")) {
        errors.email = "Invalid email address";
        console.log("validation fail")
    }

    // field is not empty
    if (!email) {
        errors.email = "Email cannot be empty";
        console.log("validation fail");
    }

    // contains @
    if (!email.includes('@')) {
        console.log(email)
        errors.email = "Invalid email address";
        console.log("validation fail");
    }

    // characters exist after @
    const emailAfterAt = email.split("@");
    if (emailAfterAt[1] !== undefined) {
        if (emailAfterAt[1].includes(".")) {
            const emailDomain = emailAfterAt[1].split(".");

            // beofre dot > 2 characters
            if (emailDomain[0] < 2) {
                errors.email = "Invalid email address";
        console.log("validation fail");
            }

            // after dor > 2 characters
            if (emailDomain[0] < 2) {
                errors.email = "Invalid email address";
        console.log("validation fail");
            }
        } 
        // contains a dot
        /*else {
            errors.email = "Invalid email address";
        console.log("validation fail");
        }*/
    } 
    // contains characters after @
    else {
        errors.email = "Invalid email address";
        console.log("validation fail");
    }


    function hasNumber(myString) {
        return /\d/.test(myString);
    }
    const password = form.password;

    if (uniSSO !== true) {
        // --- Password ---
    
        // at least one number
        if (!hasNumber(password)) {
            if (switchForm) {
                errors.password = "Invalid password"
            console.log("validation fail");
            }
            else {
                errors.password = "Password must contain at least one number";
            console.log("validation fail");
            }
        }
    
        // at least 8 characters
        if (password < 8) {
            if (switchForm) {
                errors.password = "Invalid password";
            console.log("validation fail");
            } else {
                errors.password = "Password must be at least 8 characters long";
            console.log("validation fail");
            }
        }
    
        // must not contains spaces
        if (password.includes(" ")) {
            if (switchForm) {
                errors.password = "Invalid password";
            console.log("validation fail");
            } else {
                errors.password = "Password cannot contain spaces";
            console.log("validation fail");
            }
        }
    
        // field is not empty
        if (!password) {
            errors.password = "Password cannot be empty";
            console.log("validation fail");
        }
    }

    // if signing up
    if (!switchForm) {
        // --- DisplayName ---
        const displayName = form.displayName;

        // must not contain spaces
        /*if (displayName.includes(" ")) {
            errors.displayName = "Display name cannot contain spaces";
        console.log("validation fail");
        }*/

        // maximum of 25 characters
        if (displayName > 25) {
            errors.displayName =
                "Display name cannot be more than 25 characters long";
        console.log("validation fail");
        }

        // minimum of 3 characters
        if (displayName < 3) {
            errors.displayName =
                "Display name must be at least 3 characters long";
        console.log("validation fail");
        }

        if (!displayName) {
            errors.displayName = "Display name cannot be empty";
        console.log("validation fail");
        }

        if (uniSSO !== true) {
            // --- ConfirmPassword ---
            const confirmPassword = form.confirmPassword;
    
            // field cannot be empty
            if (!confirmPassword) {
                errors.confirmPassword = "Confirm password cannot be empty";
            console.log("validation fail");
            }
    
            // passwords match
            if (password !== confirmPassword) {
                errors.password = "Passwords must match";
            console.log("validation fail");
            }
        }
    }

    if (!errors.email && !errors.password && !errors.displayName && !errors.confirmPassword) {
        isFormValid = true;
    }

    if (isFormValid) {
        callback(form);
    } else {
        errorsCallback(errors);
    }
};
