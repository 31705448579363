import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Septodont from "../../../../../images/septodont.png";
import availablePracticals from "../../../../InfoJSONs/availablePracticals.json";
import { GetInfoFromID } from "../../../../../Utils/getInfoFromID";
import { CheckForPremium } from "../../../../../Utils/checkForPremium";
import { GetUserData } from "../../../../../Utils/getUserData";
import { PracticalPopup } from "./PracticalPopup/PracticalPopup";
import { CheckIfLocked } from "../../../../../Utils/checkIfLocked";
import { firebaseCall } from "../../../../../Utils/firebaseAnalytics";

export const ExperienceCard = (props) => {
    const id = props.id;
    const available = availablePracticals.available;
    const experienceInfo = GetInfoFromID(id);
    var info
    var locked
    var type
    var comingSoon = false
    if (experienceInfo !== undefined) {
        info = experienceInfo.info;
        type = experienceInfo.type
        locked = experienceInfo.locked

        if (
            experienceInfo.info.id === "toolsOfTradeAnim_lesson" ||
            experienceInfo.info.id === "laTechniquesPart1_lesson" ||
            experienceInfo.info.id === "laTechniquesPart2_lesson" ||
            experienceInfo.info.id === "laProblemsHints_lesson"
        ) {
            comingSoon = false;
        }

        if (
            experienceInfo.info.id === "chronologyTimeline_lesson" ||
            experienceInfo.info.id === "extractionsMethodLessonAnim_lesson"
        ) {
            comingSoon = true;
        }
    }


    if (
        info !== undefined &&
        info.description !== undefined &&
        info.description.length !== 0
    ) {
        info.description = info.description.substring(0, 100) + "...";
    }

    const [progress, setProgress] = useState(<></>);
    const [offerToggle, setOfferToggle] = useState(true);
    const [premium, setPremium] = useState(false)

    useEffect(() => {
        if (type === "lesson") {
            GetUserData([id], gotUserDataCallback);
        }
        CheckForPremium(checkForPremiumCallback, "experienceCard");
    }, []);

    /*var monthlyPrice = localStorage.getItem("monthlyPrice")
    var yearlyPrice = localStorage.getItem("yearlyPrice")
    var monthlyPrevious = localStorage.getItem("monthlyPrevious")
    var yearlyPrevious = localStorage.getItem("yearlyPrevious")

    if (monthlyPrice === undefined || monthlyPrice === null) {
        monthlyPrice = "$7.49"
        yearlyPrice = "$59.99"
        monthlyPrevious = "$14.99"
        yearlyPrevious = "$119.99"
    }*/

    function checkForPremiumCallback(premiumParam) {
        // console.log({reason})
        setPremium(premiumParam)
        // console.log({premiumParam})
        if (premiumParam === true || premiumParam === "true" || CheckIfLocked(id, false) === false) {
            setOfferToggle(false)
        }
    }

    function gotUserDataCallback(data) {

        if (data !== undefined && data !== {}) {
            const currentScore = JSON.parse(data)[id].Value;
            const percentage = (100 * currentScore) / 5

            if (type !== 'practical') {
                setProgress(
                    <div className="progress">
                        <div
                            className="bar"
                            style={{ width: 0 + percentage + "%" }}
                        >
                            <p className="percent"></p>
                        </div>
                    </div>
                );
            }
        } 
    }

    function handleClick() {
        firebaseCall('lesson_navigation_open', {activity_id:id})
        //this stops it from redirecting to the practical page
        //need to add a check in here to see if its in the json
        if (type !== 'practical' && comingSoon === false || available.includes(id)) {
            console.log(type, comingSoon, available.includes(id))
        // if (type !== 'practical' && comingSoon === false) {
            props.displayContentCallback(id);
            window.history.replaceState(
                null,
                "",
                process.env.REACT_APP_CLIENT_URL + "/Experience/" + id
            );
        }
    }

    function handlePremiumClick() {
        firebaseCall('lesson_navigation_open', {activity_id:id})
        if (type !== 'practical' && (premium === true || premium === "true") && comingSoon === false || available.includes(id)) {
            props.displayContentCallback(id);
            window.history.replaceState(
                null,
                "",
                process.env.REACT_APP_CLIENT_URL + "/Experience/" + id
            );
        }
    }

    // function handleSponserClicked () {
    //     //window.location.href = "https://www.septodont.co.uk";
    // }

    return (
        <>
            {info !== undefined ? (
                <div
                    className="mb-4 col-md-4 col-xxl-3"
                    onClick={
                        
                        locked === false
                            ? () => handleClick()
                            : () => handlePremiumClick()                        

                    }
                    data-bs-toggle={offerToggle ? "modal" : ""}
                    data-bs-target={offerToggle ? "#offer_popup" : ""}
                >
                    <div
                        className={
                            locked === true &&
                            (premium === false || premium === "false")
                                ? "content_links premium_item"
                                : "content_links"
                        }
                    >
                        <div
                            className={
                                comingSoon === true
                                    ? "video_item cmng_sn"
                                    : "video_item"
                            }
                            data-bs-toggle={type === "practical" ? "modal" : ""}
                            data-bs-target={
                                type === "practical"
                                    ? "#premium_content" + id
                                    : ""
                            }
                        >
                            <span
                                className={
                                    "link_type " +
                                    (type === "lesson"
                                        ? "lesson"
                                        : type === "animated"
                                        ? "animated_less"
                                        : "exp")
                                }
                            >
                                {type=='practical'? 'Experience': type}
                            </span>

                            <div
                                className={
                                    comingSoon === true ? "d-block" : "d-none"
                                }
                            >
                                <div className="coming_soon">
                                    <span> Coming Soon</span>
                                </div>

                                <div className="launch_soon">
                                    <h5>This topic will be launching soon</h5>
                                </div>
                            </div>
                            <div className="act_icon">
                                <img
                                    src={
                                        "https://d1476tmm9qfop0.cloudfront.net/TestFiles/Icons/webp/px-" +
                                        info.iconLocation
                                            .slice(6)
                                            .slice(0, -4) +
                                        ".webp"
                                    }
                                    className="img-fluid"
                                    alt="Activity - Icon"
                                />
                            </div>

                            <h5 className="act_title">{info.title}</h5>
                            {id === "localAna_prac" ? (
                                <>
                                    <div className="sponsor_tag">
                                        <h5>
                                            Sponsored by
                                            <img
                                                src={Septodont}
                                                alt="Septodont"
                                            />
                                            <span>Septodont</span>
                                        </h5>
                                    </div>
                                </>
                            ) : (
                                <></>
                            )}
                            {progress}
                        </div>
                    </div>
                </div>
            ) : (
                <></>
            )}
            {/* this is what displays practical pop up */}
            {/* need to disable this for certain ids */}
            {/* {info !== undefined && type === "practical"? ( */}
            {info !== undefined && type === "practical" && !(available.includes(id))? (
                <PracticalPopup id={id} />
            ) : (
                <></>
            )}
        </>
    );
};

ExperienceCard.propTypes = {
    id: PropTypes.string,
    displayContentCallback: PropTypes.func,
};
