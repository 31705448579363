import React from "react";
import termsofservice from "../images/landing/termsofservice.svg";
import { Header } from "../Landing/Header/Header";
import { Footer } from "../Landing/Footer/Footer";

export const TermsOfService = () => {
    return (
        <>
        <Header link={true} />
        <section className="container privacy_p">
            <div className="row">
                <div className="col-md-7">
                    <div className="in_page_text">
                        <div className="main_head">
                         <h2>
                         Terms of Service<span className="end_not"></span>
                        </h2>
                       
                        </div>
                    </div>
                </div>
                <div className="col-md-5">
                <div className="in_page_img">
                    <img src={termsofservice} alt="privacy policy" />
                </div>
                </div>
            </div>
        </section>

            <section className="container privacy_p_content" id="page-content">
                <div className="row">
                    <div className="col-md-12">
                        <div className="clearfix">

                            <div className="t_blocks">
                                <h1>Introduction</h1>
                                <div className="prv_block">
                                    <h2>Who is immersify</h2>
                                    <p>Immersify is a leading provider of educational content and software solutions for higher education. Our platform offers a comprehensive range of learning tools including lessons quizzes flashcards and simulations designed to enhance the educational experience for students and institutions. Immersify’s unique platform provides a personalized feed of quality assured content and custom activities tailored to each student.</p>
                                </div>
                                <div className="prv_block">
                                    <h2>Legal entities</h2>
                                    <p>Immersify operates under the legal entity: Immersify Education Ltd registered in the United Kingdom and Immersify Ed in the United States. All references to &#34;Immersify&#34; &#34;we&#34; &#34;us&#34; or &#34;our&#34; in these Terms of Service pertain to the Topco Immersify Education Ltd.</p>
                                </div>
                                <div className="prv_block">
                                    <h2>The purpose of the TOS</h2>
                                    <p>These Terms of Service (&#34;TOS&#34;) govern your access to and use of the Immersify platform including any content functionality and services offered through Immersify Education&#34;s applications collectively known as (the &#34;Platform&#34;). By using the Platform you agree to comply with and be bound by these TOS. If you do not agree to these TOS you must not access or use the Platform.</p>
                                </div>
                                <div className="prv_block">
                                    <h2>Statement of agreement to the terms by using the platform</h2>
                                    <p>By accessing or using the Platform you acknowledge that you have read understood and agree to be bound by these TOS as well as our Privacy Policy which is incorporated herein by reference. These TOS apply to all users of the Platform including but not limited to students educators and administrators.</p>
                                </div>
                            </div>

                            <div className="t_blocks">
                                <h1>Overview of the Platform</h1>
                                <div className="prv_block">
                                    <h2>Brief introduction to Immersify</h2>
                                    <p>Immersify is an innovative educational platform designed to
                                        enhance learning and teaching experiences in higher education.
                                        Our platform provides a variety of educational tools and content
                                        including interactive lessons quizzes flashcards and simulations to support student learning and engagement.</p>
                                </div>
                                <div className="prv_block">
                                    <h2>Target audience</h2>
                                    <p>Immersify serves a diverse range of users including universities
                                        individual educators and students. Our platform is tailored to
                                        meet the needs of higher education institutions looking to
                                        supplement their curriculum individual educators seeking
                                        to enhance their teaching materials and students striving to improve their understanding and retention of course content.</p>
                                </div>
                                <div className="prv_block">
                                    <h2>The different platforms</h2>
                                    <p>Immersify offers its services across multiple platforms
                                        to ensure accessibility and convenience. Users can
                                        access our content through: web browser mobile and
                                        tablet applications. This multi-platform approach
                                        allows users to engage with our educational
                                        resources anytime anywhere providing flexibility and continuity in their learning experience.</p>
                                </div>
                            </div>

                            <div className="t_blocks">
                                <h1>Immersify Content</h1>
                                <div className="prv_block">
                                    <h2>Types of Activities</h2>
                                    <ul>
                                        <li>Lessons: Detailed and interactive lessons covering a wide range of subjects.</li>
                                        <li>Quizzes: Formative and summative assessments to test knowledge and reinforce learning.</li>
                                        <li>Flashcards: Study aids to help students memorize key concepts and terms.</li>
                                        <li>Simulations: Interactive simulations that provide practical hands-on learning experiences.</li>
                                    </ul>
                                </div>
                                <div className="prv_block">
                                    <h2>Content Localization</h2>
                                    <p>Immersify recognizes the importance of delivering content that
                                        is relevant to users based on their location of study.
                                        Our platform offers localized content to enhance the
                                        learning experience by providing region-specific
                                        information examples and resources. Users have
                                        the option to select their location of study
                                        during the account setup process or through
                                        their profile settings. This selection helps
                                        tailor the content to better meet the educational
                                        standards and requirements of the chosen region.</p>
                                    <p>Once a location of study is selected Immersify will
                                        deliver content that aligns with the educational
                                        curriculum and is mapped to the standards of that region.</p>
                                    <p>It is the user&#34;s responsibility to select the correct
                                        location of study to ensure they receive the most
                                        relevant content. Users can update their location
                                        of study through their profile settings if they
                                        change their region or if they find the content is
                                        not aligned with their current studies. The content
                                        available to users may vary significantly based on
                                        their selected location of study. By choosing a
                                        different location users may gain access to
                                        different materials that are better suited to
                                        their educational needs and regional requirements.
                                        Immersify continually works to improve the relevance
                                        and quality of localized content. Users are encouraged
                                        to provide feedback on the localized materials to help us
                                        enhance the learning experience further. Feedback can be
                                        submitted through designated channels on the platform.
                                        Immersify strives to ensure that all localized content
                                        complies with the educational standards and regulations
                                        of the respective regions. We work closely with local
                                        educators and experts to keep our content accurate and
                                        up-to-date. Localized content is subject to periodic
                                        reviews and updates to reflect changes in regional
                                        educational standards curriculum updates and user feedback.
                                        Users will be notified of significant updates to localized content
                                        through the platform&#34;s notification system.</p>
                                </div>
                            </div>

                            <div className="t_blocks">
                                <h1>QA process </h1>
                                <p>
                                    At Immersify, quality is of the highest importance. We ensure the accuracy, relevance,
                                    and educational value of our content through a meticulous Quality Assurance (QA) process
                                    that involves multiple stages and extensive expert collaboration:
                                </p>
                                <div className="prv_block">
                                    <h2>Roadmap and Standards Mapping </h2>
                                    <p>
                                        The initial stage involves developing a content roadmap that outlines learning objectives
                                        and maps the content to educational standards.
                                        This roadmap ensures that the content is aligned with the curriculum requirements and learning goals.
                                    </p>
                                </div>
                                <div className="prv_block">
                                    <h2>Initial Structure QA </h2>
                                    <p>
                                        The structure of the content is created based
                                        on the roadmap and undergoes a rigorous QA
                                        process to ensure it aligns with educational standards and user needs.
                                    </p>
                                </div>

                                <div className="prv_block">
                                    <h2>Content Creation</h2>
                                    <p>Once the structure is approved, subject matter experts create the content aligned to each subheading using credible sources for reference when appropriate.</p>
                                </div>
                                <div className="prv_block">
                                    <h2>Content QA</h2>
                                    <p>The created content undergoes a detailed QA process to verify its accuracy, completeness, and educational value. This involves multiple reviews by experts and educators. A list of these experts can be found on our website.</p>
                                </div>
                                <div className="prv_block">
                                    <h2>Visuals Creation and QA</h2>
                                    <p>Visual elements such as diagrams, illustrations, and videos are developed to complement the content. These visuals are then subjected to a thorough QA process to ensure they are clear, accurate, and effective in enhancing learning.</p>
                                </div>
                                <div className="prv_block">
                                    <h2>Questions Creation and QA</h2>
                                    <p>Following the content and visuals, questions are created to test comprehension and reinforce learning. These questions undergo a comprehensive QA process to ensure they are clear, relevant, and aligned with the content.</p>
                                </div>
                                <div className="prv_block">
                                    <h2>Collaborative Review</h2>
                                    <p>Our content has been reviewed by multiple independent universities to ensure it meets high educational standards. Each lesson contains information about the authors and QA reviewers at the start.</p>
                                </div>
                                <div className="prv_block">
                                    <h2>Continuous Monitoring and Updates</h2>
                                    <p>After publication, content is continuously monitored for accuracy and relevance. User feedback and changing educational standards are regularly reviewed to keep content up-to-date. Significant updates are communicated to users through the platform&apos;s notification system.</p>
                                </div>
                                <div className="prv_block">
                                    <h2>Content Availability and Access</h2>
                                    <p>Immersify aims to provide users with seamless access to high-quality educational content 24 hours a day, 7 days a week. Our platform is accessible through various devices, including desktops, laptops, tablets, and smartphones, ensuring flexibility and convenience for users to engage with educational resources anytime, anywhere. Access to certain content and features may be restricted based on the user&apos;s subscription plan, and a stable internet connection is required for initial content download, updates, and interactive features. While some features may be available offline, an active internet connection is essential for optimal performance. Periodic maintenance and updates may be necessary to enhance the platform&apos;s functionality and content, and users will be notified in advance of any planned maintenance that may temporarily affect content availability. In certain circumstances, access to content may be temporarily restricted due to technical issues, legal requirements, or other unforeseen events, and Immersify will work diligently to resolve any access issues promptly. Users are responsible for ensuring they have the necessary hardware, software, and internet connectivity to access Immersify&apos;s content, and any issues related to personal device compatibility or internet connectivity are the user&apos;s responsibility to resolve.</p>
                                </div>
                                <div className="prv_block">
                                    <h2>Content Updates and Revisions</h2>
                                    <p>Immersify is committed to providing the most current and relevant educational content to our users. To ensure this, we regularly update and revise our content based on the latest information, educational standards, and user feedback. Immersify conducts regular reviews and updates of content with the aim of ensuring that users have access to the most up-to-date information and learning materials. This includes reflecting new developments, research, and educational standards. Significant updates or changes to content are communicated to users through the platform&apos;s notification system or via email, keeping users informed about important revisions that may impact their learning experience. Our content is reviewed by multiple universities and subject matter experts to ensure it meets high educational standards. Collaborative input from these experts helps us maintain the quality and accuracy of our materials. When content is updated, it is automatically made available to users without any need for manual intervention, ensuring that users always have access to the latest version of the materials. Immersify is dedicated to the continuous improvement of our content. By integrating the latest educational practices, technologies, and user insights, we strive to enhance the learning experience for all users.</p>
                                </div>
                                <div className="prv_block">
                                    <h2>User Feedback and Contributions</h2>
                                    <p>Immersify highly values user feedback and contributions as they are essential for the continuous improvement and relevance of our educational content. Users are encouraged to provide feedback on their learning experience and the content provided through the platform settings or directly via email at info@immersifyeducation.com or support@immersifyeducation.com. Additionally, users are invited to contribute content, such as supplementary materials, lesson ideas, or additional resources. While all feedback and contributions are carefully reviewed by our content team and may be incorporated into the platform, we are unable to individually acknowledge or credit contributions without a signed consultancy agreement in place, similar to those in place with our Quality assurance reviewers and consultants. All intellectual property rights related to user feedback and contributions remain with Immersify.</p>
                                </div>
                                <div className="prv_block">
                                    <h2>Ownership of Content</h2>
                                    <p>Immersify owns all the content on the platform unless otherwise explicitly specified. This includes lessons, quizzes, flashcards, simulations, and all other educational materials provided. While we collaborate with third-party content providers, such as Oxford University Press, the content from these providers is modified and enhanced to fit our platform&apos;s unique style and requirements. We transform this third-party content by adding visual elements, questions, and simulations—a process we refer to as &quot;Immersifying&quot; content. As a result, even third-party content on our platform is significantly altered to provide a more engaging and interactive learning experience.</p>
                                </div>

                                <div className="prv_block">
                                    <h2>Content Usage Terms</h2>
                                    <p>Users are granted a limited, non-exclusive license to access and use Immersify&apos;s content for personal educational purposes only. The following terms apply to the use of our content:</p>
                                    <ul>

                                        <li><strong>Personal Use Only</strong> - Users may use the content solely for their own personal educational purposes. Any commercial use of the content is strictly prohibited without prior written consent from Immersify.</li>
                                        <li><strong>No Redistribution</strong> - Users are not allowed to redistribute, sell, lease, or sublicense any part of the content. Sharing content with others, either in its original form or modified, is not permitted.</li>
                                        <li><strong>No Derivative Works</strong> - Users may not create derivative works based on the content without explicit permission from Immersify. This includes but is not limited to translating, adapting, or creating summaries of the content.</li>
                                        <li><strong>Respect for Intellectual Property</strong> - Users must respect all intellectual property rights associated with the content. Any unauthorized use of Immersify&apos;s content or violation of these terms may result in legal action.</li>
                                        <li><strong>Compliance with Platform Policies</strong> - Users must comply with all platform policies and guidelines when using Immersify&apos;s content. This includes adhering to the user responsibilities and conduct guidelines outlined in our Terms of Service.</li>
                                        <li><strong>Limited License</strong> - The license to use Immersify&apos;s content is limited to the duration of the user&apos;s subscription or access period. Upon termination or expiration of the subscription, users must cease all use of the content.</li>
                                    </ul>
                                </div>


                                <div className="prv_block">
                                    <h2>Intellectual Property</h2>
                                    <p>Immersify retains all intellectual property rights to the content provided on the platform, including lessons, quizzes, flashcards, simulations, and other educational materials, unless otherwise explicitly specified. All, logos, icons and other assets displayed on the platform are the property of Immersify. Users are not permitted to use these assets without prior written consent from Immersify. Immersify actively enforces its intellectual property rights, and any unauthorized use may result in legal action, including but not limited to seeking injunctive relief, damages, and legal fees.</p>
                                </div>
                                <div className="prv_block">
                                    <h2>Content Removal Policy</h2>
                                    <p>Immersify reserves the right to remove content from the platform solely at our discretion for any reason we determine necessary. This may include, but is not limited to, if inaccuracies are found, for maintenance and updates, to comply with legal requirements, to resolve technical issues, if it no longer meets the quality standards set by Immersify, or if it becomes outdated or irrelevant to the current educational standards or curriculum.</p>
                                </div>
                                <div className="prv_block">
                                    <h2>Content Disclaimer</h2>
                                    <p>While we strive to ensure that all content on the Immersify platform is accurate and up-to-date and follow the aforementioned, rigorous QA process, we do not warrant the completeness or accuracy of the content. Since content varies between locations users are encouraged to use their judgment, verify information independently and seek professional guidance.</p>
                                </div>
                            </div>
                            
                            <div className="t_blocks">
                                <h1>Account Creation and User Information </h1>

                                <div className="prv_block">
                                    <h2>Content Creation</h2>
                                    <p>Once the structure is approved, subject matter experts create the content aligned to each subheading using credible sources for reference when appropriate.</p>
                                </div>
                                <div className="prv_block">
                                    <h2>Content QA</h2>
                                    <p>The created content undergoes a detailed QA process to verify its accuracy, completeness, and educational value. This involves multiple reviews by experts and educators. A list of these experts can be found on our website.</p>
                                </div>
                                <div className="prv_block">
                                    <h2>Visuals Creation and QA</h2>
                                    <p>Visual elements such as diagrams, illustrations, and videos are developed to complement the content. These visuals are then subjected to a thorough QA process to ensure they are clear, accurate, and effective in enhancing learning.</p>
                                </div>
                                <div className="prv_block">
                                    <h2>Questions Creation and QA</h2>
                                    <p>Following the content and visuals, questions are created to test comprehension and reinforce learning. These questions undergo a comprehensive QA process to ensure they are clear, relevant, and aligned with the content.</p>
                                </div>
                                <div className="prv_block">
                                    <h2>Collaborative Review</h2>
                                    <p>Our content has been reviewed by multiple independent universities to ensure it meets high educational standards. Each lesson contains information about the authors and QA reviewers at the start.</p>
                                </div>
                                <div className="prv_block">
                                    <h2>Continuous Monitoring and Updates</h2>
                                    <p>After publication, content is continuously monitored for accuracy and relevance. User feedback and changing educational standards are regularly reviewed to keep content up-to-date. Significant updates are communicated to users through the platform’s notification system.</p>
                                </div>
                                <div className="prv_block">
                                    <h2>Content Availability and Access</h2>
                                    <p>Immersify aims to provide users with seamless access to high-quality educational content 24 hours a day, 7 days a week. Our platform is accessible through various devices, including desktops, laptops, tablets, and smartphones, ensuring flexibility and convenience for users to engage with educational resources anytime, anywhere. Access to certain content and features may be restricted based on the user’s subscription plan, and a stable internet connection is required for initial content download, updates, and interactive features. While some features may be available offline, an active internet connection is essential for optimal performance. Periodic maintenance and updates may be necessary to enhance the platform’s functionality and content, and users will be notified in advance of any planned maintenance that may temporarily affect content availability. In certain circumstances, access to content may be temporarily restricted due to technical issues, legal requirements, or other unforeseen events, and Immersify will work diligently to resolve any access issues promptly. Users are responsible for ensuring they have the necessary hardware, software, and internet connectivity to access Immersify’s content, and any issues related to personal device compatibility or internet connectivity are the user’s responsibility to resolve.</p>
                                </div>
                                <div className="prv_block">
                                    <h2>Content Updates and Revisions</h2>
                                    <p>Immersify is committed to providing the most current and relevant educational content to our users. To ensure this, we regularly update and revise our content based on the latest information, educational standards, and user feedback. Immersify conducts regular reviews and updates of content with the aim of ensuring that users have access to the most up-to-date information and learning materials. This includes reflecting new developments, research, and educational standards. Significant updates or changes to content are communicated to users through the platform’s notification system or via email, keeping users informed about important revisions that may impact their learning experience. Our content is reviewed by multiple universities and subject matter experts to ensure it meets high educational standards. Collaborative input from these experts helps us maintain the quality and accuracy of our materials. When content is updated, it is automatically made available to users without any need for manual intervention, ensuring that users always have access to the latest version of the materials. Immersify is dedicated to the continuous improvement of our content. By integrating the latest educational practices, technologies, and user insights, we strive to enhance the learning experience for all users.</p>
                                </div>
                                <div className="prv_block">
                                    <h2>User Feedback and Contributions</h2>
                                    <p>Immersify highly values user feedback and contributions as they are essential for the continuous improvement and relevance of our educational content. Users are encouraged to provide feedback on their learning experience and the content provided through the platform settings or directly via email at info@immersifyeducation.com or support@immersifyeducation.com. Additionally, users are invited to contribute content, such as supplementary materials, lesson ideas, or additional resources. While all feedback and contributions are carefully reviewed by our content team and may be incorporated into the platform, we are unable to individually acknowledge or credit contributions without a signed consultancy agreement in place, similar to those in place with our Quality assurance reviewers and consultants. All intellectual property rights related to user feedback and contributions remain with Immersify.</p>
                                </div>
                                <div className="prv_block">
                                    <h2>Ownership of Content</h2>
                                    <p>Immersify owns all the content on the platform unless otherwise explicitly specified. This includes lessons, quizzes, flashcards, simulations, and all other educational materials provided. While we collaborate with third-party content providers, such as Oxford University Press, the content from these providers is modified and enhanced to fit our platform’s unique style and requirements. We transform this third-party content by adding visual elements, questions, and simulations—a process we refer to as &quot;Immersifying&quot; content. As a result, even third-party content on our platform is significantly altered to provide a more engaging and interactive learning experience.</p>
                                </div>
                                <div className="prv_block">
                                    <h2>Grounds for Termination or Suspension</h2>
                                    <p>
                                        Immersify reserves the right to terminate or suspend user accounts under certain conditions
                                        to maintain the security and integrity of the platform. Grounds for termination or suspension include, but are not limited to, the following:
                                    </p>
                                    <ul>
                                        <li><strong>Violation of Terms of Service</strong>
                                            <ul>
                                                <li>Any breach of Immersify&apos;s Terms of Service, including engaging in prohibited activities such as cheating, harassment, or spamming.</li>
                                            </ul>
                                        </li>
                                        <li><strong>Providing False Information</strong>
                                            <ul>
                                                <li>Providing inaccurate, incomplete, or misleading information during the account registration process or at any other time.</li>
                                            </ul>
                                        </li>
                                        <li><strong>Account Sharing</strong>
                                            <ul>
                                                <li>Sharing accounts with other individuals or creating multiple accounts for the same person.</li>
                                            </ul>
                                        </li>
                                        <li><strong>Unauthorized Access</strong>
                                            <ul>
                                                <li>Attempting to access or use another user&apos;s account without permission.</li>
                                            </ul>
                                        </li>
                                        <li><strong>Inappropriate Conduct</strong>
                                            <ul>
                                                <li>Engaging in behaviour that is deemed inappropriate, offensive, or harmful to other users or the platform&apos;s community.</li>
                                            </ul>
                                        </li>
                                        <li><strong>Legal Compliance</strong>
                                            <ul>
                                                <li>Engaging in activities that violate applicable laws or regulations.</li>
                                            </ul>
                                        </li>
                                        <li><strong>Security Risks</strong>
                                            <ul>
                                                <li>Activities that compromise the security or integrity of the platform, such as hacking or distributing malware.</li>
                                            </ul>
                                        </li>
                                    </ul>
                                    <p>
                                        Users whose accounts are terminated or suspended will be notified via email. Immersify reserves the right to
                                        determine the appropriate course of action, including permanent termination or temporary suspension,
                                        based on the severity of the violation. Users may contact Immersify support for further assistance or to appeal a suspension or termination decision.
                                    </p>
                                </div>
                            </div>
                            
                            <div className="t_blocks">
                                <h1>Platform Personalization, Progress and content feed </h1>
                                <div className="prv_block">
                                    <h2>Introduction</h2>
                                    <p>Immersify’s platform is designed to provide a tailored educational experience for each individual user. Personalization begins with the data collected during the account creation process, including the user’s location of study, year of study, and specific educational goals. This information helps Immersify customize the content and learning pathways to meet the unique needs of each user.</p>
                                    <p>As users interact with the platform, their engagement with lessons, quizzes, flashcards, and simulations is analysed. This data is used to identify learning patterns, strengths, and areas that may require additional focus. Based on this analysis, the platform adapts by recommending relevant content, delivering custom activities and adjusting the difficulty level of activities to enhance the learning experience.</p>
                                    <p>Progress tracking features further support personalization by allowing users to set goals and receive reminders and updates on their progress.</p>
                                    <p>Immersify incorporates user feedback to refine and improve the personalization algorithms, ensuring that each user’s educational journey is as effective and engaging as possible. By leveraging advanced data analytics and user insights, Immersify delivers a personalized learning experience that supports individual growth and achievement.</p>
                                </div>
                                <div className="prv_block">
                                    <h2>The platform feed</h2>
                                    <p>The platform feed on Immersify is designed to deliver a personalized and dynamic learning experience by providing users with daily activities tailored to their set learning goals. Each day, the feed generates a specific number of activities, including lessons, quizzes, flashcards, and simulations, that align with the user&apos;s educational objectives and progress.</p>
                                    <p>These daily activities are selected based on data collected from the user&apos;s interactions, preferences, and progress, with the aim of ensuring that the content is relevant and appropriately challenging. By focusing on the user&apos;s unique learning journey, the platform feed helps users stay on track with their goals, reinforces previously learned concepts, and introduces new material at a suitable pace.</p>
                                </div>
                                <div className="prv_block">
                                    <h2>Options for users to customize their dashboard and learning environment</h2>
                                    <p>Immersify provides a range of customization options for the dashboard and in activities to support neurodivergence and differing learning styles. Users can switch to dark-mode to reduce eye strain, enable or disable sound effects for accessibility, and adjust font size for better readability. Background color can be changed to create a visually comfortable space, and users can control the volume and speed of narrated content to suit their auditory needs and learning pace. Immersify is committed to continually adding new features to enhance the user experience and accommodate diverse learning preferences.</p>
                                </div>
                                <div className="prv_block">
                                    <h2>Personalization settings</h2>
                                    <p>Users can adjust their personalization settings both globally and within specific activities. In the global settings, users can configure preferences. Users have the flexibility to modify these settings within individual activities to ensure an optimal learning experience tailored to their immediate needs.</p>
                                </div>
                                <div className="prv_block">
                                    <h2>Personalized experience feedback</h2>
                                    <p>Immersify actively encourages users to provide feedback on their personalized learning experience. Users can share their thoughts and suggestions through designated feedback channels within the platform or by contacting support directly. This feedback is invaluable in helping us understand user needs, identify areas for improvement, and enhance the overall personalization features of the platform. By incorporating user feedback, Immersify continually strives to improve and tailor the educational experience to better meet individual learning preferences and needs.</p>
                                </div>
                            </div>

                            <div className="t_blocks">
                                <h1>User Responsibilities and Conduct </h1>
                                <div className="prv_block">
                                    <h2>Expectations for user behaviour on the platform. </h2>
                                    <p>Immersify expects all users to conduct themselves in a respectful and responsible manner
                                        while using the platform. They should use the platform and its resources
                                        appropriately for educational purposes only, maintaining the confidentiality
                                        and security of their account credentials. Users must comply with all
                                        applicable laws and regulations and promptly report any suspicious activities,
                                        security breaches, or inappropriate content to Immersify support. By adhering
                                        to these expectations, users contribute to a positive
                                        and productive learning environment. Failure to comply with these responsibilities
                                        may result in disciplinary action, including account suspension or termination.
                                    </p>
                                </div>

                                <div className="prv_block">
                                    <h2>Guidelines for user-generated content (display names) </h2>
                                    <p>Immersify encourages users to create and use appropriate
                                        display names that foster a positive and respectful
                                        learning environment. Display names should not contain
                                        offensive, discriminatory, or inappropriate language.
                                        Users are expected to avoid impersonating others,
                                        including other users, educators, or public figures,
                                        and should not include any personal information that
                                        could compromise their privacy or security. Immersify
                                        reserves the right to review and, if necessary, request changes to display names that violate these guidelines. Adhering to these guidelines helps maintain a welcoming and safe community for all users.
                                    </p>
                                </div>
                            </div>

                            <div className="t_blocks">
                                <h1>Subscription and Payment Terms </h1>

                                <div className="prv_block">
                                    <h2>Description of different pricing models</h2>
                                    <p>Immersify offers a variety of pricing models to accommodate different user needs and preferences. Each tier allows for a different number of daily activities and active topics, and these numbers are subject to change. The tiers include Free, Bronze, Silver, Gold, and Institutional. For detailed information on the benefits and specifics of each tier, users can refer to the shop page. </p>

                                    <p>Any paid user can access their library of content to replay old activities. However, cancelling a subscription will revoke library access. Additional features may be added at each tier to enhance the user experience and provide greater value. 
                                    </p>
                                </div>

                                <div className="prv_block">
                                    <h2>Library feature </h2>
                                    <p>
                                    The library feature on Immersify provides users with access to a comprehensive collection of revision activities and a content hub. This feature allows users to revisit and replay all previously accessed activities, enabling continuous review and reinforcement of learned material. The library helps users consolidate their knowledge and track their progress over time. Note that access to the library is available to paid users, and cancelling a subscription will revoke access to this feature. 
                                    </p>
                                </div>

                                <div className="prv_block">
                                    <h2>Accepted Payment Methods </h2>
                                    <p>
                                    Immersify accepts a variety of payment methods to ensure a seamless and convenient user experience. Payments can be made using any method accepted by Stripe, Google Play Store, or Apple App Store. This includes major credit and debit cards, digital wallets, and other region-specific payment options supported by these platforms. 
                                    </p>
                                </div>

                                <div className="prv_block">
                                    <h2>Billing cycles and frequency of charges. </h2>
                                    <p>
                                    Immersify offers flexible billing cycles to accommodate different user preferences. Users can choose between monthly and annual subscription options. Monthly subscriptions are billed on a recurring monthly basis, while annual subscriptions are billed once per year. Users will be charged according to their selected billing cycle, and the subscription will automatically renew at the end of each period unless cancelled. 
                                    </p>
                                </div>

                                <div className="prv_block">
                                    <h2>Refund Policy  </h2>
                                    <p>
                                    Immersify strives to provide a satisfying learning experience for all users. If you are not completely satisfied with your subscription, you may be eligible for a refund under the following conditions: 
                                    </p>
                                    <ul>
                                    <li><strong>Request Within 7 Days</strong>
                                        <ul>
                                        <li>Refund requests must be submitted within 7 days of the initial purchase or renewal date. Refunds will not be issued for requests made after this period.</li>
                                        </ul>
                                    </li>
                                    <li><strong>Usage Limitation</strong>
                                        <ul>
                                        <li>Refunds are only available if the user has not extensively used the platform&apos;s content during the refund period. Extensive use is defined as completing more than 20 activities during the subscription period.</li>
                                        </ul>
                                    </li>
                                    <li><strong>Contact Support</strong>
                                        <ul>
                                        <li>To request a refund, users must contact Immersify support at support@immersifyeducation.com with their account details and reason for the refund request. Our support team will review the request and process the refund if it meets the eligibility criteria.</li>
                                        </ul>
                                    </li>
                                    </ul>
                                    <p>
                                    Please note that refunds may take several business days to process and appear in your account. Immersify reserves the right to deny refund requests that do not comply with the policy conditions. 
                                    </p>
                                </div>
                                <div className="prv_block">
                                    <h2>Automatic Renewal  </h2>
                                    <p>
                                    All Immersify subscriptions are set to automatically renew at the end of the billing cycle to ensure uninterrupted access to the platform&apos;s content and features. Users will be charged according to their selected billing cycle (monthly or annual) unless the subscription is cancelled prior to the renewal date. </p>

                                    <p>Please note that cancelling your subscription will prevent future charges but will not provide a refund for the remaining period of the current subscription. You will continue to have access to the platform until the end of the current billing cycle. 
                                    </p>
                                </div>

                                <div className="prv_block">
                                    <h2>Promotions and Discounts </h2>
                                    <p>
                                    Immersify occasionally offers promotions and discounts to provide users with opportunities to access premium features at reduced rates. These promotions may include limited-time discounts, special offers for new users, or seasonal sales. Eligibility criteria for promotions and discounts may vary depending on the specific offer, and promotional codes or discounts must be applied at the time of purchase. They cannot be applied retroactively to previous purchases or combined with other offers unless explicitly stated. Multiple offers cannot be used in conjunction with one another. Each promotion or discount will have specific validity periods and terms. Refunds for subscriptions purchased with a promotional discount will be processed according to the Refund Policy, with the refunded amount reflecting the discounted purchase price. Immersify reserves the right to modify or discontinue promotions and discounts at any time. For the latest information on current promotions and discounts, users can visit the shop page or contact Immersify support. 
                                    </p>

                                    
                                </div>

                                <div className="prv_block">
                                    <h2>Payment Issues and Resolution</h2>
                                    <p>
                                    Immersify is committed to providing a seamless payment experience for all users. In the event of any payment issues, such as failed transactions, incorrect charges, or other billing problems, users should take the following steps for resolution: 
                                    </p>
                                    <ul>
                                    <li><strong>Check Payment Details</strong>
                                        <ul>
                                        <li>Ensure that all payment details entered are accurate, including credit card information, billing address, and any relevant promotional codes.</li>
                                        </ul>
                                    </li>
                                    <li><strong>Contact Payment Provider</strong>
                                        <ul>
                                        <li>If the issue persists, users should contact their bank or payment provider to verify that there are no holds or issues with the payment method being used.</li>
                                        </ul>
                                    </li>
                                    <li><strong>Contact Immersify Support</strong>
                                        <ul>
                                        <li>For further assistance, users can contact Immersify support at support@immersifyeducation.com with details of the payment issue. Our support team will investigate the problem and work to resolve it as quickly as possible.</li>
                                        </ul>
                                    </li>
                                    <li><strong>Documentation</strong>
                                        <ul>
                                        <li>Users may be asked to provide documentation or screenshots of the payment issue to help facilitate the resolution process.</li>
                                        </ul>
                                    </li>
                                    </ul>
                                    <p>
                                    Immersify is dedicated to resolving payment issues promptly to ensure uninterrupted access to our platform. Please note that resolving payment issues may take several business days, depending on the nature of the problem and the cooperation of the payment provider. 
                                    </p>
                                </div>

                                
                                <div className="prv_block">
                                    <h2>Upgrade and Downgrade Policy </h2>
                                    <p>
                                    Immersify provides flexible options for users to upgrade or downgrade their subscription tiers according to their needs. When upgrading to a higher tier, a pro-rata payment will be taken to cover the difference for the remainder of the current billing cycle. This ensures immediate access to the additional features and benefits of the higher tier. Conversely, when downgrading to a lower tier, the change will take effect at the end of the current billing cycle. Users will continue to have access to the features of their current tier until the cycle concludes. This policy ensures a smooth transition between tiers while maintaining uninterrupted access to the platform&apos;s content and features. 
                                    </p>
                                </div>

                                <div className="prv_block">
                                    <h2>Taxes and Fees </h2>
                                    <p>
                                    All subscription fees for Immersify services are inclusive of any applicable taxes. The price displayed at the time of purchase includes all necessary taxes, such as sales tax, value-added tax (VAT), or other similar charges. Users are not required to pay any additional taxes on top of the listed subscription price. However, any additional fees imposed by payment providers, such as transaction fees or currency conversion fees, are the responsibility of the user. Immersify ensures transparency by displaying the total amount due, inclusive of taxes, at the time of payment. Users are advised to review the final amount before completing their purchase to ensure they understand the total cost of their subscription. 
                                    </p>
                                </div>
                            </div>

                            <div className="t_blocks">
                                <h1>Disclaimers and Limitation of Liability </h1>
                                
                                <div className="prv_block">
                                <h2>Use of Third-Party Services </h2>
                                    <p>Immersify integrates with various third-party services to enhance the user experience and provide additional functionality. These services may include, but are not limited to, payment processors, analytics providers, and content delivery networks. While Immersify selects reputable third-party providers, we do not control their actions and are not responsible for their performance or reliability. Users interacting with these third-party services through the Immersify platform do so at their own risk and must comply with the terms and conditions of these third-party providers. The use of third-party services is governed by the respective privacy policies and terms of service of those providers, which may differ from Immersify’s policies. For more details on how third-party services handle user data, please refer to our Privacy Policy. </p>
                                </div>

                                <div className="prv_block">
                                <h2>Limitation of liability for errors or omissions in content.  </h2>
                                    <p>While Immersify strives to ensure the accuracy and reliability of the content provided on the platform, errors or omissions may occur. Immersify does not warrant the completeness, accuracy, or usefulness of the information provided. Users acknowledge that any reliance on such content is at their own risk. To the fullest extent permitted by law, Immersify, its affiliates, and their respective employees, agents, officers, and directors will not be liable for any errors or omissions in the content, or for any loss or damage of any kind incurred as a result of the use of any content made available on the platform. Users are encouraged to verify any information before relying on it for personal, educational, or professional purposes. </p>
                                </div>
                               
                                <div className="prv_block">
                                <h2>Specific results or outcomes from using the platform.</h2>
                                    <p>Immersify aims to provide high-quality educational content and resources to enhance learning experiences. However, Immersify does not guarantee specific results or outcomes from using the platform. Individual learning outcomes can vary based on a variety of factors, including but not limited to the user’s engagement, study habits, prior knowledge, and external influences. Users acknowledge that their success depends on their own efforts and circumstances, and Immersify is not responsible for any failure to achieve desired results or educational goals. The platform is intended to support and supplement learning, but it is not a substitute for individual effort and commitment. </p>
                                </div>

                                <div className="prv_block">
                                <h2>Limitations on Immersify’s liability for damages.</h2>
                                    <p>To the fullest extent permitted by law, Immersify, its affiliates, and their respective employees, agents, officers, and directors shall not be liable for any indirect, incidental, special, consequential, or punitive damages, including but not limited to loss of profits, data, use, goodwill, or other intangible losses, resulting from (i) your access to or use of or inability to access or use the platform; (ii) any conduct or content of any third party on the platform; (iii) any content obtained from the platform; and (iv) unauthorized access, use, or alteration of your transmissions or content. In no event shall Immersify’s total liability to you for all claims arising out of or related to the use of the platform exceed the amount paid by you, if any, for accessing the platform during the twelve (12) months preceding the event giving rise to such liability. These limitations of liability apply whether the claims are based on warranty, contract, tort, or any other legal theory, and whether or not Immersify has been informed of the possibility of such damages. </p>
                                </div>

                                <div className="prv_block">
                                <h2>User agreement to indemnify Immersify against certain types of claims. </h2>
                                    <p>By using the Immersify platform, users agree to indemnify, defend, and hold harmless Immersify, its affiliates, and their respective employees, agents, officers, and directors from and against any and all claims, damages, obligations, losses, liabilities, costs, or debt, and expenses (including but not limited to attorney’s fees) arising from: (i) user’s use of and access to the platform; (ii) user’s violation of any term of these Terms of Service; (iii) user’s violation of any third-party right, including without limitation any intellectual property, privacy, or other proprietary right; or (iv) any claim that user’s content caused damage to a third party. This defence and indemnification obligation will survive the termination, modification, or expiration of these Terms of Service and the user’s use of the platform. </p>
                                </div>
                            </div>

                            <div className="t_blocks">
                                <h1>Governing Law and Dispute Resolution</h1>
                                <div className="prv_block">
                                    <h2>Jurisdiction governing the Terms of Service. </h2>
                                    <p>These Terms of Service and any disputes arising out of or related to them or the Immersify platform shall be governed by and construed in accordance with the laws of the United Kingdom, without regard to its conflict of law principles. By using the platform, users agree that any legal actions or proceedings arising out of or related to these Terms of Service shall be brought exclusively in the courts located in the United Kingdom, and users hereby consent to the jurisdiction and venue of such courts. </p>
                                </div>
                                <div className="prv_block">
                                    <h2>Methods for resolving disputes. </h2>
                                    <p>
                                        In the event of any disputes arising out of or relating to these Terms of Service or the use of the Immersify platform, the parties agree to first attempt to resolve the dispute informally by contacting Immersify support. If the dispute cannot be resolved informally, the parties agree to resolve any claims through binding arbitration or mediation, as chosen by Immersify, in accordance with the rules of the United Kingdom Arbitration Act 1996 or another applicable arbitration framework. </p>

                                    <p>Arbitration or mediation shall take place in the United Kingdom, and the language of the proceedings shall be English. The arbitrator’s decision shall be final and binding, and judgment on the award rendered by the arbitrator may be entered in any court having jurisdiction thereof. </p>

                                    <p>Users agree that any dispute resolution proceedings will be conducted only on an individual basis and not in a class, consolidated, or representative action. By agreeing to these methods of dispute resolution, users waive any right to participate in a class action or other representative proceeding.
                                    </p>
                                </div>

                                <div className="prv_block">
                                    <h2>Waiver of the right to participate in class action lawsuits. </h2>
                                    <p>By using the Immersify platform, users agree to resolve any disputes arising out of or related to these Terms of Service or the platform through individual arbitration or mediation. Users expressly waive any right to bring or participate in class action lawsuits, consolidated claims, or representative actions. This means that all disputes will be handled on an individual basis, and users cannot combine their claims with those of others in any class or representative proceeding. This waiver is an essential part of the agreement between users and Immersify and is necessary to ensure efficient and fair resolution of disputes. </p>
                                </div>
                            </div>

                            <div className="t_blocks">
                                <h1>Changes to the Terms of Service </h1>
                                <div className="prv_block">
                                    <h2>Right to Modify Terms </h2>
                                    <p>Immersify reserves the right to modify these Terms of Service at any time. Any changes to the terms will be effective immediately upon posting the updated version on the platform. Users are responsible for regularly reviewing the Terms of Service to stay informed of any modifications. Continued use of the platform after any such changes constitutes the user’s acceptance of the new terms. If a user does not agree with the modified terms, they should discontinue using the platform and, if applicable, cancel their subscription. Immersify reserves the right to modify these Terms of Service at any time. Any changes to the terms will be effective immediately upon posting the updated version on the platform. Users are responsible for regularly reviewing the Terms of Service to stay informed of any modifications. Continued use of the platform after any such changes constitutes the user’s acceptance of the new terms. If a user does not agree with the modified terms, they should discontinue using the platform and, if applicable, cancel their subscription. </p>
                                </div>
                                <div className="prv_block">
                                    <h2>Notification of Changes </h2>
                                    <p>
                                    Immersify will notify users of any significant changes to these Terms of Service through appropriate communication channels, such as email or in-platform notifications. Users are encouraged to review these notifications promptly to stay informed about updates that may affect their use of the platform.  
                                    </p>
                                </div>
                            </div>

                            <div className="t_blocks">
                                <h1>Contact Information  </h1>
                                <div className="prv_block">
                                    <ul>
                                        <li><strong>Email:</strong> For general support and inquiries: support@immersifyeducation.com</li>
                                        <li><strong>Mailing Address : </strong>Immersify Education Ltd. Unit 16, Empress Business Centre, 380 Chester Road, Manchester, United Kingdom, M16 9EA  </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </section>
            <Footer link={true} />
        </>
    );
};
