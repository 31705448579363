import React from "react";
import PropTypes from "prop-types";
import { GetInfoFromID } from "../../../../../../Utils/getInfoFromID";

export const AnimatedLessonHeader = (props) => {
    
    const id = props.id;

    const info = GetInfoFromID(id).info
    const title = info.title
    const iconLocation = info.iconLocation

    return (
        <>
            <div className="activity_header">
                <div className="left-head">
                    <img
                        src={
                            "https://d1476tmm9qfop0.cloudfront.net/TestFiles/Icons/webp/px-" +
                            iconLocation.slice(6).slice(0, -4) +
                            ".webp"
                        }
                        className="img-fluid"
                        alt="Activity - Icon"
                    />
                    <h2>{title}</h2>
                </div>
            </div>
        </>
    );
};

AnimatedLessonHeader.propTypes = {
    id: PropTypes.string,
};
