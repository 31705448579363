import AppleSignin from 'react-apple-signin-auth';
import React from "react";
import "../SCSS/style.css";
import apple_image from "../images/apple.svg";
import { AppleSignIn } from './scripts/AppleSignIn';
//import { OIDCSignIn } from './scripts/OIDCSignIn';

var randomString = function(length) {
    var text = "";
    var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    for(var i = 0; i < length; i++) {
        text += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    return text;
}

/** Apple Signin button */
export const MyAppleSigninButton = () => (
    <AppleSignin
        /** Auth options passed to AppleID.auth.init() */
        //com.immersifyeducation.immersifydental - app ID
        //app.immersifyplatform.com - Service ID
        //group.immersifyeducation.app - group ID
        //app.immersifyplatform.mobile.com - for app - testing
        //https://immersify-dev-app.herokuapp.com/api/AppleRedirect - redirect URI
        authOptions={{
            clientId: "app.immersifyplatform.com",
            scope: "email name",
            redirectURI:
                "https://immersifyeducation.com/api/AppleRedirect",
            state: "",
            nonce: randomString(),
            usePopup: true, // if false, server side, if true, client side
        }} // REQUIRED
        /** General props */
        uiType="dark"
        /** className */
        className="apple-auth-btn"
        /** Removes default style tag */
        noDefaultStyle={false}
        /** Allows to change the button's children, eg: for changing the button text */
        buttonExtraChildren="Continue with Apple"
        /** Extra controlling props */
        /** Called upon signin success in case authOptions.usePopup = true -- which means auth is handled client side */
        onSuccess={(response) => {
            let idToken = response.authorization.id_token;
            AppleSignIn(idToken);
            // login with open id connect instead
            //OIDCSignIn("AppleOIDCLogin",idToken);
        }} // default = undefined
        /** Called upon signin error */
        onError={(error) => handleError(error)} // default = undefined
        /** Skips loading the apple script if true */
        skipScript={false} // default = undefined
        /** Apple image props */
        //iconProp={{ style: { marginTop: "10px" } }} // default = undefined
        /** render function - called with all props - can be used to fully customize the UI by rendering your own component  */
        render={(props) => (
            <button {...props} className="btn_login apple">
                <img alt="placeholder" src={apple_image} />
                Login with Apple
            </button>
        )}
    />
);

function handleError (error) {
    alert("Apple login failed")
    console.error(error)
}

export const MyAppleSigninButtonMobile = () => (
    <AppleSignin
        /** Auth options passed to AppleID.auth.init() */
        //com.immersifyeducation.immersifydental - app ID
        //app.immersifyplatform.com - Service ID
        authOptions={{
            clientId: "app.immersifyplatform.com",
            scope: "email name",
            redirectURI:
                "https://immersify-dev-app.herokuapp.com/api/AppleRedirect",
            state: "",
            nonce: randomString(),
            usePopup: true, // if false, server side, if true, client side
        }} // REQUIRED
        /** General props */
        uiType="dark"
        /** className */
        className="apple-auth-btn"
        /** Removes default style tag */
        noDefaultStyle={false}
        /** Allows to change the button's children, eg: for changing the button text */
        buttonExtraChildren="Continue with Apple"
        /** Extra controlling props */
        /** Called upon signin success in case authOptions.usePopup = true -- which means auth is handled client side */
        onSuccess={(response) => {
            console.log(response);
            let idToken = response.authorization.id_token;
            console.log(idToken);

            // now we need to deeplink ID token back to app
        }} // default = undefined
        /** Called upon signin error */
        onError={(error) => console.error(error)} // default = undefined
        /** Skips loading the apple script if true */
        skipScript={false} // default = undefined
        /** Apple image props */
        //iconProp={{ style: { marginTop: "10px" } }} // default = undefined
        /** render function - called with all props - can be used to fully customize the UI by rendering your own component  */
        render={(props) => (
            <button {...props} className="btn_login apple">
                <img alt="placeholder" src={apple_image} />
                Login with Apple
            </button>
        )}
    />
);

export default MyAppleSigninButton;

