import React, { useEffect } from "react";
import PropTypes from "prop-types";

import TopicInfo from "../../../../InfoJSONs/TopicInfo.json"

import "../../../../../SCSS/style.css";
import "../../../../../SCSS/fonts.css";

export const DisplayExperiencesHeader = (props) => {
    var topicTitle;
    var topicDescription;
    var iconLocation
    TopicInfo.topics.forEach((element) => {
        if (element.id == props.topicID) {
            topicTitle = element.title;
            topicDescription = element.description;
            iconLocation = element.roundedIconLocation
        }
    });

    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])

    return (
        <>
            <div className="col-md-12">
                <h2 className="main_heading">
                    <span className="topic-image">
                        <img
                            src={
                                "https://d1476tmm9qfop0.cloudfront.net/TestFiles/Icons/webp/px-" +
                                        iconLocation
                                            .slice(6)
                                            .slice(0, -4) +
                                        ".webp"
                            }
                            className="img-fluid"
                            alt="Activity - Icon"
                        />
                    </span>
                    {topicTitle}
                </h2>
            </div>
            <div className="col-md-12">
                {/* <div className="topic_tags">
                    <span className="new">new</span>
                    <span className="mp">Most played</span>
                    <span className="tn">topicTitle</span>
                </div> */}
            </div>
            <div className="col-md-12">
                <div className="topic_desc">
                    <p>{topicDescription}</p>
                </div>
            </div>
        </>
    );
};

DisplayExperiencesHeader.propTypes = {
    topicID: PropTypes.string,
    topicImageSrc: PropTypes.string,
};
