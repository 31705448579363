import React, { useState } from "react";
import { useEffect } from "react";
import classNames from "classnames";
import "../../SCSS/animate.min.css";
// import { ContentSection } from "../ContentSection/ContentSection";
import WOW from "wowjs";
import Slider from "react-slick";
// import learner_banner from "../../images/landing/learner_banner.svg";
// import team_1 from "../../images/landing/team_1.svg";
// import team_2 from "../../images/landing/team_2.svg";
// import team_3 from "../../images/landing/team_3.svg";
// import team_4 from "../../images/landing/team_4.svg";


import test_1 from "../../images/landing/test_1.png";
// import instagram from "../../images/landing/instag.png";
import brushyourteefies from "../../images/landing/testimonial_person.png";
import queenmary from "../../images/landing/queenmary.png";

//import partner3 from "../../images/landing/partners/p3.png";
import dentistry from "../../images/landing/dentistry_et.svg";
import nursing from "../../images/landing/nursing_et.svg";
import engineering from "../../images/landing/engineering_st.svg";
// import dentistry from "../../images/landing/dentistry1.png";
// import nursing from "../../images/landing/nursing.png";
// import engineering from "../../images/landing/engineering.png";

// import vmcq from "../../images/landing/mockvideos/vmcq.mp4";
// import sim from "../../images/landing/mockvideos/sim.mp4";
// import lessons from "../../images/landing/mockvideos/lessons.mp4";
// import threedmodels from "../../images/landing/mockvideos/3dmodels.mp4";
// import ar from "../../images/landing/mockvideos/ar.mp4";
import { LandingModel } from "../LandingModel/LandingModel";

export const Students = () => {


    // const [landingModel] = useState(<LandingModel modelLoaded={modelLoaded} />);
    const [tooltipActive, setTooltipActive] = useState(false);

    function modelLoaded() {
        setTooltipActive(true);
        const timeId = setTimeout(() => {
            // After n seconds set the show value to false
            setTooltipActive(false);
        }, 5000);

        return () => {
            clearTimeout(timeId);
        };
    }
    const [landingModel] = useState(<LandingModel modelLoaded={modelLoaded} />);

    const [addCSS, setAddCss] = useState(true)
    
    
    function modelClicked() {
        setTooltipActive(false);
    }

    document.addEventListener("DOMContentLoaded", function(){
    
        const observer = new IntersectionObserver(entries => {
            // Loop over the entries
            entries.forEach(entry => {
              // If the element is visible
              if (entry.isIntersecting) {
                  // Add the animation class
                  setAddCss(true)
              }
            });
          });
          
          observer.observe(document.querySelector('.stats_section'));
      
      });
    

    useEffect(() => {
        new WOW.WOW({
            live: false,
        }).init();
    }, []);

    const settings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToScroll: 1,
        arrows: true,
        responsive: [
            {
              breakpoint: 767,
              settings: {
                slidesToShow: 1,
              },
            },
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 2,
              },
            },
            {
              breakpoint: 1200,
              settings: {
                slidesToShow: 3,
              },
            },
            {
                breakpoint: 8000,
                settings: {
                  slidesToShow: 3,
                },
                
              }]
    };

    // const settings2 = {
    //     dots: true,
    //     infinite: true,
    //     speed: 500,
    //     slidesToScroll: 1,
    //     arrows: false,
    //     autoplay: true,
    //     autoplaySpeed: 3000
    // };

    //   function handleTryWebPlatform () {
    //     window.location.href = process.env.REACT_APP_CLIENT_URL + "/Experience"
    // }

    // function handleDentistryPage() {
    //     window.location.href = process.env.REACT_APP_CLIENT_URL + "/dentistry";
    // }
    function handleTryWebPlatform() {
        window.location.href = process.env.REACT_APP_CLIENT_URL + "/Profile";
    }

    return (
        <>
            <section className="academic_areas">
                <div className="student_ac_ar">

                   
                <div className="academic_sub">
                    <div className="container">
                    <ul className="nav nav-tabs" id="myTab" role="tablist">
                        <li className="nav-item" role="presentation">
                            <button
                                className="nav-link active"
                                id="home-tab"
                                data-bs-toggle="tab"
                                data-bs-target="#dentistry"
                                type="button"
                                role="tab"
                                aria-controls="dentistry"
                                aria-selected="true"
                            >
                                <span>Dentistry</span>
                            </button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button
                                className="nav-link"
                                id="profile-tab"
                                data-bs-toggle="tab"
                                data-bs-target="#nursing"
                                type="button"
                                role="tab"
                                aria-controls="nursing"
                                aria-selected="false"
                            >
                                <span>Nursing</span>
                            </button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button
                                className="nav-link"
                                id="contact-tab"
                                data-bs-toggle="tab"
                                data-bs-target="#engineering"
                                type="button"
                                role="tab"
                                aria-controls="engineering"
                                aria-selected="false"
                            >
                                <span>Engineering</span>
                            </button>
                        </li>
                    </ul>
                    <div className="tab-content" id="myTabContent">
                        <div
                            className="tab-pane fade show active"
                            id="dentistry"
                            role="tabpanel"
                            aria-labelledby="dentistry-tab"
                        >
                            <div className="row">
                                <div className="col-lg-8">
                                    <div className="short_acd">
                                        <h2 className="acd_head">
                                            Studying Dentistry?{" "}
                                        </h2>
                                        <p className="landing_main_para">
                                        Immersify is the dental resource that can help make your study sessions more enjoyable
                                         by providing engaging and immersive experiences. With a range of resources and 
                                         interactive modules, Immersify helps you develop the skills you need to excel 
                                         in your studies and enhance your knowledge. Whether you&apos;re a visual learner 
                                         or someone who benefits from hands-on experience, Immersify has got you covered. 
                                        </p>

                                        <p className="ext_link">
                                            <i className="fa fa-globe"></i>{" "}
                                            <button className="btn_readmore" onClick={handleTryWebPlatform}><b>Explore</b></button> Immersify’s dentistry
                                            content and stay up to date.
                                        </p>
                                        {/* <div className="btn_group">
                                            <button
                                                className="btn_common mt-2"
                                                onClick={handleTryWebPlatform}
                                            >
                                                Try it now
                                            </button>
                                            <button
                                                className="btn_outline mt-2"
                                                onClick={handleDentistryPage}
                                            >
                                                Learn more
                                            </button>
                                        </div> */}
                                    </div>
                                </div>

                                <div className="col-lg-4 d-lg-block d-none">
                                    <div className="tab_img">
                                        <img src={dentistry} alt="Dentistry" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            className="tab-pane fade"
                            id="nursing"
                            role="tabpanel"
                            aria-labelledby="nursing-tab"
                        >
                            <div className="row">
                                <div className="col-lg-8">
                                    <div className="short_acd">
                                        <h2 className="acd_head">
                                            Studying Nursing?{" "}
                                        </h2>
                                        <p className="landing_main_para">
                                        If you&apos;re a nursing student, Immersify is your go-to resource for immersive and engaging study sessions.
                                         Immersify offers interactive modules and a wide range of resources that can help you
                                          enhance your knowledge and develop the skills you need to excel in your studies. 
                                          Trust Immersify to make your nursing studies effective and to offer additional hands-on experience. 
                                        </p>
                                        {/* <p className="ext_link">
                                            <img
                                                src={instagram}
                                                alt="Instagram Immersify"
                                            />{" "}
                                            Stay up to date and join the
                                            community on Instagram.{" "}
                                            <a
                                                href="https://www.instagram.com/immersify_nursing/"
                                                rel="noreferrer"
                                                target="_blank"
                                                className="join_btn_insta"
                                            >
                                                Join now
                                            </a>
                                        </p> */}
                                        <p className="ext_link">
                                            Access Immersify’s nursing resources.
                                            
                                            <a
                                                href="https://linktr.ee/immersifynursing"
                                                rel="noreferrer"
                                                target="_blank"
                                                className="join_btn"
                                            >
                                                Download Immersify
                                            </a>
                                        </p>
                                    </div>
                                </div>
                                <div className="col-lg-4 d-lg-block d-none">
                                    <div className="tab_img">
                                        <img src={nursing} alt="Dentistry" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            className="tab-pane fade"
                            id="engineering"
                            role="tabpanel"
                            aria-labelledby="engineering-tab"
                        >
                            <div className="row">
                                <div className="col-lg-7">
                                    <div className="short_acd">
                                        <h2 className="acd_head">
                                            Studying Engineering?{" "}
                                        </h2>
                                        <p className="landing_main_para">
                                            New academic area incoming!
                                        </p>
                                        <p className="ext_link">
                                            <i className="fa fa-handshake-o "></i>{" "}
                                            Join the waitlist and be notified
                                            first when it&#39;s ready.{" "}
                                            <a
                                                href="https://docs.google.com/forms/d/e/1FAIpQLSeQHLjgcTCenlUr_neZxJKtJYx0SPnL21XEEpCN0Kf6RNuh4w/viewform"
                                                rel="noreferrer"
                                                target="_blank"
                                                className="join_btn"
                                            >
                                                Join now
                                            </a>
                                        </p>
                                    </div>
                                </div>

                                <div className="col-lg-5 d-lg-block d-none">
                                    <div className="tab_img">
                                        <img
                                            src={engineering}
                                            alt="Engineering"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
            </section>
            <section className="first_banner_sec mt-lg st_bg container wow animate__animated animate__fadeInUp">
            <div className="row">
                    <div className="col-lg-6">
                        <div className="first_banner_txt mt-4 mt-lg-0">
                            <h2 className="landing_main_head">
                                Engaging resources for better education
                                <span className="end_not"></span>
                            </h2>
                            <p>
                            Whether you&lsquo;re looking to revise for exams, test your knowledge, or prepare for practical assessments, 
                            Immersify offers a range of features including interactive 3D models and augmented reality simulations. 
                            </p>
                            {/* <p className="landing_main_para">
                                Immersify offers a range of features designed to make studying
                                more engaging and effective. From flashcards and quizzes to
                                simulations and interactive lessons, our platform provides
                                students with multiple ways to learn and practice essential
                                clinical skills. Our high-quality visuals and 3D resources
                                provide a comprehensive and immersive learning experience,
                                while our on-demand content means you can study whenever
                                and wherever you want. Whether you&apos;re looking to revise
                                for exams, test your knowledge with MCQs, or prepare
                                for practical assessments, Immersify has a solution for you.
                            </p> */}
                          
                        </div>
                    </div>

                    <div className="col-lg-6">
                        <div className="first_banner_img text-center">
                        <div
                                className="video_sec"
                                onClick={modelClicked}
                                onMouseDown={modelClicked}
                            >
                                <div
                                    className={
                                        tooltipActive
                                            ? "tip_model"
                                            : "tip_model d-none"
                                    }
                                >
                                    <div className="tip_img d-none d-md-block"></div>
                                    <div className="tip_img2 d-block d-md-none"></div>
                                    <div className="tip_txt">
                                        <h3>It&#39;s a 3D model!</h3>
                                        <h5>You can pan  the model.</h5>
                                    </div>
                                </div>
                             
                                {landingModel}
                            </div>

                            {/* <img src={learner_banner} alt="Student banner" /> */}
                        </div>
                    </div>
                </div>
            </section>

            <section className="stats_section">
                <div className="container ">
                <div className="row">
                    
                <div className="col-lg-12 wow animate__animated animate__fadeInUp">
                        <div className="box_head">
                            <h2 className="landing_main_head">
                                Why you can rely on Immersify
                                <span className="end_not"></span>
                            </h2>
                            <p className="landing_main_para">
                            Results of a study conducted by an independent research company.
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-12 wow animate__animated animate__fadeInUp">
                    <div className="stats_wrap">
                            <div className="row">
                                <div className="col-lg-3 col-md-6">
                                    <div className="stat_block mb-80">
                                        <div className="stat_num">
                                        <div className="numbers">
                                            <span className="numbers__window">
                                                <span className={classNames({"numbers__window__digit numbers__window__digit--1": true, "num_anim": addCSS })} data-fake="8642519073">9</span>
                                            </span>
                                            <span className="numbers__window">
                                                <span className={classNames({"numbers__window__digit numbers__window__digit--2": true, "num_anim": addCSS })} data-fake="5207186394">7</span>
                                            </span>
                                          
                                                <span className="percnt_ic">%</span>
                                            
                                        </div>
                                        </div>
                                        <p> of users agree that the platform has high quality visuals and resources. </p>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6">
                                    <div className="stat_block mb-80">
                                        <div className="stat_num">
                                        <div className="numbers">
                                            <span className="numbers__window">
                                                <span className={classNames({"numbers__window__digit numbers__window__digit--1": true, "num_anim": addCSS })} data-fake="8642519073">9</span>
                                            </span>
                                            <span className="numbers__window">
                                                <span className={classNames({"numbers__window__digit numbers__window__digit--1": true, "num_anim": addCSS })} data-fake="5207186394">6</span>
                                            </span>
                                          
                                                <span className="percnt_ic">%</span>
                                            
                                        </div>
                                        </div>
                                        <p>
                                        say that Immersify makes studying more enjoyable.
                                        </p>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6">
                                    <div className="stat_block mb-80">
                                        <div className="stat_num">
                                        <div className="numbers">
                                            <span className="numbers__window">
                                                <span className={classNames({"numbers__window__digit numbers__window__digit--1": true, "num_anim": addCSS })} data-fake="8642519073">9</span>
                                            </span>
                                            <span className="numbers__window">
                                                <span className={classNames({"numbers__window__digit numbers__window__digit--1": true, "num_anim": addCSS })} data-fake="5207186394">3</span>
                                            </span>
                                          
                                                <span className="percnt_ic">%</span>
                                            
                                        </div>
                                        </div>
                                        <p>
                                        of Immersify users think that it’s relevant to them and their studies. 
                                        </p>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6">
                                    <div className="stat_block l_child">
                                        <div className="stat_num">
                                        <div className="numbers">
                                            <span className="numbers__window">
                                                <span className={classNames({"numbers__window__digit numbers__window__digit--1": true, "num_anim": addCSS })} data-fake="8642519073">9</span>
                                            </span>
                                            <span className="numbers__window">
                                                <span className={classNames({"numbers__window__digit numbers__window__digit--2": true, "num_anim": addCSS })} data-fake="5207186394">3</span>
                                            </span>
                                          
                                                <span className="percnt_ic">%</span>
                                            
                                        </div>
                                        </div>
                                        <p>find the platform useful to prepare for their exams.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                </div>
            </section>

            {/* <section className="team_det wow animate__animated animate__fadeInUp">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="box_head">
                            <h2 className="landing_main_head mb-5">
                                Immersify more than a resource, a community
                                <span className="end_not"></span>
                            </h2>
                        </div>
                    </div>

                    <div className="col-lg-12 wow">
                        <div className="row">
                            <div className="col-md-3 mb-4">
                                <div className="team_block">
                                    <img src={team_1} alt="Team image" />
                                    <p>
                                        We collaborate with industry
                                        professionals to ensure accuracy and
                                        give you the most up-to-date resources.
                                    </p>
                                </div>
                            </div>
                            <div className="col-md-3 mb-4">
                                <div className="team_block">
                                    <img src={team_2} alt="Team image" />
                                    <p>
                                        We host regular student and young
                                        professional focus groups, providing
                                        relevant and sought-after topics.
                                    </p>
                                </div>
                            </div>
                            <div className="col-md-3 mb-4">
                                <div className="team_block">
                                    <img src={team_3} alt="Team image" />
                                    <p>
                                        We work with partners and utilise their
                                        market knowledge, to give you
                                        high-quality content.
                                    </p>
                                </div>
                            </div>
                            <div className="col-md-3 mb-4">
                                <div className="team_block">
                                    <img src={team_4} alt="Team image" />
                                    <p>
                                        We have a community of student content
                                        curators and testers ensures that our
                                        content aligns with your learning needs.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}

            

            <section className="testimonials">
            <div className="container">
                <div className="row">
                <div className="col-md-12">
                       <div className="testimonial_head">
                       <h2>What people say about Immersify<span className="end_not"></span></h2>
                        </div> 
                    </div>
                    <div className="col-md-12 p-rel">
                        <div className="testimonial_sld">
                            <Slider {...settings}>
                                <div className="testimonial_item">
                                    <p>
                                        &#34;I love the way this app uses
                                        interactive 3D models to help educate,
                                        as if we were learning in-person. The
                                        models are high resolution and
                                        manoeuvring them is easy. Plus, there
                                        are many lessons available. A great
                                        experience.&#34;
                                    </p>

                                    <div className="testimonial_person">
                                        <div className="testm_img">
                                            <img
                                                src={brushyourteefies}
                                                alt="testimonials"
                                            />
                                        </div>
                                        <div className="testm_det">
                                            <h2>@brushyourteefies</h2>
                                            <h5>Dental Student</h5>
                                        </div>
                                    </div>
                                </div>

                                <div className="testimonial_item">
                                    <p>
                                        &#34;We decided to purchase the
                                        Immersify Platform to support our
                                        dentistry and hygiene and therapy
                                        students with technological enhanced
                                        resources that can support their
                                        learning experience and help them with
                                        our studies.&#34;
                                    </p>

                                    <div className="testimonial_person">
                                        <div className="testm_img">
                                            <img
                                                src={queenmary}
                                                alt="testimonials"
                                            />
                                        </div>
                                        <div className="testm_det">
                                            <h2>
                                                Queen Mary University of London
                                            </h2>
                                            <h5>University</h5>
                                        </div>
                                    </div>
                                </div>

                                <div className="testimonial_item">
                                    <p>
                                        &#34;COVID friendly, case-by-case
                                        patient simulation possible anywhere on
                                        our smartphones! The augmented reality
                                        models take the learning experience to a
                                        whole different level.&#34;
                                    </p>

                                    <div className="testimonial_person">
                                        <div className="testm_img">
                                            <img
                                                src={test_1}
                                                alt="testimonials"
                                            />
                                        </div>
                                        <div className="testm_det">
                                            <h2>Isaac</h2>
                                            <h5>
                                                Fourth-year Dentistry Student
                                            </h5>
                                        </div>
                                    </div>
                                </div>
                            </Slider>
                        </div>
                    </div>
                </div>
                </div>
            </section>

            

            {/* <section className="stats_section rev wow animate__animated animate__fadeInUp">
                <div className="row">
                    <div className="col-lg-6 order-2 order-lg-1">
                        <div className="stats_wrap">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="stat_block mb-80">
                                        <div className="stat_num bg_stat1">
                                            92%
                                        </div>
                                        <p>
                                            Of lecturers agree the content is
                                            relevant to their curriculum
                                        </p>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="stat_block mb-80">
                                        <div className="stat_num bg_stat2">
                                            2x
                                        </div>
                                        <p>
                                            More engaging than traditional
                                            delivery methods
                                        </p>
                                    </div>
                                </div>
                                <div className="col-md-6 offset-md-3">
                                    <div className="stat_block">
                                        <div className="stat_num bg_stat3">
                                            47%
                                        </div>
                                        <p>
                                            Cheaper than other traditional
                                            content delivery resources
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-6 order-1 order-lg-2">
                        <div className="box_head">
                            <h2 className="landing_main_head">
                                What our community says about Immersify
                                <span className="end_not"></span>
                            </h2>
                        </div>
                    </div>
                </div>
            </section> */}

           

            <section className="faq wow animate__animated animate__fadeInUp">
                <div className="container">
                <div className="row">
                    <div className="col">
                        <div className="box_head">
                            <h2 className="landing_main_head">
                                Frequently asked questions
                                <span className="end_not"></span>
                            </h2>
                        </div>

                        <div className="faq_content">
                            <div className="faq_questions">
                                <button
                                    className="faq_que"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#faq_1"
                                    aria-expanded="false"
                                    aria-controls="faq_1"
                                >
                                    What academic areas does your educational
                                    platform cover?{" "}
                                    <span className="arrow_change">
                                        <i className="fa fa-angle-right"></i>
                                    </span>
                                </button>
                                <div className="collapse" id="faq_1">
                                    <div className="faq_answ">
                                    Immersify offers resources for dental related academic areas. Coming in spring 2023, Nursing topics will be available.
                                    </div>
                                </div>
                            </div>

                            <div className="faq_questions">
                                <button
                                    className="faq_que"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#faq_2"
                                    aria-expanded="false"
                                    aria-controls="faq_2"
                                >
                                    Where can I access Immersify and what
                                    devices are compatible with your platform?{" "}
                                    <span className="arrow_change">
                                        <i className="fa fa-angle-right"></i>
                                    </span>
                                </button>
                                <div className="collapse" id="faq_2">
                                    <div className="faq_answ">
                                    Access Immersify on 
                                    <button onClick={handleTryWebPlatform} className="btn_readmore"> &nbsp;Desktop</button>,
                                    <button onClick={handleTryWebPlatform} className="btn_readmore">&nbsp;laptop</button>, 
                                    <a href="https://immersifydental.page.link/?link=https://immersifyeducation.com/deeplink?dl=%5Bimmersifyeducation%3A%2F%2Fimmersifydental%3Firclickid%3DFAQL%5D%26apn%3Dcom.immersifyeducation.immersifydental%26isi%3D1509735183%26ibi%3Dcom.immersifyeducation.immersifydental">&nbsp;tablet </a>or 
                                    <a href="https://immersifydental.page.link/?link=https://immersifyeducation.com/deeplink?dl=%5Bimmersifyeducation%3A%2F%2Fimmersifydental%3Firclickid%3DFAQL%5D%26apn%3Dcom.immersifyeducation.immersifydental%26isi%3D1509735183%26ibi%3Dcom.immersifyeducation.immersifydental">&nbsp;mobile</a>.
                                    </div>
                                </div>
                            </div>

                            <div className="faq_questions">
                                <button
                                    className="faq_que"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#faq_3"
                                    aria-expanded="false"
                                    aria-controls="faq_3"
                                >
                                    Do I need to go to university to gain access
                                    to Immersify?{" "}
                                    <span className="arrow_change">
                                        <i className="fa fa-angle-right"></i>
                                    </span>
                                </button>
                                <div className="collapse" id="faq_3">
                                    <div className="faq_answ">
                                        No, the Immersify Platform is accessible
                                        to anyone.
                                        <br />
                                        <br />
                                        You can access our content through a
                                        guest login, or you can sign in through
                                        your Apple ID or Facebook login and get
                                        started with our educational resources.
                                        <br />
                                        <br />
                                        Whether you are just about to start your
                                        undergraduate course or are a qualified
                                        professional, everyone can access our
                                        content.
                                    </div>
                                </div>
                            </div>

                            <div className="faq_questions">
                                <button
                                    className="faq_que"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#faq_4"
                                    aria-expanded="false"
                                    aria-controls="faq_4"
                                >
                                    I’ve already graduated, can I still get
                                    access?
                                    <span className="arrow_change">
                                        <i className="fa fa-angle-right"></i>
                                    </span>
                                </button>
                                <div className="collapse" id="faq_4">
                                    <div className="faq_answ">
                                        Yes – our platform’s login process is
                                        simple. You can create an account once
                                        you download, or you can continue as a
                                        guest.
                                        <br />
                                        <br />
                                        Our platform isn’t restricted to
                                        individuals who are attending university
                                        or college.
                                        <br />
                                        <br />
                                        Access Immersify on 
                                    <button onClick={handleTryWebPlatform} className="btn_readmore"> &nbsp;Desktop</button>,
                                    <button onClick={handleTryWebPlatform} className="btn_readmore">&nbsp;laptop</button>, 
                                    <a href="https://immersifydental.page.link/?link=https://immersifyeducation.com/deeplink?dl=%5Bimmersifyeducation%3A%2F%2Fimmersifydental%3Firclickid%3DFAQL%5D%26apn%3Dcom.immersifyeducation.immersifydental%26isi%3D1509735183%26ibi%3Dcom.immersifyeducation.immersifydental">&nbsp;tablet </a>or 
                                    <a href="https://immersifydental.page.link/?link=https://immersifyeducation.com/deeplink?dl=%5Bimmersifyeducation%3A%2F%2Fimmersifydental%3Firclickid%3DFAQL%5D%26apn%3Dcom.immersifyeducation.immersifydental%26isi%3D1509735183%26ibi%3Dcom.immersifyeducation.immersifydental">&nbsp;mobile</a>.
                                    
                                    </div>
                                </div>
                            </div>

                            <div className="faq_questions">
                                <button
                                    className="faq_que"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#faq_5"
                                    aria-expanded="false"
                                    aria-controls="faq_5"
                                >
                                    How do I use the Augmented Reality (AR)
                                    resources?
                                    <span className="arrow_change">
                                        <i className="fa fa-angle-right"></i>
                                    </span>
                                </button>
                                <div className="collapse" id="faq_5">
                                    <div className="faq_answ">
                                        Here is a{" "}
                                        <a
                                            href="https://www.youtube.com/watch?v=k_4FvO6rdzc&ab_channel=Immersify"
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            video
                                        </a>{" "}
                                        on how to use the AR resources within
                                        our platform. If you’re still having
                                        trouble, please get in touch through our
                                        form at the bottom of this page.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
            </section>

            {/* <ContentSection textContent={"students text content"} /> */}
        </>
    );
};
