import React from 'react';
import PropTypes from "prop-types";

const ProductCard = ({product, period, selectedProduct, setSelectedProduct, activeProduct}) => {
  const isSelected = selectedProduct && selectedProduct.name === product.name;
  const isActive = activeProduct && activeProduct.name === product.name;

  return (
    <label className="product-card">
      <div className={"product-card-inner"}>
        <div className="product-card-header">

          <div className='product_title'>

          <input type="radio" name={"product-"+period} checked={isSelected} value={product.name} onChange={() => {
            setSelectedProduct(product)
          }}/>

          <div className="product-card-name">{product.name}</div>
          {isActive && (
            <div className="product-card-active">Active</div>
          )}
          </div>
          <div>
          {period === "monthly" ? product.price_monthly && (
            
            <div className="product-card-price">
            <span className="product-card-price-value">
              {product.price_monthly}
            </span>
              /Mo
            </div>
          ) : product.price_yearly && (
            <div className="product-card-price">
            <span className="product-card-price-value">
              {product.price_yearly}
            </span>
              /Yr
            </div>
            
          )}
          </div>
        </div>

        <div className="product-card-body">
          <div className="product-card-daily-activities">
            <h4>{product.daily_activities} activities per day</h4>
          </div>
          {Object.keys(product.features).map((feature) => (
            <div key={feature} className="product-card-feature">
              <p>{product.features[feature] ? "✓" : "✗"} {feature}</p>
            </div>
          ))}
        </div>
      </div>
    </label>
  )
}

ProductCard.propTypes = {
  product: PropTypes.object.isRequired,
  period: PropTypes.string.isRequired,
  selectedProduct: PropTypes.object.isRequired,
  setSelectedProduct: PropTypes.func.isRequired,
  activeProduct: PropTypes.object.isRequired
}

export default ProductCard;