import CurrentFreeTopics from '../ContentDeliverySystem/InfoJSONs/CurrentFreeTopics.json'
import ModuleStructure from '../ContentDeliverySystem/InfoJSONs/ModuleStructure.json'

import { GetExperienceTopic } from './getExperienceTopic'

export function CheckIfLocked (id, isTopic) {
    // eslint-disable-next-line
    const freeTopics = CurrentFreeTopics.freeTopics[0].topics;
    const freeActivities = CurrentFreeTopics.freeTopics[0].activities;

    if (isTopic) {
        // If topic is free
        if (freeTopics.includes(id)) {
            return false;
        }
        
        var value = true
        ModuleStructure.modules.forEach((module) => {
            module.topics.forEach((topic) => {
                if (topic.topicID === id) {
                    topic.lessonIDs.forEach(lesson => {
                        if (freeActivities.includes(lesson))
                        value = false
                    });
                }
            });
        });
        return value

    } else {
        if (freeActivities.includes(id)) {
            return false;
        }

        var locked = true;
        GetExperienceTopic(id).forEach((topic) => {
            if (freeTopics.includes(topic)) {
                locked = false;
            }
        }); // eslint-disable-next-line
        return locked;
    }
}