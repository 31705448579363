import React from "react";
import PropTypes from "prop-types";

import Score from "../../../../Images/score.svg";
import tryAgain from "../../../../Images/try_again.svg";
import { UpdateUserData } from "../../../../../../../../../Utils/updateUserData";
import { GetUserData } from "../../../../../../../../../Utils/getUserData";
import { CheckIfLoggedIn } from "../../../../../../../../../Utils/CheckIfLoggedIn";
import { firebaseCall } from "../../../../../../../../../Utils/firebaseAnalytics";

var id;
var score;

export const QuizComplete = (props) => {
    id = props.id;
    score = props.score;
    const noQuestions = props.noQuestions;

    if (CheckIfLoggedIn() === true) {
        GetUserData([id], gotUserDataCallback);
    }
    firebaseCall("complete_quiz", { activity_id: id, quiz_score: score });

    function gotUserDataCallback(data) {
        console.log("got user data", data);
        if (data !== undefined && data !== "{}") {
            const currentScore = JSON.parse(data)[id].Value;

            if (currentScore < score || currentScore === undefined) {
                updateScore();
            }
            if (currentScore == undefined) {
                firebaseCall("first_complete_quiz", { activity_id: id, quiz_score: score });
            }
        } else {
            updateScore();
        }
    }

    function updateScore() {
        console.log("update score");
        UpdateUserData(id, score);
    }

    return (
        <div className="mcq_end">
            <div className="score_img">
                <img alt="placeholder" src={score / noQuestions > 0.5 ? Score : tryAgain} />
            </div>

            <h4>{score / noQuestions > 0.5 ? "Awesome" : "Try again"}</h4>
            <div className="score_txt">
                <h3>
                    <span>{score}</span>/ {noQuestions}
                </h3>
            </div>

            <div className="show_mcq">
                <button className="btn_outline" onClick={props.handleRestartQuiz}>
                    RESTART
                </button>
                <button className="btn_common" data-bs-dismiss="modal" aria-label="Close">
                    END
                </button>
            </div>
        </div>
    );
};

QuizComplete.propTypes = {
    id: PropTypes.string,
    handleRestartQuiz: PropTypes.func,
    score: PropTypes.number,
    noQuestions: PropTypes.number,
};
