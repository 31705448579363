import { currentAssetObject, currentAssetRelQData, holdingAssetSubMeshes} from '../VMCQ.js';
import '../../../../../../../Scripts/aframe/create-entities';
import { waitUntil } from '../../../../../../../components/Utils/asyncTools';
import { externalAnswered } from '../VMCQ.js';

let selfRef;

/* global AFRAME */
AFRAME.registerComponent("modelswapper", {
  schema:{},
  init:function(){
      selfRef = this;
      this.firstTime = true;

      let cameraManagerEntity = selfRef.el.sceneEl.querySelector('[vmcq-camera-manager]'); 
      cameraManagerEntity.setAttribute('position', '0 0 0')

      this.onClick = this.onClick.bind(this);
      window.addEventListener('click', this.onClick, false);

      this.loadModel();

      this.el.addEventListener("model-loaded", this.modelLoaded);

      if (document.getElementById("resumeButton")) {
        //1 second delay as don't want it to tick to 29 instantly
        setTimeout(document.getElementById("resumeButton").click(), 1000);
      }
      
  },

  loadModel:function(){
    let gltfComp = document.getElementById('SwapModel');
    if(gltfComp !== undefined){
      if (gltfComp.hasAttribute("gltf-model")) {
        gltfComp.removeAttribute("gltf-model");
      }
      gltfComp.setAttribute('gltf-model', '#visibleModel');
    }   
  },

  onClick: function(event) {
    if (event.target.id === "vmcqStartButton" || event.target.id === "vmcqContinueButton") {
      this.loadModel();
    }
  },

  modelLoaded: async function () {
    let nodesToHL = [];
    // set the position & rotation from JSON
    let swapModel = document.getElementById('SwapModel');
    swapModel.setAttribute('position', currentAssetRelQData.position);
    swapModel.setAttribute('rotation', currentAssetRelQData.rotation);

    let cameraManagerEntity = selfRef.el.sceneEl.querySelector('[vmcq-camera-manager]'); 
    let cameraManagerComp = cameraManagerEntity.components['vmcq-camera-manager'];
    
    
    // wait until the glt-model comp has been created
    if(selfRef.firstTime) { 
      await waitUntil(() => document.querySelectorAll('[gltf-model]').length > 0);
      console.log("done waiting for gltf-model");
    }
    // set default camera pos / zoom out
    // instruct camera manager to only do dolly in the first time (since we will remain in the same scene every other time), and have no delay
    let delay = selfRef.firstTime ? 200 : 0; // for the first model we need a delay
    cameraManagerComp.resetCamPos(selfRef.firstTime, delay); 
    selfRef.firstTime = false;


    swapModel.getObject3D("mesh").traverse(
      (node) => {
          // first turn off all highlights & all labels            
          selfRef.hideNodeMatchingAffix(node, currentAssetObject.highlightAffix);
          selfRef.hideNodeMatchingAffix(node, currentAssetObject.labelAffix);
          if(holdingAssetSubMeshes !== undefined){
            holdingAssetSubMeshes.forEach((sm) => {
              if(node.name==sm.name) {
                // if its a visible highlight mesh; add highlight component to this object (fade in and out)    
                if( (node.name.startsWith(currentAssetObject.highlightAffix) || node.name.endsWith(currentAssetObject.highlightAffix)) && sm.visible){
                  nodesToHL.push(node);                
                }else{
                  // might not be a highlight? might just be a node we need to turn on/off 🤷‍♀️
                  node.visible = sm.visible;
                }
              }
            })
          }
        }        
      );

    // Highlight
    if(nodesToHL !== undefined || nodesToHL.length <= 0){
      nodesToHL.forEach((nodeToHL) =>{
        selfRef.makeVisible(nodeToHL);
        nodeToHL.children.forEach((nodeChild)=>{
          if(nodeChild.material !== undefined){
            selfRef.tweenFromTo(nodeChild.material, 0.5, 0, true); 
          }        
        })
      })          
    }

    if (!externalAnswered) {
      if (document.getElementById("resumeButton")) {
        //1 second delay as don't want it to tick to 29 instantly
        setTimeout(document.getElementById("resumeButton").click(), 1000);
      }
    }


    //this is to reset the distance from camera to tooth each time a new tooth is loaded
    document.querySelector('[vmcq-im-orbit-controls]').components['vmcq-camera-manager'].resetCamPos(true, 200)

  },

  // if the nodes name starts or ends with the specified affix, hide it
  hideNodeMatchingAffix:function(node, affix){
    if(node.name.startsWith(affix) || node.name.endsWith(affix)){
      node.visible = false;
    } 
  },

  // when making a node visible, make sure all of its children are also visible
  makeVisible:function(parentNode){
    parentNode.visible =true;
    parentNode.children.forEach((child) => {
      child.visible = true;
    })
  },

  // Tweening to blink highlight on and off
  tweenFromTo: function(targetObject, startValue, targetValue, looping){
    let selfRef = this;
    selfRef.tweenInProgress = true;
    
    // we create a temporary "material" (object),
    // this object gets manipulated internally by the tween
    // and the result gets written back to the target object/mat
    var tempMat = {
      'alpha': 0,
    }
    
    this.modelAlphaAnimation = AFRAME.ANIME({          
      targets:tempMat,
      alpha:[startValue, targetValue],
      delay:0,
      endDelay:-500,
      duration:2500,
      direction: 'alternate',
      easing: 'easeInOutCirc',
      loop: looping,
    
      update:function(){
        targetObject.opacity = tempMat.alpha;
      },
      loopComplete:function(){
        selfRef.tweenInProgress = false;
      },
      complete:function(){
        selfRef.tweenInProgress = false;
      }
    });
  }
})