import React, {useRef} from 'react';
import throttle from "lodash.throttle";

function BuildLoadingBar () {
    const progressLabel = useRef(0);
    const progressBar = useRef(0);

    const animate = ({ timing, draw, duration, complete }) => {
        let start = performance.now();
      
        requestAnimationFrame(function animate(time) {
          // timeFraction goes from 0 to 1
          let timeFraction = (time - start) / duration;
      
          if (timeFraction > 1) timeFraction = 1;
      
          // calculate the current animation state
          let progress = timing(timeFraction/0.1);
      
          draw(progress); // draw it
      
          if (timeFraction < 1) {
            requestAnimationFrame(animate);
          }
          if (timeFraction === 1) {
            complete(progress);
          }
        });
    };

    const throttledDraw = throttle((progress) => {
        let percentage = Math.round(progress * 100);
        try {
            progressBar.current.value = percentage;
            progressLabel.current.innerHTML = `${percentage}%`;
        } catch (error) {
            return;
        }
    }, 500);

    const circ = (timeFraction) => {
        return 0.08+(timeFraction/(1+timeFraction));
    };

    animate({
        duration: 80 * 1000,
        timing: circ,
        draw: throttledDraw,
        complete: () => console.log("completed")
    })

    return (
        <div className='progress-bar-container'>
            <label ref={progressLabel} id="progress-bar-label" htmlFor="progress-bar">0%</label>
            <progress ref={progressBar} id="progress-bar" value="0" max="100"></progress>
        </div>
    );
  }

//the function gets rendered and called multiple times so needs memoizing
export const LoadingBar = React.memo(BuildLoadingBar);