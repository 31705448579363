import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "../SCSS/style.css";

import immersify_logo from "../images/immersify_logo.svg";
import shape_1 from "../images/shape_1.svg";
import password_image from "../images/password.svg";
import close_button_image from "../images/close_btn.svg";
import verify_email from "../images/verify_em.svg";

import { MyAppleSigninButton } from "./MyAppleSigninButton";
import { GetLoginData } from "./scripts/GetLoginData";
import { PlayfabSignUp } from "./scripts/PlayfabSignUp";
import { EmailUpdatesSignUp } from "./scripts/EmailUpdatesSignUp";
import { HandleValidation } from "./scripts/HandleValidation";
import { SendResetEmail } from "./scripts/SendResetEmail";
import { GoogleLoginButton } from "./Google/GoogleLoginButton2";
import { FacebookLoginComponent } from "./Facebook/FacebookLoginComponent";
import { UniSSO } from "./scripts/UniSSO";
import { UniEmailSignIn } from "./scripts/UniEmailSignIn";
import { PopulateData } from "./scripts/PlayfabSignUp";
import { CheckIfLoggedIn } from "../Utils/CheckIfLoggedIn";
import { SetPrice } from "../Utils/setPrice";
import { Encrypt } from "../Utils/Encrypt";
import { GetSubbedUnis } from "../Utils/getSubbedUnis";
import { CheckForPremium } from "../Utils/checkForPremium";
import { Decrypt } from "../Utils/Decrypt";
import { CountryOptions } from "../ContentDeliverySystem/Experience/DisplayContent/DisplayProfile/EditProfile/CountryOptions/CountryOptions";
import { UpdateContactEmail } from "./scripts/UpdateContactEmail";
import { UpdateUserData } from "../Utils/updateUserData";
import { firebaseCall } from "../Utils/firebaseAnalytics";

export const Login = () => {
    const [suffixAccessList, setSuffixAccessList] = useState();
    const [emailVerifyList, setEmailVerifyList] = useState();

    useEffect(() => {
        // if (!gotGoggle) {
        //     waitForGoogle();
        // }
        GetSubbedUnis(getSubbedUnisCallback);
    }, []);

    function getSubbedUnisCallback(data) {
        setSuffixAccessList(data.suffixAccessList);
        setEmailVerifyList(data.emailVerifyList);
    }

    // var loadGoogleAttempts = 0;
    // const [gotGoggle, setGotGoogle] = useState(false);
    // const waitForGoogle = () => {
    //     /* global google */
    //     if (window.google !== undefined) {
    //         setGotGoogle(true);
    //         google.accounts.id.initialize({
    //             client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
    //             callback: GoogleSignIn,
    //         });

    //         google.accounts.id.renderButton(document.getElementById("googleSignInButton"), { theme: "outline", size: "large" });
    //     } else if (loadGoogleAttempts < 5) {
    //         setTimeout(function () {
    //             loadGoogleAttempts++;
    //             waitForGoogle();
    //         }, 500);
    //     } else {
    //         console.log("too many attempts loading google scripts");
    //     }
    // };

    // Form changes, update state
    const [form, setForm] = useState({
        email: "",
        password: "",
        useUniSSO: false,
        confirmPassword: "",
        displayName: "",
        country: "",
        referralCode: "",
        emailSignUp: false,
    });
    const handleChange = (e) => {
        e.preventDefault();

        setEmailValidationError("");
        setPasswordValidationError("");
        setSignUpValidationError("");

        setForm({
            ...form,
            [e.target.name]: e.target.value,
        });

        const emailAfterAt = form.email.toLowerCase().split("@")[1];
        console.log({ emailAfterAt });

        if (emailAfterAt === "cardiff.ac.u" || emailAfterAt === "qmul.ac.u" || emailAfterAt === "abdn.ac.u") {
            setCheckedOne(true);
        }
    };

    const handleSubmit = (e) => {
        firebaseCall("login", {
            login_type: localStorage.getItem("loginType"),
            email_suffix: localStorage.getItem("suffix"),
            full_email: localStorage.getItem("contactEmail"),
            display_name: localStorage.getItem("displayName"),
        });
        e.preventDefault();
        localStorage.clear();
        form.email = form.email.trim();
        // If on login form
        if (switchForm) {
            HandleValidation(switchForm, form, handleLogin, handleValidationErrors, checkedOne);
        }
        // else if on sign up form
        else {
            HandleValidation(switchForm, form, handleSignUp, handleValidationErrors, checkedOne);
        }
    };

    const [emailValidationError, setEmailValidationError] = useState("");
    const [passwordValidationError, setPasswordValidationError] = useState("");
    const [facebookValidationError, setFacebookValidationError] = useState("");

    const handleValidationErrors = (errors) => {
        console.log(errors);
        setEmailValidationError(errors.email);
        setPasswordValidationError(errors.password);
        if (errors.email) {
            setSignUpValidationError(errors.email);
        } else if (errors.password) {
            setSignUpValidationError(errors.password);
        } else if (errors.playfabLogin) {
            firebaseCall("playfab_login_error", { error_code: errors.playfabLogin });
            setSignUpValidationError(errors.playfabLogin);
            setEmailValidationError(errors.playfabLogin);
        } else if (errors.facebook) {
            setFacebookValidationError(errors.facebook);
        } else if (errors.displayName) {
            setSignUpValidationError(errors.displayName);
        } else if (errors.confirmPassword) {
            setSignUpValidationError(errors.confirmPassword);
        } else {
            setSignUpValidationError(JSON.stringify(errors));
            setEmailValidationError(JSON.stringify(errors));
        }
        setLoggingIn(false);
    };

    const [loggingIn, setLoggingIn] = useState(false);
    const handleLogin = (form) => {
        checkIfSuffixInList(form.email);

        console.log({ checkedOne });

        setLoggingIn(true);

        const emailAfterAt = form.email.toLowerCase().split("@")[1];

        if (emailAfterAt === process.env.REACT_APP_SUBBED_UNIS && !checkedOne) {
            UniEmailSignIn(form.email, form.password);
        } else if (!checkedOne) {
            GetLoginData(form.email.toLowerCase(), form.password, handleLoginComplete, handleValidationErrors);
        } else {
            handleUniSSO(form.email.toLowerCase(), form.password, handleUniSSORedirect, handleValidationErrors);
        }
    };

    /*const handleTestLogin = (email, password) => {
        setLoggingIn(true);
        GetLoginData(
            email,
            password,
            handleLoginComplete,
            handleValidationErrors
        );
    };

    const handleTestUniLogin = () => {
        handleUniSSO(
            "dantest@qmul.ac.uk",
            "test456",
            handleUniSSORedirect,
            handleValidationErrors
        );
    }

    const handleTestLogOut = () => {
        Logout();
    };*/

    function checkIfEmailVerified(checkIfEmailVerifiedCallback) {
        var myHeaders = new Headers();
        myHeaders.append("X-Authorization", Decrypt(localStorage.getItem(process.env.REACT_APP_SESSION_TICKET_CODE_NAME)));
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            PlayFabId: localStorage.getItem("playfabID"),
            ProfileConstraints: { ShowContactEmailAddresses: true },
        });

        var requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow",
        };

        fetch("https://29001.playfabapi.com/Client/GetPlayerProfile?=", requestOptions)
            .then((response) => response.text())
            .then((result) => {
                const contactEmails = JSON.parse(result).data.PlayerProfile.ContactEmailAddresses;
                var verified = false;
                if (contactEmails) {
                    contactEmails.forEach((email) => {
                        console.log(email);
                        console.log(email.VerificationStatus);
                        if (email.VerificationStatus !== undefined) {
                            if (email.VerificationStatus === "Confirmed") {
                                verified = true;
                            }
                        }
                    });
                }
                checkIfEmailVerifiedCallback(verified);
            })
            .catch((error) => {
                console.log("error", error);
                checkIfEmailVerifiedCallback(false);
            });
    }

    function sendVerifyEmail(url) {
        console.log("SEND EMAIL");
        var myHeaders = new Headers();
        myHeaders.append("X-Authorization", Decrypt(localStorage.getItem(process.env.REACT_APP_SESSION_TICKET_CODE_NAME)));
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            EventName: "EmailVerificationEvent",
            Body: {
                Status: "SentEmail",
                EmailAddress: localStorage.getItem("contactEmail"),
            },
        });

        console.log(raw);

        var requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow",
        };

        fetch("https://29001.playfabapi.com/Client/WritePlayerEvent", requestOptions)
            .then((response) => response.text())
            .then((result) => {
                console.log(result);
                window.location.href = url;
            })
            .catch((error) => {
                console.log("error", error);
                window.location.href = url;
            });
    }

    function checkIfEmailVerifiedCallback(verified) {
        if (verified === true) {
            completeNavigation();
        } else {
            if (localStorage.getItem("mustVerify") === "true") {
                CheckForPremium(checkPremiumMustVerifyCallback, "Login");
            } else {
                CheckForPremium(checkPremiumVerifyOptionalCallback, "Login");
            }
        }
    }

    function checkPremiumMustVerifyCallback(data, reason) {
        console.log({ data });
        console.log({ reason });
        console.log("must verify");
        sendVerifyEmail(process.env.REACT_APP_CLIENT_URL + "/Verify/U2FsdGVkX18ZVCtlqBreiPZVYjWI3968eH4B7Penpdw=");
    }

    function checkPremiumVerifyOptionalCallback(data, reason) {
        console.log({ data });
        console.log({ reason });
        console.log("can verify");
        sendVerifyEmail(process.env.REACT_APP_CLIENT_URL + "/Verify/U2FsdGVkX1+GbeK2pbM7dyDcdqyyDhLQQxodhX3d490=");
    }

    const handleLoginComplete = () => {
        const loginDate = JSON.stringify(new Date());
        const year = loginDate.substring(1, 5);
        const month = loginDate.substring(6, 8);
        const day = loginDate.substring(9, 11);
        const time = loginDate.substring(12, 20);
        UpdateUserData("LastLoginDateWeb", day + "/" + month + "/" + year + " " + time);
        SetPrice();
        checkIfEmailVerified(checkIfEmailVerifiedCallback);
        firebaseCall("login_end", {
            login_type: localStorage.getItem("loginType"),
            email_suffix: localStorage.getItem("suffix"),
            full_email: localStorage.getItem("contactEmail"),
            display_name: localStorage.getItem("displayName"),
        });
    };

    const navigate = useNavigate();
    const completeNavigation = () => {
        console.log("Check for premium");
        CheckForPremium(checkForPremiumCallback, "Login");
    };

    function checkForPremiumCallback(premium) {
        console.log({ premium });
        navigate("/Profile");
    }

    const handleSignUpComplete = () => {
        var populateRaw = JSON.stringify({
            Data: {
                CanEmail: "true",
                Favourites: [],
                Guest: "false",
                Medals: [],
                VIP: "false",
                Enterprise: "false",
                Avatar: '["Head:Blank_User","Clothes:empty","Addon:empty","Mouth:empty","Hair:empty","Eyewear:empty","Other:empty","Covering:empty"]',
                RefCode: "",
                AcademicArea: "",
                Subscriber: suffixCheck,
            },
            Permission: "Public",
        });
        const sTicket = CheckIfLoggedIn(true);
        PopulateData(sTicket, populateRaw, false);
        UpdateContactEmail(sTicket, localStorage.getItem("contactEmail"));

        if (checkedTwo) {
            EmailUpdatesSignUp();
        }
        firebaseCall("signup");
        handleLoginComplete();
    };

    var signUpForm;

    function checkIfSuffixInList(email) {
        const emailAfterAt = email.toLowerCase().split("@");
        console.log({ emailAfterAt });

        var returnArray = [""];

        if (suffixAccessList === undefined) {
            console.error("Suffix list is undefined");
            return [];
        }

        suffixAccessList.forEach((suffix) => {
            console.log(emailAfterAt[1]);
            if (suffix.suffix == emailAfterAt[1]) {
                console.log("SUFFIX MATCH");
                localStorage.setItem("suffix", suffix.suffix);
                if (suffix.trial === true || suffix.trial === "true") {
                    console.log("TRIAL TRUE");
                    // Give premium content
                    localStorage.setItem(process.env.REACT_APP_PREMIUM_CODE_NAME + "value", Encrypt(true));
                    localStorage.setItem(process.env.REACT_APP_PREMIUM_CODE_NAME + "date", Encrypt(new Date().addHours(4)));

                    returnArray[0] = suffix;
                    returnArray.push("suffixList", "trialTrue");
                    console.log(returnArray);
                } else {
                    console.log("TRIAL FALSE");
                    // Give premium content
                    localStorage.setItem(process.env.REACT_APP_PREMIUM_CODE_NAME + "value", Encrypt(true));
                    localStorage.setItem(process.env.REACT_APP_PREMIUM_CODE_NAME + "date", Encrypt(new Date().addHours(4)));

                    returnArray[0] = suffix;
                    returnArray.push("suffixList");
                    returnArray.push("trialFalse");
                }
            }
        });

        emailVerifyList.forEach((suffix) => {
            if (suffix.suffix === emailAfterAt[1]) {
                returnArray.push("emailList");
                if (suffix.verifiable === true || suffix.verifiable === "true") {
                    console.log("MUST VERIFY");
                    localStorage.setItem("mustVerify", true);
                    // Must verify
                    // show verify popup and don't allow to redirect
                    returnArray.push("mustVerify");
                }
            }
        });

        console.log(returnArray);
        return returnArray;
    }

    const [suffixCheck, setSuffixCheck] = useState([]);

    const handleSignUp = (form) => {
        console.log("SIGN UP TRUE");
        setLoggingIn(true);
        signUpForm = form;
        const tempSuffixCheck = checkIfSuffixInList(signUpForm.email);
        setSuffixCheck(tempSuffixCheck);

        if (suffixCheck.includes("suffixList")) {
            localStorage.setItem(process.env.REACT_APP_PREMIUM_CODE_NAME + "value", Encrypt(true));
            localStorage.setItem("activeUntil", "Mon Jan 01 2032 00:00:00 GMT+0000 (Greenwich Mean Time)");
        }

        console.log(tempSuffixCheck);
        if (suffixCheck.includes("emailVerify")) {
            if (suffixCheck.includes("mustVerify")) {
                localStorage.setItem("mustVerify", true);
            }
        }

        performSignUp(signUpForm);
    };

    function performSignUp(form) {
        console.log(form);
        PlayfabSignUp(form, handleSignUpComplete, signUpErrorCallback);
    }

    const [signUpValidationError, setSignUpValidationError] = useState("");

    function signUpErrorCallback(error) {
        console.log(error);
        setLoggingIn(false);
        setSignUpValidationError(error.errorMessage);
    }

    function handleUniSSORedirect(url) {
        console.log("handle redirect: " + url);
        return <a href={url}>UniSSORedirect</a>;
    }

    /*const handleTestEmailSignups = () => {
        EmailUpdatesSignUp('dantest@test.com')
    }

    const handleTestSignUp = () => {
        //LessonData/StandardLesson/ProcessAndCharting.json
        
        //setLoggingIn(true);
        //PlayfabSignUp(form, handleSignUpComplete);
    };

    const handleDeleteTestAccount = () => {
        DeleteAccount();
    }*/

    // eslint-disable-next-line
    function handleAppleLogin() {
        setLoggingIn(true);
    }

    // const handleGoogleLogin = () => {
    //     setLoggingIn(true);
    //     // GoogleSignIn(handleLoginComplete);
    // };

    function handleFacebookLogin() {
        setLoggingIn(true);
    }

    const handleUniSSO = (email, password) => {
        const tempCheck = checkIfSuffixInList(form.email);
        setSuffixCheck(tempCheck);
        var onList = false;
        var suffix;

        if (tempCheck.includes("suffixList")) {
            onList = true;
            suffix = tempCheck[0];
        }

        UniSSO(email, password, handleUniSSORedirect, handleValidationErrors, onList, suffix);
    };

    // Displaying Login/Sign-Up form
    const [switchForm, setSwitchForm] = useState(true);
    const handleSwitchForm = () => {
        if (switchForm) {
            setSwitchForm(false);
        } else {
            setSwitchForm(true);
        }
    };

    // Uni SSO checkbox
    const [checkedOne, setCheckedOne] = useState(false);
    const updateOne = () => {
        setCheckedOne(!checkedOne);
    };

    // Email updates checkbox
    const [checkedTwo, setCheckedTwo] = useState(true);
    const updateTwo = () => {
        setCheckedTwo(!checkedTwo);
    };

    const [resetFlag, setResetFlag] = useState(false);
    const [resetEmail, setResetEmail] = useState("");
    const [resetMessage, setResetMessage] = useState(<p>No worries, we’ll send you reset instructions.</p>);
    const handleForgotPasswordSubmit = (e) => {
        e.preventDefault();

        const email = e.target.email.value;

        // If a new reset email should be sent
        if (resetFlag === false) {
            SendResetEmail(email, setResetMessage, setResetEmail, setResetFlag);
        } else if (resetEmail === email) {
            setResetMessage(<p>An email to reset your password has already been sent.</p>);
        } else {
            SendResetEmail(email, setResetMessage, setResetEmail, setResetFlag);
        }
    };

    /*const handleUpdateUserDataTest = () => {
        UpdateUserData("instrument_topic", [
            {
                activityName: "introInstruments_lesson",
                hasCompleted: true,
                progress: 1,
            },
        ]);
    };*/

    function handleBackToExperience() {
        window.location.href = process.env.REACT_APP_API_URL + "/Profile";
    }

    return (
        <>
            {/*<button
                onClick={() => handleTestLogin("dantest@test.com", "test456")}
            >
                TestLogin
            </button>
            <button onClick={handleUpdateUserDataTest}>UpdateData</button>
            <button onClick={handleTestLogOut}>LogOut</button>
            <button onClick={() => handleTestSignUp()}>TestSignUp</button>
            <button onClick={handleDeleteTestAccount}>DeleteTestAccount</button>
            <button onClick={handleTestUniLogin}>TestUniLogin</button>
            <button onClick={handleTestEmailSignups}>TestEmailSignup</button>*/}
            <div
                className="modal fade verify_email"
                id="verify_email"
                data-bs-backdrop="static"
                data-bs-keyboard="false"
                tabIndex={-1}
                aria-labelledby="Email Verification"
                aria-hidden="true"
            >
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <button type="button" className="btn_mod_close" data-bs-dismiss="modal" aria-label="Close">
                                <img alt="placeholder" src={close_button_image} />
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="em_img_block">
                                <img alt="placeholder" src={verify_email} />
                            </div>

                            <div className="fgt_header">
                                <h5>Verify Email</h5>
                                <p>
                                    {" "}
                                    We’have sent an email to <span>johndoe@outlook.com</span> To confirm your email address, tap the button in the
                                    email we sent to you.
                                </p>

                                <h6>Didn’t receive the mail? You may have to check your spam/junk folders as well.</h6>

                                <button className="btn_common">Resend</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <section className="container-fluid">
                <div className="row">
                    <div className="col-xl-7 col-md-6 left_block d-none d-md-block">
                        <div className="signup_image">
                            <div className="shape_block">
                                <div className="shape_1">
                                    <img alt="placeholder" src={shape_1} />
                                </div>
                                <div className="shape_2">
                                    <img alt="placeholder" src={shape_1} />
                                </div>
                            </div>
                            <div className="login_text_block" onClick={handleBackToExperience}>
                                <h1>LEARN.</h1>
                                <h1>EXPERIENCE.</h1>
                                <h1>CONNECT.</h1>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-5 col-md-6">
                        <a className="logo_wrap" href={"/Landing"}>
                            <img alt="placeholder" src={immersify_logo} />
                        </a>
                        <div className="signup_form_area">
                            <h3>Welcome </h3>
                            <h2>{switchForm ? "Login" : "Sign Up"}</h2>
                            <div className="form-group">
                                {/*------******** Sign up form  *********-----*/}
                                {switchForm ? (
                                    <form onSubmit={handleSubmit}>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="form-field">
                                                    <input
                                                        type="text"
                                                        name="email"
                                                        value={form.email}
                                                        onChange={(e) => handleChange(e)}
                                                        className="form-control email"
                                                        placeholder="Email*"
                                                        required
                                                    />
                                                    <span className="error_txt">{emailValidationError}</span>
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="form-field">
                                                    {checkedOne === false ? (
                                                        <input
                                                            type="password"
                                                            name="password"
                                                            value={form.password}
                                                            onChange={(e) => handleChange(e)}
                                                            className="form-control name"
                                                            placeholder="Password*"
                                                            required
                                                        />
                                                    ) : (
                                                        <></>
                                                    )}

                                                    <span className="error_txt">{passwordValidationError}</span>
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="check_area login_check">
                                                    <div className="form-check">
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            name="useUniSSO"
                                                            checked={checkedOne}
                                                            onChange={updateOne}
                                                            id="uni_single_sign"
                                                        />
                                                        <label className="form-check-label" htmlFor="uni_single_sign">
                                                            Use university single sign on
                                                        </label>
                                                    </div>
                                                    <div className="forgot_pwd">
                                                        <a
                                                            href="test"
                                                            className="btn_underline"
                                                            data-bs-toggle="modal"
                                                            data-bs-target="#forgotPassword"
                                                        >
                                                            Forgot Password
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="btn_area">
                                                    <button
                                                        className={loggingIn ? "btn_common w-100 start_anim2" : "btn_common w-100"}
                                                        to="/MainPage"
                                                    >
                                                        Submit
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                ) : (
                                    <form onSubmit={handleSubmit} id="signup_form">
                                        <div className="row">
                                            <span className="error_txt">{signUpValidationError}</span>
                                            <div className="col-md-6">
                                                <div className="form-field">
                                                    <input
                                                        type="text"
                                                        className="form-control email"
                                                        name="email"
                                                        value={form.email}
                                                        onChange={(e) => handleChange(e)}
                                                        placeholder="Email*"
                                                        required
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-field">
                                                    <input
                                                        type="text"
                                                        className="form-control name"
                                                        name="displayName"
                                                        value={form.displayName}
                                                        onChange={(e) => handleChange(e)}
                                                        placeholder="Display name*"
                                                        required
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-field">
                                                    <input
                                                        type="password"
                                                        name="password"
                                                        value={form.password}
                                                        onChange={(e) => handleChange(e)}
                                                        className="form-control pwd"
                                                        placeholder="Password*"
                                                        required
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-field">
                                                    <input
                                                        type="password"
                                                        name="confirmPassword"
                                                        value={form.confirmPassword}
                                                        onChange={(e) => handleChange(e)}
                                                        className="form-control pwd"
                                                        placeholder="Confirm Password*"
                                                        required
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-field">
                                                    <select
                                                        className="form-select form-control"
                                                        id="sel_country"
                                                        aria-label="Default select example"
                                                        required
                                                    >
                                                        <CountryOptions selectedCountry={"Country*"} />
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-field">
                                                    <input
                                                        type="text"
                                                        name="referralCode"
                                                        value={form.referralCode}
                                                        onChange={(e) => handleChange(e)}
                                                        className="form-control"
                                                        placeholder="Referral code (Optional)"
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="check_area">
                                                    {/*<div className="form-check">
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            name="useUniSSO"
                                                            checked={checkedOne}
                                                            onChange={updateOne}
                                                            id="uni_single_sign"
                                                        />
                                                        <label
                                                            className="form-check-label"
                                                            htmlFor="uni_single_sign"
                                                        >
                                                            Use university
                                                            single sign on
                                                        </label>
                                                    </div>*/}
                                                    <div className="form-check">
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            name="emailSignUp"
                                                            checked={checkedTwo}
                                                            onChange={updateTwo}
                                                            id="update_check"
                                                        />
                                                        <label className="form-check-label" htmlFor="update_check">
                                                            Sign me up to receive email updates on exciting new content and news that I may be
                                                            interested in. (You can opt-out any time)
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="btn_area">
                                                    <button className={loggingIn ? "btn_common w-100 start_anim2" : "btn_common w-100"}>
                                                        Submit
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                )}

                                {/*------******** Sign up form  *********-----*/}
                                <div className="other_signup">
                                    <div className="sign_in_header">{switchForm ? <h5>Or login with</h5> : <h5>Or sign up with</h5>}</div>
                                    {/*------******** Other Sign up Options  *********-----*/}
                                    <div className="signup_options">
                                        {/* <button
                                            onClick={() =>
                                                window.AppleID.auth.signIn()
                                            }
                                        >
                                            Apple Sign In
                                        </button> */}
                                        <MyAppleSigninButton callback={handleAppleLogin} />
                                        {/* <button id="googleSignInButton"></button> */}
                                        <GoogleLoginButton />

                                        <FacebookLoginComponent
                                            loggingInHook={setLoggingIn}
                                            onClick={handleFacebookLogin}
                                            handleValidationErrors={handleValidationErrors}
                                        />
                                        <span className="error_txt mt-2">{facebookValidationError}</span>
                                    </div>
                                    {/*------******** Other Sign up Options  *********-----*/}
                                    <h4>
                                        {switchForm ? "Don't have an account?" : "Already have an account?"}{" "}
                                        <button className="switch_signup" onClick={handleSwitchForm}>
                                            {switchForm ? "Sign Up" : "Login"}
                                        </button>
                                    </h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div
                className="modal fade forgot_password"
                id="forgotPassword"
                data-bs-backdrop="static"
                data-bs-keyboard="false"
                tabIndex={-1}
                aria-labelledby="forgotPassword"
                aria-hidden="true"
            >
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <div className="fgt_header">
                                <img alt="placeholder" src={password_image} />
                                <h5>Forgot Password?</h5>
                                {resetMessage}
                            </div>
                            <button type="button" className="btn_mod_close" data-bs-dismiss="modal" aria-label="Close">
                                <img alt="placeholder" src={close_button_image} />
                            </button>
                        </div>
                        <div className="modal-body">
                            <form onSubmit={handleForgotPasswordSubmit}>
                                <div className="form-group mb-3">
                                    <input
                                        type="text"
                                        name="email"
                                        value={form.email}
                                        onChange={(e) => handleChange(e)}
                                        className="form-control"
                                        placeholder="Email"
                                        required=""
                                    />
                                </div>
                                <button className="btn_common w-100">Submit</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
