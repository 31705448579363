import React from 'react';
import paymentsuccess from "../images/payment_success.svg";
import {Link, useSearchParams} from "react-router-dom";

const SubscriptionUpdated = () => {
  const [urlParams] = useSearchParams();

  const cancelled = urlParams.get('cancelled');

  return (
    <section className="error_block">

      <div className="error_img">
        <img
          src={paymentsuccess}
          className="img-fluid"
          alt="Payment - Updated"
        />
      </div>

      <div className="error_txt">
        {cancelled === 'true' ? <h2>CANCELLED!</h2> : <h2>SUCCESS!</h2>}
        <p>Your subscription has been updated and will be charged to your new .</p>
        <Link to='/Profile/Subscription' className="btn_common mx-50">DONE</Link>
      </div>

    </section>
  );
};

export default SubscriptionUpdated;