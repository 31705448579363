/* global AFRAME, THREE */
AFRAME.registerComponent('center-locator', {
    schema:{
        distance:{default:8}
    },
    init:function(){
        this.onModelLoaded = this.onModelLoaded.bind(this);
        this.el.addEventListener('model-loaded', this.onModelLoaded);
    },
    onModelLoaded:function(){
        this.center = this.getCenterPoint();
        this.size = this.getObjectSize();
    },
    getCenterPoint:function () {
        let gltfObject = this.el.getObject3D('mesh');
        let box = new THREE.Box3().setFromObject(gltfObject); // create a box from our model
        let center = box.getCenter(new THREE.Vector3()); // get the center of the box        
        return center;
    },
    getObjectSize:function(){
        let gltfObject = this.el.getObject3D('mesh');
        let box = new THREE.Box3().setFromObject(gltfObject); // create a box from our model
        let boxSize = box.getSize(new THREE.Vector3());
        return boxSize;
    },
    resetCamPos:function(fov, height){
        let dist = height / 2 / Math.tan(Math.PI * fov / 360);

        let orbitControlsEntity = this.el.sceneEl.querySelector('[vmcq-im-orbit-controls]'); 
        let orbitControlsComp = orbitControlsEntity.components['vmcq-im-orbit-controls'];
        // zoom out
        //console.log(this.data.distance);
        orbitControlsComp.dollyIn(dist*this.data.distance);
    },
    getGLTFMesh:function(){
        console.log(this.el);
        console.log(this.el.getObject3D('mesh'));
        return this.el.getObject3D('mesh');
    }
});