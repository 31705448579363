import { Decrypt } from "../../../../../../../Utils/Decrypt";
import { UpdateUserData } from "../../../../../../../Utils/updateUserData";

export function UpdateDisplayName(newDisplayName, successCallback) {
    const sessionTicket = Decrypt(localStorage.getItem(process.env.REACT_APP_SESSION_TICKET_CODE_NAME));

    var myHeaders = new Headers();
    myHeaders.append("X-Authorization", sessionTicket);
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
        DisplayName: newDisplayName,
        LastWriteDevice: "",
    });

    var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
    };

    fetch("https://29001.playfabapi.com/Client/UpdateUserTitleDisplayName", requestOptions)
        .then((response) => response.text())
        .then((result) => {
            console.log(result);
            localStorage.setItem("displayName", newDisplayName);
            if (successCallback) successCallback(true);
            UpdateUserData("LastWriteDevice", "");
        })
        .catch((error) => {
            if (successCallback) {
                successCallback(false);
            }
            console.log("error", error);
        });
}
