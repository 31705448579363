import { firebaseCall } from "../../components/Utils/firebaseAnalytics";

// eslint-disable-next-line
export async function downloadS3File(inputFilename) {
    var downloads3Headers = new Headers();
    downloads3Headers.append("Content-Type", "application/json");

    var downloads3Raw = JSON.stringify({
        filename: inputFilename,
    });

    var downloads3RequestOptions = {
        method: "POST",
        headers: downloads3Headers,
        body: downloads3Raw,
        redirect: "follow",
    };

    var output = "";
    await fetch(
        process.env.REACT_APP_SERVER_URL + "/api/s3download",
        downloads3RequestOptions
    )
        .then((response) => response.text())
        .then((result) => {
            output = result;
        })
        .catch((error) =>  {
            console.log("error", error);
            firebaseCall("s3_download_error", {"error_code": error, "file_name": inputFilename})
        });
    return output;
}