import 'aframe';
import 'aframe-extras';

import { Entity, Scene } from "aframe-react";
import React,{useEffect,useState} from "react";
import PropTypes from "prop-types";


//import { arShadow } from '../../Scripts/aframe/ar-shadows'
// import { vmcqFeedbackModelMan } from '../../../../../../../Scripts/aframe/vmcq-feedback-models'

import './model-entity-maker'
import './camera-manager.component'
import '../../../../../../../Scripts/aframe/Utils/center-locator.component'
import './vmcq-im-orbit-controls-fb'
import '../../../../../../../Scripts/aframe/light-manager.component'

export const VMCQFeedbackTemplate = (props) => {

    var initialised = false

    document.addEventListener("initialised", () => {
        initialised = true
    });
    
    //console.log(props);
    //const [isModelLoaded,setIsModelLoaded] = useState(false);
    const [isAllModelsLoaded,setIsAllModelsLoaded] = useState(false);
    const [isLessonDataLoaded,setIsLessonDataLoaded] = useState(false);

    useEffect(() => {
        handleMakeModelEntitiesClick();
    }, []);

    {/* LOAD HANDLERS*/}
    const allModelsLoaded = () => {
        setIsAllModelsLoaded(true);
        if(isLessonDataLoaded){
            setIsLoadingComplete(true);
        } 
    };
    const lessonDataLoaded = () => {
        console.log("lesson data loaded");
        setIsLessonDataLoaded(true);       
        if(isAllModelsLoaded){
            setIsLoadingComplete(true);
        }       
    };

    const [isLoadingComplete, setIsLoadingComplete] = useState(false);
    // Event that sets up the animation & slider vars
    // Can be used to trigger anything that needs to happen once lesson & model are loaded
    const [doneLoadingComplete, setDoneLoadingCompelte] = useState(false);
    // eslint-disable-next-line
    const onAllLoadingComplete = () => {
        if(!isLoadingComplete || doneLoadingComplete){return;}
        //console.log("all loading complete, performing final setup");

        setDoneLoadingCompelte(true);
    }

    const handleMakeModelEntitiesClick = () => {

        if (initialised === true) {
            // trigger the make entities event
            let modelMakerID = "modelEntityMaker"+props.id;
            let modelMakerDOM = document.getElementById(modelMakerID);
            
            let modelMaker = modelMakerDOM.components['feedback-model-entity-maker'];
            modelMaker.makeModelEntities(props.modelsForThisPoint, props.id);
        }
        else {
            setTimeout(() => handleMakeModelEntitiesClick(), 100);
        }
    }

    return (
      <>
        <div className="AFrameSceneSection" id={"AFrameSceneSection" + props.id}>
          <Scene
            id={"AFrameScene" + props.id}
            embedded
            device-orientation-permission-ui="enabled: false"
            light="defaultLightsEnabled: false"
            class="ModelViewerScene"
            renderer="colorManagement: true; antialias: true"
            vr-mode-ui="enabled: false"
            light-manager
          >
            <Entity
              id="directionalLight"
              light="type: directional; color: #FFF; intensity: 0.3"
              position="-0.5 1 1"
            ></Entity>
            <Entity
              id="ambientLight"
              light="type: ambient; color: #FFF; intensity: 0.3"
            ></Entity>
              {/* {console.log(vmcqFeedbackModelMan)} */}
            <Entity
              id={"sceneCam" + props.id}
              class="sceneCam"
              camera="fov:36"
              camera-manager={
                "distance: 0.5"+
                // +vmcqFeedbackModelMan.modelsJSON.modelData.models[props.id].distance +
                "; pointID: " + props.id
              }
              vmcq-im-orbit-controls-fb={
                "target: #camTarget" + props.id + "; rotateTo:0 0 5;"
              }
              light="type:spot; color: #FFF; intensity: 1"
            />

            <Entity id="WorldCenter" position="0 0 0" rotation="0 0 0" />

            {/* Target Cube */}
            {/* Position is overwritten by find center code */}
            {/* Can also be changed by lesson json file (camPos) */}
            <a-box
              id={"camTarget" + props.id}
              class="camTarget"
              position="0 2 1.175"
              rotation="0 0 0"
              scale="0 0 0"
              color="green"
            />

            {/* MODELS */}
            <Entity
              id={"modelEntityMaker" + props.id}
              feedback-model-entity-maker={"loaderID:loader" + props.id}
            />

            {/* SKY */}
            {/* <a-sky color="#ECECEC" /> */}
            <a-sky color="#ffffff" />
            {/* LOADING EVENTS */}
            <Entity
              id={"loader" + props.id}
              events={{
                onAllModelsLoaded: () => allModelsLoaded(),
                onLessonDataLoaded: () => lessonDataLoaded(),
              }}
            />
            {/* END LOADING EVENTS */}
          </Scene>
        </div>
      </>
    );
};

VMCQFeedbackTemplate.propTypes = {
    id: PropTypes.number,
    modelsForThisPoint: PropTypes.any
};
