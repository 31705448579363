import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import avatar_image from "../../../../../images/user_default.svg";
import { UsernameField } from "./UsernameField/UsernameField";
import { UpdateProfile } from "./UpdateProfile/UpdateProfile";
// import { CountryOptions } from "./CountryOptions/CountryOptions";
// import { UniversityOptions } from "./UniversityOptions/UniversityOptions";
import { GetUserData } from "../../../../../Utils/getUserData";
// import { DisplayDropdown } from "./DisplayDropdown/DisplayDropdown";
import { EmailField } from "./EmailField/EmailField";
import { PasswordReset } from "./PasswordReset";

var usernameChanged = false;
var emailChanged = false;
// var countryChanged = false;
// var universityChanged = false;
var updatesChanged = false;

export const EditProfile = (props) => {
    const [username, setUsername] = useState();
    const [email, setEmail] = useState();

    // country stuff was perpetually loading and doesn't really do anything, so I commented it out as don't have time to fix rn
    // const [country, setCountry] = useState();
    // const [university, setUniversity] = useState();

    // const [countryOptions, setCountryOptions] = useState(
    //     <>
    //         <option />
    //         <option key={"listNameCountries"} disabled value={"Country*"}>
    //             Loading...
    //         </option>
    //     </>
    // );

    // const [universityOptions, setUniversityOptions] = useState(
    //     <>
    //         <option />
    //         <option key={"listNameUniversities"} disabled value={"University*"}>
    //             Loading...
    //         </option>
    //     </>
    // );

    useEffect(() => {
        // GetUserData(["Country", "CanEmail"], getPlayerCountryCallback);
        GetUserData(["Country", "CanEmail"]);

        setUsername(localStorage.getItem("displayName"));
        setEmail(localStorage.getItem("contactEmail"));
    }, []);

    const [updatesCheckboxValue, setUpdatesCheckboxValue] = useState(false);

    // const [countrySelected, setCountrySelected] = useState(false);

    // function getPlayerCountryCallback(data) {
    //     console.log(data);
    //     var selectedCountry;
    //     if (data !== undefined) {
    //         selectedCountry = JSON.parse(data).Country.Value;
    //         setCountrySelected(true);
    //         if (JSON.parse(data).CanEmail !== undefined) {
    //             if (JSON.parse(data).CanEmail.Value === "true" || JSON.parse(data).CanEmail.Value === true) {
    //                 setUpdatesCheckboxValue(true);
    //             } else {
    //                 setUpdatesCheckboxValue(false);
    //             }
    //         }
    //     } else {
    //         selectedCountry = "Country*";
    //         setCountrySelected(false);
    //     }
    //     setCountryOptions(<CountryOptions selectedCountry={selectedCountry} />);
    //     UniversityOptions(selectedCountry, setUniversityOptions, false);
    // }

    function handleUsernameChanged(username) {
        setUsernameValidationErrors("");
        setUsername(username);
        usernameChanged = true;
        setSuccess("UPDATE");
    }

    function handleEmailChanged(email) {
        setEmailValidationErrors("");
        setEmail(email);
        emailChanged = true;
        setSuccess("UPDATE");
    }

    // function handleCountryChanged(countryName) {
    //     UniversityOptions(countryName, setUniversityOptions, true);
    //     setCountry(countryName);
    //     countryChanged = true;
    //     setSuccess("UPDATE");
    //     setCountrySelected(true);
    // }

    // function handleUniversityChanged(university) {
    //     setUniversity(university);
    //     universityChanged = true;
    //     setSuccess("UPDATE");
    // }

    function handleUpdatesCheckbox() {
        setUpdatesCheckboxValue(!updatesCheckboxValue);
        updatesChanged = !updatesChanged;
        setSuccess("UPDATE");
    }

    const [usernameValidationErrors, setUsernameValidationErrors] = useState("");
    const [emailValidationErrors, setEmailValidationErrors] = useState("");

    function handleFormSubmit(e) {
        e.preventDefault();

        var usernameOutput = undefined;
        var emailOutput = undefined;
        // var countryOutput = undefined;
        // var universityOutput = undefined;
        var updatesOutput = undefined;
        var tempErrors = "";

        if (usernameChanged === true) {
            tempErrors = validateDisplayName(username);
            setUsernameValidationErrors(tempErrors);
            if (tempErrors === "") {
                usernameOutput = username;
            }
        }
        if (emailChanged === true) {
            tempErrors = validateEmail(email);
            setEmailValidationErrors(tempErrors);
            if (tempErrors === "") {
                emailOutput = email;
            }
        }
        // if (countryChanged === true) {
        //     countryOutput = country;
        // }
        // if (universityChanged === true) {
        //     universityOutput = university;
        // }
        if (updatesChanged === true) {
            updatesOutput = updatesCheckboxValue;
        }
        if (tempErrors === "") {
            // UpdateProfile(usernameOutput, emailOutput, countryOutput, universityOutput, updatesOutput, handleSuccess);
            UpdateProfile(usernameOutput, emailOutput, undefined, undefined, updatesOutput, handleSuccess);
        }
    }

    function validateDisplayName(displayName) {
        // must not contain spaces
        var errors = "";
        if (displayName.includes(" ")) {
            errors = "Display name cannot contain spaces";
            console.log("validation fail");
        }

        // maximum of 25 characters
        if (displayName > 25) {
            errors = "Display name cannot be more than 25 characters long";
            console.log("validation fail");
        }

        // minimum of 3 characters
        if (displayName < 3) {
            errors = "Display name must be at least 3 characters long";
            console.log("validation fail");
        }

        if (!displayName) {
            errors = "Display name cannot be empty";
            console.log("validation fail");
        }

        return errors;
    }

    function validateEmail(email) {
        var errors = "";
        // must not contain spaces
        if (email.includes(" ")) {
            errors = "Invalid email address";
            console.log("validation fail");
        }

        // field is not empty
        if (!email) {
            errors = "Email cannot be empty";
            console.log("validation fail");
        }

        // contains @
        if (!email.includes("@")) {
            console.log(email);
            errors = "Invalid email address";
            console.log("validation fail");
        }

        // characters exist after @
        const emailAfterAt = email.split("@");
        if (emailAfterAt[1] !== undefined) {
            if (emailAfterAt[1].includes(".")) {
                const emailDomain = emailAfterAt[1].split(".");

                // beofre dot > 2 characters
                if (emailDomain[0] < 2) {
                    errors = "Invalid email address";
                    console.log("validation fail");
                }

                // after dor > 2 characters
                if (emailDomain[0] < 2) {
                    errors = "Invalid email address";
                    console.log("validation fail");
                }
            }
            // contains a dot
            else {
                errors = "Invalid email address";
                console.log("validation fail");
            }
        }
        // contains characters after @
        else {
            errors = "Invalid email address";
            console.log("validation fail");
        }
        return errors;
    }

    const [success, setSuccess] = useState("UPDATE");

    function handleSuccess(success) {
        console.log(success);
        if (success === true) {
            setSuccess("UPDATED");
        } else {
            setSuccess("UPDATE");
        }
    }
    const [passwordResetModalOpen, setPasswordResetModalOpen] = useState(true);

    return (
        <>
            {props.passwordReset && passwordResetModalOpen ? (
                <PasswordReset passwordResetModalOpen={passwordResetModalOpen} setPasswordResetModalOpen={setPasswordResetModalOpen} />
            ) : null}
            <div className="profile_edit_sec">
                <h3>Edit Profile</h3>
                <div className="profile_img">
                    <img src={avatar_image} alt="Profile Image" />
                    {/*<button className="edit_pic">
                        <i className="fa fa-edit"></i>
                    </button>*/}
                </div>
                <form className="edit_pro_fields" onSubmit={handleFormSubmit}>
                    <span className="error_txt">{usernameValidationErrors}</span>
                    <UsernameField username={username} handleUsernameChanged={handleUsernameChanged} />
                    <span className="error_txt">{emailValidationErrors}</span>
                    <EmailField email={email} handleEmailChanged={handleEmailChanged} />

                    {/* <DisplayDropdown options={countryOptions} onChange={handleCountryChanged} name={"Country"} />
                    {countrySelected === true ? (
                        <DisplayDropdown options={universityOptions} onChange={handleUniversityChanged} name={"University"} />
                    ) : (
                        <></>
                    )} */}
                    <div className="form-check">
                        <input
                            className="form-check-input"
                            type="checkbox"
                            name="emailSignUp"
                            checked={updatesCheckboxValue}
                            onChange={handleUpdatesCheckbox}
                            id="update_check"
                        />
                        <label className="form-check-label" htmlFor="update_check">
                            Sign me up to receive email updates on exciting new content and news that I may be interested in. (You can opt-out any
                            time)
                        </label>
                    </div>
                    <div className="form-group">
                        <button className={success === "UPDATE" ? "btn_common w-100" : "btn_success w-100"} type="submit">
                            {success}
                        </button>
                    </div>
                </form>
            </div>
        </>
    );
};

EditProfile.propTypes = {
    passwordReset: PropTypes.bool,
};
