// eslint-disable-next-line
AFRAME.registerComponent("model-load-check", {
    init: function () {
        this.el.addEventListener("model-loaded", this.modelLoaded);
    },
    modelLoaded: function () {
        this.loadCheckEntity = document.getElementById("loadCheckEntity");
        this.loadCheckEntity.emit("onModelLoaded");
    }
});
