// eslint-disable-next-line
import React from "react";
import PropTypes from "prop-types";

import '../SCSS/style.css';
import { StandardLessonTemplate } from "./StandardLessonTemplate";

import zoom from "../images/zoom.png";

// export const AFramePlayer = (props) => {  
//     useEffect(() => {
//         console.log("Use effect (AframePlayer)");
//     }, []);


export const AFramePlayer = (props) => {

    const getModelsForThisPoint = () => {
        let modelsForThisPointList = [];
        props.modelData.forEach(model =>{
            if(model.points.includes(props.id)){
                modelsForThisPointList.push(model);
            }
        });
        return modelsForThisPointList;
    }

    return (
        <div className="anim_video">
            <StandardLessonTemplate
                key={props.id}
                id={props.id}
                modelsForThisPoint={getModelsForThisPoint()}
            />
            <span className="pan_info">
                <div className="tooltip_pan">
                <img
                            src={zoom}
                            className="img-fluid"
                            alt="avatar"
                        />
                    <p>
                        You can pan and zoom the model.
                    </p>
                </div>
                <i className="fa fa-info"></i>
            </span>
        </div>
    );
};

AFramePlayer.propTypes = {
    id: PropTypes.number,
    modelData: PropTypes.any
};