import React from "react";
import PropTypes from "prop-types";
import "../../../../SCSS/style.css";

import ModuleStructure from "../../../InfoJSONs/ModuleStructure.json"

import { DisplayExperiencesHeader } from "./DisplayExperiencesHeader/DisplayExperiencesHeader";
import { ExperienceCard } from "./ExperienceCard/ExperienceCard";

var allLessons = []
var allPracticals

export const DisplayExperiences = (props) => {

    const topicId = props.topicId
    
    ModuleStructure.modules.forEach(module => {
        module.topics.forEach(topic => {
            if (topic.topicID === topicId) {

                allLessons = topic.lessonIDs.map((lesson, index) =>
                    <ExperienceCard
                        key={"lessonCard" + index}
                        id={lesson}
                        displayContentCallback={props.displayContentCallback}
                        type={"lesson"}
                    />
                );
                
                if (topic.pracID !== "") {
                    allPracticals = (
                        <ExperienceCard
                            key={"practicalCard0"}
                            id={topic.pracID}
                            displayContentCallback={props.displayContentCallback}
                            type={"practical"}
                        />
                    );
                }
            }
        });
    });

    const wrapper = () => {
        return (
            <section className="main_topics">
                <div className="row">
                    <DisplayExperiencesHeader topicID={props.topicId} />
                    {allLessons}
                    {allPracticals}
                </div>
            </section>
        );
    };

    return <>{wrapper()}</>;
};

DisplayExperiences.propTypes = {
    topicId: PropTypes.string,
    displayContentCallback: PropTypes.func,
};
