

import React from "react";
import { databaseRequest } from "./Utils/databaseRequest";

export const APITest = () => {

    
    Testing();

    return (
        <>
            <h1>test</h1>
        </>
    );
};

async function Testing() {
    const data = await databaseRequest("get", "users");
    console.log(data);
}