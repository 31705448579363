import React from "react";
var axios = require("axios");

export function SendResetEmail(email, setResetMessage, setResetEmail, setResetFlag) {
    setResetMessage(<p>Sending reset email...</p>);
    setResetEmail(email);

    // Get contact email from login email
    let contactEmail = "";
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var raw = JSON.stringify({
        Email: email,
    });

    var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
    };
    fetch(process.env.REACT_APP_SERVER_URL + "/api/getContactEmail", requestOptions)
        .then((response) => response.text())
        .then((result) => {
            contactEmail = JSON.parse(result).data.PlayerProfile.ContactEmailAddresses[0]?.EmailAddress;
            if (contactEmail) {
                contactEmailAddressExists();
            } else {
                onlyLoginEmailExists();
            }
        })
        .catch((error) => {
            console.log("error", error);
        });

    function onlyLoginEmailExists() {
        var forgotPasswordHeaders = new Headers();
        forgotPasswordHeaders.append("Content-Type", "application/json");
        var forgotPasswordaccountInfoRaw = JSON.stringify({
            Email: email,
            TitleId: "29001",
        });
        var forgotPasswordAccountInfoRequestOptions = {
            method: "POST",
            headers: forgotPasswordHeaders,
            body: forgotPasswordaccountInfoRaw,
            redirect: "follow",
        };
        fetch("https://29001.playfabapi.com/Client/SendAccountRecoveryEmail", forgotPasswordAccountInfoRequestOptions)
            .then((response) => response.text())
            .then((result) => {
                switch (JSON.parse(result).code) {
                    case 200:
                        setResetFlag(true);
                        setResetMessage(<p>Password reset email has been sent.</p>);
                        break;
                    case 400:
                        setResetFlag(false);
                        setResetMessage(<p>Invalid email address</p>);
                        break;
                    default:
                        break;
                }
            })
            .catch((error) => {
                console.log("error", error);
                setResetFlag(false);
                setResetMessage(<p>There was an error sending your email.</p>);
            });
    }

    // this takes login email and sends reset email to contact email
    function contactEmailAddressExists() {
        var data = JSON.stringify({
            EmailTemplateId: "FFA2DA6477FC9357",
            Email: email,
        });
        var config = {
            method: "post",
            url: "https://29001.playfabapi.com/Server/SendCustomAccountRecoveryEmail",
            headers: {
                "X-SecretKey": process.env.REACT_APP_PLAYFAB_SECRET_KEY,
                "Content-Type": "application/json",
            },
            data: data,
        };
        axios(config)
            .then(function () {
                setResetFlag(true);
                setResetMessage(<p>Password reset email has been sent.</p>);
            })
            .catch(function (error) {
                console.log(error);
                setResetFlag(false);
                setResetMessage(<p>Invalid email address</p>);
            });
    }

    // }
}
