import React from "react";

export const FreeBlock = () => {

    var currency
    if (localStorage.getItem("monthlyPrice") !== null) {
        currency = localStorage.getItem("monthlyPrice").charAt(0);
    }
    else currency = "$"
        return (
            <div className="col-md-6 order-2 order-lg-1">
                <div className="p_block free">
                    <div className="p_block_top">
                        <span className="p_mode">Free</span>
                        <h4>
                            <span>
                                {currency}
                            </span>
                            0.00
                        </h4>
                    </div>
                    <div className="p_block_contents">
                        <ul>
                            <li className="web_mob">
                                <p>Access to 2 topics</p>
                            </li>
                            <li className="mob">
                                <p>10 activities to play</p>
                            </li>
                            <li className="mob">
                                <p>1 token per day to play the challenges</p>
                            </li>
                        </ul>
                    </div>
                    <div className="btm_button mb-2">
                        <span>Current Plan</span>
                    </div>
                </div>
            </div>
        );
};
