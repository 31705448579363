import { CheckIfLoggedIn } from "./CheckIfLoggedIn";
import { Decrypt } from "./Decrypt";

export function getPlayerProfile(getPlayerProfileCallback) {

    const loginCheck = CheckIfLoggedIn()
    if (loginCheck === false || loginCheck === undefined) {
        getPlayerProfileCallback(false)
        return false
    }

    var myHeaders = new Headers();
    myHeaders.append(
        "X-Authorization",
        Decrypt(
            localStorage.getItem(process.env.REACT_APP_SESSION_TICKET_CODE_NAME)
        )
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
        PlayFabId: localStorage.getItem("playfabID"),
        ProfileConstraints: { ShowLocations: true },
    });

    var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
    };

    fetch(
        "https://29001.playfabapi.com/Client/GetPlayerProfile?=",
        requestOptions
    )
        .then((response) => response.text())
        .then((result) => {
            getPlayerProfileCallback(JSON.parse(result).data.PlayerProfile);
        })
        .catch((error) => {
            console.log("error", error);
            getPlayerProfileCallback(false);
        });
}
