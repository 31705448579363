import React, { useState } from "react";
//import { GetLoginData } from "../../../../../Login/scripts/GetLoginData";
import forgot_password from "../../../../../images/forgot_pwd.svg";
var axios = require("axios");

export const PasswordAndSecurity = () => {
    /*const [currentPassword, setCurrentPassword] = useState()
    const [newPassword, setNewPassword] = useState()
    const [confirmPassword, setConfirmPassword] = useState()

    

    function handleCurrentPasswordChanged (e) {
        setCurrentPassword(e)
    }

    function handleNewPasswordChanged (e) {
        setNewPassword(e)
    }

    function handleConfirmPasswordChanged (e) {
        setConfirmPassword(e)
    }

    function handleFormSubmit (e) {
        e.preventDefault()

        // Check if current password is correct
        GetLoginData(localStorage.getItem('contactEmail'), currentPassword, loginCallback, loginErrorsCallback)
    }

    function loginCallback () {
        // if yes then check new and confirm
        if (newPassword === confirmPassword) {
            // if yes then update password
            console.log('update password')
        }
    }

    function loginErrorsCallback() {

    }*/

    const [sendText, setSendText] = useState("Send mail to change password");

    function handleSubmit() {
        if (sendText !== "Send mail to change password") {
            return;
        }

        var data = JSON.stringify({
            EmailTemplateId: "FFA2DA6477FC9357",
            Email: localStorage.getItem("loginEmail"),
        });

        var config = {
            method: "post",
            url: "https://29001.playfabapi.com/Server/SendCustomAccountRecoveryEmail",
            headers: {
                "X-SecretKey": process.env.REACT_APP_PLAYFAB_SECRET_KEY,
                "Content-Type": "application/json",
            },
            data: data,
        };

        axios(config)
            .then(function (response) {
                console.log(response);
                setSendText("Email Sent!");
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    return (
        <>
            <div className="profile_edit_sec">
                <h3>Reset Password</h3>
                <div className="frgt_pd">
                    <img src={forgot_password} alt="Forgot password" />

                    <h4>
                        Click the button below to change your password. We will
                        send a mail with a link where you can change your
                        password.
                    </h4>
                </div>
                <div className="form-group mb-0">
                    <button className="btn_common w-100" onClick={handleSubmit}>
                        {sendText}
                    </button>
                </div>
            </div>
        </>
    );
};
