import React, { useState } from "react";
import PropTypes from 'prop-types'
import FacebookLogin from "react-facebook-login";
import { FacebookSignIn } from "../scripts/FacebookSignIn";

export function FacebookLoginComponent(props) {
    const [login, setLogin] = useState(false);
    const [data, setData] = useState({});
    const [picture, setPicture] = useState("");
    const handleValidationErrors = props.handleValidationErrors

    const responseFacebook = (response) => {

        FacebookSignIn(response.accessToken, props.callback)
        // Login failed
        if (response.status === "unknown") {
            props.loggingInHook(false)
            var errors = {}
            errors.facebook = "Facebook login failed"
            handleValidationErrors(errors)
            setLogin(false);
            return false;
        }
        setPicture(response.picture.data.url);
        if (response.accessToken) {
            props.loggingInHook(true)
            setLogin(true);
        } else {
            setLogin(false);
        }
    };
    const logout = () => {
        setLogin(false);
        setData({});
        setPicture("");
    };

    return (
        <div className="fb_login">
            {!login && (
                <FacebookLogin
                    appId={process.env.REACT_APP_FACEBOOK_APP_ID}
                    autoLoad={false}
                    fields="name,email,picture"
                    scope="public_profile,email,user_friends"
                    callback={responseFacebook}
                    icon="fa-facebook"
                />
            )}

            {login && (
                <div className="card">
                    <div className="card-body">
                        <img className="rounded" src={picture} alt="Profile" />
                        <h5 className="card-title">{data.name}</h5>
                        <p className="card-text">Email ID: {data.email}</p>
                        <a
                            href="#"
                            className="btn btn-danger btn-sm"
                            onClick={logout}
                        >
                            Logout
                        </a>
                    </div>
                </div>
            )}
        </div>
    );
}

FacebookLoginComponent.propTypes = {
    loggingInHook: PropTypes.func,
    callback: PropTypes.func,
    handleValidationErrors: PropTypes.func,
};
