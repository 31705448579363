import { GetInfoFromID } from '../../../../../Utils/getInfoFromID';
import ModuleStructure from '../../../../InfoJSONs/ModuleStructure.json'

export function GetModuleTopics (moduleId) {

    var returnTopics = []

    ModuleStructure.modules.forEach(module => {
        if (module.moduleID === moduleId) {
            module.topics.forEach(topic => {

                const topicInfo = GetInfoFromID(topic.topicID)

                if (topicInfo !== undefined) {
                    returnTopics.push(topicInfo.info)
                }
            });
        }
    });

    return returnTopics
}