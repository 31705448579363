import React from "react";
import '../SCSS/style.css';
import '../SCSS/fonts.css';
import paymentsuccess from '../images/payment_success.svg';
import { Link } from "react-router-dom";
import { CheckForPremium } from "../Utils/checkForPremium";

export const PaymentSuccess = () => {

    CheckForPremium(checkForPremiumCallback)

    function checkForPremiumCallback () {

    }
    

    return (
        <section className="error_block">

            <div className="error_img">
                <img
                    src={paymentsuccess}
                    className="img-fluid"
                    alt="Payment - Success"
                />
            </div>

            <div className="error_txt">
                <h2>SUCCESS!</h2>
                <p>Your payment has been processed successfully.</p>
                <Link to='/Profile/Subscription' className="btn_common mx-50">DONE</Link>
            </div>

        </section>

    );
};


