import React, { useState } from "react";

import immersify_logo from "../../images/immersify_logo.svg";
import shape_1 from "../../images/shape_1.svg";
import { Decrypt } from "../../Utils/Decrypt";

export const DisplayName = () => {
    const [validationError, setValidationError] = useState();

    const [form, setForm] = useState({
        displayName: "",
    });

    const handleSubmit = (e) => {
        e.preventDefault();
        const errors = handleValidation(form);
        setValidationError(errors);

        if (errors === "") {
            setDisplayName(form.displayName);
        }
    };

    const handleChange = (e) => {
        e.preventDefault();

        setForm({
            ...form,
            [e.target.name]: e.target.value,
        });
    };

    function handleSkipButton() {
        window.location.href = process.env.REACT_APP_CLIENT_URL + "/Profile";
    }

    return (
      <>
          <section className="container-fluid">
              <div className="row">
                  <div className="col-xl-7 col-md-6 left_block d-none d-md-block">
                      <div className="signup_image">
                          <div className="shape_block">
                              <div className="shape_1">
                                  <img alt="placeholder" src={shape_1}/>
                              </div>
                              <div className="shape_2">
                                  <img alt="placeholder" src={shape_1}/>
                              </div>
                          </div>
                          <div className="login_text_block">
                              <h1>LEARN.</h1>
                              <h1>EXPERIENCE.</h1>
                              <h1>CONNECT.</h1>
                          </div>
                      </div>
                  </div>
                  <div className="col-xl-5 col-md-6">
                      <div className="logo_wrap">
                          <img alt="placeholder" src={immersify_logo}/>
                      </div>
                      <div className="signup_form_area">
                          <h3>Welcome </h3>
                          <h2>Please Set a Display Name</h2>
                          <div className="form-group">
                              <form onSubmit={handleSubmit}>
                                  <div className="row">
                                      <div className="col-md-12">
                                          <div className="form-field">
                                              <input
                                                type="text"
                                                name="displayName"
                                                value={form.displayName}
                                                onChange={(e) => handleChange(e)}
                                                className="form-control displayName"
                                                placeholder="Display Name*"
                                                required
                                              />
                                              <span className="error_txt">{validationError}</span>
                                          </div>
                                      </div>

                                      <div className="col-md-12">
                                          <div className="btn_area">
                                              <button className="btn_common w-100">Submit</button>
                                          </div>

                                          <div className="btn_area">
                                              <button className="btn_common w-100" onClick={handleSkipButton}>
                                                  Skip
                                              </button>
                                          </div>
                                      </div>
                                  </div>
                              </form>
                          </div>
                      </div>
                  </div>
              </div>
          </section>
      </>
    );

    function handleValidation(form) {
        const displayName = form.displayName;
        var errors = "";

        // must not contain spaces
        if (displayName.includes(" ")) {
            errors = "Display name cannot contain spaces";
            console.log("validation fail");
        }

        // maximum of 25 characters
        if (displayName > 25) {
            errors = "Display name cannot be more than 25 characters long";
            console.log("validation fail");
        }

        // minimum of 3 characters
        if (displayName < 3) {
            errors.displayNa = "Display name must be at least 3 characters long";
            console.log("validation fail");
        }

        if (!displayName) {
            errors = "Display name cannot be empty";
            console.log("validation fail");
        }

        return errors;
    }

    function setDisplayName(displayName) {
        const sessionTicket = Decrypt(localStorage.getItem(process.env.REACT_APP_SESSION_TICKET_CODE_NAME));

        var myHeaders = new Headers();
        myHeaders.append("X-Authorization", sessionTicket);
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            DisplayName: displayName,
        });

        var requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow",
        };

        fetch("https://29001.playfabapi.com/Client/UpdateUserTitleDisplayName", requestOptions)
          .then((response) => response.text())
          .then((result) => {
              if (JSON.parse(result).code === 200) {
                  localStorage.setItem("displayName", displayName);
                  window.location.href = process.env.REACT_APP_API_URL + "/Profile";
              }
          })
          .catch((error) => console.log("error", error));
    }
};
