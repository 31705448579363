

import React from "react";

export const Test = () => {
    return (
        <>
            <iframe
                width="1000"
                height="600"
                src="https://www.youtube.com/embed/du-TY1GUFGk"
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            ></iframe>
            ;
        </>
    );
};