import { getPlayerCountry } from "./getPlayerCountry";

var callback;

export function SetPrice(callbackParam = () => {}) {
    callback = callbackParam;
    getPlayerCountry(getPlayerCountryCallback);
}

function getPlayerCountryCallback(countryCode) {
    if (process.env.REACT_APP_OFFER === true) {
        switch (countryCode) {
            case "GB":
                setPriceInStorage(
                    "£5.99",
                    "£47.99",
                    "£11.99",
                    "£95.99",
                    "£3.99",
                    "£7.99"
                );
                break;
            case "IN":
                setPriceInStorage(
                    "$2.50",
                    "$20.09",
                    "$4.85",
                    "$38.85",
                    "$1.67",
                    "$3.24"
                );
                break;
            case "AU":
                setPriceInStorage(
                    "$7.49",
                    "$62.99",
                    "$14.49",
                    "$118.99",
                    "$5.25",
                    "$9.95"
                );
                break;
            default:
                setPriceInStorage(
                    "$7.49",
                    "$59.99",
                    "$14.99",
                    "$119.99",
                    "$4.99",
                    "$9.99"
                );
                break;
        }
    }
    else {
        switch (countryCode) {
            case "GB":
                setPriceInStorage(
                    "£8.99",
                    "£83.99",
                    "£8.99",
                    "£83.99",
                    "£6.99",
                    "£6.99"
                );
                break;
            case "IN":
                setPriceInStorage(
                    "$4.85",
                    "$38.85",
                    "$4.85",
                    "$38.85",
                    "$3.24",
                    "$3.24"
                );
                break;
            case "AU":
                setPriceInStorage(
                    "$14.49",
                    "$118.99",
                    "$14.49",
                    "$118.99",
                    "$9.92",
                    "$9.92"
                );
                break;
            default:
                setPriceInStorage(
                    "$10.99",
                    "$98.99",
                    "$10.99",
                    "$98.99",
                    "$8.25",
                    "$10.99"
                );
                break;
        }
    }
}

function setPriceInStorage(
    monthly,
    yearly,
    mprev,
    yprev,
    yearlyPerMonth,
    yearlyPreviousPerMonth
) {
    localStorage.setItem("monthlyPrice", monthly);
    localStorage.setItem("yearlyPrice", yearly);
    localStorage.setItem("yearlyPricePerMonth", yearlyPerMonth);
    localStorage.setItem("monthlyPrevious", mprev);
    localStorage.setItem("yearlyPrevious", yprev);
    localStorage.setItem("yearlyPreviousPerMonth", yearlyPreviousPerMonth);
    callback(
        monthly,
        yearly,
        mprev,
        yprev,
        yearlyPerMonth,
        yearlyPreviousPerMonth
    );
}
