import "aframe";
import "aframe-particle-system-component";
import "aframe-extras";

import { Entity, Scene } from "aframe-react";
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

// eslint-disable-next-line
import { animationManager } from '../../../../../../Scripts/aframe/animation-manager.component'
// eslint-disable-next-line
import { imModelManager } from '../../../../../../Scripts/aframe/im-model-manager.component'
// eslint-disable-next-line
import { fadeMaterial } from '../../../../../../Scripts/aframe/fade-material'
// eslint-disable-next-line
import { modelViewer } from "../../../../../../Scripts/aframe/model-viewer";
// eslint-disable-next-line
import { arShadow } from "../../../../../../Scripts/aframe/ar-shadows";

import { lessonMan } from "../../../../../../Scripts/aframe/lesson-data-manager";
import { LessonControls } from "../../../../../AFrame/LessonControls";
import { waitUntil } from "../../../../../Utils/asyncTools";
import { GetLessonData } from "../StandardLesson/StandardLessonContent/InitialiseScene/GetLessonData/GetLessonData";
import { LoadingBar } from "./LoadingBar";

export const AnimatedLesson = (props) => {

    const id = props.id
    const modelDataFileName = GetLessonData(id).modelDataFileName

    const [isModelLoaded, setIsModelLoaded] = useState(false);
    const [isLessonDataLoaded, setIsLessonDataLoaded] = useState(false);
    const [isLessonDataInit, setIsLessonDataInit] = useState(false);

    const toggleFullScreen = (isFullscreen) => {
        console.log({isFullscreen})
    };

    const [animManager, setAnimManager] = useState("test");

    let modelViewer;
    let modelViewerComp;

    useEffect(() => {

        if (modelViewer === undefined) {
            modelViewer = document.getElementById("ModelViewerScene");
            modelViewerComp = modelViewer.components["model-viewer"];

            setupEntireLesson();
        } else {
            return;
        }
    }, []);

    async function setupEntireLesson() {
        // get the lesson data (using prop)
        console.log({modelDataFileName})
        await lessonMan.getLessonData(
            "LessonData/AnimationViewer/" + modelDataFileName
        );
        await waitUntil(() => modelViewerComp.initDone); // wait for model entities to init
        let modelEntity = document.getElementById("model");
        let animManagerComp = modelEntity.components["animation-manager"];
        lessonMan.animationManager = animManagerComp; // set the lesson managers anim vars
        lessonMan.init(); // init the lesson (setup text, points, audio)
    }

    {
        /* LOAD HANDLERS*/
    }
    const modelLoaded = () => {
        setIsModelLoaded(true);
    };
    const lessonDataLoaded = () => {
        setIsLessonDataLoaded(true);
    };
    const lessonDataInit = () => {
        setIsLessonDataInit(true);
    };

    const onAllLoadingComplete = () => {
        waitUntilAnimManager();
    };
    async function waitUntilAnimManager() {
        let modelEntity = document.getElementById("model");
        let animManagerComp = modelEntity.components["animation-manager"];
        await waitUntil(() => animManagerComp !== undefined);
        animManagerComp.setupAnimation();
        setAnimManager(animManagerComp);
    }

    const AssetSection = () => {
        return (
            <a-asset-items
                id="lesson-model-gltf"
                src={
                    process.env.REACT_APP_S3_WEBAPP_ROOT +
                    "LessonData/AnimationViewer/" +
                    lessonMan.lessonJSON.modelData.URL
                }
            />
        );
    };

    const [showText, setShowText] = useState(false);
    const handleShowText = () => {
        if (showText === false) {
            setShowText(true);
        } else {
            setShowText(false);
        }
    };

    const [isActive, setActive] = useState(false);

    const toggleClass = () => {
      setActive(!isActive);
      
    };
    
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])

    return (
        <>
            <div
                className={
                    isActive ? "animation_player" : "animation_player show_ctrl"
                }
                id="animvid"
            >
                <div className="anim_video">
                    {/* LOADING COMPLETE */}
                    {isLessonDataLoaded && isModelLoaded ? (
                        onAllLoadingComplete()
                    ) : (
                        <>
                            <LoadingBar />
                        </>

                    )}

                    {/*SCREEN SPACE UI */}
                    <div className="ctrl_click" onClick={toggleClass}></div>
                    {isLessonDataLoaded && isModelLoaded ? (
                        <LessonControls
                            animManagerProp={animManager}
                            showTextCallback={handleShowText}
                            toggleFullScreen={toggleFullScreen}
                        />
                    ) : null}

                    <div
                        className={
                            showText === true ? "text_exp" : "text_exp d-none"
                        }
                    >
                        <p id="stagePara"></p>
                    </div>

                    <div
                        id="AFrameScene"
                        style={{
                            position: "absolute",
                            height: "100%",
                            width: "100%",
                            pointerEvents: "none",
                        }}
                    >
                        <Scene
                            id="AnimLessonScene"
                            embedded
                            device-orientation-permission-ui="enabled: false"
                            webxr="optionalFeatures: {hit-test, local-floor}"
                            renderer="colorManagement: true; logarithmicDepthBuffer: true"
                            vr-mode-ui="enabled: false"
                        >
                            <a-assets>
                                {/* LOAD MODEL FROM S3 */}
                                {isLessonDataLoaded ? <AssetSection /> : null}
                                <a-asset-item
                                    id="reticle"
                                    src="https://cdn.aframe.io/examples/ar/models/reticle/reticle.gltf"
                                    response-type="arraybuffer"
                                />
                                <img
                                    id="shadow"
                                    src="https://cdn.glitch.com/20600112-c04b-492c-8190-8a5ccc06f37d%2Fshadow.png?v=1606338852399"
                                    crossOrigin="anonymous"
                                />
                                <img
                                    id="grid"
                                    src="https://cdn.glitch.global/77a7d71e-ba1a-4b2d-b416-39f5cce1072f/border.png?v=1654679365401"
                                    crossOrigin="anonymous"
                                />
                            </a-assets>
                            {/* MODEL */}
                            <Entity
                                model-viewer={
                                    isLessonDataInit
                                        ? "gltfModel: #lesson-model-gltf"
                                        : ""
                                }
                                id="ModelViewerScene"
                            />
                            {/* CAMERA */}
                            {/* Controls disabled for now */}
                            {/*  im-orbit-controls="target:#box2" */}
                            <Entity
                                light="type: directional; color: #FFF; intensity: 0.5"
                                position="-0.5 1 1"
                            ></Entity>
                            <Entity id="camControl">
                                <Entity id="camRig">
                                    <Entity id="camOffset" rotation="90 -180 0">
                                        <Entity
                                            id="cameraMain"
                                            camera="fov:36"
                                        />
                                    </Entity>
                                </Entity>
                            </Entity>

                            {/* END CAMERA */}

                            {/* LOADING EVENTS */}
                            <Entity
                                id="loader"
                                events={{
                                    onModelLoaded: () => modelLoaded(),
                                    onLessonDataLoaded: () =>
                                        lessonDataLoaded(),
                                    onLessonDataInit: () => lessonDataInit(),
                                }}
                            />
                            {/* END LOADING EVENTS */}

                            {/* STATIC AUDIO */}
                            <audio
                                id="staticAudioSource"
                                src=""
                                autoPlay={true}
                                preload="metadata"
                            />
                            {/* END STATIC AUDIO */}
                        </Scene>
                    </div>
                </div>
            </div>
        </>
    );
};

AnimatedLesson.propTypes = {
    id: PropTypes.any,
};
