import React from "react";
//import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import "../../../SCSS/style.css";
import defaultUser from "../../../images/user_def.svg";
import avatar_image from "../../../images/user_default.svg";
import { CheckIfLoggedIn } from "../../../Utils/CheckIfLoggedIn";
import { SetPrice } from "../../../Utils/setPrice";
import { firebaseCall } from "../../../Utils/firebaseAnalytics";
import immersify_logo from "../../../images/immersify_logo.svg";

// import { SearchBar } from "./SearchBar/SearchBar";

export const TopHeader = () => {

    let loggedIn = false;
    var displayName;
    if (CheckIfLoggedIn() === true) {
        loggedIn = true;
        displayName = localStorage.getItem("displayName");
    }

    const handleLogOut = () => {
        localStorage.clear();
        SetPrice()
        window.location.href = process.env.REACT_APP_API_URL + "/Landing";
        firebaseCall("sign_out");

    };

    return (
        <div className="top_header">
            <div className="search_n_profile">
                <a className="navbar-brand" href="/">
                    <img src={immersify_logo} alt="Logo"/>
                </a>
                {/*<SearchBar />*/}
                <div className="account_area">
                    <div className="dropdown">
                        {loggedIn ? (
                          <button
                            className="dropdown-toggle"
                            type="button"
                            id="dropdownMenu2"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                              <div className="usr_name">
                                  <span>Hi {displayName}</span>
                              </div>
                              <div className="user_img">
                                  <img
                                    src={avatar_image}
                                    alt="avatar"
                                  />
                              </div>
                          </button>
                        ) : (
                          <button
                            className="dropdown-toggle"
                            type="button"
                            id="dropdownMenu2"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                              <div className="usr_name">
                                  <span>Hi There</span>
                              </div>
                              <div className="user_img">
                                  <img
                                    src={defaultUser}
                                    alt="avatar"
                                  />
                              </div>
                          </button>
                        )}
                        <ul
                          className="dropdown-menu"
                          aria-labelledby="dropdownMenu2"
                        >
                            {loggedIn ? (
                              <>
                                  <li>
                                      <Link
                                        className="dropdown-item"
                                        to="/Profile"
                                      >
                                          {" "}
                                          <i className="fa fa-user"></i>
                                          Profile
                                      </Link>
                                  </li>
                                  <li>
                                      <button
                                        className="dropdown-item"
                                        onClick={handleLogOut}
                                        type="button"
                                      >
                                          <i className="fa fa-sign-out"></i>
                                          Signout
                                      </button>
                                  </li>
                              </>
                            ) : (
                              <li>
                                  <Link className="dropdown-item" to="/Login">
                                      <i className="fa fa-sign-in"></i>Sign in
                                  </Link>
                              </li>
                            )}
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
};
