import { delay } from '../../../components/Utils/asyncTools'
/* global AFRAME, THREE */
AFRAME.registerComponent('camera-manager', {
    schema:{
        distance:{default:8},
        pointID:{default:0}
    },
    init:function(){
        this.onAllModelEntitiesLoaded = this.onAllModelEntitiesLoaded.bind(this);
        this.el.addEventListener('onAllModelEntitiesLoaded', this.onAllModelEntitiesLoaded);

        this.onKeyDown = this.onKeyDown.bind(this);
        window.addEventListener('keydown', this.onKeyDown, false);
    },
    onKeyDown: function (event) {
        //console.log('onKeyDown');
        //console.log(event);
    
        if(event.code == "KeyC"){
            console.log("c pressed");
            let allCamTargets = document.querySelectorAll('.camTarget');
            allCamTargets.forEach(element =>{
                let pos = element.getAttribute('position');
                console.log(this.data.pointID + " " + element.id + " " + JSON.stringify(pos));
            })
        }
      },

    onAllModelEntitiesLoaded:function(){
        //console.log("ALL MODEL ENTITIES FOR THIS SCENE HAVE BEEN CREATED");
        this.resetCamPos();
    },

    resetCamPos:async function(doDollyIn, delayIn){
        let _doDollyIn = doDollyIn;
        let _delay = delayIn;
        // if no doDollyIn value is passed in, set it to true
        if(doDollyIn == undefined || doDollyIn == null){ _doDollyIn = true; }
        if(delayIn == undefined || delayIn == null){ _delay = 200; }
        // wait until this model is displayed before trying to get the scale and set the distance
        await delay(_delay);
        // find the largest model
        let gtlfObjects = this.el.sceneEl.querySelectorAll('[gltf-model]');     
        let largestModel = this.findLargestModel(gtlfObjects);

        // center the camera on the largest model
        this.centerOnLargest(largestModel);        
        // calculate the ideal distance
        let dist = this.calcCamDistance(largestModel);

        let orbitControlsEntity = this.el.sceneEl.querySelector('[im-orbit-controls]'); 
        let orbitControlsComp = orbitControlsEntity.components['im-orbit-controls'];

        // zoom out
        if(_doDollyIn === true){ orbitControlsComp.dollyIn(dist*this.data.distance); }        
    },

    findLargestModel:function(modelEntityList){
        //console.log(modelEntityList);
        let largestBoxScale = 0;
        let largestModel = null;
        modelEntityList.forEach(model =>{
            let gltfObject = model.getObject3D('mesh');
            let box = new THREE.Box3().setFromObject(gltfObject); // create a box from our model
            let boxSize = box.getSize(new THREE.Vector3());
            let boxScale = boxSize.x*boxSize.y*boxSize.z;

            if(boxScale > largestBoxScale)
            {
                largestBoxScale = boxScale;
                largestModel = gltfObject;
            }
        });
        return largestModel;     
    },
    centerOnLargest:function(gltfModel){
        let box = new THREE.Box3().setFromObject(gltfModel); // create a box from our model
        let center = box.getCenter(new THREE.Vector3()); // get the center of the box

        // set the target of orbit controls to center of the model
        let camTarget = this.el.sceneEl.querySelector('.camTarget');        
        camTarget.setAttribute('position', center);
    },
    calcCamDistance:function(model){
        let box = new THREE.Box3().setFromObject(model); // create a box from the largest model
        let boxSize = box.getSize(new THREE.Vector3()); // get its size
        let height = boxSize.y; // get the height

        let cam = this.el.sceneEl.querySelector('.sceneCam').getAttribute('camera');
        let fov = cam.fov;

        let dist = height / 2 / Math.tan(Math.PI * fov / 360);
        return dist;
    }
});

