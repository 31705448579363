import React from "react";
// import privacy_img from "../images/landing/privacy_policy.svg";
// import { Header } from "../Landing/Header/Header";
import { Footer } from "../Landing/Footer/Footer";

export const PrescriptionDisclaimer = () => {
    return (
        <>
            {/* <Header link={true} /> */}
            <section className="container privacy_p">
                <div className="row">
                    <div className="col-md-7">
                        <div className="in_page_text">
                            <div className="main_head">
                                <h2>
                                    Disclaimer
                                    <span className="end_not"></span>
                                </h2>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="container privacy_p_content" id="page-content">
                <div className="row">
                    <div className="col-md-12">
                        <div className="clearfix">
                            <div className="prv_block">
                                This educational resource is intended solely for
                                informational and educational purposes. All the
                                content related to dental prescribing relates to
                                adult patients, to immediate release tablets or
                                capsules, and to an oral route of
                                administration. <br></br>
                                <br></br>
                                Whilst every effort has been made to ensure the
                                accuracy and completeness of the information
                                provided, it should not be used as a substitute
                                for professional advice, diagnosis, or
                                treatment. <br></br>
                                <br></br>
                                Healthcare professionals should exercise their
                                clinical judgment and expertise when
                                interpreting and applying this information to
                                their practice. <br></br>
                                <br></br>
                                Prescribing medications involves complex
                                decision-making, taking into account individual
                                patient needs, potential drug interactions, and
                                the latest clinical guidelines. It is essential
                                to consult relevant drug monographs, clinical
                                guidelines, other authoritative resources, and
                                other healthcare professionals when making
                                prescribing decisions. <br></br>
                                <br></br>
                                Neither the authors nor the publishers of this
                                resource are responsible or liable for any
                                errors, omissions, or for any outcomes related
                                to the use of this material.
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer link={true} />
        </>
    );
};
