import React from 'react';
import ProductCard from "./ProductCard";
import PropTypes from "prop-types";


const ProductCards = ({products, period, selectedProduct, setSelectedProduct, activeProduct}) => {
  return (
    <div className={"product-cards"}>
      {products.map((product) => (
        <ProductCard key={product.name} product={product} period={period} selectedProduct={selectedProduct} setSelectedProduct={setSelectedProduct} activeProduct={activeProduct}/>
      ))}

    </div>
  );
};

ProductCards.propTypes = {
  products: PropTypes.array.isRequired,
  period: PropTypes.string.isRequired,
  selectedProduct: PropTypes.object,
  setSelectedProduct: PropTypes.func.isRequired,
  activeProduct: PropTypes.object.isRequired
}

export default ProductCards;