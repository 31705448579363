import { GetAccountInfo } from "./GetLoginData";

export function FacebookSignIn(accessToken, callback = () => {}) {
    console.log("sign in");

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
        AccessToken:
            accessToken,
        TitleId: "29001",
        CreateAccount: "true",
    });

    var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
    };

    fetch(
        "https://29001.playfabapi.com/Client/LoginWithFacebook",
        requestOptions
    )
        .then((response) => response.text())
        .then((result) => {
            GetAccountInfo(result, 'facebook', handleGotAccountInfo)
        })
        .catch((error) => console.log("error", error));

    callback(true);
}

function handleGotAccountInfo () {
    window.location.href = process.env.REACT_APP_API_URL + "/Premium";
}