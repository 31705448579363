import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import "../../../../../SCSS/style.css";
import "../../../../../SCSS/fonts.css";

//import close_button_image from "../../../../../images/close_btn.svg"

import premium from "../../../../../images/diamond.svg";
import { PremiumBlock } from "./PremiumBlock/PremiumBlock";
import { FreeBlock } from "./FreeBlock/FreeBlock";
import { CheckForPremium } from "../../../../../Utils/checkForPremium";

export const PremiumAdvert = (props) => {
    const [subscribed, setSubscribed] = useState(false)
    const [expiredReceipt, setExpiredReceipt] = useState(false)

    useEffect(() => {
        CheckForPremium(isSubscriberCallback, "premiumAdvert");
    }, []);
    
    function isSubscriberCallback(premium, expiredReceipt) {

        if (premium === true || premium === "true") {
            window.location.href = process.env.REACT_APP_CLIENT_URL + "/Experience"
        }

        setSubscribed(premium)
        setExpiredReceipt(expiredReceipt)
        if ((premium === false || premium === "false") && expiredReceipt === true) {
            alert("SHOW POPUP")
        }
    }

    function handleFormSubmit () {

        localStorage.removeItem("activeUntil")
        localStorage.removeItem("interval")
        localStorage.removeItem(process.env.REACT_APP_PREMIUM_CODE_NAME + "date")
        localStorage.removeItem(process.env.REACT_APP_PREMIUM_CODE_NAME + "value")

        if (monthly === "false" || monthly === false) {
            props.handleFormSubmit("yearly")
        }
        else props.handleFormSubmit("monthly")
    }

    const [monthly, setMonthly] = useState(true)

    function handleToggle () {
        if (monthly === true) {
            setMonthly(false)
        }
        else {
            setMonthly(true)
        }
    }

    return (
        <>
            {expiredReceipt === true || expiredReceipt === "true" ? (
                <h1>Expired Popup</h1>
            ) : (
                <></>
            )}
            {subscribed === true ? (
                <h1>Already subscribed</h1>
            ) : (
                <section className="container-fluid">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="premium_header">
                                <img src={premium} alt="Premium" />
                                <span>GO PREMIUM</span>
                            </div>
                            <div className="pay_route">
                                <span>Pay Monthly</span>{" "}
                                <div className="toggle_sw">
                                    <input
                                        type="checkbox"
                                        id="switch"
                                        onChange={handleToggle}
                                    />
                                    <label htmlFor="switch"></label>
                                </div>
                                <span>Pay Yearly</span>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="premium_blocks">
                                <div className="row">
                                    <FreeBlock />
                                    <PremiumBlock
                                        handleFormSubmit={handleFormSubmit}
                                        monthly={monthly}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            )}
        </>
    );
};

PremiumAdvert.propTypes = {
    handleFormSubmit: PropTypes.func,
};
