import React, { useEffect } from "react";
import PropTypes from "prop-types";
import "../../../../SCSS/style.css";

import { GetLessonType } from "./GetLessonType/GetLessonType";

import { AnimatedLesson } from "./AnimatedLesson/AnimatedLesson";
import { StandardLesson } from "./StandardLesson/StandardLesson";
import { ErrorPage } from "../../../error";
import { NextComing } from "./NextComing/NextComing";
// import { Recommended } from "./Recommended/Recommended";
import { AnimatedLessonHeader } from "./AnimatedLesson/AnimatedLessonHeader/AnimatedLessonHeader";
import { CheckForPremium } from "../../../../Utils/checkForPremium";
import { CheckIfLocked } from "../../../../Utils/checkIfLocked";
import {firebaseCall} from "../../../../Utils/firebaseAnalytics"
// import { VMCQ } from "./VMCQ/VMCQ";

export const DisplayLesson = (props) => {

    useEffect(() => {
        const locked = CheckIfLocked(id)
        if (locked === true) {
            CheckForPremium(checkForPremiumCallback, "displayLesson")
        }
        firebaseCall("launch_activity", {activity_id: id, contact_email: localStorage.getItem("contactEmail")});
        firebaseCall("lesson_start", {activity_id: id});
        firebaseCall("lesson_navigation_open", {activity_id: id});
    }, []);

    function checkForPremiumCallback (data) {
        if (data === false || data === "false") {
            window.location.href = process.env.REACT_APP_API_URL + "/Experience";
        }
    }

    const id = props.id;

    const lessonType = GetLessonType(id);
    console.log(lessonType);


    const content = () => {
        switch (lessonType) {
            case 'animated':
                return  (
                    <>
                        <AnimatedLessonHeader id={id} />
                        <AnimatedLesson id={id} />
                    </>
                )
            case 'standard':
                return <StandardLesson id={id} />
            // case 'vmcq':
            //     return <VMCQ id={id}/>
            default:
                return <ErrorPage />
        }
    }


    const wrapper = () => {
        return (
            <>
                <section className="container-fluid lesson_wrap">
                    <div className="row">
                        <div className="col-md-9">
                            <div className="lesson_block">
                                {content()}
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="suggest_block">
                                <NextComing id={id} displayContentCallback={props.displayContentCallback} />
                               
                                {/* <div className="content_links">
                                <Recommended id={id} displayContentCallback={props.displayContentCallback} />
                                </div> */}
                            </div>
                        </div>
                    </div>
                </section>
            </>
        );
    }

    return <>{wrapper()}</>;
};

DisplayLesson.propTypes = {
    id: PropTypes.string,
    displayContentCallback: PropTypes.func,
};
