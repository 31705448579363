import React from "react";
import PropTypes from "prop-types";

export const CreateAssets = (props) => {

    const standardLessonMan = props.standardLessonMan

    // need to create asset tags for eah model in this lesson
    let models = standardLessonMan.lessonJSON.modelData.models;

    let output = models.map((model) => (
        <a-asset-items
            key={model.id}
            id={model.id}
            src={process.env.REACT_APP_S3_WEBAPP_ROOT + model.URL}
        />
    ));

    return output;
};

CreateAssets.propTypes = {
    standardLessonMan: PropTypes.any,
}
