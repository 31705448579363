import { waitUntil } from "../../components/Utils/asyncTools";

const initialisedEvent = new CustomEvent("initialised");

/* global AFRAME,THREE */
AFRAME.registerComponent("model-entity-maker", {
    schema: {
        loaderID: { default: "loader" },
    },
    init: function () {
        this.loader = document.getElementById(this.data.loaderID);

        this.onModelLoaded = this.onModelLoaded.bind(this);

        this.totalModels = 0;
        this.modelsToLoad = [];
        this.modelJSONList = [];
        this.loadedModels = [];
        this.billboardObjects = [];
        this.allModelsLoaded = false;
        this.modelLoaded = false;
        this.modelsSetup = false;
        //this.propID = null;

        document.dispatchEvent(initialisedEvent);

        //this.onKeyDown = this.onKeyDown.bind(this);
        //window.addEventListener('keydown', this.onKeyDown, false);
    },

    makeModelEntities: async function (listModelJSON, propID) {
        //this.propID = propID;
        this.modelJSONList = listModelJSON;
        this.totalModels = listModelJSON.length;

        listModelJSON.forEach((modelJSONObj) => {
            this.modelsToLoad.push(modelJSONObj.id); // add this id to an internal list of IDs

            let newModelEntity = document.createElement("a-entity");
            newModelEntity.id = modelJSONObj.id + "ModelEntity";
            newModelEntity.setAttribute("gltf-model", "#" + modelJSONObj.id);

            newModelEntity.addEventListener("model-loaded", this.onModelLoaded);

            this.el.sceneEl.appendChild(newModelEntity);

            this.setupBillboard(newModelEntity, modelJSONObj.billboardAffix);

            if (modelJSONObj.relPointData !== undefined) {
                let relatedPointDataForModelTEMP =
                    modelJSONObj.relPointData.filter((obj) => {
                        return obj.pointIndex == propID;
                    });
                //console.log(propID);
                if (
                    relatedPointDataForModelTEMP === undefined ||
                    relatedPointDataForModelTEMP.length <= 0
                ) {
                    return;
                }
                let relatedPointDataForModel = relatedPointDataForModelTEMP[0];
                if (relatedPointDataForModel.position !== undefined) {
                    newModelEntity.setAttribute(
                        "position",
                        relatedPointDataForModel.position
                    );
                }
                if (relatedPointDataForModel.rotation !== undefined) {
                    newModelEntity.setAttribute(
                        "rotation",
                        relatedPointDataForModel.rotation
                    );
                }
                if (relatedPointDataForModel.subMeshes !== undefined) {
                    this.setupSubMeshes(
                        newModelEntity,
                        relatedPointDataForModel.subMeshes
                    );
                }
                //Aniamtion
                if (relatedPointDataForModel.animated !== undefined) {
                    newModelEntity.setAttribute("animation-mixer", "");
                    if (relatedPointDataForModel.animated === true) {
                        this.playAnimation(newModelEntity);
                    }
                }
                // Camera Focus point
                if (relatedPointDataForModel.camPos !== undefined) {
                    this.camPos = relatedPointDataForModel.camPos;
                } else {
                    this.camPos = "";
                }
            }
        });

        this.modelsSetup = true;
    },

    onModelLoaded: function (e) {
        this.loadedModels.push(e.target.id.replace("ModelEntity", "")); // remove 'ModelEntity' from the id so we are left with the raw model ID
        this.checkAllModelsLoaded(e.target.parentNode); // each time a model is loaded, check if all models have been loaded
        this.updateCamTargetPos();
        this.modelLoaded = true;
    },

    // eslint-disable-next-line no-unused-vars
    checkAllModelsLoaded: function (sceneNode) {
        if (this.loadedModels.length != this.totalModels) {
            return;
        }

        if (
            this.loadedModels.sort().join(",") ===
            this.modelsToLoad.sort().join(",")
        ) {
            //console.log("all models loaded for " + sceneNode.id);
            this.allModelsLoaded = true;
            this.loader.emit("onAllModelsLoaded"); // emit the all models loaded event
            // once we have create all the model entities, setup the camera
            let camManager = this.el.sceneEl.querySelector(".sceneCam");
            camManager.emit("onAllModelEntitiesLoaded");
        } else {
            console.log(
                "same NUMBER of models were loaded, but not all models were loaded"
            );
        }
    },

    // UPDATE CAM TARGET POS
    updateCamTargetPos: async function () {
        let selfRef = this;
        try {
            if (selfRef.camPos === "" || selfRef.camPos === undefined) {
                return;
            }
            let camManager = this.el.sceneEl.querySelector(".sceneCam");
            await camManager.components["camera-manager"].resetCamPos();
            //await delay(100);
            //let id = selfRef.data.loaderID.replace("loader", "");
            //console.log(id);
            let camTargetName = selfRef.data.loaderID.replace(
                "loader",
                "camTarget"
            );
            //console.log(camTargetName);
            let camTargetElement = document.getElementById(camTargetName);
            //console.log(camTargetElement);
            //console.log(selfRef.camPos);
            camTargetElement.setAttribute("position", selfRef.camPos);
        } catch (e) {
            console.log(e);
        }
    },

    // ANIMATION
    playAnimation: async function (modelEntity) {
        let selfRef = this;
        // wait until the model is loaded before we try to play any animations
        await waitUntil(() => selfRef.allModelsLoaded === true);
        //console.log(modelEntity.components);
        let animMixer = modelEntity.components["animation-mixer"];
        animMixer.mixer.timeScale = 1;
        //console.log(animMixer.mixer._root.animations)
        //this.animDuration = this.animationMixer.mixer._root.animations[0].duration;
        //this.tracks = this.animationMixer.mixer._root.animations[0].tracks;
    },

    // SUB MESHES
    setupSubMeshes: async function (modelEntity, subMeshes) {
        let selfRef = this;
        // wait until the model is loaded before we try to get the mesh
        await waitUntil(() => selfRef.allModelsLoaded === true);
        var mesh = modelEntity.getObject3D("mesh");
        mesh.traverse((node) => {
            // if sub meshes contains this node then change its visiblity
            subMeshes.forEach((sm) => {
                if (sm.name == node.name) {
                    //console.log(node.name);
                    //console.log(sm.name);
                    //console.log(sm.visible);
                    // Set visible state
                    node.visible = sm.visible;
                }
            });
        });
    },

    tick: function () {
        if (this.modelsSetup) {
            this.billboard(this.billboardObjects);
        }
    },

    // Billboard
    setupBillboard: async function (modelEntity, billboardAffix) {
        let selfRef = this;
        // wait until the model is loaded before we try to get the mesh
        await waitUntil(() => selfRef.allModelsLoaded === true);
        var mesh = modelEntity.getObject3D("mesh");
        mesh.traverse((node) => {
            if (node.name.includes(billboardAffix)) {
                selfRef.billboardObjects.push(node);
            }
        });
    },
    billboard: function (billboardObjects) {
        let sceneCam = document.getElementById(
            "sceneCam" + localStorage.getItem("currentPoint")
        );

        // make each 3D object face the camera
        billboardObjects.forEach((threeDObject) => {
            //console.log(threeDObject);
            let camPos = new THREE.Vector3(
                sceneCam.components["position"].data.x,
                sceneCam.components["position"].data.y,
                sceneCam.components["position"].data.z
            );
            threeDObject.lookAt(camPos.x, camPos.y, camPos.z); // look at cam
            threeDObject.rotateOnAxis(
                new THREE.Vector3(-1, 0, 0),
                Math.PI * -0.5
            ); // fix rotation on pitch axis to face cam
        });
    },
});
