import React from "react";
import PropTypes from "prop-types";

import Universities from "../../../../../InfoJSONs/Universities.json";

export const CountryOptions = (props) => {
    const selectedCountry = props.selectedCountry;

    var countryOptions = [
        <option key={"listNameCountries"} disabled value={"Country*"}>
            Country*
        </option>,
    ];

    if (selectedCountry === "Country*") {
        countryOptions[0] = <option key={"listNameCountries"} disabled selected value={"Country*"}>
            Country*
        </option>
    }

    const backup = console.warn;

    console.error = function filterWarnings(msg) {
        const supressedWarnings = ["Use the `defaultValue` or `value` props on <select> instead of setting `selected` on <option>."];

        if (!supressedWarnings.some((entry) => msg.includes(entry))) {
            backup.apply(console, arguments);
        }
    };

    Universities.map((countryObj, index) => {
        const country = Object.keys(countryObj)[0];
        if (country.toLowerCase() === selectedCountry) {
            countryOptions.push(
                <option
                    key={"option" + country + index}
                    value={country}
                    selected
                >
                    {country}
                </option>
            );
        }
        else {
            countryOptions.push(
                <option key={"option" + country + index} value={country}>
                    {country}
                </option>
            );
        }
    });

    return countryOptions;
};

CountryOptions.propTypes = {
    selectedCountry: PropTypes.string,
};
