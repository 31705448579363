import React from "react";

export const QRcodes = () => {
   

    return (
        <>
            <div className="profile_edit_sec qr_c">
            <h3>Content available</h3>
            <p>Scan the QR code to directly access the specific topic in the app.</p>
                
                <div className="reports_sec">
                    <iframe src="https://im-sales-portal-8acc2a83f73b.herokuapp.com/QRCodes.html"></iframe>
                </div>
                
            </div>
        </>
    );
};

