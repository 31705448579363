import React, { useEffect } from "react";

import immersify_logo from "../../images/immersify_logo.svg";
import shape_1 from "../../images/shape_1.svg";

import { useParams } from "react-router-dom";
import { DownloadS3File } from "../../Utils/downloadS3File";
import { TokenRequest } from "@openid/appauth";

var code;

// try to deploy

// testingouinsadf


export const UniSSOAppCode = () => {
    code = Object.values(useParams())[0];

    useEffect(() => {
        DownloadS3File(
            "UniSSO/connections_list_live.json",
            gotConnectionsListCallback,
            "com.immersifyeducation.webdev"
        );
    }, []);

    function handleManualRedirect() {
        DownloadS3File(
            "UniSSO/connections_list_live.json",
            gotConnectionsListCallback,
            "com.immersifyeducation.webdev"
        );
    }
    function gotConnectionsListCallback(data) {
        // function generateNonce(length) {
        //     var nonce = CryptoJS.lib.WordArray.random(length).toString(CryptoJS.enc.Base64);
        //     return nonce;
        // }

        const connections = JSON.parse(data).OpenIDConnections;
        var connection_id;
        let suffix = localStorage.getItem("suffix");
        if (!suffix) {
            suffix = "jcu.edu.au";
        }
        console.log({ suffix });

        var accessTokenURL;
        let client_id;
        // const tokenHandler = new BaseTokenRequestHandler();
        let request;
        let id_token_data;
        connections
            .forEach((connection) => {
                console.log(connection.suffix);

                const openIdConnectUrl = "https://sso.jcu.edu.au/openam/oauth2/.well-known/openid-configuration";

                let configuration = null;

                console.log(openIdConnectUrl);
                fetch(openIdConnectUrl)
                    .then((response) => {
                        if (!response.ok) {
                            throw new Error(`HTTP error! Status: ${response.status}`);
                        }
                        return response.json();
                    })
                    .then((data) => {
                        console.log("OpenID Configuration:", data);
                        configuration = data; // Set the configuration variable to the response
                        console.log(configuration);
                        console.log(connection.suffix);
                        console.log(suffix);
                        if (connection.suffix === suffix) {
                            console.log(connection);
                            console.log("connection suffix checked as " + suffix);
                            let extras;
                            if (request && request.internal) {
                                extras = {};
                                extras["code_verifier"] = request.internal["code_verifier"];
                            }
                            request = new TokenRequest({
                                client_id: connection.clientID,
                                redirect_uri: process.env.REACT_APP_CLIENT_URL + "/UniSSO",
                                grant_type: "authorization_code",
                                code: code,
                                client_secret: process.env.REACT_APP_JCU_SECRET,
                                refresh_token: undefined,
                                extras: extras,
                            });
                            // const tokenEndpoint = configuration.token_endpoint; // Get this from the OpenID Configuration

                            const tokenRequestBody = new URLSearchParams();
                            tokenRequestBody.append("client_id", request.client_id);
                            tokenRequestBody.append("redirect_uri", request.redirect_uri);
                            tokenRequestBody.append("grant_type", request.grant_type);
                            tokenRequestBody.append("code", request.code);
                            tokenRequestBody.append("client_secret", request.client_secret);
                            tokenRequestBody.append("access_type", "offline");
                            // Add any other required parameters as necessary

                            console.log(request);
                            console.log(tokenRequestBody);

                            connection_id = connection.connectionID;
                            accessTokenURL = connection.mechanism.accessTokenURL;
                            accessTokenURL = accessTokenURL + "?code=" + code;
                            client_id = connection.clientID;
                            accessTokenURL = accessTokenURL + "&client_id=" + client_id;
                            accessTokenURL =
                                accessTokenURL +
                                "&client_secret=" +
                                encodeURIComponent(process.env.REACT_APP_JCU_SECRET) +
                                "&redirect_uri=" +
                                process.env.REACT_APP_CLIENT_URL +
                                "/UniSSO" +
                                "&grant_type=authorization_code";
                            +"&access_type=offline";

                            console.log(accessTokenURL);
                            fetch(accessTokenURL, {
                                method: "POST",
                                headers: {
                                    "Content-Type": "application/x-www-form-urlencoded",
                                },
                                body: tokenRequestBody,
                            })
                                .then((response) => response.json())
                                .then((data) => {
                                    console.log("Token Response:", data);
                                    id_token_data = data;
                                    console.log(connection.mechanism.scopes);
                                    const connectionScopesArray = connection.mechanism.scopes.split(" ");
                                    console.log(connectionScopesArray);
                                    const userScopesArray = data.scope.split(" ");
                                    console.log(userScopesArray);
                                    const allScopesPresent = connectionScopesArray.every((scope) =>
                                        userScopesArray.includes(scope)
                                    );
                                    console.log("allScopesPresent", allScopesPresent);
                                    if (allScopesPresent) {
                                        window.location.href =
                                            "immersifyeducation://immersifydental?idToken=" + id_token_data.id_token;
                                    } else {
                                        window.location.href = process.env.REACT_APP_CLIENT_URL + "/Profile";
                                    }
                                })
                                .catch((error) => {
                                    console.error("Error fetching the token:", error);
                                });
                        }
                    });
                console.log(connection_id);
            })
            .catch((error) => {
                console.error("Failed to fetch OpenID Configuration:", error);
            });

        // if (connection_id === undefined || connection_id === null || connection_id === "") {
        //     return;
        // }

        console.log(accessTokenURL);

        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        myHeaders.append("Access-Control-Allow-Origin", "*");
        myHeaders.append("client_secret", process.env.REACT_APP_JCU_SECRET);

        var raw = JSON.stringify({
            grant_type: "authorization_code",
            code: code,
        });

        var requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow",
            mode: "no-cors",
        };

        console.log(accessTokenURL);
        fetch(accessTokenURL, requestOptions)
            .then((response) => {
                console.log("Status:", response.status);
                console.log("Status text:", response.statusText);
                console.log("Headers:", [...response.headers]);
                return response.text(); // or response.json() if you expect a JSON response
            })
            .then((result) => {
                console.log("Body:", result);
            })
            .catch((error) => console.log("Error:", error));
    }

    return (
        <>
            <section className="container-fluid">
                <div className="row">
                    <div className="col-xl-7 col-md-6 left_block d-none d-md-block">
                        <div className="signup_image">
                            <div className="logo_wrap">
                                <img alt="placeholder" src={immersify_logo} />
                            </div>
                            <div className="shape_block">
                                <div className="shape_1">
                                    <img alt="placeholder" src={shape_1} />
                                </div>
                                <div className="shape_2">
                                    <img alt="placeholder" src={shape_1} />
                                </div>
                            </div>
                            <div className="login_text_block">
                                <h1>LEARN.</h1>
                                <h1>EXPERIENCE.</h1>
                                <h1>CONNECT.</h1>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-5 col-md-6">
                        <div className="signup_form_area">
                            <h3>Please do not refresh </h3>
                            <h2>Redirecting...</h2>
                        </div>
                        <button onClick={handleManualRedirect}>
                            If you have been waiting longer than a few seconds please click here
                        </button>
                    </div>
                </div>
            </section>
        </>
    );
};
