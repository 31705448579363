export async function databaseRequest(
    method,
    route,
    body
) {
    const BASE_URL = process.env.REACT_APP_SERVER_URL || "";
    method = method.toUpperCase();
    if (route.startsWith("/")) route = route.substring(1);
    if (body === undefined || body === null || method === "GET") body = {};

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
        ...body
    });

    var requestOptions = {
    method: method,
    headers: myHeaders,
    ...(method !== "GET" && { body: raw }),
    redirect: 'follow'
    };

    fetch(BASE_URL + "/api/API/" + method + "/" + route, requestOptions)
    .then(response => response.text())
    .then(result => console.log("GOT RESULT", result))
    .catch(error => console.log('error', error));
}
