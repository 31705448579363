import React from 'react'
import PropTypes from 'prop-types'

export const UsernameField = (props) => {

    const username = props.username
    const handleUsernameChanged = props.handleUsernameChanged

    return (
        <>
            <div className="form-group">
                <div className="form-field">
                    <input
                        type="text"
                        onChange={(e) => handleUsernameChanged(e.target.value)}
                        className="form-control email"
                        value={username}
                    />
                    <span className="error_txt d-none">Incorrect email</span>
                </div>
            </div>
        </>
    );
}

UsernameField.propTypes = {
    username: PropTypes.string,
    handleUsernameChanged: PropTypes.func,
}
