// eslint-disable-next-line
// import {downloadS3File} from './S3-file-manager'
function vmcqFeedbackModelManager(){
    this.gotModelsJSON = false;
    this.initialized = false;    
    this.modelsJSON;


    this.init = async function(modelFilename, loaderID, incorrectAnswerData) {
        // console.log("loader id", loaderID)
        this.loader = document.getElementById(loaderID);
        await this.getLessonData(modelFilename, incorrectAnswerData);        
        
        this.initialized = true;              
    }

    this.getLessonData = async function(modelFilename, incorrectAnswerData) {
        // var s3Result = await downloadS3File(modelFilename);
        // this.modelsJSON = JSON.parse(s3Result);
        this.modelsJSON = incorrectAnswerData;
        console.log("models json", this.modelsJSON, incorrectAnswerData);
        this.gotModelsJSON = true;
        this.loader.emit("onVMCQModelsLoaded"); 
    }

    this.removePoint = function(index){
        let allAframeScenes = document.querySelectorAll('.standard_player');
        allAframeScenes[index].parentNode.removeChild(allAframeScenes[index]);
    }
}

export var vmcqFeedbackModelMan = new vmcqFeedbackModelManager();
export async function setupLesson(modelFilename, loaderID, incorrectAnswerData){
    vmcqFeedbackModelMan.init(modelFilename, loaderID, incorrectAnswerData);
}