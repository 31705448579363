import React from 'react';
import { useTimer  } from 'react-timer-hook';
import PropTypes from "prop-types";

let notFullTimeAddition = 0;
export default function Timer({ timeChange, gameOver, expiryTimestamp, answered, isCorrect }) {

    const {
        seconds,
        pause,
        resume,
        restart,
      } = useTimer({ expiryTimestamp, 
          onExpire: () => {
            console.log('onExpire called') 
            gameOver();
          }
        });

  function changeTimer() {
      //time to be added 
      
      const time = new Date();
      //if its over 25 seconds only want to go up to 30
      if (seconds + timeChange > 30) {
        time.setSeconds(time.getSeconds() + 30);
        notFullTimeAddition = 30 - seconds;
      } else {
        time.setSeconds(time.getSeconds() + seconds + timeChange);
        notFullTimeAddition = timeChange;
      }
      if (seconds + timeChange <= 0) {
        gameOver();
      }
      restart(time);
      pause();
  }
  function paused() {
    pause();
  }
  function resumed() {
    resume();
  }

  return (
    <>
      <div className="vmcqTimer">
        <svg
        height="40"
        width="40"
  >     
        <circle
              stroke="#e6e6e6"
              fill="#FFF"
              strokeDasharray="108.9 108.9"
              strokeWidth="4"
              style={{"strokeDashoffset": 0}}
              r="17.333"
              cx="20"
              cy="20"
            />
          <circle
            stroke={seconds > 3 ?"#346eba":"#f00"}
            fill="transparent"
            strokeDasharray="108.9 108.9"
            strokeWidth="4"
            transform='rotate(-90) translate(-40 0)'
            style={{"strokeDashoffset": -108.9 + (seconds / 30) * 108.9}}
            r="17.333"
            cx="20"
            cy="20"
            />

            <text  
              textAnchor="middle"
              className={seconds > 3 ? "" : "vmcqTimerFlash"}
              fill={seconds > 3 ?"#346eba":"#f00"}
              style={{"fontWeight": 600}}
              x="20" y="25">
              {seconds}
            </text>
        </svg>
        <div></div>
        <button style={{display:'none'}} id="pauseButton" onClick={paused}></button>
        <button style={{display:'none'}} id="resumeButton" onClick={resumed}></button>
        <button style={{display:'none'}} id="restartButton" onClick={changeTimer}></button>
      </div>
      <div
        id="vmcqTimeChange"
        className={ answered ? isCorrect ? "vmcqCorrectAnimation" : "vmcqIncorrectAnimation" : ""}
      >
        {timeChange > 0 ? "+" +notFullTimeAddition : timeChange}
      </div>
    </>

  );
}

Timer.propTypes = {
    expiryTimestamp: PropTypes.date,
    gameOver: PropTypes.func,
    timeChange: PropTypes.number,
    answered: PropTypes.bool,
    isCorrect: PropTypes.bool,
};