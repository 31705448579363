//import { waitUntil } from '../../../src/components/Utils/asyncTools'
/* global AFRAME, THREE */
AFRAME.registerComponent('fade-material', {
  init: function () {
    this.fadedMats = [];
    this.fadeAnimations = [];
  },

  setMaterialOpacity:function(value, mayaMat){
    let mesh = this.el.getObject3D("mesh");
    mesh.traverse((node) => {
      if (node.material !== undefined && mayaMat.name == node.material.name) {
        node.material.opacity = value;     
      }
    });
  },

  fadeOutMaterials: function (targetMaterials, duration) {
    //console.log("FADING OUT MATERIALS!");
    let mesh = this.el.getObject3D("mesh");
    let selfRef = this;
    selfRef.beginFade(targetMaterials, duration, mesh, selfRef, true);
  },
  fadeInMaterials: function (targetMaterials, duration) {
    //console.log("FADING IN MATERIALS!");
    let mesh = this.el.getObject3D("mesh");
    let selfRef = this;
    selfRef.beginFade(targetMaterials, duration, mesh, selfRef, false);
  },
  beginFade: async function(mayaMats, duration, mesh, selfRef, fadeOut){
    let mayaMatNames = [];
    let foundMatNames = [];
    let foundNodeMat = [];

    mayaMats.forEach(function (mayaMat) { mayaMatNames.push(mayaMat.name); });

    mesh.traverse((node) => {
      if (node.material !== undefined && mayaMatNames.includes(node.material.name)) {
        foundMatNames.push(node.material.name); 
        foundNodeMat.push(node.material); 
      }
    });

    foundMatNames.forEach(function(foundMatName){
      if(mayaMatNames.includes(foundMatName)){        
        // get the matching maya mat, and the matching nodeMat
        let mayaMat = mayaMats.find(obj => obj.name === foundMatName);
        let nodeMat = foundNodeMat.find(obj => obj.name === foundMatName);
        nodeMat.transparent = true;
        nodeMat.depthWrite = false;
        nodeMat.side = 2; // ensure the material is 2 sided

        // define a fade animation to track the progress of each animation
        var fadeAnimation = {
          material: mayaMat,
          fadeAnimDuration: duration,
          fadeAnimOut:fadeOut,
          status: "in-progress"
        };
        selfRef.fadeAnimations.push(fadeAnimation);
        // now play the fade animation
        selfRef.fadeMaterial(mayaMat, nodeMat, duration, selfRef, fadeOut, fadeAnimation);
        
        // if we were fading in, after the fade has completed,
        // reset the material properties (otherwise it will look weird, in some cases)
        if(!fadeOut){
          // changing this back causes the model to flash for some reason
          //nodeMat.transparent = false;
          nodeMat.depthWrite = true;
          nodeMat.side = THREE.DoubleSide;
        }

        if(fadeOut){
          selfRef.fadedMats.push(mayaMat);
          localStorage.setItem("FadedMats", JSON.stringify(selfRef.fadedMats));
        }else{
          // remove faded mat
          let indexOfObjToRemove = selfRef.fadedMats.findIndex(
            (element) =>
              element.name == mayaMat.name && 
              element.outTo == mayaMat.outTo &&
              element.inTo == mayaMat.inTo
          );
          selfRef.fadedMats.splice(indexOfObjToRemove, 1);
          // remove from local storage
          if(selfRef.fadedMats.length == 0){ localStorage.removeItem("FadedMats");} 
        }        
      }
    }); 
  },
  fadeMaterial: function (mayaMat, material, duration, selfRef, fadeOut, fadeAnim) {
    //var timeElapsed = 0;
    let startingOpacity = material.opacity;
    //let fadeComplete = false;
    
    // function to animate fade
    function animateFade() { // can have input 'timestamp'
        //var delta = timestamp - lastTimestamp;
        //lastTimestamp = timestamp;
        //timeElapsed += delta;
        //delta = delta / 1000; // convert delta to ms
        
        // calculate the fade rate
        var fadeRate;
        if(fadeOut){
          fadeRate = ((startingOpacity - mayaMat.outTo) / (duration))/30;
        }else{
          fadeRate = ((startingOpacity - mayaMat.inTo) / (duration))/30;
        } 
        
        // calculate the current opacity of the material
        let currentOpacity;
        if(fadeOut){
          currentOpacity = (material.opacity - fadeRate);
        }else{
          currentOpacity = (material.opacity + (fadeRate*-1));
          //console.log(material.name + " in opacity: " + currentOpacity);        
        }
        material.opacity = currentOpacity;

        // check if the animation is complete
        if(fadeOut){
          if (currentOpacity <= mayaMat.outTo) {
            material.opacity = mayaMat.outTo;          
            //fadeComplete = true;
            fadeAnim.status = "complete";
            //console.log("fade out complete");
          }
        }else{
          if(currentOpacity >= mayaMat.inTo){   
            material.opacity = mayaMat.inTo;      
            //fadeComplete = true;
            fadeAnim.status = "complete";
            //console.log("fade in complete");
          }
        }

        // if the animation is not complete, call the animateFade function again
        if (fadeAnim.status !== "complete" && !fadeAnim.status !== "cancelled") {
            requestAnimationFrame(animateFade);
        }
    }
    requestAnimationFrame(animateFade);
  },

  // function to cancel all in-progress fades
  cancelAllFades: function(selfRef) {
    //console.log(selfRef.fadeAnimations);
    selfRef.fadeAnimations.forEach(function(fadeAnimation) {
      if(fadeAnimation.status === 'in-progress') {
        fadeAnimation.status = 'cancelled';
      }
    });
    
    selfRef.fadeAnimations = [];
  },

  // check if mesh material is faded
  /*checkMatFaded:function(matName){
    let mesh = this.el.getObject3D("mesh");
    mesh.traverse((node) => {
      if (node.material !== undefined && node.material.name == matName && node.material.opacity < 0.98) {
        console.log("FADED MAT DETECTED");
        return true;
      }
    });
  }*/
});