import { GetLoginData } from "./GetLoginData";

export function UniEmailSignIn (email, password) {
    GetLoginData(
        email,
        password,
        handleLoginComplete,
        handleValidationErrors
    );
}

function handleLoginComplete () {

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
        filename: "UniSSO/suffix_list.json",
    });

    var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
    };

    fetch(process.env.REACT_APP_SERVER_URL + "/api/s3download", requestOptions)
        .then((response) => response.text())
        .then((result) => {
            const suffixList = JSON.parse(result)
            const emailSuffix = localStorage.getItem('contactEmail').split("@")
            
            suffixList.EmailVerifyList.forEach(element => {
                if (element.suffix === emailSuffix[1] && element.verifiable === true) {
                    alert('need to verify')
                }
                else if (element.suffix === emailSuffix[1] && element.verifiable === false) {
                    alert('do not need to verify')
                }
            });
        })
        .catch((error) => {
            console.log("error", error);
        });
}

function handleValidationErrors () {
    alert('login failed')
}