// eslint-disable-next-line
AFRAME.registerComponent("im-model-manager", {
    schema: {},
    init: function () {
        // bind the methods
        this.modelLoadedFlag = false;
        this.modelLoaded = this.modelLoaded.bind(this);
        this.el.addEventListener("model-loaded", this.modelLoaded);  

        // MODEL VARS
        this.highlightMeshes = [];
        this.cameraTransforms = [];
        this.cameraHolderMesh;
        this.cameraTargetMesh;
        this.isSetupCam = false;

        this.loader = document.getElementById("loader");
    },
    modelLoaded: function () {
        //console.log(Object.getOwnPropertyNames(this.el.components["gltf-model"].el.object3D));
        var mesh = this.el.getObject3D("mesh"); // maybe this isnt recursive?
        mesh.traverse((node) => { // maybe non-recursive?
            //console.log("Node Name: " + node.name);
            // Camera nodes
            if (node.name.includes("Camera")) {
                this.cameraTransforms.push(node);
                console.log("CAMERA NODE: " + node.name);
            }

            // Highlights
            if (node.isMesh) {
                //console.log("Node Properties: " + Object.getOwnPropertyNames(node));
                //console.log("Node Name: " + node.name);
                if (
                    node.name.includes("Highlight") ||
                    node.name.includes("Highlights")
                ) {
                    this.highlightMeshes.push(node);
                }
            }
        });
        //console.log(this.cameraTransforms.length);
        
        // dont hide highlights anymore!
        //this.hideAll(this.highlightMeshes);
        // this.highlightMeshes.forEach((hlMesh) => {
        //     //console.log("HLMESH: " + hlMesh.name);
        // });

        // SETUP CAMERA OBJECTS
        this.setupCamera();

        this.modelLoadedFlag = true;

        this.loader.emit("onModelLoaded");
    },  
    setupCamera:function(){
        this.cameraTransforms.forEach((camTrans) => {
            console.log("CAMTRANS: " + camTrans.name);
            if (
              camTrans.name === "DummyCamera" ||
              //camTrans.name === "DummyCameraShape" ||
              //camTrans.name === "DummyCameraPIV" || //sometimes the GLTF export process adds "PIV" or "Shape"s           
              camTrans.name === "Camera_Maker" ||
              camTrans.name === "CameraMarker"
            ) {
                console.log("setting cam holder to: " + camTrans.name);
              this.cameraHolderMesh = camTrans;
            }
            if (
              camTrans.name === "DummyCameraTarget" ||
              //camTrans.name === "DummyCameraTargetShape" ||
              //camTrans.name === "DummyCameraTargetPIV" ||              
              camTrans.name === "CameraAim"
            ) {
                console.log("setting cam target to: " + camTrans.name);
              this.cameraTargetMesh = camTrans;
            }
        });
        this.isSetupCam = true;
    },
    // MESH VISIBILITY CONTROLS
    changeVisibility: function (inputArray, value) {
        inputArray.forEach((x) => (x.visible = value));
    },
    hideAll: function (inputArray) {
        this.changeVisibility(inputArray, false);
    },
    showAll: function (inputArray) {
        this.changeVisibility(inputArray, true);
    }
});
