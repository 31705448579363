import { v4 as uuidv4 } from "uuid";

// test

export function UniSSO(
    email,
    password,
    callback = () => {},
    errorsCallback = () => {},
    onList,
    suffix
) {
    if (onList) {
        console.log("Uni SSO login");
        var connectionId = ""

        switch (suffix.suffix) {
            case "cardiff.ac.uk":
                connectionId = "CardiffSSO";
                break;
            case "qmul.ac.uk":
                connectionId = "QMULLive";
                break;
            case "abdn.ac.uk":
                connectionId = "UoAberdeenSSO";
                break;
            case "jcu.edu.au":
                connectionId = "JCUAusSSO";
                break;
            default:
        }

        OpenPopup(callback(), errorsCallback, connectionId);
    }
    else {
        errorsCallback("Email is not from a subscribed university");
    }
}

async function OpenPopup(callback = () => {}, errorsCallback = () => {}, connectionId) {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
        filename: "UniSSO/connections_list_live.json",
    });

    var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
    };

    fetch(process.env.REACT_APP_SERVER_URL + "/api/s3download", requestOptions)
        .then((response) => response.text())
        .then((result) => {
            const connectionsList = JSON.parse(result).OpenIDConnections;

            connectionsList.forEach((connection) => {
                if (connection.connectionID === connectionId) {
                    const oidcMechanism = connection.mechanism;
                    console.log(oidcMechanism.scopes);
                    let scope = oidcMechanism.scopes;
                    if (scope === undefined || scope === "undefined") {
                        scope = "openid";
                    }

                    const tempAuthURL =
                        oidcMechanism.authorizeURL + // required
                        "?client_id=" +
                        connection.clientID + //required
                        "&redirect_uri=" +
                        process.env.REACT_APP_CLIENT_URL +
                        "/UniSSO" + //required
                        "&response_type=" +
                        (oidcMechanism.responseType || "id_token") +
                        "&scope=" + // required
                        scope + // required (non-essential: =openidprofile)
                        "&response_mode=" +
                        (oidcMechanism.responseMode || "form_post") + // required
                        "&nonce=" +
                        uuidv4(); // required nonce value

                    window.location.href = tempAuthURL;
                } else {
                    console.log(connection);
                    console.log(connectionId);
                }
            });
        })
        .catch((error) => {
            console.log("error", error);
            errorsCallback("could not download connections file");
            callback()
        });
}
