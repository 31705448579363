/* global AFRAME */
AFRAME.registerComponent('light-manager', {
    schema:{

    },
    init:function(){
        this.onModelLoaded = this.onModelLoaded.bind(this);
        this.el.addEventListener('model-loaded', this.onModelLoaded);        
    },
    onModelLoaded: function () {
        //console.log("Light Manager: Model loaded");
        this.findAllLights();
    },
    findAllLights: async function(){       
        //await delay(1000);

        let lights = this.el.sceneEl.querySelectorAll('[light]');
        //console.log(lights);
        
        lights.forEach(element => {
            //console.log(element);
            //console.log(element.id);
            if(element.id === "")
            {
                //console.log("Light without ID detected!");
                element.remove();
            }
        });

        //if(lights.length > 3){ console.log("MORE than 3 LIGHTS") }
    }
});
