import React from "react";

import app_policy_img from "../images/landing/app_policy.svg";

import { Header } from "../Landing/Header/Header";
import { Footer } from "../Landing/Footer/Footer";

export const AppPrivacyPolicy = () => {
    return (
        <>
            <Header link={true} />
            <section className="container privacy_p">
                <div className="row">
                    <div className="col-md-7">
                        <div className="in_page_text">
                            <div className="main_head">
                                <h2>
                                    Application Privacy Policy<span className="end_not"></span>
                                </h2>
                                <h5>Learn more about Immersify&#39;s data collection and use, as well as your rights.</h5>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-5">
                        <div className="in_page_img">
                            <img src={app_policy_img} alt="privacy policy" />
                        </div>
                    </div>
                </div>
            </section>

            <section className="container privacy_p_content" id="page-content">
                <div className="row">
                    <div className="col-md-12">
                        <div id="content" className="no-sidebar-page">
                            <div id="content-wrap" className="be-wrap no-page-builder">
                                <section id="page-content">
                                    <div className="clearfix">
                                        <p>
                                            This privacy policy governs your use of the software application Immersify Dental (the “App”) for mobile
                                            devices that was created by Immersify Education Limited (company number 12099861) the registered address
                                            being 100 Chapel Lane, Longton, Preston PR4 5FB. We understand that your privacy is important to you and
                                            that you care about how your personal data is used. We respect and value the privacy of everyone who uses
                                            the App and will only collect and use personal data in ways that are described here, and in a way that is
                                            consistent with our obligations and your rights under the law.
                                        </p>

                                        <p>
                                            The App combines accessible technology with expert content, bespoke 3D visuals and interactivity to create
                                            resources for dental students.
                                        </p>

                                        <p>Please read this Privacy Policy carefully and ensure that you understand it.</p>

                                        <p>
                                            <strong>Your Consent</strong>
                                        </p>

                                        <p>
                                            By using the Application, and/or contacting our support channels via the app, email, tech support tickets,
                                            or surveys, you are consenting to our processing of your information as set forth in this Privacy Policy
                                            now and as amended by us. “Processing,” means using data in any way, including, but not limited to,
                                            collecting, storing, deleting, using, combining and disclosing.
                                        </p>

                                        <p>
                                            <strong>Information About Us</strong>
                                        </p>

                                        <p>The App is owned and operated by Immersify Education Limited.</p>

                                        <p>Data Protection Officer: Maxwell Boardman</p>

                                        <p>Email address: info@immersifyeducation.com</p>

                                        <p>
                                            Contact address: Immersify Education, Unit 16, Empress Business Centre, 380 Chester Road, Manchester, M16
                                            9EA.
                                        </p>

                                        <p>
                                            <strong>What Does This Policy Cover?</strong>
                                        </p>

                                        <p>
                                            This Privacy Policy applies to the use of our App as well as in customer service communication via
                                            messaging or surveys. Our App may contain links to websites and applications. Please note that we have no
                                            control over how your data is collected, stored, or used by other websites and applications and we advise
                                            you to check the privacy policies of any such websites and applications before providing any data to them.{" "}
                                        </p>

                                        <p>
                                            Information is stored within Playfab a mobile backend system provided by Playfab Inc. Microsoft
                                            Corporation.{" "}
                                        </p>

                                        <p>
                                            The Platform uses a third-party plugin “Vuforia” for video recording that has its own privacy policy which
                                            can be found here:{" "}
                                            <a href="https://chalk.vuforia.com/content/vuforia-chalk/en/privacy.html">
                                                https://chalk.vuforia.com/content/vuforia-chalk/en/privacy.htm
                                            </a>
                                        </p>

                                        <p>
                                            The Platform uses a third-party plugin “Google Firebase” to collect anonymised information regarding how
                                            the application is used. Google Firebase has its own privacy policy which can be found here:{" "}
                                            <a href="https://firebase.google.com/support/privacy">https://firebase.google.com/support/privacy</a>
                                        </p>

                                        <p>
                                            The Platform uses a third-party plugin “TrueDepth API” and “ARKit Face Tracking” to more accurately track
                                            facial data when using augmented reality for face tracking. The information collected is not stored in any
                                            way and is not shared with any 3rd party.{" "}
                                        </p>

                                        <p>
                                            The Platform uses a third-party plugin “AWSSDK” to effectively credit affiliates using discount codes. The
                                            data collected is shared with <a href="https://impact.com/">Impact</a> for the purposes of tracking
                                            downloads and purchases attributed to affiliates. No personal data is collected or shared. AWS SDK and
                                            Impact have their own privacy policies which can be found here:{" "}
                                        </p>

                                        <p>
                                            <a href="https://aws.amazon.com/privacy/">https://aws.amazon.com/privacy/</a>
                                        </p>

                                        <p>
                                            <a href="https://impact.com/privacy-policy/"></a>
                                            <a rel="noreferrer noopener" href="https://impact.com/privacy-policy/" target="_blank">
                                                impact.com/privacy-policy/
                                            </a>
                                        </p>

                                        <p>The Platform is built using the Unity Game Engine and AR Foundation utilising:</p>

                                        <p>
                                            Unity, provided by Unity and governed by&nbsp;
                                            <a rel="noreferrer noopener" href="https://unity3d.com/legal/privacy-policy" target="_blank">
                                                Unity Privacy Policy
                                            </a>
                                            .
                                        </p>

                                        <p>
                                            ARKit, provided by Apple and governed by the&nbsp;
                                            <a rel="noreferrer noopener" href="https://www.apple.com/uk/legal/privacy/en-ww/" target="_blank">
                                                Apple Privacy Policy.
                                            </a>
                                        </p>

                                        <p>
                                            ARCore, provided by Google LLC and governed by the&nbsp;
                                            <a rel="noreferrer noopener" href="https://policies.google.com/privacy" target="_blank">
                                                Google Privacy Policy,
                                            </a>
                                        </p>
                                        <p>
                                            Customer service enquires, feedback, and tech support submissions submitted through Google Forms, provided
                                            by Google LLC and governed by the <a href="https://policies.google.com/privacy">Google Privacy Policy.</a>
                                        </p>

                                        <p>
                                            <strong>What Is Personal Data?</strong>
                                        </p>

                                        <p>
                                            Personal data is defined by the General Data Protection Regulation (EU Regulation 2016/679) (the “GDPR”)
                                            and the Data Protection Act 2018 (collectively, “the Data Protection Legislation”) as ‘any information
                                            relating to an identifiable person who can be directly or indirectly identified in particular by reference
                                            to an identifier’.
                                        </p>

                                        <p>
                                            <strong>What Are My Rights?</strong>
                                        </p>

                                        <p>
                                            Under the Data Protection Legislation, you have the following rights, which we will always work to uphold:
                                        </p>

                                        <ol type="1">
                                            <li>The right to access the personal data we hold about you. Part 11 will tell you how to do this.</li>
                                            <li>
                                                The right to have your personal data rectified if any of your personal data held by us is inaccurate
                                                or incomplete. Please contact us using the details in Part 12 to find out more.
                                            </li>
                                            <li>
                                                The right to be forgotten, i.e. the right to ask us to delete or otherwise dispose of any of your
                                                personal data that we hold. Please contact us using the details in Part 12 to find out more.
                                            </li>
                                            <li>The right to restrict (i.e. prevent) the processing of your personal data.</li>
                                            <li>The right to object to us using your personal data for a particular purpose or purposes.</li>
                                            <li>
                                                The right to withdraw consent. This means that, if we are relying on your consent as the legal basis
                                                for using your personal data, you are free to withdraw that consent at any time.
                                            </li>
                                            <li>
                                                The right to data portability. This means that, if you have provided personal data to us directly, we
                                                are using it with your consent or for the performance of a contract, and that data is processed using
                                                automated means, you can ask us for a copy of that personal data to re-use with another service or
                                                business in many cases.
                                            </li>
                                            <li>
                                                Rights relating to automated decision-making and profiling. We do not use your personal data in this
                                                way.
                                            </li>
                                        </ol>

                                        <p>
                                            For more information about our use of your personal data or exercising your rights as outlined above,
                                            please contact us using the details provided in Part 12.
                                        </p>

                                        <p>
                                            It is important that your personal data is kept accurate and up-to-date. If any of the personal data we
                                            hold about you changes, please keep us informed as long as we have that data.
                                        </p>

                                        <p>
                                            Further information about your rights can also be obtained from the Information Commissioner’s Office or
                                            your local Citizens Advice Bureau.
                                        </p>

                                        <p>
                                            If you have any cause for complaint about our use of your personal data, you have the right to lodge a
                                            complaint with the Information Commissioner’s Office. We would welcome the opportunity to resolve your
                                            concerns ourselves, so please contact us first, using the details in Part 12.
                                        </p>

                                        <p>
                                            <strong>Data We Collect</strong>
                                        </p>

                                        <p>
                                            Under the Data Protection Legislation, we must always have a lawful basis for using personal data. We may
                                            collect some or all of the following personal and non-personal data:
                                        </p>

                                        <ul type="1">
                                            <li>display name;</li>
                                            <li>the type of mobile device you use;</li>
                                            <li>your mobile devices unique device ID;</li>
                                            <li>the IP address of your mobile device;</li>
                                            <li>your educational interests;</li>
                                            <li>contact information such as email addresses;</li>
                                            <li>demographic information such country;</li>
                                            <li>university;</li>
                                            <li>your year of study;</li>
                                            <li>passwords;</li>
                                            <li>your mobile operating system;</li>
                                            <li>the type of mobile internet browsers you use;</li>
                                            <li>information about the way you use the Application;</li>
                                            <li>user likeness details in the form of avatars;</li>
                                            <li>
                                                software and vendor account details which allow us to interact with your software vendors on your
                                                behalf to fulfil our contract;
                                            </li>
                                            <li>your device camera access.</li>
                                        </ul>

                                        <p>
                                            <strong>Use of the data</strong>
                                        </p>

                                        <p>We may use your data for the following purposes:</p>

                                        <ul>
                                            <li>Providing and managing your access to the Platform;</li>
                                            <li>The creation of avatars;</li>
                                            <li>Providing you with augmented reality applications;</li>
                                            <li>Personalising and tailoring your experience by presenting content;</li>
                                            <li>Informing you of news relating to our services;</li>
                                            <li>Supplying our products and/or services to you;</li>
                                            <li>Personalising and tailoring our products and/or services for you;</li>
                                            <li>Replying to emails from you;</li>
                                            <li>
                                                Supplying you with emails that you have opted into (you may unsubscribe or opt-out at any time by
                                                contacting us;
                                            </li>
                                            <li>Market research;</li>
                                            <li>Marketing attribution;</li>
                                            <li>
                                                Analysing your use of the App and gathering feedback to enable us to continually improve the App and
                                                your user experience;
                                            </li>
                                            <li>
                                                To help us accurately identify and diagnose user issues for resolution and app performance
                                                improvements;
                                            </li>
                                        </ul>

                                        <p>
                                            With your permission and/or where permitted by law, we may also use your personal data for marketing
                                            purposes, which may include contacting you with information, news, and offers on our products and
                                            services. You will not be sent any unlawful marketing or spam. We will always work to fully protect your
                                            rights and comply with our obligations under the Data Protection Legislation and the Privacy and
                                            Electronic Communications (EC Directive) Regulations 2003, and you will always have the opportunity to
                                            opt-out. We will always obtain your express opt-in consent before sharing your personal data with third
                                            parties for marketing purposes and you will be able to opt-out at any time.
                                        </p>

                                        <p>
                                            We use automated systems for carrying out certain kinds of administration and service provision. If at any
                                            point you wish to query any action that we take on the basis of this or wish to request ‘human
                                            intervention’ (i.e. have someone review the action themselves, rather than relying only on the automated
                                            method), the Data Protection Legislation gives you the right to do so. Please contact us to find out more
                                            using the details in Part 12.
                                        </p>

                                        <p>
                                            We will only use your personal data for the purpose(s) for which it was originally collected unless we
                                            reasonably believe that another purpose is compatible with that or those original purpose(s) and need to
                                            use your personal data for that purpose. If we do use your personal data in this way and you wish us to
                                            explain how the new purpose is compatible with the original, please contact us using the details in Part
                                            12.
                                        </p>

                                        <p>
                                            If we need to use your personal data for a purpose that is unrelated to, or incompatible with, the
                                            purpose(s) for which it was originally collected, we will inform you and explain the legal basis which
                                            allows us to do so.
                                        </p>

                                        <p>
                                            In some circumstances, where permitted or required by law, we may process your personal data without your
                                            knowledge or consent. This will only be done within the bounds of the Data Protection Legislation and your
                                            legal rights.
                                        </p>

                                        <p>
                                            <strong>Children</strong>
                                        </p>

                                        <p>
                                            We do not use the Application to knowingly solicit data from or market to children under the age of 13 in
                                            compliance with the Children’s Online Privacy Protection Act (COPPA). If a parent or guardian becomes
                                            aware that his or her child has provided us with information without their consent, s/he should contact us
                                            at info@immersifyeducation.com We will delete such information from our files within a reasonable time.
                                        </p>

                                        <p>
                                            <strong>How Long Will You Keep My Personal Data?</strong>
                                        </p>

                                        <p>
                                            We will not keep your personal data for any longer than is necessary in light of the reason(s) for which
                                            it was first collected.
                                        </p>

                                        <p>
                                            <strong>How and Where Do You Store or Transfer My Personal Data?</strong>
                                        </p>

                                        <p>
                                            The security of your personal data is essential to us and to protect your data we take a number of
                                            important measures, including the following:
                                        </p>

                                        <ul>
                                            <li>
                                                limiting access to your personal data to those employees, agents, contractors, and other third parties
                                                with a legitimate need to know and ensuring that they are subject to duties of confidentiality;
                                            </li>
                                            <li>
                                                procedures for dealing with data breaches (the accidental or unlawful destruction, loss, alteration,
                                                unauthorised disclosure of, or access to, your personal data) including notifying you and/or the
                                                Information Commissioner’s Office where we are legally required to do so.
                                            </li>
                                        </ul>

                                        <p>
                                            <strong>Do You Share My Personal Data?</strong>
                                        </p>

                                        <p>
                                            We will not share any of your personal data with any third parties for any purposes, subject to the
                                            following exceptions:
                                        </p>

                                        <p>The storage of data on Playfab;</p>

                                        <p>The use of Vuforia application.</p>

                                        <p>
                                            If we sell, transfer, or merge parts of our business or assets, your personal data may be transferred to a
                                            third party. Any new owner of our business may continue to use your personal data in the same way(s) that
                                            we have used it, as specified in this Privacy Policy.
                                        </p>

                                        <p>
                                            In some limited circumstances, we may be legally required to share certain personal data, which might
                                            include yours, if we are involved in legal proceedings or complying with legal obligations, a court order,
                                            or the instructions of a government authority.
                                        </p>

                                        <p>
                                            If any of your personal data is shared with a third party, as described above, we will take steps to
                                            ensure that your personal data is handled safely, securely, and in accordance with your rights, our
                                            obligations, and the third party’s obligations under the law, as described above in Part 8.
                                        </p>

                                        <p>
                                            If any personal data is transferred outside of the EEA, we will take suitable steps in order to ensure
                                            that your personal data is treated just as safely and securely as it would be within the UK and under the
                                            Data Protection Legislation, as explained above in Part 8.
                                        </p>

                                        <p>
                                            <strong>Can I Withhold Information?</strong>
                                        </p>

                                        <p>
                                            You may be able to access certain areas of the App without providing any personal data at all. However, to
                                            use all features and functions available on the App you may be required to submit or allow for the
                                            collection of certain data.
                                        </p>

                                        <p>
                                            <strong>How Can I Access My Personal Data?</strong>
                                        </p>

                                        <p>
                                            If you want to know what personal data we have about you, you can ask us for details of that personal data
                                            and for a copy of it (where any such personal data is held). This is known as a “subject access request”.
                                        </p>

                                        <p>
                                            All subject access requests should be made in writing and sent to the postal addresses shown in Part 12.
                                            To make this as easy as possible for you, a Subject Access Request Form is available for you to use. You
                                            do not have to use this form, but it is the easiest way to tell us everything we need to know to respond
                                            to your request as quickly as possible.
                                        </p>

                                        <p>
                                            There is not normally any charge for a subject access request. If your request is ‘manifestly unfounded or
                                            excessive’ (for example, if you make repetitive requests) a fee may be charged to cover our administrative
                                            costs in responding.
                                        </p>

                                        <p>
                                            We will respond to your subject access request within 21 days and, in any case, not more than one month of
                                            receiving it. Normally, we aim to provide a complete response, including a copy of your personal data
                                            within that time. In some cases, however, particularly if your request is more complex, more time may be
                                            required up to a maximum of three months from the date we receive your request. You will be kept fully
                                            informed of our progress.
                                        </p>

                                        <p>
                                            <strong>How Can I Access My Personal Data?</strong>
                                        </p>

                                        <p>
                                            To contact us about anything to do with your personal data and data protection, including to make a
                                            subject access request, please use the following details (for the attention of Maxwell Boardman):
                                        </p>

                                        <p>Email address: info@immersifyeducation.com</p>

                                        <p>
                                            Contact address: Immersify Education, Unit 16, Empress Business Centre, 380 Chester Road, Manchester, M16
                                            9EA
                                        </p>

                                        <p>
                                            <strong>Changes to this Privacy Policy</strong>
                                        </p>

                                        <p>
                                            We may change this Privacy Notice from time to time. This may be necessary, for example, if the law
                                            changes, or if we change our business in a way that affects personal data protection.
                                        </p>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer link={true} />
        </>
    );
};
