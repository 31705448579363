export const changeSubscription = (priceId) => {

  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");

  var raw = JSON.stringify({
    priceId: priceId,
    playfabId: localStorage.getItem("playfabID"),
  });

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  fetch(
    process.env.REACT_APP_SERVER_URL + "/change-subscription",
    requestOptions
  )
    .then(async (response) => {
        const url = await response.text();
        if (response.status === 200) {
          window.location.href = url;
        } else {
          console.error("Error changing subscription: ", response);
        }
    })
    .catch((error) => console.log("error", error));
};