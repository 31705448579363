import React, {useState, useEffect } from 'react';
import { Entity, Scene } from "aframe-react";

import PropTypes from "prop-types";
import restartIcon from '../../../../../images/restart_icon.svg';
import Timer from './timer/timer';
import circleSVG from "../../../../../images/shape_vmcq.svg";
import { GetInfoFromID } from '../../../../../Utils/getInfoFromID';
// import { UpdateUserData } from '../../../../../Utils/updateUserData';
// import { GetUserData } from '../../../../../Utils/getUserData';
import Slider from "react-slick";
import { InitialiseSceneFeedback } from './vmcqModel/InitialiseSceneFeedback';
import { SetPoint } from './vmcqModel/SetPoint';

import './vmcqModel/vmcq-im-orbit-controls';
import './vmcqModel/camera-manager.component';
import './vmcqModel/model-swapper';
// import '../../../../../../Scripts/aframe/Utils/center-locator.component';

// eslint-disable-next-line
import feedbackmodelswapper from "./vmcqModel/feedback-model-swapper";

export let currentAssetObject;
export let currentAssetSubMeshes;
export let holdingAssetSubMeshes;
export let currentAssetRelQData;
export let modelsData;
export let externalAnswered;

let internalLevel;
let levelsData;
let levelModifiers;
let availableQuestions;
let questionNumber;
let questionId
let internalNextQuestion;
let internalSeconds = 0;
let questions;
let incorrectAnswerData = [];
let optionClicked;
let currentModelURL;
let holdingAssetURL;
let currentAssetRotation;
let currentAssetPosition;
// let bestScore;
// let signedIn = false;
let modelsFeedbackArray = [];
let internalRoundQuestions = 0;
let internalVMCQ = true;
let imageVMCQ = false;
let currentPoint = 0;
let moving = false;

export const VMCQ = (props) => {
    const [level, setLevel] = useState(1);
    const [roundQuestions, setRoundQuestions] = useState(0);
    const [score, setScore] = useState(0);
    const [ displayIntro, setDisplayIntro ] = useState(true);
    const [ displayQuestion, setDisplayQuestion ] = useState(false);
    const [ displayResults, setDisplayResults ] = useState(false);
    const [ displayFeedback, setDisplayFeedback ] = useState(false);
    const [ correctAnswer, setCorrectAnswer ] = useState(0);
    const [ incorrectAnswer, setIncorrectAnswer ] = useState(0);
    const [ possibleScore, setPossibleScore ] = useState(0);
    const [ isCorrect, setisCorrect ] = useState(false);
    const [ answered, setAnswered ] = useState(false);
    const [currentAnswers, setCurrentAnswers] = useState([]);
    const [ displayCorrectAnswers, setDisplayCorrectAnswers ] = useState(false);
    const [ questionTextText, setquestionTextText ] = useState("");
    const [ animationScore, setAnimationScore ] = useState(100);
    const [ internalSecondsState, setInternalSecondsState ] = useState(0);
    const [ hideAnswers, setHideAnswers ] = useState(true);
    const [ modelAssetVisible, setModelAssetVisible ] = useState();
    const [ modelAssetHidden, setModelAssetHidden ] = useState();
    const [ vmcqModel, setVmcqModel ] = useState();
    const [ currentModelURLState, setCurrentModelURLState ] = useState(null);
    const [ loadingModels, setLoadingModels ] = useState(true);


    const settings = {
      dots: false,
      infinite: false,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      draggable: false,
      nextArrow: <NextArrow />,
      prevArrow: <PrevArrow />,
      afterChange: (current) => handleSlideChange(current),
    };

    function NextArrow(props) {
      // eslint-disable-next-line
      const { className, style, onClick } = props;
      return (
          <div
              className={className}
              style={{ ...style, display: "block" }}
              onClick={function () {
                  if (onClick !== null) {
                      onClick();
                      nextPoint();
                  }
              }}
          />
      );
  }

  function PrevArrow(props) {
      // eslint-disable-next-line
      const { className, style, onClick } = props;
      return (
          <div
              className={className}
              style={{ ...style, display: "block" }}
              onClick={function () {
                  if (onClick !== null) {
                      onClick();
                      prevPoint();
                  }
              }}
          />
      );
  }
    //get data
    useEffect(() => {      
        //check if mcq or vmcq
        if (!(props.id.toLowerCase().includes("vmcq"))) {
            internalVMCQ = false;
        }   
        if (props.id == "radiologyID_prac") {
            imageVMCQ = true;
            internalVMCQ = true;

        }
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        var raw = JSON.stringify({
            practicalID: props.id,
            vmcq: internalVMCQ,
        });

        var requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow",
        };

        fetch(process.env.REACT_APP_SERVER_URL+"/api/VMCQ", requestOptions)
            .then((response) => response.text())
            .then((result) => {
                gotQuestions(result);
            })
            .catch((error) => console.log("error", error));
    }, []);
        
    //questions recieved
    const gotQuestions = (result) => {
        result = JSON.parse(result);
        levelsData = result.questionObjects.questionsJSON;
        levelModifiers = result.questionObjects.levelModifiers;
        modelsData = result.modelData.models;
        internalLevel = 1;
        getNewRoundQuestions();
        questions = {
            current:{
                question: "",
                answers:[],
            },
            next:{
                question: "",
                answers:
                [],
            }
        };

        getNewQuestion();
    }

    function getNewRoundQuestions() {
        availableQuestions = [];
        //filter out questions that are not for this level
        levelsData.forEach(element => {
            if (element.Levels) {
                if (element.Levels.split('::').includes(internalLevel.toString())) {
                    availableQuestions.push(element);
                } 
            } else {
                //commas broke these questions??
            }


        });
    }

    function getNewQuestion() {
            // move loaded question to current question
            questions.current = questions.next;
            questions.next = {};

            //randomly choose question
            questionNumber = Math.floor(Math.random()*availableQuestions.length);
            questionId = availableQuestions[questionNumber].ID;

            if (availableQuestions.length == 0) {
                console.log("ran out of questions")
            }
            // setCurrentQuestion(availableQuestions[questionNumber].Question);
            internalNextQuestion = availableQuestions[questionNumber].Question;
            //build next answers
            let nextAnswers = [];
            let dataIncorrectAnswers = availableQuestions[questionNumber]["Incorrect"];
            if (dataIncorrectAnswers.includes("::")) {
                dataIncorrectAnswers = dataIncorrectAnswers.split("::");
                dataIncorrectAnswers.map((answer) => {
                    nextAnswers.push({answerText:answer, correct:false});
                });
            } else {
                nextAnswers.push({answerText:dataIncorrectAnswers, correct:false});
            }
            nextAnswers.push({answerText: availableQuestions[questionNumber]["Correct"], correct:true});
            //push next question to questions array
            questions.next.answers = nextAnswers;
            questions.next.question = internalNextQuestion;
            switch (availableQuestions[questionNumber].ContentType) {
                case "2DImage":
                    setVmcqModel("image");
                    break;
                case "Text":
                    setVmcqModel("text");
                    break;
                case "3DModel":
                    setVmcqModel("model");
                    break;
                default:
                    setVmcqModel("model");
                    break;
            }

            holdingAssetSubMeshes = currentAssetSubMeshes;
            holdingAssetURL = currentModelURL;

            //get corresponding model
            if (typeof modelsData !== 'undefined') {modelsData.forEach(element => {
                if (element.questionIDs.includes(String(questionId))) {
                    currentAssetObject = element;
                    currentModelURL = element.URL;
                    setCurrentModelURLState(element.URL);
                    element.relQuestionData.forEach(elementRelQData => {
                        currentAssetRelQData = elementRelQData;
                        if (elementRelQData.questionID == String(questionId)) {
                            if (typeof elementRelQData.subMeshes !== "undefined") {
                              currentAssetSubMeshes = elementRelQData.subMeshes;
                            } else {
                              currentAssetSubMeshes = undefined;
                            }
                            currentAssetPosition = elementRelQData.position;
                            currentAssetRotation = elementRelQData.rotation;
                        }
                    });
                } 
            })} 
            setModelAssetVisible(
                <a-asset-item
                    id="visibleModel"
                    src={modelAssetHidden !== undefined ? modelAssetHidden.props.src : ''}
                    response-type="arraybuffer"
                    crossorigin="anonymous"
                    position={modelAssetHidden !== undefined ? modelAssetHidden.props.position : ''}
                    rotation={modelAssetHidden !== undefined ? modelAssetHidden.props.rotation : ''}
                ></a-asset-item>
            );
            setModelAssetHidden(
                <a-asset-item
                    id="hiddenModel"
                    src={"https://dsmhsp9m0ds1d.cloudfront.net/"+currentModelURL}
                    response-type="arraybuffer"
                    crossorigin="anonymous"
                    position={currentAssetPosition}
                    rotation={currentAssetRotation}
                ></a-asset-item>
            )    
        
            //shuffle
            questions.current.answers.sort(() => Math.random() - 0.5);
            setCurrentAnswers(questions.current.answers);

            setDisplayCorrectAnswers(false);
            setHideAnswers(true);
            questionText();

            setLoadingModels(false);

            //end round move to next level
            if (internalRoundQuestions == 3) {
                if (level == 10) {
                    gameOver();
                } else {
                    internalLevel = internalLevel + 1;
                    setLevel(level + 1);
                    setRoundQuestions(0);
                    internalRoundQuestions = 0;
                    getNewRoundQuestions();
                }
            }
    }

    function questionText() {
        setquestionTextText(questions.current.question);
        setAnswered(false);
        externalAnswered = false;
        setHideAnswers(false);
        if (!internalVMCQ || imageVMCQ) {
        //resume timer here
          if (document.getElementById("resumeButton")) {
            console.log("resume")
              document.getElementById("resumeButton").click();
          } 
        }
    }

    function initializeQuiz() {
        setRoundQuestions(0);
        internalRoundQuestions = 0;
        setScore(0);
        setLevel(1);
        setCorrectAnswer(0);
        setIncorrectAnswer(0);
        setPossibleScore(0);
        setDisplayIntro(false);
        setDisplayQuestion(true);
        setDisplayFeedback(false);
        setVmcqModel("model");
        incorrectAnswerData = [];
        internalLevel = 1;
        // questionId = 0;
        // holdingAssetSubMeshes = undefined;
        // currentAssetSubMeshes = undefined;
        // getHighScore();
        // checkForTimedOut();
        getNewQuestion();
    }

    function questionAnswered(question){           
        setAnswered(true);
        externalAnswered = true;
        setDisplayCorrectAnswers(true);
        //pause timer here
        document.getElementById("pauseButton").click();
        setRoundQuestions(roundQuestions + 1);
        internalRoundQuestions = internalRoundQuestions + 1;
        optionClicked = question.answerText;

        //check if answer is correct
        if (question.correct) {
            setisCorrect(true);
            setCorrectAnswer(correctAnswer + 1);
            updateScoreAndTime(true);
            //reomve question from available questions
            availableQuestions.splice(questionNumber, 1);
        } else {
            setisCorrect(false)
            setIncorrectAnswer(incorrectAnswer + 1);
            updateScoreAndTime(false);
            incorrectAnswerData.push(
                {
                    currentQuestion: questions.current,
                    answered:question.answerText,
                    //current model url is incorrect as thats the old url
                    modelURL:"https://dsmhsp9m0ds1d.cloudfront.net/"+holdingAssetURL,
                    position: modelAssetVisible.props.position,
                    rotation: modelAssetVisible.props.rotation,
                    relPointData:[{pointIndex: questionId, position: modelAssetVisible.props.position, rotation: modelAssetVisible.props.rotation}],
                    subMeshes: holdingAssetSubMeshes,
                    id: String(incorrectAnswerData.length)

                }
            )
        }

        //weird bug where state isn't updated when function is called so wait a little bit
        setTimeout(() => {
            document.getElementById("restartButton").click();
        }, 2);
    }

    function nextQuestion() {
        setquestionTextText("");
        getNewQuestion();
    }

    function updateScoreAndTime(correct) {
        let internalPossibleScore = 100;
        internalPossibleScore = parseInt(levelModifiers[level-1].Score);
        if (correct) {
          internalSeconds = parseInt(levelModifiers[level-1].LevelTimeGain);
          setScore(score + internalPossibleScore);
        } else{
          internalSeconds = -1*parseInt(levelModifiers[level-1].LevelTimeLoss);
        }
        setAnimationScore(internalPossibleScore);
        setPossibleScore(possibleScore + internalPossibleScore);
        setInternalSecondsState(internalSeconds);
    }

    function restartQuiz() {
      //write a function that refreshes the current page
      window.location.reload(false);

        // setDisplayIntro(true);
        // setDisplayQuestion(false);
        // setDisplayFeedback(false);
    }

    function gameOver() {
        setDisplayQuestion(false);
        setDisplayResults(true);
        setDisplayFeedback(false);
        //check if score is better than best score
        // checkBestScore();
    }

    // function displayErrorPage() {
    //     setDisplayError(true);
    //     setDisplayIntro(false);
    //     setDisplayQuestion(false);
    //     setDisplayResults(false);

    //     does this need encoding?
    // localStorage.setItem("assetTimeoutScore", score);
    // }

    function showFeedback() {
        //build models
        incorrectAnswerData.map((item, index) => {
            //create model 
            modelsFeedbackArray[index] = 
            <a-asset-item
                id={"visibleModel"+index}
                src={item.modelURL}
                response-type="arraybuffer"
                crossorigin="anonymous"
                position="0 0 0"
                rotation="0 0 0"
            ></a-asset-item>
        })
        currentPoint = 0;
        //hide stuff
        if (incorrectAnswerData.length !== 0) {
          setDisplayResults(false);
          setDisplayFeedback(true);
        }
    }

    const [pointData, setPointData] = useState();
    const [ sliderPoints, setSliderPoints ] = useState(   
    <>
      <>
        <article key={"sliderPoint"} className="lesson_slide">
            <div className="std_lessons">
                <div className="std_img_block">
                    <div className="skeleton-box bx1"></div>
                </div>
                <div className="std_text_block">
                    <div className="skel_box">
                        <div className="skeleton-box bx2"></div>
                        <div className="skeleton-box bx3"></div>
                    </div>
                </div>
            </div>
        </article>
        <div></div>
      </>
      <div>
      </div>
    </>     
);
  function handleSlideChange() {
    SetPoint(pointData, setSliderPoints, currentPoint);
    moving = false;
  }
  
  function nextPoint() {
    if (moving === false) {
        currentPoint = currentPoint + 1;
        moving = true;
    }
}

function prevPoint() {
    if (moving === false) {
        currentPoint = currentPoint - 1;
        moving = true;
    }
}

    const time = new Date();
    time.setSeconds(time.getSeconds() + 30); // 30 second timer

    return (
      <>
        {displayIntro ? (
          <div className="vmcqInstructionsContainer">
            <div className="vmcqInstructionsHeader">
              <img
                src={
                  "https://d1476tmm9qfop0.cloudfront.net/TestFiles/Icons/webp/px-" +
                  GetInfoFromID(props.id)
                    .info.iconLocation.slice(6)
                    .slice(0, -4) +
                  ".webp"
                }
                id="vmcqIconHeader"
                className="img-fluid"
                alt="Activity - Icon"
              />
              <h2>
                {" "}
                <b>{GetInfoFromID(props.id).info.title}</b>
              </h2>
            </div>
            <div className="vmcqInstructionsBody">
              <h5>
                <b>Welcome to VMCQ</b>
              </h5>
              <div>
                <div className='vmcqInstructionsListParent'>
                  <ul className="vmcqInstructionsList">
                    <li></li>
                  </ul>
                  <span className='vmcqInstructionsText'>Choose the right answer from the options given.</span>
                </div>

                <div className='vmcqInstructionsListParent'>
                  <ul className="vmcqInstructionsList">
                    <li></li>
                  </ul>
                  <span>{"There is a 30 second time limit. For each right answer you'll get additional time and you will lose time for wrong answers."}</span>
                </div>

                <div className='vmcqInstructionsListParent'>
                  <ul className="vmcqInstructionsList">
                  <li></li>
                  </ul>
                  <span>Each level has 3 questions. You will get more points as you complete each level.</span>
                </div>

              </div>
              <button
                type="button"
                id="vmcqStartButton"
                className="btn_common pulse vmcqStartButton"
                onClick={() => initializeQuiz()}
                disabled={loadingModels}
              >
                Start
              </button>
            </div>
          </div>
        ) : null}

        {/* Generic Question page */}
        {displayQuestion ? (
          <div className="vmcqQuestionPage">
            <div className="vmcqStatusBar">
              <div></div>

              <div className="vmcqProgressContainer">
                <div className="vmcqLevelDisplay">
                  <div className="vmcqLevelText">
                    <b>
                      <span className="vmcqCurrentLevel">{level}</span>/10
                    </b>
                  </div>
                  <div>Level</div>
                </div>
                <div className="vmcqLivesAndTime">
                  <Timer
                      timeChange={internalSecondsState}
                      gameOver={gameOver}
                      expiryTimestamp={time}
                      answered={answered}
                      isCorrect={isCorrect}
                    />

                  <div className="vmcqLivesContainer">
                    <div
                      className={
                        "vmcqLivesDisplay" +
                        (roundQuestions > 0 ? " full" : " empty")
                      }
                    ></div>
                    <div
                      className={
                        "vmcqLivesDisplay" +
                        (roundQuestions > 1 ? " full" : " empty")
                      }
                    ></div>
                    <div
                      className={
                        "vmcqLivesDisplay" +
                        (roundQuestions > 2 ? " full" : " empty")
                      }
                    ></div>
                  </div>

                </div>

                <div className="vmcqLevelDisplay score">
                  <div className="vmcqLevelText">
                    <b>
                      <span
                        id="vmcqCurrentScore"
                        className={"vmcqCurrentLevel"}
                      >
                        {score}
                      </span>
                    </b>
                    <div
                      id="vmcqGreen"
                      className={
                        answered
                          ? isCorrect
                            ? "vmcqCorrectAnimation"
                            : ""
                          : ""
                      }
                    >
                      +{animationScore}
                    </div>
                  </div>
                  <div>Score</div>
                </div>
              </div>

              <button
                type="button"
                className="vmcqRestartButton"
                onClick={() => restartQuiz()}
              >
                <img src={restartIcon} alt="Restart" />
              </button>
            </div>
            <div>
              <div className="vmcqQuestion">
                <h5>
                  <b id="questionTextId">{questionTextText}</b>
                </h5>
              </div>
              <div className="vmcqQuestionData">
                {vmcqModel !== "text" ? (
                  <div
                    className={
                      "vmcqModel " + (vmcqModel == "image" ? "image" : "")
                    }
                  >
                    <div className="landing_model">
                      <div id="AFrameScene">
                        {vmcqModel == "model" ? (
                          <>
                            <Scene
                              embedded
                              device-oriented-permission-ui="enabled: false"
                              class="ModelViewerScene"
                              vr-mode-ui="enabled: false"
                              loading-screen="dotsColor: blue; backgroundColor: white"
                              shadow="type: pcfsoft"
                              modelswapper                              
                            >
                            {/* <!-- CAM -->  */}
                            <a-camera
                            id="camera"
                            class="sceneCam"
                            vmcq-im-orbit-controls="target: #box2; rotateTo:0 0 0.5"
                            vmcq-camera-manager="distance:1"
                            >
                                <a-light light="type:spot; color: #FFF; intensity: 0.3"></a-light>
                            </a-camera>

                            <a-box
                            id="box2"
                            class="camTarget"
                            position="0 0 0"
                            rotation="0 0 0"
                            ></a-box>                              
                            <Entity id="WorldCenter" position="0 0 0" rotation="0 0 0"></Entity>

                            {/* <!-- LIGHTING -->*/}
                            <Entity
                                id="directionalLight"
                                light="type: directional; color: #FFF; intensity: 0.3"
                                position="-0.5 1 1"
                            ></Entity>
                            <Entity
                                id="ambientLight"
                                light="type: ambient; color: #FFF; intensity: 1"
                            ></Entity>

                            {/* <!-- ASSETS --> */}
                            <a-assets>
                              {modelAssetHidden}
                              {modelAssetVisible}
                            </a-assets>
                            <Entity
                              id="SwapModel"
                              // gltf-model={"#modelToBeSwapped"}
                            ></Entity>
                            </Scene>
                          </>
                        ) : vmcqModel == "image" ? (
                          <img
                            src={
                              "https://dsmhsp9m0ds1d.cloudfront.net/" +
                              currentModelURLState
                            }
                          ></img>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                ) : null}
                <div
                  className="vmcqAnswerData"
                  style={
                    vmcqModel == "text" ? { width: "100%" } : { width: "50%" }
                  }
                >
                  <ul className="vmcqList">
                    {currentAnswers.map((answerOption, index) => (
                      <li
                        key={"answer" + index}
                        className={vmcqModel !== "text" ? " model" : ""}
                      >
                        <button
                          disabled={answered}
                          className={
                            "vmcqAnswerButton " +
                            (displayCorrectAnswers
                              ? answerOption.correct === true
                                ? "right_ans"
                                : optionClicked == answerOption.answerText
                                ? "wrong_ans"
                                : ""
                              : "")
                          }
                          onClick={() => questionAnswered(answerOption)}
                        >
                          {hideAnswers ? " " : answerOption.answerText}
                        </button>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
              <div id="vmcqContinueButtonParent">
                <button
                  id="vmcqContinueButton"
                  className={
                    "btn_common " +
                    (vmcqModel == "image" ? "image " : "") +
                    (answered ? "" : "continueDisabled ")
                  }
                  //model load check
                  disabled={ answered ? false : true}
                  onClick={() => nextQuestion()}
                >
                  Continue
                </button>
              </div>
            </div>
          </div>
        ) : null}

        {/* Results page */}
        {displayResults ? (
          <div className="vmcqResultsBackground">
            <div className="vmcqPurpleCircleContainer">
              <img
                className="vmcqPurpleCircle"
                loading="eager"
                src={circleSVG}
              />
            </div>
            <div className="vmcqResultsPage">
              <h6 className="vmcqCongratulations">
                {score > 800 ? "CONGRATULATIONS!" : "WORK TO DO"}
              </h6>
              <div className="vmcqResultsScore">
                <b>You scored</b>
                <div>
                  <span className="vmcqAchievedScore">{score}</span>
                  {/* <span className="vmcqTotalPossibleScore">
                    {" "}
                    / {possibleScore}
                  </span> */}
                </div>
              </div>
              <div className="vmcqResultsParent">
                <div className="vmcqResultsColumn">
                  <div className="vmcqResultsRow">
                    <ul className="vmcqResultsBullet vmcqCorrect">
                      <li></li>
                    </ul>
                    <span className="vmcqResultsNumber vmcqCorrect">
                      {correctAnswer}
                    </span>
                  </div>
                  <div>Correct</div>
                </div>
                <div className="vmcqResultsColumn">
                  <div className="vmcqResultsRow">
                    <ul className="vmcqResultsBullet vmcqIncorrect">
                      <li></li>
                    </ul>
                    <span className="vmcqResultsNumber vmcqIncorrect">
                      {incorrectAnswer}
                    </span>
                  </div>
                  <div>Incorrect</div>
                </div>
              </div>
              <div className={"vmcqFeedbackShow " +(incorrectAnswerData.length == 0 ? "inactive":"")} onClick={() => showFeedback()}>
                Feedback
              </div>
              <div className="vmcqResultsButtons">
                {/* <button onClick={() => exitVMCQ()} className="quitButton btn_common">Quit</button>  */}
                <button
                  onClick={() => restartQuiz()}
                  className="restartButton btn_common"
                >
                  Restart
                </button>
              </div>
            </div>
          </div>
        ) : null} 

        {/* FEEDBACK */}
        {displayFeedback ? (
          <div className="vmcqFeedback">
            <div className="vmcqPurpleCircleContainer">
              <img className="vmcqPurpleCircle" src={circleSVG} />
            </div>
            <div className="vmcqFeedbackHeader">
              <button className="vmcqFeedbackBack" onClick={() => gameOver()}>
                <i className="fa fa-long-arrow-left"></i>
                {" Go back"}
              </button>
              {/* <div> */}
                {/* <div className="vmcqResultsParent">
                  <div className="vmcqResultsColumn">
                    <div className="vmcqResultsRow">
                      <ul className="vmcqResultsBullet vmcqCorrect">
                        <li></li>
                      </ul>
                      <span className="vmcqResultsNumber vmcqCorrect">
                        {correctAnswer}
                      </span>
                    </div>
                    <div className='correctText'>Correct</div>
                  </div>
                  <div className="vmcqResultsColumn">
                    <div className="vmcqResultsRow">
                      <ul className="vmcqResultsBullet vmcqIncorrect">
                        <li></li>
                      </ul>
                      <span className="vmcqResultsNumber vmcqIncorrect">
                        {incorrectAnswer}
                      </span>
                    </div>
                    <div className='incorrectText'>Incorrect</div>
                  </div>
                </div> */}
                {/* <div className='vmcqFeedbackScore'>
                  <b>Score</b>
                  <div className='vmcqFeedbackScoreContainer'>
                    <span className="vmcqAchievedScore">{score}</span>
                    {/* <span className="vmcqTotalPossibleScore">{" "} / {possibleScore}</span>
                  </div>
                </div> */}
              {/* </div> */}

              <button
                  onClick={() => restartQuiz()}
                  className="restartButton btn_common"
                >
                  Restart
                </button>
          
            </div>
            
            <div className="vmcqFeedbackCarousel">
              {/* {incorrectAnswerData.length > 0 ? ( */}
                <>
                  <Slider {...settings}>
                      {(internalVMCQ && !imageVMCQ)?sliderPoints:
                        incorrectAnswerData.map(
                            (option, index) => (
                                <div key={"answer" + index} >
                                    <div className='vmcqFeedbackQuestions'>
                                        <div className='vmcqFeedbackQuestionCounter'>
                                            <b>{index + 1}/{incorrectAnswerData.length}</b>
                                        </div>
                                        <h5 className='vmcqFeedbackQuestionText'>
                                            <b>{option.currentQuestion.question}</b>
                                        </h5>
                                        <div className='vmcqQuestionData'>
                                            {vmcqModel!=='text'?<div className='vmcqFeedbackQuestionModel'>
                                                <div className="landing_model"> 
                                                    {vmcqModel=='image'?<img src={option.modelURL}></img>:null}
                                                </div>
                                            </div>:''}
                                            <div className={vmcqModel!=='text'?'vmcqFeedbackAnswers':'mcqFeedbackAnswers'}>
                                                <ul className='vmcqList'>
                                                {option.currentQuestion.answers.map(
                                                    (element) => (
                                                        <li key={element.answerText}>
                                                            <button
                                                                className={'vmcqAnswerButton '+(element.correct?'right_ans':(element.answerText == option.answered?'wrong_ans':''))}
                                                                disabled
                                                            >
                                                                {element.answerText}
                                                            </button>
                                                        </li>
                                                    )
                                                )}
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                            </div>
                            )
                        )
                      }

                  </Slider>
                  <InitialiseSceneFeedback
                      setSliderPoints={setSliderPoints}
                      setPointData={setPointData}
                      handleSlideChange={handleSlideChange}
                      id={"LessonData/StandardLesson/IntroductionToInstrumentsAndEquipment.json"}
                      incorrectAnswerData={incorrectAnswerData}
                  /> 
                </>
{/* 
              ) : (
                <div className="vmcqZeroIncorrect">None wrong...</div>
              )} */}
            </div>

          </div>
        ) : null}

        {/* Error Page */}
        {/* {displayError?
            <div>
                ERROR. The model timed out and progress was lost, if it`s a high score it`s been recorded
            </div>:''} */}
      </>
    );
}

VMCQ.propTypes = {
    id: PropTypes.string,
    title: PropTypes.string,
};
