import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import "../../../../SCSS/style.css";

import AcademicAreasData from "../../../InfoJSONs/AcademicAreasData.json";
import { GetUserData } from "../../../../Utils/getUserData";

import { TopicCard } from "./TopicCard/TopicCard";
import { GetModuleTopics } from "./GetModuleTopics/GetModuleTopics";
import { CheckForPremium } from "../../../../Utils/checkForPremium";

export const DisplayTopics = (props) => {
  const [premium, setPremium] = useState(undefined);
  const [activeAcademicArea, setActiveAcademicArea] = useState();
  const [topics, setTopics] = useState([]);

  useEffect(() => {
    CheckForPremium(checkForPremiumCallback, "displayTopics");
    getAcademicArea();
  }, []);

  useEffect(() => {
    if (activeAcademicArea) {
      fetchTopics();
    }
  }, [activeAcademicArea]);

  function checkForPremiumCallback(data) {
    setPremium(data);
  }

  function getAcademicArea() {
      const academicArea = localStorage.getItem("AcademicArea");
      console.log(academicArea);
      if (academicArea === "UCLA") {
          setActiveAcademicArea("ucla");
          return;
      }
      if (academicArea === null || academicArea === undefined) {
          //if not in local get academic area from user data
          const academicAreaData = GetUserData(["AcademicArea"]);
          if (academicAreaData === false || academicAreaData === undefined) {
              setActiveAcademicArea("Dentistry");
          } else {
              GetUserData(["AcademicArea"], gotAcademicArea);
          }
      } else {
          setActiveAcademicArea(academicArea);
      }
  }

  function gotAcademicArea(data) {
      data = JSON.parse(data);
      console.log(data?.AcademicArea?.Value);
      if (data?.AcademicArea?.Value === "UCLA") {
          setActiveAcademicArea("ucla");
      } else {
          setActiveAcademicArea(data?.AcademicArea?.Value);
      }
  }

  function fetchTopics() {
      const updatedTopics = [];
      if (activeAcademicArea.toUpperCase() !== "UCLA") {
          setActiveAcademicArea("dentistry");
      }


      AcademicAreasData.academicAreas.forEach((academicArea) => {
          if (academicArea.id === activeAcademicArea) {
              academicArea.modules.forEach((module) => {
                  GetModuleTopics(module).forEach((topic) => {
                      updatedTopics.push(topic);
                  });
              });
          }
      });

      setTopics(updatedTopics);
  }

  const allTopics = topics.map((topic, index) => (
    <TopicCard
      key={"topicCard" + index}
      id={topic.id}
      displayContentCallback={props.displayContentCallback}
      premium={premium}
    />
  ));

  return (
    <section className="main_topics py-5">
      <div className="container-fluid px-0">
        <div className="row">
          <div className="col-md-12">
            <h2 className="main_heading">Topics</h2>
          </div>
          {allTopics}
        </div>
      </div>
    </section>
  );
};

DisplayTopics.propTypes = {
  displayContentCallback: PropTypes.func,
  premium: PropTypes.bool
};