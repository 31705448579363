// eslint-disable-next-line
//import {lessonMan} from './lesson-data-manager'

// eslint-disable-next-line
export function lessonAudioManager() {
    this.s3RootURL = process.env.REACT_APP_S3_MOBILE_ROOT;
    this.s3AudioFullURL;
    this.isPlaying = false; // start paused
    this.staticAudioSource = document.getElementById("staticAudioSource");
    this.audioDuration = 0;

    this.playAudioURL = async function (URL) {
        if(URL === ""){ console.log("no audio for this stage!"); return; }
        //console.log(URL);
        //console.log(this.staticAudioSource);
        this.staticAudioSource.src = URL;
        await this.playAudio(); // need to wait for audio metadata
    };

    this.playAudio = async function () {
        this.isPlaying = true;

        let selfRef = this;
        let audioSource = this.staticAudioSource;
        
        let playPromise = audioSource.play();

        if (playPromise !== undefined) {
            playPromise.then(function() {
                selfRef.audioDuration = audioSource.duration;
                //console.log("audio duration (self ref): " + selfRef.audioDuration);
            }).catch(function() {
                //console.error(error);
                // Automatic playback failed.
                // Show a UI element to let the user manually start playback.
            });
        }
        await playPromise;
    }

    this.pauseAudio = function () {
        this.isPlaying = false;
        let pausePromise = this.staticAudioSource.pause();
        if (pausePromise !== undefined) {
            pausePromise.then(function () {
                // 
            })
            .catch(function (error) {
                console.error(error);
                // Automatic playback failed.
                // Show a UI element to let the user manually start playback.
            });
        }
    };

    this.toggleAudio = function(){
        if(this.isPlaying){
            this.pauseAudio();
        } else {
            this.playAudio();
        }
    };
}
