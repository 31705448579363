import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import { Entity, Scene } from "aframe-react";
import { setupLesson, standardLessonMan } from "../../../../../../../../Scripts/aframe/StandardLesson/standard-lesson-data-manager";

import { getLessonUserData } from "./GetLessonUserData/GetLessonUserData";
import { GetExperienceTopic } from "../../../../../../../Utils/getExperienceTopic";
import { GetLessonData } from "./GetLessonData/GetLessonData";
import { GetPointData } from "./GetPointData/GetPointData";
import { CreateAssets } from "./CreateAssets/CreateAssets";
import { SetPoint } from "../SetPoint/SetPoint";
import { Quiz } from "../StandardLessonHeader/Quiz/Quiz";
import { shuffle } from "../../../../../../../Utils/arrayTools";

var pointData;

export const InitialiseScene = (props) => {

    const gotUserData = props.gotUserData
    const setGotUserData = props.setGotUserData
    //const standardLessonMan = props.standardLessonMan
    const setSliderPoints = props.setSliderPoints
    const setContentLength = props.setContentLength
    const setPointData = props.setPointData
    const setIsQuizDataLoaded = props.setIsQuizDataLoaded
    const isQuizDataLoaded = props.isQuizDataLoaded
    const setQuizJSX = props.setQuizJSX

    const id = props.id
    const topicId = GetExperienceTopic(id)[0]
    
    const [lessonStarted, setLessonStarted] = useState(false)

    const [isLessonDataLoaded, setIsLessonDataLoaded] = useState(false)

    const [assetTags, setAssetTags] = useState(undefined)

    useEffect(() => {

        if (lessonStarted === false) {
            beginLesson();
            setLessonStarted(true);
        }

        if (isLessonDataLoaded === true && isQuizDataLoaded === true && assetTags === undefined) {
            pointData = GetPointData(standardLessonMan)
            setPointData(pointData)
            SetPoint(pointData, setSliderPoints, 0, true, true)
            setContentLength(standardLessonMan.lessonJSON.textData.contentText.length);
            setAssetTags(<CreateAssets standardLessonMan={standardLessonMan} />)

            var noQuestions = 5
            const allQuestions = standardLessonMan.quizJSON.questions;
            if (allQuestions.length < 5) {
                noQuestions = allQuestions.length;
            }
            const questions = shuffle(allQuestions).slice(0, noQuestions);
            setQuizJSX(<Quiz standardLessonMan={standardLessonMan} id={id} questions={questions} noQuestions={noQuestions} />)
        }
        
    }, [isLessonDataLoaded, isQuizDataLoaded]);
    //

    const beginLesson = () => {

        getLessonUserData(
            topicId,
            gotUserData,
            setGotUserData
        );

        const lessonData = GetLessonData(id)

        // this data should come from outside
        console.log("BEGIN LESSON CALLED")
        setupLesson(
            "LessonData/StandardLesson/" + lessonData.lessonFileName + ".json",
            "LessonData/StandardLesson/" + lessonData.quizFileName + ".json",
            "loaderTOP"
        );
    };

    const lessonDataLoaded = () => {
        setIsLessonDataLoaded(true);
    };

    const quizDataLoaded = () => {
        setIsQuizDataLoaded(true);
    };
    
    
    return (
        <>
            <div className="d-none">
                {/* this first scene is only needed to tigger this loading event*/}
                <Scene embedded vr-mode-ui="enabled: false" light="defaultLightsEnabled: false">
                    <a-assets>
                        {isLessonDataLoaded ? <>{assetTags}</> : null}
                    </a-assets>
                    <Entity
                        id="loaderTOP"
                        events={{
                            onLessonDataLoaded: () => lessonDataLoaded(),
                            onQuizDataLoaded: () => quizDataLoaded(),
                        }}
                    />
                </Scene>
            </div>
        </>
    );
}

InitialiseScene.propTypes = {
    standardLessonMan: PropTypes.any,
    gotUserData: PropTypes.bool,
    setGotUserData: PropTypes.func,
    setSliderPoints: PropTypes.func,
    id: PropTypes.string,
    setContentLength: PropTypes.func,
    setPointData: PropTypes.func,
    setIsQuizDataLoaded: PropTypes.func,
    isQuizDataLoaded: PropTypes.bool,
    setQuizJSX: PropTypes.func,
}