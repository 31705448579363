import ModuleStructure from "../ContentDeliverySystem/InfoJSONs/ModuleStructure.json";
import TopicInfo from "../ContentDeliverySystem/InfoJSONs/TopicInfo.json";
import LessonInfo from "../ContentDeliverySystem/InfoJSONs/LessonInfo.json";
import PracticalInfo from "../ContentDeliverySystem/InfoJSONs/PracticalInfo.json";

import { CheckIfLocked } from "./checkIfLocked";

export function GetInfoFromID(id) {
    const infoType = GetInfoType(id);
    var returnInfo

    switch (infoType) {
        case "topic":
            TopicInfo.topics.forEach((topic) => {
                if (topic.id === id) {
                    returnInfo = {
                        info: topic,
                        type: "topic",
                        locked: CheckIfLocked(id, true),
                    };
                }
            });
            break;
        case "lesson":
            LessonInfo.lessons.forEach(lesson => {
                if (lesson.id === id) {
                    returnInfo = {
                        info: lesson,
                        type: "lesson",
                        locked: CheckIfLocked(id),
                    };

                    returnInfo.info.sceneParams.forEach((sceneParam) => {
                        if (sceneParam.includes("ModelDataFile")) {
                            returnInfo = {
                                info: lesson,
                                type: "animated",
                                locked: CheckIfLocked(id),
                            };
                        }
                    });
                }
            });
            if (returnInfo === undefined) {
                PracticalInfo.practicals.forEach((practical) => {
                    if (practical.id === id)
                        returnInfo = {
                            info: practical,
                            type: "practical",
                            locked: CheckIfLocked(id),
                        };
                });
            }
            break;
        case "practical":
            PracticalInfo.practicals.forEach(practical => {
                if (practical.id === id) returnInfo = {
                    info: practical,
                    type: "practical",
                    locked: CheckIfLocked(id),
                };
            });
            break;
        default:
            break;
    }

    return returnInfo
}

function GetInfoType(id) {
    var returnVar = 'test'

    if (id === undefined) {
        id = ""
    }
    
    if (id.includes("_prac")) {
        returnVar = 'practical'
    }
    else {
        ModuleStructure.modules.forEach((module) => {
            module.topics.forEach((topic) => {
                if (topic.topicID === id) {
                    returnVar = "topic";
                }
                topic.lessonIDs.forEach((lesson) => {
                    if (lesson === id) {
                        returnVar = 'lesson';
                    }
                });
                if (topic.pracID === id) {
                    returnVar = "practical";
                }
            });
        });
    }

    return returnVar
}
