import React from "react";
import PropTypes from "prop-types";

export const EmailField = (props) => {
    const email = props.email
    const handleEmailChanged = props.handleEmailChanged;

    return (
        <>
            <div className="form-group">
                <div className="form-field">
                    <input
                        type="text"
                        onChange={(e) => handleEmailChanged(e.target.value)}
                        className="form-control email"
                        value={email}
                    />
                    <span className="error_txt d-none">Incorrect email</span>
                </div>
            </div>
        </>
    );
};

EmailField.propTypes = {
    email: PropTypes.string,
    handleEmailChanged: PropTypes.func,
};
