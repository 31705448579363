import { CheckIfLoggedIn } from "./CheckIfLoggedIn";
import { Decrypt } from "./Decrypt";
import { Encrypt } from "./Encrypt";
import { UpdateUserData } from "./updateUserData";

Date.prototype.addHours = function (h) {
    this.setHours(this.getHours() + h);
    return this;
};

export function CheckForPremium(callback = () => {}) {
    if (localStorage.getItem("clickId") !== null) {
        UpdateUserData("ClickID", localStorage.getItem("clickId"));
    }

    const loggedIn = CheckIfLoggedIn();

    if (loggedIn === null || loggedIn === undefined || loggedIn === false) {
        callback(false, "no login");
        setStorage(false);
        return;
    }

    const encryptedDate = localStorage.getItem(
        process.env.REACT_APP_PREMIUM_CODE_NAME + "date"
    );

    const encryptedValue = localStorage.getItem(
        process.env.REACT_APP_PREMIUM_CODE_NAME + "value"
    );

    var checkedDate = undefined;
    var value = undefined;
    if (encryptedDate !== null) {
        checkedDate = Decrypt(encryptedDate);
    }
    if (
        encryptedValue !== null &&
        encryptedValue !== "false" &&
        encryptedDate !== false
    ) {
        value = Decrypt(encryptedValue);
    }

    if (checkedDate !== undefined) {
        const today = new Date();
        const lastChecked = new Date(checkedDate);
        if (today < lastChecked.addHours(4)) {
            if (value === undefined || value === null || value === "") {
                callback(false, "checked but no value set");
                setStorage(false);
                return;
            } else {
                callback(value, "checked value in storage");
                setStorage(value);
                return;
            }
        }
    }

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const sessionTicket = CheckIfLoggedIn(true);

    if (
        sessionTicket === null ||
        sessionTicket === undefined ||
        sessionTicket === false
    ) {
        callback(false, "not logged in");
        setStorage(false);
        return;
    }
    console.log("GET USER DATA");

    var raw = JSON.stringify({
        PlayFabId: localStorage.getItem("playfabID"),
        Keys: [
            "ReceiptStripeActiveUntilDate",
            "Receipt",
            "ProductInterval",
            "SubOverride",
        ],
        SessionTicket: sessionTicket,
    });

    var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
    };

    fetch(process.env.REACT_APP_SERVER_URL + "/api/getUserData", requestOptions)
        .then((response) => response.text())
        .then((data) => {
            var stripeReceipt = JSON.parse(data).ReceiptStripeActiveUntilDate;
            var receipt = JSON.parse(data).Receipt;
            var productInterval = JSON.parse(data).ProductInterval;
            var subOverride = JSON.parse(data).SubOverride;

            if (
                stripeReceipt === undefined &&
                receipt === undefined &&
                productInterval === undefined &&
                subOverride === undefined
            ) {
                setStorage(false);
                callback(false, "no playfab data");
                return;
            }

            if (stripeReceipt !== undefined) {
                stripeReceipt = new Date(stripeReceipt.Value);
                const today = new Date();
                if (stripeReceipt > today) {
                    localStorage.setItem("activeUntil", stripeReceipt);
                    if (productInterval !== undefined) {
                        localStorage.setItem("interval", productInterval.Value);
                    }
                    setStorage(true, "stripe");
                    callback(true, "stripe receipt valid");
                    return;
                } else {
                    setStorage(false, "stripe");
                    localStorage.setItem("expiredReceipt", true);
                    callback(false, "stripe receipt expired");
                    return;
                }
            }

            if (receipt !== undefined) {
                var myHeaders = new Headers();
                myHeaders.append("Content-Type", "application/json");

                var raw = JSON.stringify({
                    PlayFabId: localStorage.getItem("playfabID"),
                });

                var requestOptions = {
                    method: "POST",
                    headers: myHeaders,
                    body: raw,
                    redirect: "follow",
                };

                fetch(
                    process.env.REACT_APP_SERVER_URL + "/api/verifyReceipt",
                    requestOptions
                )
                    .then((response) => response.text())
                    .then((result) => {
                        const valid = JSON.parse(result).valid;
                        const platform = JSON.parse(result).platform;
                        const expired = JSON.parse(result).expired;
                        setStorage(valid, platform);

                        if (expired === true || expired === "true") {
                            window.location.href =
                                process.env.REACT_APP_CLIENT_URL +
                                "/RestorePurchase";
                        } else {
                            callback(valid, "cross platform");
                        }
                        return;
                    })
                    .catch((error) => console.log("error", error));
            }

            if (subOverride !== undefined) {
                subOverride = subOverride.Value;
                if (subOverride === true || subOverride === "true") {
                    setStorage(true, "uniSSO");
                    callback(true, "sub override true");
                    return;
                } else {
                    setStorage(false, "uniSSO");
                    callback(false, "sub override false");
                    return;
                }
            }

            setStorage(false, "NA");
            callback(false, "no valid receipt");
        })
        .catch((error) => {
            if (String(error).includes("undefined is not an object")) {
                callback(false, "undefined data");
            } else {
                console.log("error", error);
            }
        });
}

function setStorage(value, platform) {
    localStorage.setItem(
        process.env.REACT_APP_PREMIUM_CODE_NAME + "value",
        Encrypt(value)
    );
    localStorage.setItem(
        process.env.REACT_APP_PREMIUM_CODE_NAME + "date",
        Encrypt(new Date().addHours(4))
    );
    if (platform) {
        localStorage.setItem("platform", platform);
    }
}
