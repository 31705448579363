import React, { useEffect } from "react";
import PropTypes from "prop-types";

import { useSearchParams } from "react-router-dom";

import immersify_logo from "../images/immersify_logo.svg";
import shape_1 from "../images/shape_1.svg";
import loader from "../images/loader3.gif";
import { firebaseCall } from "../Utils/firebaseAnalytics";
const { encode, decode } = require("url-encode-decode");

//import { getMobileOperatingSystem } from '../Utils/getMobileOperatingSystem';

// https://immersifydental.page.link/?link=https://immersifyeducation.com/deeplink?dl[PARAMS_GO_HERE]&apn=com.immersifyeducation.immersifydental&isi=1509735183&ibi=com.immersifyeducation.immersifydental
// https://immersifydental.page.link/?link=https://immersifyeducation.com/deeplink?dl=immersifyeducation://immersifydental?loadScene=DentalID|sceneParams=[ModelGroupLabel:none,ContentGroupLabel:none,mode:quiz]|activityID=3dDental_prac&apn=com.immersifyeducation.immersifydental&isi=1509735183&ibi=com.immersifyeducation.immersifydental

// https://immersifyeducation.com/deeplink?dl=immersifyeducation://immersifydental?loadScene%3DDentalID%7CsceneParams%3D%5BModelGroupLabel:none,ContentGroupLabel:none,mode:quiz%5D%7CactivityID%3D3dDental_prac

// http://localhost:3000/deeplink?dl=%5Bimmersifyeducation%3A%2F%2Fimmersifydental%3Firclickid%3DTEST%5D
// http://localhost:3000/deeplink?dl=%5Bimmersifyeducation%3A%2F%2Fimmersifydental%3Firclickid%3DTESTYT%5D%26apn%3Dcom.immersifyeducation.immersifydental%26isi%3D1509735183%26ibi%3Dcom.immersifyeducation.immersifydental
// http://localhost:3000/deeplink?dl=%5Bimmersifyeducation%3A%2F%2Fimmersifydental%3Firclickid%3DGATESTING2%5D%26apn%3Dcom.immersifyeducation.immersifydental%26isi%3D1509735183%26ibi%3Dcom.immersifyeducation.immersifydental
export const Deeplinks = (props) => {
    const [searchParams] = useSearchParams();
    const appDeeplink = searchParams.get("dl");

    useEffect(() => {
        const searchTerm = props.fullSearch;
        if (searchTerm !== undefined) {
            if (searchTerm.split("clickid")[1] !== undefined) {
                let clickId = searchTerm.split("clickid")[1].split("%3D")[1].split("%5D")[0];
                localStorage.setItem("clickId", clickId);
                firebaseCall("deeplink_click", { click_id: clickId });
            }

        }

        console.log({ appDeeplink });
        if (appDeeplink !== null) {
            goToDeeplink(appDeeplink);
        } else {
            window.location.href = process.env.REACT_APP_CLIENT_URL + "/Profile";
        }
    }, []);
    firebaseCall("lesson_navigation_open", { activity_id: "id" });

    function firebase(fullURL) {
        const decoded = decode(fullURL);
        console.log("decoded", decoded);
        if (decoded.split("clickid")[1] !== undefined) {
            const clickId = decoded
                .split("clickid")[1]
                .replace("]", "")
                .replace("=", "");
            firebaseCall("deeplink_redirect", { click_id: clickId });
        }
    }
    function goToDeeplink() {
        var fullURL = window.location.href;
        console.log(fullURL);

        if (fullURL.includes("&")) {
            var decodedDeeplink = fullURL.split("%5B");
            var startOfURL = decodedDeeplink[0];
            decodedDeeplink = decodedDeeplink[1];
            decodedDeeplink = decodedDeeplink.split("%5D");
            var endOfURL = decodedDeeplink[1];
            decodedDeeplink = decodedDeeplink[0];
            decodedDeeplink = "[" + decodedDeeplink + "]";
            var encodedDeeplink = encode(decodedDeeplink);
            fullURL = startOfURL + encodedDeeplink + endOfURL;
            console.log(fullURL);
        }

        fullURL = fullURL.replace(
            "https://immersifyeducation.com",
            process.env.REACT_APP_WORDPRESS_URL
        );
        fullURL = fullURL.replace(
            "https://www.immersifyeducation.com",
            process.env.REACT_APP_WORDPRESS_URL
        );
        fullURL = fullURL.replace(
            "https://www.immersifyplatform.com",
            process.env.REACT_APP_WORDPRESS_URL
        );
        fullURL = fullURL.replace(
            "https://localhost:3000",
            process.env.REACT_APP_WORDPRESS_URL
        );

        console.log(fullURL);

        window.location.href = fullURL;
        firebase(fullURL);

        /*let formattedDL = deeplink.replace("[", "").replace("]", "");
        window.location.replace(formattedDL);
        setTimeout(openStorePage, 2000);*/
    }

    /*function openStorePage() {
        var os = getMobileOperatingSystem();
        if (os == "Android") {
            window.location.replace(
                "https://play.google.com/store/apps/details?id=com.immersifyeducation.immersifydental"
            );
        } else {
            //window.location.replace("https://apps.apple.com/gb/app/immersify-dental/id1509735183");
        }
    }

    function openStorePageBtn() {
        var os = getMobileOperatingSystem();
        if (os == "Android") {
            window.location.replace(
                "https://play.google.com/store/apps/details?id=com.immersifyeducation.immersifydental"
            );
        } else {
            window.location.replace(
                "https://apps.apple.com/gb/app/immersify-dental/id1509735183"
            );
        }
    }*/

    return (
        <section className="container-fluid">
            <div className="row">
                <div className="col-xl-7 col-md-6 left_block d-none d-md-block">
                    <div className="signup_image">
                        <div className="shape_block">
                            <div className="shape_1">
                                <img alt="placeholder" src={shape_1} />
                            </div>
                            <div className="shape_2">
                                <img alt="placeholder" src={shape_1} />
                            </div>
                        </div>
                        <div className="login_text_block">
                            <h1>LEARN.</h1>
                            <h1>EXPERIENCE.</h1>
                            <h1>CONNECT.</h1>
                        </div>
                    </div>
                </div>
                <div className="col-xl-5 col-md-6">
                    <div className="logo_wrap">
                        <img alt="placeholder" src={immersify_logo} />
                    </div>
                    <div className="signup_form_area redirect_sec">
                        <div className="loader_dir">
                            <img src={loader} alt="loader" />
                        </div>
                        <h2>Redirecting...</h2>
                        <h3>Please do not refresh </h3>
                        <p>
                            If you have been waiting longer than a few seconds
                            please
                            <button
                                className="btn_underline"
                                onClick={goToDeeplink}
                            >
                                Click here
                            </button>
                        </p>
                    </div>
                </div>
            </div>
        </section>
    );
};

Deeplinks.propTypes = {
    deeplinksId: PropTypes.string,
    search: PropTypes.string,
    fullSearch: PropTypes.string
};
