import React from "react";

import { AFramePlayer } from "../../../../../../../../AFrame/AFramePlayer";
import { ImagePlayer } from "../../../../../../../../AFrame/ImagePlayer";

export function GetPointData(standardLessonMan) {
    
    const textContent = standardLessonMan.lessonJSON.textData.contentText;
    const subheadingText = standardLessonMan.lessonJSON.textData.subheadingText;

    const modelData = standardLessonMan.lessonJSON.modelData.models;
    const imageData = standardLessonMan.lessonJSON.imageData.images;

    let content = [];

    for (let index = 0; index < textContent.length; index++) {
        switch (textContent[index].type) {
            case "3DModel":
                if (modelData === undefined) {
                    console.log("no model data");
                    break;
                }
                content[index] = (
                    <AFramePlayer
                        key={"AframePlayer" + index}
                        id={index}
                        modelData={modelData}
                    />
                );
                break;
            case "2DImage":
                if (imageData === undefined) {
                    console.log("no image data");
                    break;
                }
                content[index] = (
                    <ImagePlayer
                        key={"ImagePlayer" + index}
                        id={index}
                        imageData={imageData}
                    />
                );
                break;
        }
    }

    return {
        textContent: textContent,
        subheadingText: subheadingText,
        content: content,
    };
}
