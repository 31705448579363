
import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { StandardLessonContent } from "./StandardLessonContent/StandardLessonContent";

import "../../../../../SCSS/style.css";
import "../../../../../SCSS/fonts.css";

export const StandardLesson = (props) => {

    const id = props.id
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])

    return (
        <>
            <StandardLessonContent id={id} />
        </>
    );
}

StandardLesson.propTypes = {
    id: PropTypes.any,
    className: PropTypes.any,
    style: PropTypes.any,
    onClick: PropTypes.any,
};