import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types'

import "../../../../../../SCSS/style.css";
import "../../../../../../SCSS/fonts.css";
import closeButton from "../../../../../../images/close_btn.svg";
import google from "../../../../../../images/google_play.png";
import apple from "../../../../../../images/apple_store.png";
import Septodont from "../../../../../../images/septodont.png";
import { GetInfoFromID } from '../../../../../../Utils/getInfoFromID';
import { CheckForPremium } from '../../../../../../Utils/checkForPremium';
import { CheckIfLocked } from '../../../../../../Utils/checkIfLocked';

export const PracticalPopup = (props) => {

    const [premium, setPremium] = useState(false)

    const id = props.id

    const experienceInfo = GetInfoFromID(id);
    var info;
    var title
    var iconLocation
    var qrCodeLocation
    var deeplink
    var locked

    if (experienceInfo !== undefined) {
        info = experienceInfo.info;
        title = info.title;
        iconLocation = info.iconLocation;
        qrCodeLocation = info.qrCodeLocation;
        if (
            qrCodeLocation === "" ||
            qrCodeLocation === undefined ||
            qrCodeLocation === null
        ) {
            qrCodeLocation = "Launch-App-qr-code.png";
        }
        deeplink = info.deeplink;
        if (
            deeplink === "" ||
            deeplink === undefined ||
            deeplink === null
        ) {
            deeplink =
                "https://immersifyeducation.com/deeplink?dl=%5Bimmersifyeducation%3A%2F%2Fimmersifydental%5D";
        }
        if (qrCodeLocation === "") {
            qrCodeLocation = "InstrumentID-qr-code.png";
        }
    }

    var description
    if (
        info !== undefined &&
        info.description !== undefined &&
        info.description.length !== 0
    ) {
        description = info.description.substring(0, 250) + "...";
    }



    if (id === "localAna_prac") {
        description = "New pain control practical. Using your knowledge of anaesthetics, administer the correct dose in the right place to control your patient's pain."
    }

    useEffect(() => {
        if (CheckIfLocked(id, false)) {
            locked = true
            CheckForPremium(checkForPremiumCallback, "practicalPopup");
        }
        else {
            setPremium(true)
            locked = false
        }
    }, []);

    function checkForPremiumCallback(data) {
        console.log({ data })
        setPremium(data);
    }

    function handleCloseModal() {
        document.getElementById("modal").classList.remove("show");
    }

    function handleDeeplinkClicked () {
        window.location.href = deeplink
    }
    
    function handleSponserClicked () {
        window.open("https://www.septodont.co.uk");
    }

    return (
        <>
            <div
                className="modal fade prm_content_popup"
                id={"premium_content" + id}
                data-bs-keyboard="true"
                tabIndex={-1}
                aria-labelledby="quiz"
                aria-hidden="true"
            >
                <div className="modal-dialog">
                    <div className="modal-content">
                        <button type="button" className="btn_mod_close" data-bs-dismiss="modal" aria-label="Close">
                            <img alt="placeholder" src={closeButton} />
                        </button>
                        <div className="modal-body">
                            <div className="prm_wrap">
                                <div className="prm_left">
                                    <div className="prm_left_header">
                                        <div className={"act_icon" + locked === "true" ? " premium" : {}}>
                                            <img
                                                src={
                                                    "https://d1476tmm9qfop0.cloudfront.net/TestFiles/Icons/webp/px-" +
                                                    iconLocation.slice(6).slice(0, -4) +
                                                    ".webp"
                                                }
                                                className="img-fluid"
                                                alt="Activity - Icon"
                                            />
                                        </div>
                                        <div className="title_block_m">
                                            <h2>{title}</h2>

                                            {id === "localAna_prac" ? (
                                                <>
                                                    <div className="sponsor_tag">
                                                        <h5>
                                                            Sponsored by
                                                            <img src={Septodont} alt="Septodont" />
                                                            {/* <button target="_blank" onClick={handleSponserClicked}>
                                                                Septodont
                                                            </button> */}
                                                            <Link
                                                                to="route"
                                                                target={"_blank"}
                                                                onClick={handleSponserClicked}
                                                            >
                                                                Septodont
                                                            </Link>
                                                        </h5>
                                                    </div>
                                                </>
                                            ) : (
                                                <></>
                                            )}
                                        </div>
                                    </div>
                                    <p>{description}</p>

                                    <div className="exp_video_sec">
                                        <div className="exp_vid"></div>
                                        <div className="exp_txt">
                                            <h2>Available in the app</h2>
                                            {(premium === "false" || premium === false) && locked === true ? (
                                                <h3>Get your 14 day free trial now!</h3>
                                            ) : (
                                                <h3>Download the app and start playing now!</h3>
                                            )}
                                            {(premium === "false" || premium === false) && locked === true ? (
                                                <Link to="/Premium" className="btn_premium" onClick={handleCloseModal}>
                                                    GO PREMIUM
                                                </Link>
                                            ) : (
                                                <></>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className="prm_right">
                                    <h2 className="d-none d-md-block">Download the app</h2>
                                    <h2 className="d-block d-sm-block d-md-none">
                                        <button className="play_link" onClick={handleDeeplinkClicked}>
                                            Click to Play now
                                        </button>
                                    </h2>
                                    <div className="qr_code">
                                        <div className="qr_block">
                                            {qrCodeLocation !== undefined ? (
                                                <img
                                                    src={
                                                        "https://d1476tmm9qfop0.cloudfront.net/TestFiles/QR_CODES/" +
                                                        qrCodeLocation
                                                    }
                                                    className="img-fluid"
                                                    alt="QR Code"
                                                />
                                            ) : (
                                                <></>
                                            )}
                                        </div>

                                        <h5>Scan the above QR code to play this experience in the app.</h5>
                                    </div>

                                    <div className="play_store">
                                        <a
                                            href="https://apps.apple.com/us/app/immersify-dental/id1509735183"
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            <img src={apple} className="img-fluid" alt="QR Code" />
                                        </a>
                                        <a
                                            href="https://play.google.com/store/apps/details?id=com.immersifyeducation.immersifydental"
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            <img src={google} className="img-fluid" alt="QR Code" />
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

PracticalPopup.propTypes = {
    id: PropTypes.string,
}
