import React from "react";
import Slider from "react-slick";
import '../SCSS/landing.css';
import '../SCSS/fonts.css';

export const RelatedLinks = () => {


    function handleBlog () {
        window.location.href = process.env.REACT_APP_WORDPRESS_URL + "/blog";
    }

    function handleStudents () {
        window.location.href = process.env.REACT_APP_CLIENT_URL + "/landing";
    }

    


    const settings = {
        dots: false,
        arrows:true,
        infinite: false,
        speed: 500,
        slidesToShow: 3,

        responsive: [{
            breakpoint: 991,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              initialSlide: 1,
              arrows:false,
              dots:true,
              margin:10
            }
          },
          {
          breakpoint: 767,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 1,
            arrows:false,
            dots:true
          }}]
      };

    return (
        <>
            <section className="findoutmore">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="f_more_head">
                                <h4>Find out more</h4>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="f_more_slider">
                            <Slider {...settings}>
                                <div className="f_more_item" onClick={handleStudents}>
                                    <div className="f_icon_top">
                                        <i className="icon-learners"></i>
                                    </div>
                                    <div className="f_more_txt">
                                        <h5>Students</h5>
                                        <p>
                                        Engaging resources for better education.
                                        </p>
                                    </div>
                                </div>

                                <div className="f_more_item" onClick={handleStudents}>
                                    <div className="f_icon_top">
                                        <i className="icon-educator"></i>
                                    </div>
                                    <div className="f_more_txt">
                                        <h5>Institutions</h5>
                                        <p>
                                        Enhancing teaching experiences through advanced technology.
                                        </p>
                                    </div>
                                </div>

                                <div className="f_more_item" onClick={handleStudents}>
                                    <div className="f_icon_top">
                                        <i className="icon-professional"></i>
                                    </div>
                                    <div className="f_more_txt">
                                        <h5>Professionals</h5>
                                        <p>
                                        Looking for a reliable resource that is relevant to industry standards?
                                        </p>
                                    </div>
                                </div>

                                <div className="f_more_item" onClick={handleBlog}>
                                    <div className="f_icon_top">
                                        <i className="fa fa-file-text"></i>
                                    </div>
                                    <div className="f_more_txt">
                                        <h5>Blog</h5>
                                        <p>
                                        Discover Immersify&apos;s latest news, practical teaching advice, and smart studying tips.
                                        </p>
                                    </div>
                                </div>
                            </Slider>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};