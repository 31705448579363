export function UpdateContactEmail(sessionTicket, email) {
    var myHeaders = new Headers();
    myHeaders.append("X-Authorization", sessionTicket);
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
        EmailAddress: email,
    });

    var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
    };

    fetch("https://29001.playfabapi.com/Client/AddOrUpdateContactEmail", requestOptions)
        .then((response) => response.text())
        .then((result) => console.log(result))
        .catch((error) => console.log("error", error));
}
