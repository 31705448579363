import React, {useState, useEffect} from "react";
import {useParams} from "react-router-dom";
import PropTypes from "prop-types";
// import Slider from "react-slick";
import "../../../SCSS/style.css";

import {GetInfoFromID} from "../../../Utils/getInfoFromID";
// import sparkles from "../../../images/sparkles.svg";
// import { SideMenu } from "../SideMenu/SideMenu";
// import { TopHeader } from "../Header/TopHeader";
// import { Breadcrumb } from "../Breadcrumb/Breadcrumb";

import {DisplayTopics} from "../DisplayContent/DisplayTopics/DisplayTopics";
import {DisplayExperiences} from "./DisplayExperiences/DisplayExperiences";
import {DisplayLesson} from "../DisplayContent/DisplayLesson/DisplayLesson";
import {DisplayPremium} from "./DisplayPremium/DisplayPremium";
import {DisplayProfile} from "./DisplayProfile/DisplayProfile";
// import close_button_image from "../../../images/close_btn.svg";
// import { CheckIfLoggedIn } from "../../../Utils/CheckIfLoggedIn";
// import { SetPrice } from "../../../Utils/setPrice";
import {CheckForPremium} from "../../../Utils/checkForPremium";
import {GetExperienceTopic} from "../../../Utils/getExperienceTopic";
import availablePracticals from "../../InfoJSONs/availablePracticals.json";
import {DisplayPractical} from "./DisplayPracticals/DisplayPractical";
import {updateUserProperties} from "../../../Utils/firebaseAnalytics";
import {TopHeader} from "../Header/TopHeader";
// import { AcademicArea } from "../../academicArea";
// import { GetUserData } from "../../../Utils/getUserData";

export const DisplayContent = (props) => {
  let urlParams = Object.values(useParams())[0];
  let path;
  const available = availablePracticals.available;

  if (props.path !== undefined) {
    path = props.path.toLowerCase();
  } else {
    path = "";
  }
  const [id, setId] = useState(props.id);
  // const [sideMenuExpanded, setSideMenuExpanded] = useState(true);

  // const [monthlyPrice, setMonthlyPrice] = useState(localStorage.getItem("monthlyPrice"));
  // const [yearlyPrice, setYearlyPrice] = useState(localStorage.getItem("yearlyPrice"));
  // const [yearlyPerMonth, setYearlyPerMonth] = useState(localStorage.getItem("yearlyPricePerMonth"));
  // const [monthlyPrevious, setMonthlyPrevious] = useState(localStorage.getItem("monthlyPrevious"));
  // const [yearlyPrevious, setYearlyPrevious] = useState(localStorage.getItem("yearlyPrevious"));
  // const [yearlyPreviousPerMonth, setYearlyPreviousPerMonth] = useState(localStorage.getItem("yearlyPreviousPerMonth"));
  // // const [ selectedAcademicArea, setSelectedAcademicArea ] = useState(false);
  // const [lessonViewed, setLessonViewed] = useState(false);

  // if (monthlyPrice === undefined || monthlyPrice === null) {
  //     const loggedIn = CheckIfLoggedIn();
  //     if (loggedIn === undefined || loggedIn === false) {
  //         SetPrice(gotPriceCallback);
  //     } else {
  //         setMonthlyPrice("$7.49");
  //         setYearlyPrice("$59.99");
  //         setYearlyPerMonth("$4.99");
  //         setMonthlyPrevious("$14.99");
  //         setYearlyPrevious("$119.99");
  //         setYearlyPreviousPerMonth("$9.99");
  //     }
  // }
  //
  // function gotPriceCallback(m, y, mp, yp, ypm, yppm) {
  //     setMonthlyPrice(m);
  //     setYearlyPrice(y);
  //     setYearlyPerMonth(ypm);
  //     setMonthlyPrevious(mp);
  //     setYearlyPrevious(yp);
  //     setYearlyPreviousPerMonth(yppm);
  // }

  const info = GetInfoFromID(id);
  var type = "";
  if (info !== undefined) {
    type = GetInfoFromID(id).type;
  }

  var premium;

  useEffect(() => {
    if (urlParams !== "" && urlParams !== undefined) {
      if (urlParams !== "") {
        setId(urlParams);
      }
    }
    CheckForPremium(checkForPremiumCallback);
    updateUserProperties("web_platform");
    // checkIfAcademicAreaSelected()
  }, []);
  // function checkIfAcademicAreaSelected () {
  //     GetUserData(["AcademicArea"], gotAcademicArea);
  // }
  // function gotAcademicArea(data) {
  //     data = JSON.parse(data);
  //     if (data.AcademicArea.Value == undefined) {
  //         setSelectedAcademicArea(true);
  //     }
  // }
  // useEffect(() => {
  //     if (type === "lesson" || type === "animated" || type === "practical") {
  //         setLessonViewed(true);
  //         setSideMenuExpanded(false);
  //     } else {
  //         setSideMenuExpanded(true);
  //         setLessonViewed(false);
  //     }
  // }, [path, id, type]);

  function checkForPremiumCallback(data, reason) {
    console.log({data});
    console.log({reason});
    if (data === true || data === "true") {
      premium = true;
    } else {
      console.log("set to false");
      premium = true;
    }
  }

  const contentToDisplay = () => {
    switch (path) {
      case "experience":
        switch (type) {
          case "practical":
            // check if available
            if (available.includes(id)) {
              return <DisplayPractical id={id}/>;
            } else {
              //if not return to topic page
              window.location.href = process.env.REACT_APP_CLIENT_URL + "/experience/" + GetExperienceTopic(id);
              break;
            }
          case "lesson":
            return <DisplayLesson id={id} displayContentCallback={updateState} premium={premium}/>;
          case "animated":
            return <DisplayLesson id={id} displayContentCallback={updateState} premium={premium}/>;
          case "topic":
            return <DisplayExperiences topicId={id} displayContentCallback={updateState} premium={premium}/>;
          default:
            return <DisplayTopics displayContentCallback={updateState} premium={premium} academicArea={false}/>;
        }
        break;
      case "premium":
        return <DisplayPremium premium={premium}/>;
      case "profile":
        return <DisplayProfile premium={premium}/>;
      case "profile/resetpassword":
        return <DisplayProfile premium={premium} passwordReset={true}/>;
      case "profile/subscription":
        return <DisplayProfile premium={premium} initialPath={"subscription"}/>;
      default:
        return <DisplayTopics displayContentCallback={updateState} premium={premium}/>;
    }
  };

  function filterCallback() {
  }

  function updateState(idParam) {
    setId(idParam);
  }

  // const navigate = useNavigate();
  // function handleOfferClick() {
  //     navigate("/Premium");
  // }

  //uncomment this when it's time to deploy academic
  // if (selectedAcademicArea == false) {
  return (
    <section
      className={
        "main_area_clean"
      }
    >
      {/*<div className="modal fade offer_popup" id="offer_popup" data-bs-keyboard="true" tabIndex={-1} aria-labelledby="Offer" aria-hidden="true">*/}
      {/*    <div className="modal-dialog">*/}
      {/*        <div className="modal-content">*/}
      {/*            <div className="modal-header">*/}
      {/*                <button type="button" className="btn_mod_close" data-bs-dismiss="modal" aria-label="Close">*/}
      {/*                    <img alt="placeholder" src={close_button_image} />*/}
      {/*                </button>*/}
      {/*            </div>*/}
      {/*            <div className="modal-body">*/}
      {/*                <div className="offer_block">*/}
      {/*                    <div className="offer_head">*/}
      {/*                        <h3>Get your 14 day free trial</h3>*/}
      {/*                    </div>*/}
      {/*                    {process.env.REACT_APP_OFFER === true ? (*/}
      {/*                        <div className="offer_sec">*/}
      {/*                            <div className="sparkles">*/}
      {/*                                <div className="one">*/}
      {/*                                    <img src={sparkles} alt="Sparkles" />*/}
      {/*                                </div>*/}
      {/*                                <div className="two">*/}
      {/*                                    <img src={sparkles} alt="Sparkles" />*/}
      {/*                                </div>*/}
      {/*                            </div>*/}
      {/*                            <div className="diamond_1"></div>*/}
      {/*                            <div className="diamond_2"></div>*/}
      {/*                            <h1>*/}
      {/*                                50 <span className="slash_rt">/</span>*/}
      {/*                                <span className="off_nj"> off.</span>*/}
      {/*                            </h1>*/}
      {/*                            <h5>Offer is valid for limited time only.</h5>*/}
      {/*                        </div>*/}
      {/*                    ) : (*/}
      {/*                        <></>*/}
      {/*                    )}*/}
      {/*                    <div className="offer_details">*/}
      {/*                        <div className="off_opt">*/}
      {/*                            <h5>Monthly</h5>*/}
      {/*                            <h2>*/}
      {/*                                {monthlyPrice} <div className="per_mo">/ Mo</div>*/}
      {/*                                {process.env.REACT_APP_OFFER === true ? <span>{monthlyPrevious}</span> : <></>}*/}
      {/*                            </h2>*/}
      {/*                        </div>*/}

      {/*                        <div className="off_opt">*/}
      {/*                            <h5>Annually</h5>*/}
      {/*                            <h2>*/}
      {/*                                {yearlyPerMonth}*/}
      {/*                                <div className="per_mo">/ Mo</div>*/}
      {/*                                {process.env.REACT_APP_OFFER === true ? <span>{yearlyPreviousPerMonth}</span> : <></>}*/}
      {/*                            </h2>*/}
      {/*                            <div className="bill_yearly">*/}
      {/*                                <h6>*/}
      {/*                                    Billed annually for {yearlyPrice}*/}
      {/*                                    <span>{process.env.REACT_APP_OFFER === true ? { yearlyPrevious } : <></>}</span>*/}
      {/*                                </h6>*/}
      {/*                            </div>*/}
      {/*                        </div>*/}
      {/*                    </div>*/}
      {/*                    <div className="off_btn_blk">*/}
      {/*                        <button className="btn_exit" data-bs-dismiss="modal">*/}
      {/*                            No thanks*/}
      {/*                        </button>*/}
      {/*                        <button className="btn_grab" data-bs-dismiss="modal" onClick={handleOfferClick}>*/}
      {/*                            Start your free trial now*/}
      {/*                        </button>*/}
      {/*                    </div>*/}
      {/*                </div>*/}
      {/*            </div>*/}
      {/*        </div>*/}
      {/*    </div>*/}
      {/*</div>*/}
      {/*{props.path !== "experience/clean" ? (*/}
      {/*    <>*/}
      {/*        <SideMenu path={path} sideMenuExpanded={sideMenuExpanded} setSideMenuExpanded={setSideMenuExpanded} />*/}

      {/*        <>*/}
      {/*        </>*/}
      {/*    </>*/}
      {/*) : null}*/}

      {/*{path === "experience" && type !== "" ? (*/}
      {/*    <>*/}
      {/*        <Breadcrumb id={id} displayContentCallback={updateState} />*/}
      {/*    </>*/}
      {/*) : (*/}
      {/*    <></>*/}
      {/*)}*/}
      <TopHeader filterCallback={filterCallback}/>

      <>{contentToDisplay()}</>
    </section>
  );
  // } else {
  //     return(
  //         <AcademicArea stateChanger={setSelectedAcademicArea}/>
  //     )

  // }
};

DisplayContent.propTypes = {
  id: PropTypes.string,
  path: PropTypes.string,
};
