import ModuleStructure from "../ContentDeliverySystem/InfoJSONs/ModuleStructure.json"

export function GetExperienceTopic(id) {

    var topicId = []

    ModuleStructure.modules.forEach((module) => {
        module.topics.forEach((topic) => {
            topic.lessonIDs.forEach((lesson) => {
                if (lesson === id) {
                    topicId.push(topic.topicID)
                }
            });
            if (topic.pracID === id) {
                topicId.push(topic.topicID)
            }
        });
    });

    return topicId
}
