// eslint-disable-next-line
import React, { useEffect } from "react";
import PropTypes from "prop-types";
import mediumZoom from "medium-zoom";

import '../SCSS/style.css';

export const ImagePlayer = (props) => {  
    useEffect(() => {
        getImageForThisPoint();
    }, []);
    const getImageForThisPoint = () => {
        let imagesForThisPointList = [];
        props.imageData.forEach(image => {
            if(image.points.includes(props.id)){
                imagesForThisPointList.push(image);
            }
        });

        if(imagesForThisPointList !== undefined && imagesForThisPointList.length > 0)
        {
            return imagesForThisPointList; 
        }
    }

    const background = "#FFF";
    const zoom = mediumZoom()
    const zoomRef = zoom.clone({ background })
    // console.log(zoomRef)

    function attachZoom(image) {
        zoomRef.attach(image);
    }

    const ImageSection = () => {
        let imageForThisPointList = getImageForThisPoint()
        if(imageForThisPointList !== undefined && imageForThisPointList.length > 0){
            return (
                <img
                    src={
                        process.env.REACT_APP_S3_WEBAPP_ROOT +
                        getImageForThisPoint()[0].URL
                    }
                    ref={attachZoom}
                />
            );
        }else{
            return null;
        }
    };

    return(
        <>
        <ImageSection />
        </>
    );
};

ImagePlayer.propTypes = {
    id: PropTypes.number,
    imageData: PropTypes.any
};