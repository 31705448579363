import React, { useEffect } from "react";
import PropTypes from "prop-types";
import "../../../../SCSS/style.css";

import { GetPracticalType } from "./GetPracticalType/GetPracticalType";

import { ErrorPage } from "../../../error";
import { NextComing } from "../DisplayLesson/NextComing/NextComing";
import { CheckForPremium } from "../../../../Utils/checkForPremium";
import { CheckIfLocked } from "../../../../Utils/checkIfLocked";
import { VMCQ } from "./VMCQ/VMCQ";

export const DisplayPractical = (props) => {

    useEffect(() => {
        const locked = CheckIfLocked(id)
        if (locked === true) {
            CheckForPremium(checkForPremiumCallback, "displayLesson")
        }
    }, []);

    function checkForPremiumCallback (data) {
        if (data === false || data === "false") {
            window.location.href = process.env.REACT_APP_API_URL + "/Experience";
        }
    }

    const id = props.id;
    const practicalType = GetPracticalType(id);

    const content = () => {
        switch (practicalType) {
            case 'vmcq':
                return  (
                    <VMCQ id={id} />
                )
            // case 'spotter':
            //     return <div>Spotter</div>
            //     // return <Spotter id={id} />
            default:
                return <ErrorPage />
        }
    }


    const wrapper = () => {
        return (
            <>
                <section className="container-fluid lesson_wrap">
                    <div className="row">
                        <div className="col-lg-9">
                            <div className="lesson_block">
                                {content()}
                            </div>
                        </div>
                        <div className="col-lg-3">
                            <div className="suggest_block practical">
                                <NextComing id={id} displayContentCallback={props.displayContentCallback} />
                            </div>
                        </div>
                    </div>
                </section>
            </>
        );
    }
    return <>{wrapper()}</>;
};

DisplayPractical.propTypes = {
    id: PropTypes.string,
    displayContentCallback: PropTypes.func,
};
