import React from "react";
import { Scene } from "aframe-react";

export function SetPoint(pointData, setSliderPoints, currentPoint) {
    const content = pointData.content;
    localStorage.setItem("currentPoint", currentPoint);

    var points = [];
    // console.log("content", content);
    const pointsJSX = content.map((element, i) => {
        console.log("element", element)
        if (currentPoint === 0 && i === 2) {
            return (
                <Scene
                    embedded
                    device-orientation-permission-ui="enabled: false"
                    vr-mode-ui="enabled: false"
                    key="empty-scene"
                ></Scene>
            );
        }
        if (
            i === currentPoint - 1 ||
            i === currentPoint ||
            i === currentPoint + 1
        ) {
            // console.log("content", content[i])
            points.push(true);
            return (
                <article key={"sliderPoint" + i} className="lesson_slide">

                        <div className="vmcqFeedbackQuestions">
                          <div className="vmcqFeedbackQuestionCounter">
                            <b>
                              {i+1}/{content.length}
                            </b>
                          </div>
                          <h5 className="vmcqFeedbackQuestionText">
                            <b>{element.props.modelData[i].currentQuestion.question}</b>
                          </h5>
                          <div className="vmcqQuestionData">
                            <div className="vmcqFeedbackQuestionModel">
                                <div className="landing_model">
                                <div className="std_lessons">
                                    <div className="std_img_block">
                                        <div className="img_box">{content[i]}</div>
                                    </div>
                                </div>
                                </div>
                              </div>
                            <div className="vmcqAnswerData">
                                <ul className="vmcqList">
                                    {element.props.modelData[i].currentQuestion.answers.map((answer) => (
                                    <li key={answer.answerText}>
                                        <button
                                        className={
                                            "vmcqAnswerButton " +
                                            (answer.correct
                                            ? "right_ans"
                                            : answer.answerText == element.props.modelData[i].answered
                                            ? "wrong_ans"
                                            : "")
                                        }
                                        disabled
                                        >
                                        {answer.answerText}
                                        </button>
                                    </li>
                                    ))}
                                </ul>
                            </div>
                          </div>
                        </div>
                </article>
            );
        } else {
            points.push(false);
            // console.log("not pushing anything ", currentPoint, i)
            return (
                <></>
            );
        }
    });

    setSliderPoints(pointsJSX);
}
