import React from "react";
import google_image from "../../images/google.svg";
// import { GoogleSignIn } from "../scripts/GoogleSignIn";

export function GoogleLoginButton() {
    const loginWithGoogle = () => {
        console.log("a");
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        var requestOptions = {
            mode: "cors",
            method: "GET",
            headers: myHeaders,
            redirect: "follow",
        };

        fetch(process.env.REACT_APP_SERVER_URL + "/api/google/google-login", requestOptions)
            .then((response) => response.text())
            .then((result) => {
                console.log("a result", result);
                window.location.href = result;
            })
            .catch((error) => console.log("error", error));
    };

    return (
        <button className="btn_login google" onClick={loginWithGoogle}>
            {" "}
            <img alt="placeholder" src={google_image} />
            Login with Google
        </button>
    );
}
