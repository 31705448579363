import React from "react";
import PropTypes from "prop-types";

export const CreateAssets = (props) => {

    const vmcqFeedbackModelMan = props.vmcqFeedbackModelMan

    // need to create asset tags for eah model in this lesson
    // let models = vmcqFeedbackModelMan.modelsJSON.modelData.models;
    let models = vmcqFeedbackModelMan.modelsJSON;
    let output = models.map((model) => (
        <a-asset-items
            key={model.id}
            id={model.id}
            // src={process.env.REACT_APP_S3_WEBAPP_ROOT + model.modelURL}
            src={model.modelURL}
        />
    ));

    return output;
};

CreateAssets.propTypes = {
    vmcqFeedbackModelMan: PropTypes.any,
}
