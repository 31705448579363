import React from "react";

import immersify_logo from "../../images/immersify_logo.svg";
import shape_1 from "../../images/shape_1.svg";

export const RestorePurchase = () => {

    function handleContinue() {
    window.location.href = process.env.REACT_APP_CLIENT_URL + "/Profile";
    }

    return (
        <>
            <section className="container-fluid">
                <div className="row">
                    <div className="col-xl-7 col-md-6 left_block d-none d-md-block">
                        <div className="signup_image">
                            <div className="logo_wrap">
                                <img alt="placeholder" src={immersify_logo} />
                            </div>
                            <div className="shape_block">
                                <div className="shape_1">
                                    <img alt="placeholder" src={shape_1} />
                                </div>
                                <div className="shape_2">
                                    <img alt="placeholder" src={shape_1} />
                                </div>
                            </div>
                            <div className="login_text_block">
                                <h1>LEARN.</h1>
                                <h1>EXPERIENCE.</h1>
                                <h1>CONNECT.</h1>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-5 col-md-6">
                        <div className="signup_form_area">
                            <h3>
                                {localStorage.getItem("platform") === "google"
                                    ? "You may have a subscription on the Google Play Store"
                                    : localStorage.getItem("platform") ===
                                      "apple"
                                    ? "You may have a subscription on the Apple App Store"
                                    : ""}{" "}
                            </h3>
                            <h2>
                                {localStorage.getItem("platform") === "google"
                                    ? "Please login on your Android device to update your subscription"
                                    : localStorage.getItem("platform") ===
                                      "apple"
                                    ? "Please login on your iOS device to update your subscription"
                                    : "You may have a subscription on another platform, please login on your mobile device to update your subscription"}
                            </h2>

                            <div className="col-md-12">
                                <div className="btn_area">
                                    <button
                                        className="btn_common w-100"
                                        onClick={handleContinue}
                                    >
                                        Continue
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};
