import PropTypes from "prop-types";
import React, { useState } from "react";
import '../SCSS/style.css';
import '../SCSS/fonts.css';
import { nextStagePressed,prevStagePressed,toggleAudio,goToStagePressed, pauseAudio, playAudio } from '../../Scripts/aframe/lesson-data-manager'
import { delay } from '../Utils/asyncTools'

export const LessonControls = (props) => {

    const [isFullscreen, setIsFullscreen] = useState(false);
    var animManager = props.animManagerProp;

    // eslint-disable-next-line
    function closeFullscreen() {
        if (document.exitFullscreen) {
            document.exitFullscreen();
        } else if (document.webkitExitFullscreen) {
            /* Safari */
            document.webkitExitFullscreen();
        } else if (document.msExitFullscreen) {
            /* IE11 */
            document.msExitFullscreen();
        }
        console.log("Not in fullscreen 2");
    }

    // function openFullscreen() {
    //     if(inFullscreen)
    //     {
    //         closeFullscreen();
    //         //enterVREventTrigger();
    //         //document.exitFullscreen();
    //         //exitVREventTrigger();
    //         //document.exitFullscreen();
    //         inFullscreen = false;
    //         console.log("Not in fullscreen 1");
    //         return;
    //     }

    //     if (elem.requestFullscreen) {
    //         //enterVREventTrigger();
    //         elem.requestFullscreen();
    //     } else if (elem.webkitRequestFullscreen) { /* Safari */
    //         //enterVREventTrigger();
    //         elem.webkitRequestFullscreen();
    //     } else if (elem.msRequestFullscreen) { /* IE11 */
    //         //enterVREventTrigger();
    //         elem.msRequestFullscreen();
    //     }
    //     inFullscreen = true;
    //     console.log("In fullscreen");
    // }

    // eslint-disable-next-line
    /*async function waitToUpdateWidth() {
        await delay(1000);
        let canvasEl = document.querySelector("[data-aframe-canvas]");
        console.log(canvasEl);
        //canvasEl.setAttribute('width', 0);
        canvasEl.width += 1;
        canvasEl.height += 1;
        await delay(1000);
        //canvasEl.setAttribute('width', 720);
        //canvasEl.setAttribute('height', 365);

        canvasEl.width += 1;
        canvasEl.height += 300;

        console.log(canvasEl);
    }*/

    const handleFullscreen = () => {
        let elem = document.getElementById("animvid");
        //let elem = document.getElementById('AnimLessonScene');

        if (isFullscreen) {
            console.log("exit fs");
            document.exitFullscreen();

            //waitToUpdateWidth();

            props.toggleFullScreen(false);
            setIsFullscreen(false);
        } else {
            if (elem.requestFullscreen) {
                elem.requestFullscreen();
            } else if (elem.webkitRequestFullscreen) {
                /* Safari */
                elem.webkitRequestFullscreen();
            } else if (elem.msRequestFullscreen) {
                /* IE11 */
                elem.msRequestFullscreen();
            }
            props.toggleFullScreen(true);
            setIsFullscreen(true);
            console.log("enter fs");
        }
    };

    // eslint-disable-next-line
    async function enterVREventTrigger() {
        let modelViewerScene = document.getElementById("ModelViewerScene");
        // let enterVREvent = new Event('enter-vr');
        // modelViewerScene.dispatchEvent(enterVREvent);
        let vrModeUIComp = modelViewerScene.components["vr-mode-ui"];
        vrModeUIComp.onEnterVRButtonClick();
    }
    // eslint-disable-next-line
    async function exitVREventTrigger() {
        console.log("trying to exit fullscreen");
        //let modelViewerScene = document.getElementById("ModelViewerScene");
        // let exitVREvent = new Event('exit-vr');
        // modelViewerScene.dispatchEvent(exitVREvent);
        //let vrModeUIComp = modelViewerScene.components["vr-mode-ui"];
        //vrModeUIComp.el.dispatchEvent(new KeyboardEvent('keypress',{'key':'Escape'}));

        enterVREventTrigger();
        await delay(500);
        closeFullscreen();

        //vrModeUIComp.onModalClick();
        //vrModeUIComp.el.exitVR();
        // await delay(500);
        // document.exitFullscreen();
    }

    const [isPlayActive, setPlayActive] = useState(true);
    const [isPlayAudioActive, setPlayAudioActive] = useState(false);
    const [animSpeedText, setAnimSpeedText] = useState("X 1");

    const toggleClass = () => {
        console.log("set play active called");
        setPlayActive(!isPlayActive);
    };

    {
        /* HANDLERS */
    }
    const [clickedPlayOnce, setClickedPlayOnce] = useState(false);

    const playPauseAnim = () => {
        if (!clickedPlayOnce) {
            //begin playing lesson
            goToStagePressed(0);
            animManager.resumeAnim();
            setClickedPlayOnce(true);
            handleTextShowPressed();
            return;
        }
        if (isPlayActive) {
            playAudio()
            animManager.resumeAnim();
        } else {
            pauseAudio();
            animManager.pauseAnim();
        }
    };
    
    function toggleAudioActive () {
        setPlayAudioActive(!isPlayAudioActive);
        toggleAudio();
    }
    const handleSetAnimationSpeed = (x) => {
        animManager.setAnimSpeed(x);
        setAnimSpeedText("X " + x);
    };
    
    const handleSeekAnimTime = (e) => {
        e.preventDefault();

        animManager.seekAnimationTime(e.target.value);
        animManager.onSliderTouched();
    };
    /*const handleSliderReleased = (e) => {
        e.preventDefault();
        animManager.onSliderTouched(); // sets seeking to false and updates internal slider value
        // Go to nearest stage, need current frame
        goToNearestStagePressed();
        // when we release the slider hide the play button (show the pause button)
        setPlayActive(false);
    };*/

    const [showText, setShowText] = useState(false);
    const handleTextShowPressed = () => {
        if (showText === false) {
            setShowText(true);
        } else {
            setShowText(false);
        }
        props.showTextCallback();
    };
    const handlePrevStagePressed = () => {
        setPlayActive(false);
        prevStagePressed();
    };
    const handleNextStagePressed = () => {
        setPlayActive(false);
        nextStagePressed();
    };


    return (
        <>
        <div className="full_screen_det"></div>
            <div className="controller_sec">
                <div className="time_slider">
                    <input
                        style={{ width: "100%" }}
                        type="range"
                        defaultValue={0}
                        className="slider"
                        id="animSlider"
                        onChange={(e) => handleSeekAnimTime(e)}
                    />
                </div>
                <div className="left_ctrls">
                    <div className="dropdown">
                        <button
                            className="dropdown-toggle"
                            aria-label="Dropdown"
                            type="button"
                            id="dropdownMenuButton1"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                        >
                            <i className="icon-play_speed"></i>{" "}
                            <span> {animSpeedText} </span>
                        </button>
                        <ul
                            className="dropdown-menu"
                            aria-labelledby="dropdownMenuButton1"
                        >
                            <li>
                                <button
                                    aria-label="Dropdown-Item"
                                    className="dropdown-item"
                                    onClick={() => handleSetAnimationSpeed(0.5)}
                                >
                                    X 0.5
                                </button>
                            </li>
                            <li>
                                <button
                                    aria-label="Dropdown-Item"
                                    className="dropdown-item"
                                    onClick={() =>
                                        handleSetAnimationSpeed(0.75)
                                    }
                                >
                                    X 0.75
                                </button>
                            </li>
                            <li>
                                <button
                                    aria-label="Dropdown-Item"
                                    className="dropdown-item"
                                    onClick={() => handleSetAnimationSpeed(1)}
                                >
                                    X 1
                                </button>
                            </li>
                            <li>
                                <button
                                    aria-label="Dropdown-Item"
                                    className="dropdown-item"
                                    onClick={() => handleSetAnimationSpeed(1.5)}
                                >
                                    X 1.5
                                </button>
                            </li>
                            <li>
                                <button
                                    aria-label="Dropdown-Item"
                                    className="dropdown-item"
                                    onClick={() =>
                                        handleSetAnimationSpeed(1.75)
                                    }
                                >
                                    X 1.75
                                </button>
                            </li>
                        </ul>
                    </div>

                    <div className="volm">
                        {/* Add class 'vol-neg' in the button to get a muted icon */}
                        <button
                            aria-label="Volume"
                            className={
                                isPlayAudioActive
                                    ? "volume_btn vol-neg"
                                    : "volume_btn"
                            }
                            onClick={toggleAudioActive}
                        >
                            <i className="icon-sound"></i>
                        </button>
                    </div>
                    <div className="volm">
                        {/* Add class 'text_shw' in the button to get an activated icon */}
                        <button
                            aria-label="Subtitles"
                            className={showText === true ? "text_shw" : ""}
                            onClick={handleTextShowPressed}
                        >
                            <i className="fa fa-file-text-o"></i>
                        </button>
                    </div>
                </div>

                <div className="mid_ctrls">
                    <button
                        aria-label="Prev"
                        className="prev_play"
                        onClick={handlePrevStagePressed}
                    >
                        <i className="icon-prev"></i>
                    </button>
                    <button
                        aria-label="Play"
                        className="play_pause"
                        onClick={playPauseAnim}
                    >
                        <i
                            className={
                                isPlayActive ? "fa fa-play" : "fa fa-pause"
                            }
                            onClick={toggleClass}
                        ></i>
                    </button>
                    <button
                        aria-label="Next"
                        className="next_play"
                        onClick={handleNextStagePressed}
                    >
                        <i className="icon-prev"></i>
                    </button>
                </div>

                <div className="full_screen">
                    {/* <a id="myEnterVRButton" href="#">
                                            <i className="icon-fullscreen"></i>
                                        </a> */}
                    <button aria-label="Fullscreen" onClick={handleFullscreen}>
                        <i className="icon-fullscreen"></i>
                    </button>
                </div>
            </div>
        </>
    );
};

// PROP TYPES
LessonControls.propTypes = {
    toggleFullScreen: PropTypes.any,
    animManagerProp: PropTypes.any,
    showTextCallback: PropTypes.func,
    //isBeginLesson: PropTypes.any
};