import React from "react";
import { useEffect, useState } from "react";
import { Header } from "../Header/Header";
import { Footer } from "../Footer/Footer";
import '../../SCSS/animate.min.css';
import WOW from 'wowjs';
import aboutus from "../../images/landing/aboutus.png";
import media1 from "../../images/landing/partners/etech_winner.png";
import media2 from "../../images/landing/partners/m2.png";
// import media3 from "../images/landing/partners/m3.png";
import edtech from "../../images/landing/partners/the_edtech.png";
import gobal_finalist from "../../images/landing/partners/gb_winner.png";
import media5 from "../../images/landing/partners/m5.png";
import media6 from "../../images/landing/partners/m6.png";
import chloe from "../../images/landing/chloe.png";
import max from "../../images/landing/max.png";
// import arrow_down from "../../images/landing/arrow_down.svg";
import partner1 from "../../images/landing/partners/p1.png";
import partner2 from "../../images/landing/partners/p2.png";
import partner3 from "../../images/landing/partners/p3.png";
import partner7 from "../../images/landing/partners/densura.png";
import partner4 from "../../images/landing/partners/p4.png";
import partner5 from "../../images/landing/partners/p5.png";
import partner6 from "../../images/landing/partners/p6.png";
import tepe from "../../images/landing/partners/tepe.png";
import { GetInTouch } from '.././GetInTouch/GetInTouch';
import closeButton from "../../images/close_btn.svg";
import { RelatedLinks } from "../RelatedLinks";

export const Aboutus = () => {

    useEffect(() => {
        new WOW.WOW({
          live: false
        }).init();
      }, [])

    function handleTryWebPlatform () {
        window.location.href = process.env.REACT_APP_CLIENT_URL + "/Profile"
    }

    const [isVisible, setIsVisible] = useState(true);
  const [height, setHeight] = useState(0)
  
  useEffect(() => {   
    window.addEventListener("scroll", listenToScroll);
    return () => 
       window.removeEventListener("scroll", listenToScroll); 
  }, [])
  
  const listenToScroll = () => {
    let heightToHideFrom = 1500;
    const winScroll = document.body.scrollTop || 
        document.documentElement.scrollTop;
    setHeight(winScroll);

    if (winScroll > heightToHideFrom) {  
         isVisible && setIsVisible(false);
    } else {
         setIsVisible(true);
    }  
  };

    return (

        <>
         <div
                className="modal fade getintouch"
                id={"getInTouchModal"}
                data-bs-keyboard="true"
                tabIndex={-1}
                aria-labelledby="quiz"
                aria-hidden="true"
            >
                <div className="modal-dialog">
                    <div className="modal-content">
                        <button
                            type="button"
                            className="btn_mod_close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                        >
                            <img alt="placeholder" src={closeButton} />
                        </button>
                        <div className="modal-body">
                            <GetInTouch />
                        </div>
                    </div>
                </div>
            </div>

         <Header link={true} />

            <section className="aboutus">
                <div className="container hero_slider">
                    <div className="row">
                        <div className="col-md-12 col-lg-6 order-2 order-lg-1">
                            <div
                                className="l_main_heading"
                            >
                                <div className="left_txt_container">
                                    <h1>
                                        We&#39;re Immersify<span className="end_not"></span>
                                    </h1>
                                    <h2>
                                        A collective group of experts, developers and 3D modelers, who are passionate about technology and education.
                                    </h2>

                                    {/* <div className="button_grp justify-content-lg-start">
                                        <button
                                            className="btn_common"
                                            onClick={handleTryWebPlatform}
                                        >
                                            Try Immersify Platform
                                        </button>

                                        <a
                                            href="https://immersifydental.page.link/?link=https://immersifyeducation.com/deeplink?dl=%5Bimmersifyeducation%3A%2F%2Fimmersifydental%3Firclickid%3DLANDING%5D%26apn%3Dcom.immersifyeducation.immersifydental%26isi%3D1509735183%26ibi%3Dcom.immersifyeducation.immersifydental"
                                            target="_blank"
                                            rel="noreferrer"
                                            className="btn_outline d-none d-md-flex"
                                        >
                                            Download Immersify app
                                        </a>
                                        <a
                                            href="https://immersifydental.page.link/?link=https://immersifyeducation.com/deeplink?dl=%5Bimmersifyeducation%3A%2F%2Fimmersifydental%3Firclickid%3DLANDING%5D%26apn%3Dcom.immersifyeducation.immersifydental%26isi%3D1509735183%26ibi%3Dcom.immersifyeducation.immersifydental "
                                            className="btn_outline d-flex d-md-none"
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            Download Immersify app
                                        </a>
                                    </div> */}
                                </div>
                            </div>
                        </div>

                        <div className="col-md-12 order-1 col-lg-6 order-lg-2">
                            <div className="video_sec">
                                <div className="about_img"><img src={aboutus} alt="About us" /></div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="media_features_lg">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 col-lg-3">
                            <div className="media_ft_txt">
                                <h2 className="landing_main_head">Media features & Awards<span className="end_not"></span></h2>
                                <p>
                                <b>Immersify Education</b> is an award winning EdTech
                                 company with a solution in the education market, 
                                 which is being used globally by students, professionals and educators.
                                </p>
                            </div>
                        </div>

                        <div className="col-md-12 col-lg-9">
                            <div className="row media_ht_st">
                                <div className="col-md-4 col-6">
                                    <div className="media_images">
                                        <div className="p_img">
                                            <img src={media1} alt="Media image" />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4 col-6">
                                    <div className="media_images">
                                        <div className="p_img">
                                            <img src={media2} alt="Media image" />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4 col-6">
                                    <div className="media_images">
                                        <div className="p_img">
                                            <img src={edtech} alt="Media image" />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4 col-6">
                                    <div className="media_images">
                                        <div className="p_img">
                                            <img src={gobal_finalist} alt="Media image" />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4 col-6">
                                    <div className="media_images">
                                        <div className="p_img">
                                            <img src={media5} alt="Media image" />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="media_images">
                                        <div className="p_img">
                                            <img src={media6} alt="Media image" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="our_story mt_pro_1">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 text-center">
                        <h2 className="landing_main_head">The Immersify Platform’s Story<span className="end_not"></span></h2>
                        </div>
                    </div>

                    <div className="row mt_pro_1">
                        <div className="col-md-6 order-lg-1 order-2">
                            <div className="people_story">
                                <h4 className="first_nm">Chloe Barrett</h4>
                                <p>
                                From competing for Great Britain’s under 21’s dressage team to 
                                becoming a dental professional, to then pursue a career as a 
                                dental education quality assurer to director of a dental education company.
                                </p>
                                <p>
                                It was during this time that Chloe spotted a gap in the education market. 
                                There wasn’t a platform out there that bridged the theoretical side and 
                                practical elements of learning together. So why not create the platform that provides this solution in a fun, engaging and interactive way?

                                </p>
                                <p>
                                Chloe is now the CEO and co-founder of Immersify Education. As she needed someone 
                                who covered the technical elements that come with creating a unique and technological-driven educational platform… 
                                </p>
                            </div>
                        </div>
                        
                        <div className="col-md-6 order-lg-2 order-1">
                            <div className="people_img">
                                <img src={chloe} alt="Chloe Barret" />
                            </div>
                        </div>
                        </div>
                        <div className="row">

                        <div className="ar_dw wow animate__animated animate__fadeIn">
                            <div id="height" className="d-none">
                                <b>height: {height} - {isVisible ? "hideArrow" : "show"}</b>
                            </div>
                            {
                                !isVisible ? 
                                (<div id="hideArrow">
                                     <svg width="497" height="228" viewBox="0 0 497 228" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path className="path" fill="white" d="M1.50573 3.45735C-0.134755 4.16886 2.70433 8.17245 3.12569 8.85157C8.12928 16.916 15.9311 23.2734 24.2374 27.5727C40.8002 36.1455 60.6128 37.02 78.8457 36.6688C91.9045 36.4173 105.489 35.5183 118.038 31.539C120.909 30.6287 126.498 28.7879 127.34 24.9284C128.559 19.3407 121.231 13.3912 117.672 10.6496C108.16 3.32046 94.2287 -0.65951 82.3469 2.2939C67.9214 5.87957 59.3273 22.7533 62.4893 37.039C66.7938 56.4863 91.5424 70.2118 107.9 76.9139C143.995 91.7022 185.677 95.0488 224.224 93.8898C250.397 93.1028 276.109 89.321 301.041 81.1446C317.023 75.9034 335.399 72.0623 350.5 64.8125C366.492 57.1352 376.778 55.067 394.5 53.3125C420.349 50.7535 454 64.8125 465.5 77.8125C496.622 112.993 504.582 179.653 484 221.312" stroke="#414141" strokeWidth="2" strokeLinecap="round" />
                                        <path className="path2" d="M477 215.188L481.901 226.687L492.828 220.648" stroke="#414141" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                                </div>
                                ):''
                                

                            }
                           

                        </div>
                       

                        
                        <div className="col-md-6">
                            <div className="people_img">
                                <img src={max} alt="Maxwell Boardman" />
                            </div>
                        </div>

                        
                        <div className="col-md-6">
                            <div className="people_story">
                                <h4 className="second_nm">Maxwell Boardman</h4>
                                {/* <h5>Welcome Max!</h5> */}
                                <p>
                                As a Learning Technologist for a large University,
                                 Max integrated gamification and personalised learning 
                                 into an undergraduate curriculum, developing over 80
                                  interactive workbooks and several applications, including 
                                  an award-winning virtual escape room. Max is now the CTO 
                                  and co-founder of Immersify Education. From their joined
                                   experience, they were able to bring Immersify Education 
                                   to life. Since then, the team has expanded into the lovely 
                                   bunch that it is today, made up of exceptional developers, 
                                   gifted content creators and graphic designers, an amazing
                                    sales team and even better marketers.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <div className="growing">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="grw_head">
                                <h2>WE ARE GROWING<span className="end_not"></span></h2>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="grw_head">
                               <p>
                               Given Chloe’s background in Dentistry, it made sense to start with this academic area. 
                               Soon, the platform will include content on Engineering; 
                               this is just the beginning of the Immersify Platform.
                               </p>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-12 p_grw">
                            <p>You can find out more about the features on our platform<button onClick={handleTryWebPlatform}>here.</button></p>
                        </div>
                    </div>
                </div>
            </div>

            <section className="container  wow animate__animated animate__fadeInUp">
                <div className="row">
                    <div className="col-md-12">
                        <div className="partners">
                            <h2 className="p_head">Our Partners</h2>
                            <div className="row">
                                <div className="col-md-3 col-6">
                                    <div className="p_img">
                                        <a
                                            href="https://adee.org"
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            <img
                                                src={partner1}
                                                alt="Partner image"
                                            />
                                        </a>
                                    </div>
                                </div>
                                <div className="col-md-3 col-6">
                                    <div className="p_img">
                                        <a
                                            href="https://www.cityandguilds.com"
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            <img
                                                src={partner2}
                                                alt="Partner image"
                                            />
                                        </a>
                                    </div>
                                </div>
                                <div className="col-md-3 col-6">
                                    <div className="p_img">
                                        <a
                                            href="https://www.tepe.com"
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            <img
                                                src={tepe}
                                                alt="Partner image"
                                            />
                                        </a>
                                    </div>
                                </div>
                                <div className="col-md-3 col-6">
                                <div className="p_img">
                                            <a
                                                href="https://www.septodont.co.uk"
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                <img
                                                    src={partner3}
                                                    alt="Media image"
                                                />
                                            </a>
                                        </div>
                                </div>
                                <div className="col-md-3 col-6">
                                    <div className="p_img">
                                        <a
                                            href="https://global.oup.com/?cc=gb"
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            <img
                                                src={partner4}
                                                alt="Partner image"
                                            />
                                        </a>
                                    </div>
                                </div>
                                <div className="col-md-3 col-6">
                                    <div className="p_img">
                                        <a
                                            href="https://www.ems-dental.com/en"
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            <img
                                                src={partner5}
                                                alt="Partner image"
                                            />
                                        </a>
                                    </div>
                                </div>
                                <div className="col-md-3 col-6">
                                    <div className="p_img">
                                        <a
                                            href="https://www.mouthcancerfoundation.org"
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            <img
                                                src={partner6}
                                                alt="Partner image"
                                            />
                                        </a>
                                    </div>
                                </div>

                                <div className="col-md-3 col-6">
                                    <div className="p_img">
                                        <a
                                            href="https://www.densura.com"
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            <img
                                                src={partner7}
                                                alt="Partner image"
                                            />
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <RelatedLinks />

            <section className="container-fluid get_in_bg">
                <div className="container">
                    <div className="row">
                <div className="col-md-12 ">
                    <div className="get_in_touch">
                        <div  className="get-50">
                        <h3>Want to know more?</h3>
                        <h5>
                            Got a question, a suggestion or wanna chat with our
                            team?
                        </h5>
                        </div>
                        <button
                            className="btn_whiteoutline"
                            data-bs-toggle="modal"
                            data-bs-target="#getInTouchModal"
                        >
                            Get in touch
                        </button>
                    </div>
                    </div>
                </div>
                </div>
            </section>

            
         <Footer link={true} />
        </>
        );
    };
    