import {downloadS3File} from './S3-file-manager'
import {lessonAudioManager} from './lesson-audio-manager'

function lessonManager(){
    this.initialized = false;
    
    this.lessonJSON;
    this.currentStage;
    this.curStageIndex = 0;

    this.stageText;
    
    this.lessonAudioMan;
    this.audioURLs = [];

    this.animationManager;

    this.camRotOffset;

    this.init = async function()
    {
        this.loader = document.getElementById("loader");
        this.staticAudioSource = document.getElementById("staticAudioSource");      
        
        this.setupLessonAudio();
        
        this.stagePara = document.getElementById("stagePara");

        this.initialized = true;
        this.loader.emit("onLessonDataInit");
    }

    this.getLessonData = async function(lessonFilename)
    {
        this.loader = document.getElementById("loader");
        var s3Result = await downloadS3File(lessonFilename);
        this.lessonJSON = JSON.parse(s3Result);
        //console.log(this.lessonJSON);
        this.camRotOffset = this.lessonJSON.modelData.camRotOffset;

        this.loader.emit("onLessonDataLoaded");   
    }

    // assumes lesson data has been downloaded
    this.setAnimFrameRate = function(){
        this.animationManager.animFrameRate = this.lessonJSON.animatorInfo.frameRate;
    }

    this.setupLessonAudio = function()
    {
        this.audioURLs = [];
        this.lessonAudioMan = new lessonAudioManager();
        // voice should be set by UI
        this.lessonAudioMan.s3AudioFullURL =
            this.lessonAudioMan.s3RootURL +
            "TestFiles/" +
            this.lessonJSON.animatorInfo.audioBaseDir +
            "/" +
            this.lessonJSON.animatorInfo.supportedVoices[0] + "/";

        //console.log(this.lessonAudioMan.s3AudioFullURL);
        // add each audio file name to the list
        this.lessonJSON.stages.forEach((stage) => {
            this.audioURLs.push(
                this.lessonAudioMan.s3AudioFullURL + stage.audioFile
            );
        });
    }

    this.goToStage = function(stageIndex, goToStartFrame)
    {
        // set index
        this.curStageIndex = stageIndex;
        this.currentStage = this.lessonJSON.stages[this.curStageIndex]; 

        // set paragraph text
        this.stagePara.innerHTML = this.currentStage.paragraph;                
        
        // Go to specific animation frame for this stage
        let startFrame = this.currentStage.startFrame;
        //let endFrame = this.currentStage.endFrame;

        // Set animation & slider to stage start time
        let startTime = startFrame/this.animationManager.animFrameRate;
        //console.log("start frame for stage: " + startFrame + "\nStart time (seconds) for stage: " + startTime);

        let timeToGoTo = startTime;
        if(goToStartFrame){
            timeToGoTo = startTime;
        }else{
            timeToGoTo = this.animationManager.slider.value;
        }

        this.animationManager.seekAnimationTime(timeToGoTo); // seek to time
        this.animationManager.isSeeking = false; // stop seeking
        this.animationManager.setSliderValue(timeToGoTo); // set slider to time
        //console.log("paused? " + this.animationManager.paused);
        if(this.animationManager.paused){
            //console.log("pausing")
            this.animationManager.pauseAnim(); // resume the animation    
        }else{
            //console.log("resuming")
            this.animationManager.resumeAnim(); // resume the animation    
        }
        //console.log("paused 2? " + this.animationManager.paused);

        // play stage audio
        this.setUpStageAudio(stageIndex);

        // handle fading (if any)
        let alphaData = this.currentStage.alphaData;
        this.fadeMaterial(alphaData);
    }

    this.getStageProgress = function()
    {
        let startFrame = this.currentStage.startFrame;
        let endFrame = this.currentStage.endFrame;
        let totalFramesInStage = endFrame-startFrame;
        let currentFrame = this.animationManager.currentFrame;
        //console.log("SF: " + startFrame + " EF: " + endFrame + " CF: " + currentFrame + " TFIS: " + totalFramesInStage);
        let stageProg = (currentFrame-startFrame) / totalFramesInStage;
        //console.log("SP: " + stageProg);
        return stageProg
    }

    // AUDIO
    this.setUpStageAudio = async function(stageIndex){
        if(this.audioURLs[stageIndex].includes("/null")){ return; }
        
        await this.lessonAudioMan.playAudioURL(this.audioURLs[stageIndex]);
        // calculate audio time to play
        this.calculateAudioTimeForFrame();
        //audioDuration is set in audio manager        
        this.animationManager.setAnimSpeedAudio(this.currentStage, this.lessonAudioMan.audioDuration);
        //console.log("audio duration (main): " + this.lessonAudioMan.audioDuration);
    }
    this.calculateAudioTimeForFrame = function()
    {
        let stageProgress = this.getStageProgress();
        let audioDuration = this.lessonAudioMan.audioDuration;
        let audioStartTime = Math.min(audioDuration * stageProgress, audioDuration);
        this.lessonAudioMan.staticAudioSource.currentTime = audioStartTime;
    }

    // MATERIAL FADE
    this.fadeMaterial = function(alphaData){
        // incoming json data
        // "alphaData": {
        //     "target": 0, //target value
        //     "duration":0.3, //duration of fade
        //     "materialNames": [ //unity mat names
        //       "Placeholder_Face",
        //       "Placeholder_MouthInner",
        //       "Placeholder_Eyes_Transparent"
        //     ],
        //     "mayaMaterials":[{"name":"Mat_Face1", "outTo":0.2, "inTo":1},
        //      {"name":"Mat_Mouth","outTo":0.2, "inTo":1},
        //      {"name":"Mat_Eye","outTo":0.2, "inTo":1}]
        //   }

        let aframeSceneEl = this.animationManager.el.sceneEl;
        let fadeMatEntity = aframeSceneEl.querySelector("[fade-material]");
        let fadeMatComp = fadeMatEntity.components["fade-material"]
        
        if(alphaData === undefined){ 
            // if there is no alpha data for this stage,
            // check for any materials that are currently faded
            // and fade them back in
            let fadedMatsLS = localStorage.getItem("FadedMats");
            if((fadedMatsLS !== undefined && fadedMatsLS !== null)){
                let fadedMats = JSON.parse(fadedMatsLS)
                if(fadedMats.length > 0){                    
                    console.log("This point has no alpha data but there are stored materials that have been faded");
                    console.log("fades: " + fadeMatComp.fadeAnimations.length);
                    console.log(fadeMatComp.fadeAnimations);
                    // if there are any fade outs currently in progress, cancel them
                    fadeMatComp.fadeAnimations.forEach(function(element){
                        if(element.fadeAnimOut && element.status === "in-progress"){
                            element.status = "cancelled";
                        }
                    });
                    fadeMatComp.fadeInMaterials(fadedMats, 0.15);                                    
                }                
            }
            // for safety check to see if there are any faded mats in the mesh data
            // and fade them back in
            let matsToFadeIn = [];
            fadeMatComp.fadeAnimations.forEach(function(fadeAnim){
                matsToFadeIn.push(fadeAnim.material);             
            });
            fadeMatComp.fadeInMaterials(matsToFadeIn, 0.15);
            return;
        }

        if(this.animationManager.isSeeking){ return; }
         
        console.log("stage has alpha data");
        console.log("fades: " + fadeMatComp.fadeAnimations.length);
        // if there are any fades in progress, cancel them
        fadeMatComp.cancelAllFades(fadeMatComp);
        fadeMatComp.fadeOutMaterials(alphaData.mayaMaterials, alphaData.duration/this.animationManager.animAudioScaledSpeed);
    };

    // this will reset any maya mats passed in
    this.resetMaterialOpacity = function(materials){
        let aframeSceneEl = this.animationManager.el.sceneEl;
        let fadeMatEntity = aframeSceneEl.querySelector("[fade-material]");
        let fadeMatComp = fadeMatEntity.components["fade-material"];
        materials.forEach(function(mayaMat){
            console.log("setting mat " + mayaMat.name + " to " + mayaMat.default);
            if(mayaMat.default !== undefined){
                fadeMatComp.setMaterialOpacity(mayaMat.default, mayaMat);
            }else{
                fadeMatComp.setMaterialOpacity(1, mayaMat);
            }            
        })        
    };
    
    // NEXT / PREV STAGE
    this.nextStage = function()
    {
        this.curStageIndex++;
        let maxStages = this.lessonJSON.stages.length-1;
        if(this.curStageIndex > maxStages){ console.log("Final stage reached!"); this.curStageIndex = maxStages; return; }
        this.goToStage(this.curStageIndex, true);
    }
    this.prevStage = function()
    {
        let nearestStageArr = this.findNearestStage(5);
        let nearestIndex = nearestStageArr[0];

        this.curStageIndex = nearestIndex-1;
        //this.curStageIndex--;
        
        if(this.curStageIndex < 0){ this.curStageIndex = 0; }
        this.goToStage(this.curStageIndex, true);
    }

    this.goToNearestStage = function(){
        // find the stage that has the startFrame nearest to this index
        //console.log("current frame: " + this.animationManager.currentFrame);       

        let nearestStageArr = this.findNearestStage(0);
        let nearestIndex = nearestStageArr[0];
        //console.log("nearest stage index: " + nearestIndex);
        // get index
        this.goToStage(nearestIndex, false);
    }
    this.findNearestStage = function(thresholdValue){
        //console.log("find nearest stage");
        let smallestDifference = parseFloat(999999);
        // we only want to use this threshold if skipping back,
        // everywhere else this should be set to 0
        let threshold = parseFloat(thresholdValue); 
        let outputArr = [];
        this.lessonJSON.stages.forEach((stage, i)=>{
            let frameDifference = Math.abs(parseFloat(this.animationManager.currentFrame)-parseFloat(stage.startFrame));            
            if(frameDifference < smallestDifference && frameDifference > threshold){
                //console.log("frame diff smallest");
                smallestDifference = frameDifference;
                outputArr = [];
                outputArr.push(i);
                outputArr.push(stage);    
            }
        });
        return outputArr;
    }
}

// HTML CALLS
export var lessonMan = new lessonManager();
export function setupLesson(lessonFilename){
    lessonMan.init(lessonFilename);    
}
export function nextStagePressed(){
    lessonMan.nextStage();
}
export function prevStagePressed(){
    lessonMan.prevStage();
}
export function goToStagePressed(index){
    lessonMan.goToStage(index);
}
export function goToNearestStagePressed(){
    lessonMan.goToNearestStage();
}

export function toggleAudio()
{
    lessonMan.lessonAudioMan.toggleAudio();
}
export function playAudio()
{
    lessonMan.lessonAudioMan.playAudio();
}
export function pauseAudio()
{
    lessonMan.lessonAudioMan.pauseAudio();
}

