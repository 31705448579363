import React from 'react'
import PropTypes from "prop-types";

import shape_1 from "../images/shape_1.svg"
import immersify_logo from "../images/immersify_logo.svg"
import verify_email from "../images/verify_em.svg"

import { getMobileOperatingSystem } from '../Utils/getMobileOperatingSystem';

export const VerifyEmail = (props) => {

    function openStorePageBtn() {
        var os = getMobileOperatingSystem();
        if (os == "Android") {
            window.location.replace(
                "https://play.google.com/store/apps/details?id=com.immersifyeducation.immersifydental"
            );
        } else {
            window.location.replace(
                "https://apps.apple.com/gb/app/immersify-dental/id1509735183"
            );
        }
    }

    function openWebApp () {
        window.location.href = process.env.REACT_APP_CLIENT_URL + "/Profile"
    }

    return (
        <>
            <section className="container-fluid">
                <div className="row">
                    <div className="col-xl-7 col-md-6 left_block d-none d-md-block">
                        <div className="signup_image">
                            <div className="shape_block">
                                <div className="shape_1">
                                    <img alt="placeholder" src={shape_1} />
                                </div>
                                <div className="shape_2">
                                    <img alt="placeholder" src={shape_1} />
                                </div>
                            </div>
                            <div className="login_text_block">
                                <h1>LEARN.</h1>
                                <h1>EXPERIENCE.</h1>
                                <h1>CONNECT.</h1>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-5 col-md-6 verify_m ">
                        <div className="logo_wrap">
                            <img alt="placeholder" src={immersify_logo} />
                        </div>
                        <div className="signup_form_area verify_sec">
                            <div className="em_img_block">
                                <img alt="placeholder" src={verify_email} />
                            </div>

                            {props.success === true ? (
                                <>
                                    <h2>Verification complete</h2>
                                    <h5 className="verify_em_t2">
                                        Your email has been successfully
                                        verified.
                                    </h5>
                                    <div className="col-md-12">
                                        <div className="btn_area">
                                            <button
                                                className="btn_common w-100"
                                                onClick={openWebApp}
                                            >
                                                Continue on web
                                            </button>
                                            <button
                                                className="btn_common w-100"
                                                onClick={openStorePageBtn}
                                                style={{ marginTop: "10px" }}
                                            >
                                                Continue in app
                                            </button>
                                        </div>
                                    </div>
                                </>
                            ) : (
                                <>
                                    <h2>Verification failed</h2>
                                    <h5 className="verify_em_t2">
                                        There was a problem verifying your
                                        email.
                                    </h5>
                                    <div className="col-md-12">
                                        <div className="btn_area">
                                            <button
                                                className="btn_common w-100"
                                                onClick={openWebApp}
                                            >
                                                Continue on web
                                            </button>
                                            <button
                                                className="btn_common w-100"
                                                onClick={openStorePageBtn}
                                                style={{ marginTop: "10px" }}
                                            >
                                                Continue in app
                                            </button>
                                        </div>
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

VerifyEmail.propTypes = {
    success: PropTypes.bool,
}
