import React from "react";
import PropTypes from "prop-types";
import { GetInfoFromID } from "../../../../../../../Utils/getInfoFromID";
import { firebaseCall } from "../../../../../../../Utils/firebaseAnalytics";

export const StandardLessonHeader = (props) => {
    
    const id = props.id;
    const isQuizDataLoaded = props.isQuizDataLoaded;

    const info = GetInfoFromID(id).info;
    const title = info.title;
    const iconLocation = info.iconLocation;

    function handleQuizClick() {
        firebaseCall("lesson_quiz_start", {activity_id: id});
    }

    return (
        <>
            <div className="activity_header">
                <div className="left-head">
                    <img
                        src={
                            "https://d1476tmm9qfop0.cloudfront.net/TestFiles/Icons/webp/px-" +
                            iconLocation.slice(6).slice(0, -4) +
                            ".webp"
                        }
                        className="img-fluid"
                        alt="Activity - Icon"
                    />
                    <h2>{title}</h2>
                </div>

                <div className="right-head">
                    {isQuizDataLoaded ? (
                        <button
                            className="btn_common pulse"
                            data-bs-toggle="modal"
                            data-bs-target="#quiz"
                            onClick={handleQuizClick}
                        >
                            {/*<span className="progz">
                            <div className="progz_slide" style={{ width: "0%" }}></div>
                        </span>*/}
                            <span className="quiz_label">Start Quiz</span>
                        </button>
                    ) : (
                        <button
                            className="btn_grey skeleton-box"
                            data-bs-toggle="modal"
                            data-bs-target="#quiz"
                        >
                            Start Quiz
                        </button>
                    )}
                </div>
            </div>
        </>
    );
}

StandardLessonHeader.propTypes = {
    isQuizDataLoaded: PropTypes.bool,
    id: PropTypes.string,
}