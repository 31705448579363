import { getPlayerProfile } from "./getPlayerProfile";

export function getPlayerCountry (getPlayerCountryCallback) {

    getPlayerProfile(getPlayerProfileCallback)

    function getPlayerProfileCallback (playerProfile) {
        if (playerProfile !== false) {
            if (playerProfile !== undefined || !playerProfile.includes("error")) {
                getPlayerCountryCallback(playerProfile.Locations[0].CountryCode)
            } else {
                noLoginFound()
            }
        }
        else noLoginFound()
    }

    function noLoginFound () {
        fetch("https://extreme-ip-lookup.com/json/?key=" + process.env.REACT_APP_IP_LOOKUP_API_KEY)
            .then((res) => res.json())
            .then((response) => {
                getPlayerCountryCallback(response.countryCode);
            })
            .catch((data) => {
                console.log("Request failed:", data);
                getPlayerCountryCallback("US")
            });
    }
}