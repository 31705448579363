import { GetInfoFromID } from "../../../../../Utils/getInfoFromID";

export function GetLessonType (id) {
    const info = GetInfoFromID(id).info
    let animated = false

    if (info !== undefined) {
        info.sceneParams.forEach(param => {
            if (param.includes('ModelDataFile')) {
                animated = true
            }
        });

        if (animated === true) {
            return 'animated'
        }
        else {
            return 'standard'
        }
    }
    else {
        return undefined
    }
}