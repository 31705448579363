import React, { useState } from "react";
import PropTypes from "prop-types";

import closeButton from "../../../Images/close_btn.svg";

import { shuffle } from "../../../../../../../../Utils/arrayTools";
import { GetInfoFromID } from "../../../../../../../../Utils/getInfoFromID";
import { QuizComplete } from "./QuizComplete/QuizComplete";
import { firebaseCall } from "../../../../../../../../Utils/firebaseAnalytics";

export const Quiz = (props) => {
    const id = props.id;
    const questions = props.questions
    const noQuestions = props.noQuestions

    const [quizComplete, setQuizComplete] = useState(false);
    const [currentQuestion, setCurrentQuestion] = useState(0);
    const [score, setScore] = useState(0)
    const [questionAnswered, setQuestionsAnswered] = useState(false)

    const title = GetInfoFromID(id).info.title;

    function handleAnswerClicked(isCorrect, index) {
        
        setQuestionsAnswered(index)

        if (isCorrect) {
            setScore(score + 1)
        }
    }

    function handleNextButtonClicked() {

        setQuestionsAnswered(false)
        setCurrentQuestion(currentQuestion + 1);

        if (currentQuestion !== (noQuestions - 1)) {
            setCurrentAnswers(getAnswers(currentQuestion + 1))
        }
        else {
            setQuizComplete(true)
        }
    }

    const getAnswers = (questionNumber) => {
        var allAnswers = questions[questionNumber].incorrectAnswers;
        var returnAnswers = []
        var duplicate = false;
        allAnswers.forEach(function(answer, index) {
            if (questions[questionNumber].correctAnswer === answer) {
                duplicate = true;
            }
            returnAnswers.push({ answerText: allAnswers[index], isCorrect: false });
        });
        if (duplicate === false) {
            returnAnswers.push({"answerText":questions[questionNumber].correctAnswer, "isCorrect":true});
        }
        // firebaseCall('lesson_quiz_start', {activity_id: id})
        return shuffle(returnAnswers);
    };

    const [currentAnswers, setCurrentAnswers] = useState(getAnswers(0));

    function handleRestartQuiz () {
        firebaseCall('restart_lesson_quiz', {activity_id: id})
        setQuizComplete(false)
        setCurrentQuestion(0)
        setScore(0)
        setCurrentAnswers(getAnswers(0))
    }

    return (
        <>
            <div
                className="modal fade quiz_modal"
                id="quiz"
                data-bs-keyboard="false"
                tabIndex={-1}
                aria-labelledby="quiz"
                aria-hidden="true"
            >
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <div className="fgt_header">
                                <h5>Multiple Choice Questions</h5>
                                <h6>{title}</h6>
                                <div className="mcq_progress">
                                    <div className="progress">
                                        <div
                                            className="bar"
                                            style={{
                                                width:
                                                    (currentQuestion /
                                                        noQuestions) *
                                                        100 +
                                                    "%",
                                            }}
                                        >
                                            <p className="percent"></p>
                                        </div>
                                    </div>
                                </div>
                                <div className="quiz_num">
                                    <span>{currentQuestion}</span>/
                                    <span>{noQuestions}</span>
                                </div>
                            </div>

                            <button
                                type="button"
                                className="btn_mod_close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            >
                                <img alt="placeholder" src={closeButton} />
                            </button>
                        </div>
                        <div className="modal-body">
                            {quizComplete === true ? (
                                <QuizComplete
                                    id={id}
                                    handleRestartQuiz={handleRestartQuiz}
                                    score={score}
                                    noQuestions={noQuestions}
                                />
                            ) : (
                                <>
                                    <div className="mcq_data">
                                        <h4>
                                            {
                                                questions[currentQuestion]
                                                    .questionText
                                            }
                                        </h4>
                                        <ul>
                                            {currentAnswers.map(
                                                (answerOption, index) => (
                                                    <li key={"answer" + index} 
                                                    className={
                                                        questionAnswered === index || (questionAnswered !== false && answerOption.isCorrect === true)
                                                            ? answerOption.isCorrect === true
                                                                ? "right_ans"
                                                                : "wrong_ans"
                                                            : ""
                                                    }>
                                                        <button
                                                            key={
                                                                "answer" + index
                                                            }
                                                            onClick={questionAnswered === false ? () =>
                                                                handleAnswerClicked(
                                                                    answerOption.isCorrect, index
                                                                ) : () => {}
                                                            }
                                                        >
                                                            <p>{answerOption.answerText}</p>
                                                        </button>
                                                    </li>
                                                )
                                            )}
                                        </ul>
                                    </div>
                                    <div className="question-text"></div>
                                    <div className="answer-section"></div>
                                    <div className="mcq_submit">
                                        <button className="btn_common" onClick={handleNextButtonClicked}>
                                            Next
                                        </button>
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

Quiz.propTypes = {
    standardLessonMan: PropTypes.any,
    id: PropTypes.string,
    questions: PropTypes.array,
    noQuestions: PropTypes.number,
};
