import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import "../../../../SCSS/style.css";

import { PremiumAdvert } from "./PremiumAdvert/PremiumAdvert";
import { CheckForPremium } from "../../../../Utils/checkForPremium"
import { changeSubscription } from "./StripeCheckout/CreateStripeCheckout/ChangeSubscription";
import { CheckIfLoggedIn } from "../../../../Utils/CheckIfLoggedIn";
import { SetPrice } from "../../../../Utils/setPrice"

export const DisplayPremium = () => {
    
    const navigate = useNavigate()

    var subscribed = false

    useEffect(() => {
        CheckForPremium(isSubscriberCallback, "displayPremium");
        if (CheckIfLoggedIn() === false) {
            SetPrice()
        }
    }, []);

    function isSubscriberCallback(premium) {
        subscribed = premium;
    }

    const handleFormSubmit = (product) => {
        const loggedIn = CheckIfLoggedIn()
        if (loggedIn === false || loggedIn === undefined) {
            navigate('/Login')
        }
        else {
            console.log({product})
            changeSubscription(product)
        }
    };
    
    const loginCheck = CheckIfLoggedIn()
    if (loginCheck === false || loginCheck === undefined) {
        SetPrice()
        return (
            <PremiumAdvert
                handleFormSubmit={handleFormSubmit}
                subscribed={false}
            />
        );
    } else if (subscribed === true) {
        return (
            <>
                <PremiumAdvert
                    handleFormSubmit={handleFormSubmit}
                    subscribed={true}
                />
            </>
        );
    } else {
        return (
            <>
                <PremiumAdvert
                    handleFormSubmit={handleFormSubmit}
                    subscribed={false}
                />
            </>
        );
    }
};
