import { UpdateUserData } from "../../../../../../Utils/updateUserData"
import { UpdateContactEmail } from "./UpdateContactEmail/UpdateContactEmail";
import { UpdateDisplayName } from "./UpdateDisplayName/UpdateDisplayName";

//import Universities from '../../../../../InfoJSONs/Universities.json'

export function UpdateProfile (username, email, country, university, updates, handleSuccess) {
    console.log({ username }, { email }, { country }, { university }, { updates });

    if (username !== undefined) {
        UpdateDisplayName(username, handleSuccess)
    }

    if (email !== undefined) {
        UpdateContactEmail(email, handleSuccess)
    }

    if (country !== undefined) {
        UpdateUserData("Country", country.toLowerCase(), handleSuccess)
    }

    if (university !== undefined) {
        UpdateUserData("university", university, handleSuccess)
    }

    if (updates !== undefined) {
        UpdateUserData("CanEmail", updates, handleSuccess)
    }
    /*else if (country !== undefined) {
        Universities.map((countryObj) => {
            const countryName = Object.keys(countryObj)[0];
            if (countryName.toLowerCase() === country.toLowerCase()) {
                const currentUni = Object.values(countryObj)[0][0]
                UpdateUserData("university", currentUni, handleSuccess)
            }
        });
    }*/
}
