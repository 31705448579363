import { GetAccountInfo } from "./GetLoginData";

export function GoogleRedirect() {
    // console.log("google sign in", query);

    // Create a URLSearchParams object with the current URL
    const urlParams = new URLSearchParams(window.location.search);
    const data =urlParams.get("data");

    GetAccountInfo(data, "google", handleGotAccountInfo);
    return null;
}

function handleGotAccountInfo() {
    window.location.href = process.env.REACT_APP_API_URL + "/Profile";
    console.log("redirected to experience page");
}
