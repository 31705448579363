import React, { useEffect } from "react";

import immersify_logo from "../../images/immersify_logo.svg";
import shape_1 from "../../images/shape_1.svg";

import { useParams } from "react-router-dom";

var id_token;

export const UniSSOApp = () => {
    id_token = Object.values(useParams())[0];

    useEffect(() => {
        
    }, []);

    function handleManualRedirect() {
        window.location.href =
            "immersifyeducation://immersifydental?idToken=" + id_token;
    }

    return (
        <>
            <section className="container-fluid">
                <div className="row">
                    <div className="col-xl-7 col-md-6 left_block d-none d-md-block">
                        <div className="signup_image">
                            <div className="logo_wrap">
                                <img alt="placeholder" src={immersify_logo} />
                            </div>
                            <div className="shape_block">
                                <div className="shape_1">
                                    <img alt="placeholder" src={shape_1} />
                                </div>
                                <div className="shape_2">
                                    <img alt="placeholder" src={shape_1} />
                                </div>
                            </div>
                            <div className="login_text_block">
                                <h1>LEARN.</h1>
                                <h1>EXPERIENCE.</h1>
                                <h1>CONNECT.</h1>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-5 col-md-6">
                        <div className="signup_form_area">
                            <h3>Please do not refresh </h3>
                            <h2>Redirecting...</h2>
                        </div>
                        <button onClick={handleManualRedirect}>
                            If you have been waiting longer than a few seconds
                            please click here
                        </button>
                    </div>
                </div>
            </section>
        </>
    );
};
