import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
//import { useNavigate } from "react-router-dom";

import { GetInfoFromID } from "../../../../../Utils/getInfoFromID";
import { GetTopicStructure } from "./GetTopicInfo/GetTopicStructure";
import { CheckForPremium } from "../../../../../Utils/checkForPremium";
import { CheckIfLocked } from "../../../../../Utils/checkIfLocked";
import { PracticalPopup } from "../../DisplayExperiences/ExperienceCard/PracticalPopup/PracticalPopup";

export const TopicCard = (props) => {
    const id = props.id;

    const [premium, setPremium] = useState(false);
    const [locked, setLocked] = useState(true);
    const [comingSoonText, setComingSoonText] = useState("Coming Soon");
    const [bsTarget, setBsTarget] = useState("");
    const [availableInApp, setAvailableInApp] = useState(false);

    useEffect(() => {
        CheckForPremium(checkForPremiumCallback);
        setLocked(CheckIfLocked(id, true));
        if (
            id === "oralSurgeryExtractions_topic" ||
            id === "implantology_topic" ||
            id === "veneersCrownsBridges_topic" ||
            id === "dentalMaterials2_topic" ||
            id === "bioactiveMaterials_topic" ||
            id === "uclaCariesRisk_topic" ||
            id === "uclaOralSurgery_topic" ||
            id === "uclaLA_topic" ||
            id === "uclaMedicinerelatedToDentistry_topic" ||
            id === "uclaPeriodontitis4_topic"
        ) {
            setComingSoonText("Available in app");
            setBsTarget("#premium_content" + id);
            setAvailableInApp(true);
        }
    }, []);

    function checkForPremiumCallback(data) {
        if (data === "false" || data === false) {
            setPremium(false);
        } else setPremium(true);
    }

    var info = GetInfoFromID(id);
    var description;
    if (info !== undefined) {
        info = info.info;
        description = info.description;
    }

    if (
        info !== undefined &&
        description !== undefined &&
        description.length !== 0
    ) {
        description = description.substring(0, 100) + "...";
    }

    const structure = GetTopicStructure(id);
    var noLessons = 0;
    var noPracticals = 1;

    structure.Lessons.forEach((experience) => {
        const experienceInfo = GetInfoFromID(experience);
        var type;
        if (experienceInfo !== undefined) {
            type = experienceInfo.type;
        }

        if (type === "lesson") {
            noLessons++;
        } else {
            noPracticals++;
        }
    });

    var comingSoon = false;

    if (noLessons === 0 && (noPracticals === 1 || noPracticals === 0)) {
        comingSoon = true;
    }

    //hack for local analgesia being incorrect
    if (info.id == "localAnalgesia_topic") {
        noLessons = 4;
        noPracticals = 3;
    }
    function handleClick() {
        if (comingSoon === false && availableInApp === false) {
            props.displayContentCallback(id);
            if (props.outsidePlatform === true) {
                window.location.href =
                    process.env.REACT_APP_CLIENT_URL + "/Experience/" + id;
            } else {
                if (window.location.href.includes("Experience/clean") === true) {
                    window.open(
                        process.env.REACT_APP_CLIENT_URL + "/Experience/" + id, '_blank'
                    );
                      
                } else {
                    window.history.replaceState(
                        null,
                        "",
                        process.env.REACT_APP_CLIENT_URL + "/Experience/" + id
                    );
                }
            }
        }
    }

    return (
        <>
            {availableInApp ? <PracticalPopup id={id} /> : <></>}
            {info !== undefined && id !== "test_topic" ? (
                <div
                    className="mb-4 col-md-6 col-lg-4 col-xxl-3"
                    onClick={handleClick}
                    data-bs-toggle="modal"
                    data-bs-target={bsTarget}
                >
                    <div
                        className={
                            premium === false &&
                            comingSoon === false &&
                            locked === true
                                ? "content_links premium_item"
                                : "content_links"
                        }
                    >
                        <div
                            tabIndex="0"
                            className={
                                "video_item " +
                                (comingSoon === false
                                    ? "av_in_app"
                                    : availableInApp === false
                                    ? "cmng_sn"
                                    : "")
                            }
                        >
                            {comingSoon === false ? (
                                <>
                                    <span className="link_type lesson">
                                        <h6>{noLessons}</h6>Lessons
                                    </span>
                                    <span className="link_type exp">
                                        <h6>{noPracticals}</h6>Experience
                                    </span>
                                </>
                            ) : (
                                <>
                                    <div className="coming_soon">
                                        <span> {comingSoonText}</span>
                                    </div>

                                    <div className="launch_soon">
                                        <h5>
                                            This topic will be launching soon
                                        </h5>
                                    </div>
                                </>
                            )}

                            {availableInApp === false ? (
                                <></>
                            ) : (
                                <>
                                    <div className="coming_soon">
                                        <span> {comingSoonText}</span>
                                    </div>

                                    <div className="launch_soon">
                                        <h5>
                                            This topic will be launching soon
                                        </h5>
                                    </div>
                                </>
                            )}

                            <div className="act_icon">
                                <img
                                    src={
                                        "https://d1476tmm9qfop0.cloudfront.net/TestFiles/Icons/webp/px-" +
                                        info.roundedIconLocation
                                            .slice(6)
                                            .slice(0, -4) +
                                        ".webp"
                                    }
                                    onError={({ currentTarget }) => {
                                        currentTarget.onerror = null; // prevents looping
                                        currentTarget.src =
                                            "https://d1476tmm9qfop0.cloudfront.net/TestFiles/Icons/webp/default_topic.webp";
                                    }}
                                    className="img-fluid"
                                    alt="Activity - Icon"
                                />
                            </div>

                            <h5 className="act_title">{info.title}</h5>
                            <p className={description !== "" ? "" : "d-none"}>
                                {description}{" "}
                                <button
                                    className="btn_readmore"
                                    onClick={handleClick}
                                >
                                    {" "}
                                    Read more
                                </button>
                            </p>
                        </div>
                    </div>
                </div>
            ) : (
                <></>
            )}
        </>
    );
};

TopicCard.propTypes = {
    id: PropTypes.string,
    displayContentCallback: PropTypes.func,
    outsidePlatform: PropTypes.bool,
};
