// eslint-disable-next-line
import {downloadS3File} from '../S3-file-manager'
//test
function standardLessonManager(){
    this.gotLessonJSON = false;
    this.initialized = false;
    
    this.lessonJSON;
    //this.currentStage;
    //this.curStageIndex = 0;

    this.init = async function(lessonFilename, quizFilename, loaderID)
    {
        this.loader = document.getElementById(loaderID);

        await this.getLessonData(lessonFilename);        
        await this.getQuizData(quizFilename);        
        
        this.initialized = true;              
    }

    this.getLessonData = async function(lessonFilename)
    {
        console.log("CALLED GET LESSON DATA")
        var s3Result = await downloadS3File(lessonFilename);
        this.lessonJSON = JSON.parse(s3Result);
        //console.log(this.lessonJSON);
        this.gotLessonJSON = true;
        this.loader.emit("onLessonDataLoaded"); 
    }

    this.getQuizData = async function(quizFilename)
    {
        console.log("CALLED GET QUIZ DATA")
        var s3Result = await downloadS3File(quizFilename);
        this.quizJSON = JSON.parse(s3Result);
        //console.log(this.quizJSON);
        this.gotQuizJSON = true;
        this.loader.emit("onQuizDataLoaded"); 
    }

    this.removePoint = function(index){
        let allAframeScenes = document.querySelectorAll('.standard_player');
        //let allImageScenes = document.querySelectorAll('.standard_image');
        allAframeScenes[index].parentNode.removeChild(allAframeScenes[index]);
    }
}

export var standardLessonMan = new standardLessonManager();
export async function setupLesson(lessonFilename, quizFilename, loaderID){
    standardLessonMan.init(lessonFilename, quizFilename, loaderID);
}