import React, { useState, useEffect } from 'react'
import PropTypes from "prop-types";
import { SetPrice } from '../../../../../../Utils/setPrice';

export const PremiumBlock = (props) => {

    const handleFormSubmit = props.handleFormSubmit

    const monthly = props.monthly
    const [monthlyPrice, setMonthlyPrice] = useState("$7.49")
    const [yearlyPrice, setYearlyPrice] = useState("$59.99");
    const [yearlyPerMonth, setYearlyPerMonth] = useState("$4.99");

    useEffect(() => {
        if (localStorage.getItem("monthlyPrice") === null) {
            SetPrice(setPriceCallback)
        }
        else {
            setMonthlyPrice(localStorage.getItem("monthlyPrice"))
            setYearlyPrice(localStorage.getItem("yearlyPrice"));
            setYearlyPerMonth(localStorage.getItem("yearlyPricePerMonth"))
        }
    }, []);


    function setPriceCallback () {
        setMonthlyPrice(localStorage.getItem("monthlyPrice"))
        setYearlyPrice(localStorage.getItem("yearlyPrice"))
            setYearlyPerMonth(localStorage.getItem("yearlyPricePerMonth"));
    }

    return (
        <div className="col-md-6 order-sm-1">
            <div className="p_block premium_set">
                <div className="p_block_top">
                    <span className="p_mode">Premium</span>
                    {process.env.REACT_APP_OFFER === true ? <span className="offer_label">50% OFF</span> : <></>}
                    {monthly ? (
                        <h4>
                            <span>{monthlyPrice.charAt(0)}</span>
                            {monthlyPrice.substring(1)} <span>/Month</span>
                        </h4>
                    ) : (
                        <h4>
                            <span>{monthlyPrice.charAt(0)}</span>
                            {yearlyPerMonth.substring(1)} <span>/Month</span>
                        </h4>
                    )}
                </div>
                <div className="p_block_contents">
                    <ul>
                        
                        <li className="web_mob">
                            <p>Unlimited access to topics</p>
                        </li>
                        <li className="mob">
                            <p>100+ activities</p>
                        </li>
                        <li className="mob">
                            <p>
                                3 tokens per day to play the
                                challenges
                            </p>
                        </li>
                    </ul>
                </div>
                <div className="btm_button">
                    <h6>
                        Billed {monthly ? "monthly" : "annually"} for{" "}
                        {monthly ? monthlyPrice : yearlyPrice}
                    </h6>
                    <button
                        className="btn_common_white"
                        onClick={handleFormSubmit}
                    >
                        Try 14 days free
                    </button>
                </div>
            </div>
        </div>
    );
}

PremiumBlock.propTypes = {
    handleFormSubmit: PropTypes.func,
    monthly: PropTypes.bool,
}
