import { GetAccountInfo } from "./GetLoginData";

export function PlayfabSignUp(form, callback = () => {}, signUpErrorCallback = () => {}) {

    console.log("sign up")
    SignUp(form, callback, signUpErrorCallback)
}

function SignUp(form, callback = () => {}, signUpErrorCallback = () => {}) {

    var myHeaders = new Headers();
    var raw;
    myHeaders.append("Content-Type", "application/json");

    console.log("form data sign up");
    raw = JSON.stringify({
        TitleId: process.env.REACT_APP_TITLE_ID,
        DisplayName: form.displayName,
        Email: form.email.toLowerCase(),
        Password: form.password,
        RequireBothUsernameAndEmail: false,
        Country: form.country,
    });
    
    var requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: raw,
    redirect: 'follow'
    };
    
    fetch("https://29001.playfabapi.com/Client/RegisterPlayFabUser", requestOptions)
    .then(response => response.text())
    .then(result => {
        if (JSON.parse(result).code == 200) {
            console.log("account created");

            const playfabID = JSON.parse(result).data.PlayFabId;
            localStorage.setItem("playfabID", playfabID);

            

            GetAccountInfo(result, 'playfab', callback)
        }
        else {
            console.log(JSON.parse(result))
            signUpErrorCallback(JSON.parse(result))
        }
    })
    .catch(error => console.log('error', error));
}

export function PopulateData (sessionTicket, raw, finalRequest) {
    var myHeaders = new Headers();
    myHeaders.append(
        "X-Authorization",
        sessionTicket
    );
    myHeaders.append("Content-Type", "application/json");

    var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
    };

    fetch("https://29001.playfabapi.com/Client/UpdateUserData", requestOptions)
        .then((response) => response.text())
        .then((result) => {
            console.log(result)
            if (!finalRequest) {
                var nextRaw = JSON.stringify({
                    Data: {
                        UserType: "",
                        AcademicYear: "",
                        ExperienceLevel: "",
                        Interests: "",
                    },
                    Permission: "Public",
                });
                PopulateData(sessionTicket, nextRaw, true)
            }
            if (JSON.parse(result).code == 200) {
                window.location.href=process.env.REACT_APP_CLIENT_URL + "/Profile"
            }
        })
        .catch((error) => console.log("error", error));
}
