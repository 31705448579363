import React from "react";
import { useParams } from "react-router-dom";

import immersify_logo from "../../images/immersify_logo.svg";
import shape_1 from "../../images/shape_1.svg";
import { Decrypt } from "../../Utils/Decrypt";
import verify_email from "../../images/verify_em.svg";

export const Verify = () => {

    const verify = Decrypt(Object.values(useParams())[0])
    console.log(verify)

    if (verify !== "verifyOptional") {
        localStorage.clear()
    }

    function handleContinue () {
        window.location.href = process.env.REACT_APP_CLIENT_URL + "/Profile"
    }

    return (
        <>
            <section className="container-fluid">
                <div className="row">
                    <div className="col-xl-7 col-md-6 left_block d-none d-md-block">
                        <div className="signup_image">
                           
                            <div className="shape_block">
                                <div className="shape_1">
                                    <img alt="placeholder" src={shape_1} />
                                </div>
                                <div className="shape_2">
                                    <img alt="placeholder" src={shape_1} />
                                </div>
                            </div>
                            <div className="login_text_block">
                                <h1>LEARN.</h1>
                                <h1>EXPERIENCE.</h1>
                                <h1>CONNECT.</h1>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-5 col-md-6 verify_m ">
                    <div className="logo_wrap">
                                <img alt="placeholder" src={immersify_logo} />
                            </div>
                        <div className="signup_form_area verify_sec">
                        <div className="em_img_block">
                                        <img alt="placeholder" src={verify_email} />
                                    </div>
                            
                            {verify === "verifyOptional" ? (
                                <>

                                    
                                    <h2>
                                       Verify your email address
                                    </h2>
                                    <h5 className="verify_em_t2">To confirm your email address, please click on the link in the email we sent you.</h5>
                                    <h4 className="verify_txt_m">Remember to check your junk and spam folders </h4>
                                    <div className="col-md-12">
                                        <div className="btn_area">
                                            <button
                                                className="btn_common w-100"
                                                onClick={handleContinue}
                                            >
                                                Continue
                                            </button>
                                        </div>
                                    </div>
                                </>
                            ) : (
                                <>
                                    <h2>
                                        You must verify your email before
                                        accessing your account
                                    </h2>
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};
