import {lessonMan} from './lesson-data-manager'

/* global AFRAME */
AFRAME.registerComponent("animation-manager", {
    schema:{
        // in seconds
        startTime: { default: 1 },
    },
    init:function(){
        // bind the methods
        this.modelLoaded = this.modelLoaded.bind(this);
        this.setStartTime = this.setStartTime.bind(this);
        // wait until the model is ready
        this.el.addEventListener("model-loaded", this.modelLoaded);

        this.canTick = false;
        this.paused = true; // start paused
        this.isSeeking = false;

        this.longestTrack;
        this.totalFrames = 0;
        this.animBaseSpeed = 1;
        this.animSpeedMod = 1;
        this.animAudioScaledSpeed = 1;
        this.animFrameRate;// = 30;// need JSON to control this, see lesson-data-manager
        this.animFrameTime;// = 1/this.animFrameRate;
        this.currentFrame = 0;

        //this.sceneFrameRate = 60;

        this.animationMixer = this.el.components["animation-mixer"];
        this.modelManager = this.el.components["im-model-manager"];
        this.modelViewer = document.querySelector("[model-viewer]").components["model-viewer"];

        this.doOnce = false;        
    },
    modelLoaded:function(){
        // SET ANIM VARS
        //console.log(Object.getOwnPropertyNames(this.animationMixer.el));
        // Frames?  Object.getOwnPropertyNames(this.animationMixer.mixer._root.animations[0].tracks
        //console.log("TRACKS2: " + Object.getOwnPropertyNames(this.animationMixer.mixer._root.animations[0].tracks[0].times));
        // /this.animationMixer = this.el.components["animation-mixer"];
        //console.log("COMPS: " + Object.getOwnPropertyNames(this.el.components));
        //console.log(Object.getOwnPropertyNames(this.animationMixer.mixer._root.animations[0].tracks));
        this.animDuration = this.animationMixer.mixer._root.animations[0].duration;
        this.tracks = this.animationMixer.mixer._root.animations[0].tracks;
        
        // Get Longest track & frame count
        let tracks = this.animationMixer.mixer._root.animations[0].tracks;
        this.longestTrack = this.findLongestTrack(tracks);
        this.totalFrames = this.longestTrack.times.length;

        this.animationMixer.mixer.timeScale = this.animBaseSpeed;        
        
        // set the start time of the first play
        //this.setStartTime();
        // set the start time on each loop
        this.el.addEventListener("animation-loop", this.setStartTime);

        // beign paused
        this.pauseAnim();

        // allow tick
        this.canTick = true;
        //console.log("ANIM DUR: " + this.animDuration + "\nTotal number of frames: " + this.totalFrames + "\nmixer time: " + this.animationMixer.mixer.time);
    },
    // THIS NEEDS TO HAPPEN AFTER BOTH MODEL & LESS DATA ARE LOADED (see LessonTemplate)
    setupAnimation:function(){
        lessonMan.setAnimFrameRate();
        this.animFrameTime = 1/this.animFrameRate;
        
        // SET SLIDER VARS
        this.slider = document.getElementById("animSlider");
        //this.slider = document.getElementById("anim_slider").children[2].children[0]
        //console.log(this.slider.value)
        this.slider.step = 1/this.animFrameRate; // step rate is 1/frameRate, this allows slider to increment per frame
        this.slider.min = 1/this.animFrameRate;
        this.slider.max = (this.animDuration+1);
        this.slider.value = 0
        this.sliderCounter = 0;
        this.setAnimFrame();
        this.setSliderValue(0);
    },
    findLongestTrack:function(trackList){
        let highestT = 0;
        let longestTrack = null;
        trackList.forEach(track => {
                if (track.times.length > highestT) {
                    highestT = track.times.length;
                    longestTrack = track;
                }
            }
        )
        return longestTrack;
    },
    setStartTime:function(){
        //this.resumeAnim();
        //this.animationMixer.mixer.setTime(0);
        console.log("LOOP");
        lessonMan.goToStage(0, true);

        // reset materials        
        let fadedMatsLS = localStorage.getItem("FadedMats");
        if((fadedMatsLS !== undefined && fadedMatsLS !== null)){
            let fadedMats = JSON.parse(fadedMatsLS)
            lessonMan.resetMaterialOpacity(fadedMats);
        }
    },
    // SEEK
    seekAnimationTime:function(timeInSeconds){
        this.isSeeking = true;
        
        let animMixer = this.animationMixer.mixer;

        animMixer.timeScale = 1;        
        animMixer.time = 0;
        for (let i = 0; i < animMixer._actions.length; i++) {
            animMixer._actions[i].time = 0;
        }
        animMixer.update(timeInSeconds);
        this.setAnimFrame(); // update the frame index     
        // retain the paused or played state (This is how it works in app)
        if(!this.paused){this.resumeAnim();}else{this.pauseAnim();}        
    },
    // SET ANIM FRAME
    setAnimFrame:function(){
        this.currentFrame = parseFloat(this.animationMixer.mixer.time*this.animFrameRate); 
    },
    // set the animation speed to a specific value
    setAnimSpeed:function(speedMod){
        this.animSpeedMod = speedMod;
        this.animAudioScaledSpeed = this.animBaseSpeed*this.animSpeedMod;
        this.animationMixer.mixer.timeScale = this.animAudioScaledSpeed; // this is unpausing the animation

        if(this.paused){
            this.pauseAnim();
        }else{
            this.resumeAnim();
        }
    },
    // set the animation speed based off of the audio duration
    // Called when going next stage (see lesson-data-manager)
    setAnimSpeedAudio:function(currentStage, audioLength){
        //console.log(currentStage);
        let numFrames = currentStage.endFrame-currentStage.startFrame;
        let stagePlayTime = parseFloat((1/this.animFrameRate)*numFrames);
        let stageAnimSpeed = stagePlayTime / audioLength;
        //console.log("stage anim speed: " + stageAnimSpeed);
        this.animBaseSpeed = stageAnimSpeed;        
        this.setAnimSpeed(this.animSpeedMod);
    },
    cycleStage:function(){
        if(lessonMan.currentStage === undefined){return;}
        // going forwards
        if(this.currentFrame >= lessonMan.currentStage.endFrame){   
            if(lessonMan.curStageIndex == lessonMan.lessonJSON.stages.length-1){
                console.log("reached final stage, cant go next");
            }else{
                //console.log("cycling to closest stage");
                lessonMan.goToNearestStage();
            }
        // going backwards
        }else if(this.currentFrame <= lessonMan.currentStage.startFrame){
            if(lessonMan.curStageIndex == 0){
                console.log("reached first stage, cant go back");
            }else{
                //console.log("cycling to closest stage");
                lessonMan.goToNearestStage();
            }
        }    
    },
    // REMOVE COMPONENT
    remove:function(){
        this.el.removeEventListener("model-loaded", this.modelLoaded);
        this.el.removeEventListener("animation-loop", this.setStartTime);
    },
    // TICK
    tick:function(){
        if (!this.canTick) { return; }
        
        if(!this.paused){
            this.whilePlaying();
        }else{
            this.whilePaused();
        }       

        this.camFollow();
    },
    
    // CAMERA FOLLOW
    camFollow:function(){
        if(!this.modelManager.isSetupCam){return;}

        // find the dummy camera object
        let camHolder = this.modelManager.cameraHolderMesh;
        //console.log(camHolder);
        //console.log(this.modelViewer.cameraRigEl);
        this.modelViewer.cameraRigEl.object3D.position.copy(camHolder.position);
        //console.log("CHPOS: " + camHolder.position.x + " " + camHolder.position.y + " " + camHolder.position.z);
        //let camRigPos = this.modelViewer.cameraRigEl.object3D.position;
        //console.log("CRPOS: " + camRigPos.x + " " + camRigPos.y + " " + camRigPos.z);

        this.modelViewer.cameraRigEl.object3D.quaternion.copy(camHolder.quaternion);
        //console.log(camHolder.quaternion);

        // Some animations will need the camera direction changing
        if(lessonMan.camRotOffset !== undefined){
            this.modelViewer.cameraOffsetEl.setAttribute('rotation' , lessonMan.camRotOffset);
        }

        let targetMesh = this.modelManager.cameraTargetMesh;
        let targetPos = targetMesh.position;
        //console.log("TPOS: " + targetPos.x + " " + targetPos.y + " " + targetPos.z);
        this.modelViewer.cameraTargetEl.object3D.position.copy(targetPos);
    },    

    // PLAY / PAUSE
    pauseAnim:function(){ this.animationMixer.mixer.timeScale = 0; this.paused = true },
    resumeAnim:function(){ this.animationMixer.mixer.timeScale = this.animAudioScaledSpeed; this.paused = false;},
    // WHILE PLAYING / PAUSED
    whilePlaying:function(){
        if(parseFloat(this.sliderCounter) >= this.slider.max){ console.log("SLIDER > MAX"); this.pauseAnim(); return;}
        if(this.isSeeking){return;}
        // increase the slider value
        this.increaseSliderValue();
        // cycle stages              
        this.cycleStage();        
    },
    whilePaused:function(){
        //console.log("Animation is paused!");
    },

    // whenever the animation is playing increase the slider value
    increaseSliderValue:function(){
        if(this.isSeeking){return;}
        if(this.paused){return;}
        this.setAnimFrame(); // update the frame index
        this.sliderCounter = this.animationMixer.mixer.time;
        //console.log("SC: " + this.sliderCounter);
        this.slider.value = parseFloat(this.sliderCounter);
        //console.log("sv: " + this.slider.value + " sm: " + this.slider.max);
    },
    onSliderTouched:function(){
        //console.log("SLIDER MOVED");
        this.sliderCounter = this.slider.value;
        this.isSeeking = false; 
    },
    setSliderValue:function(value){
        this.slider.value = parseFloat(value);
    }
});
