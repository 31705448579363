import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import "../SCSS/landing.css";
import "../SCSS/fonts.css";
//import { DefaultContent } from './DefaultContent/DefaultContent'
import adea from "../images/landing/partners/adea.png";
import partner1 from "../images/landing/partners/p1.png";
import partner2 from "../images/landing/partners/p2.png";
import partner7 from "../images/landing/partners/densura.png";
// import partner3 from "../images/landing/partners/p3.png";
// import thumbnail_model from "../images/landing/model_thumbnail.png";
import partner3 from "../images/landing/partners/p3.png";
import partner4 from "../images/landing/partners/p4.png";
import partner5 from "../images/landing/partners/p5.png";
import partner6 from "../images/landing/partners/p6.png";
import tepe from "../images/landing/partners/tepe.png";
import media1 from "../images/landing/partners/etech_winner.png";
import media2 from "../images/landing/partners/m2.png";
// import media3 from "../images/landing/partners/m3.png";
import edtech from "../images/landing/partners/the_edtech.png";
// import gesa from "../images/landing/partners/ges.png";
import gobal_finalist from "../images/landing/partners/gb_winner.png";
import media5 from "../images/landing/partners/m5.png";
import dental_industry from "../images/landing/partners/dental_industry.png";
import downloadapp from "../images/landing/phone_img.png";
import playstore from "../images/landing/play_store.png";
import appstore from "../images/landing/app_store.png";
import qrcode from "../images/landing/qr-code-1.png";
// import web_video from "../images/landing/web_2.mp4"
import closeButton from "../images/close_btn.svg";
import immersify_logo from "../images/immersify_logo.svg";

import { GetInTouch } from "./GetInTouch/GetInTouch";
import { Footer } from "./Footer/Footer";
import { Students } from "./Students/Students";
import { Educators } from "./Educators/Educators";
import { Professionals } from "./Professionals/Professionals";
import immersify_cover from "../images/landing/immersify_cover.mp4";
import immersify_cover_mobile from "../images/landing/immersify_cover_mobile.mp4";
import immersify_cover_tab from "../images/landing/immersify_cover_tab.mp4";
// import { LandingModel } from "./LandingModel/LandingModel";
import { updateUserProperties } from "../Utils/firebaseAnalytics";
//import vmcq from "../images/landing/mockvideos2/vmcq.mp4";
//import sim from "../images/landing/mockvideos2/sim.mp4";
//import lessons from "../images/landing/mockvideos2/skull.mp4";
//import threedmodels from "../images/landing/mockvideos2/3dmodels.mp4";
//import ar from "../images/landing/mockvideos2/ar.mp4";
//import Slider from "react-slick";
import { firebaseCall } from "../Utils/firebaseAnalytics";

export const Landing = (props) => {
    const [pageToShow, setPageToShow] = useState(<Educators />);
    const [studentButtonStyle, setStudentButtonStyle] = useState("");
    const [educatorsButtonStyle, setEducatorsButtonStyle] = useState("active");
    const [professionalButtonStyle, setProfessionalButtonStyle] = useState("");
    const [studentTabStyle, setStudentTabStyle] = useState("");
    // const [tooltipActive, setTooltipActive] = useState(false);

    const targetRef = useRef(null);
    // const settings2 = {
    //     dots: true,
    //     infinite: true,
    //     speed: 500,
    //     slidesToScroll: 1,
    //     arrows: false,
    //     autoplay: true,
    //     autoplaySpeed: 3000
    // };

    function handleStudentsClicked() {
        setPageToShow(<Students targetRef={targetRef} />);
        setStudentButtonStyle("active");
        setStudentTabStyle("");
        setProfessionalButtonStyle("");
        setEducatorsButtonStyle("");
    }

    function handleEducatorsClicked() {
        setPageToShow(<Educators />);
        setEducatorsButtonStyle("active");
        setStudentButtonStyle("");
        setStudentTabStyle("left50");
        setProfessionalButtonStyle("");
    }

    function handleProfessionalsClicked() {
        setPageToShow(<Professionals />);
        setProfessionalButtonStyle("active");
        setStudentTabStyle("left100");
        setEducatorsButtonStyle("");
        setStudentButtonStyle("");
    }

    // function modelClicked() {
    //     setTooltipActive(false);
    // }

    function handleMobileMenuClose() {
        if (window.innerWidth < 992) {
            document.getElementsByClassName("navbar-toggler-icon")[0].click();
        }
    }

    // function modelLoaded() {
    //     setTooltipActive(true);
    //     // const timeId = setTimeout(() => {
    //     //     // After n seconds set the show value to false
    //     //     setTooltipActive(false);
    //     // }, 5000);

    //     // return () => {
    //     //     clearTimeout(timeId);
    //     // };
    // }
    // const [landingModel] = useState(<LandingModel modelLoaded={modelLoaded} />);

    // function handleTryWebPlatform() {
    //     window.location.href = process.env.REACT_APP_CLIENT_URL + "/Experience";
    // }

    // function handleStoreLink () {
    //     window.location.href =
    //         "https://immersifyeducation.com/deeplink?dl=%5Bimmersifyeducation%3A%2F%2Fimmersifydental%3Fsection%3DShop%5D";
    // }

    // function handleAboutUs () {
    //     window.location.href = "https://immersifyeducation.com";
    // }

    // const topScrollLimit = 300;
    const landingScrollRef = useRef(null);
    const downArrowRef = useRef(null);

    function handleStudents() {
        //previously this used scrollTo, however it would scroll to incorrect places on different screen sizes
        // the position of element from top seemed to change depending on screen size for some unknown reason
        // document.getElementById("landingScrollTo").scrollIntoView({behavior: "smooth"});
        if (landingScrollRef.current) {
            landingScrollRef.current.scrollIntoView({ behavior: "smooth" });
        }
        handleStudentsClicked();
    }

    function handleEducators() {
        if (landingScrollRef.current) {
            landingScrollRef.current.scrollIntoView({ behavior: "smooth" });
        }
        handleEducatorsClicked();
    }

    function handleProfessionals() {
        if (landingScrollRef.current) {
            landingScrollRef.current.scrollIntoView({ behavior: "smooth" });
        }
        handleProfessionalsClicked();
    }

    function handleArrow() {
        if (downArrowRef.current) {
            downArrowRef.current.scrollIntoView({ behavior: "smooth" });
        }
    }

    function handleAboutus() {
        window.location.href = process.env.REACT_APP_CLIENT_URL + "/aboutus";
    }

    function handleBlog() {
        window.location.href = process.env.REACT_APP_WORDPRESS_URL + "/blog";
    }

    // const [size, setSize] = useState({
    //     x: window.innerWidth,
    //     y: window.innerHeight,
    // });
    // const updateSize = () =>
    //     setSize({
    //         x: window.innerWidth,
    //         y: window.innerHeight,
    //     });
    // useEffect(() => (window.onresize = updateSize), []);

    const [scroll, setScroll] = useState(false);
    useEffect(() => {
        window.addEventListener("scroll", () => {
            setScroll(window.scrollY > 80);
        });
        if (window.location.href.includes("Students") || props.activeLink === "Students") {
            handleStudents();
        }
        if (window.location.href.includes("Institutions") || props.activeLink === "Institutions") {
            handleEducators();
        }
        if (window.location.href.includes("Professionals") || props.activeLink === "Professionals") {
            handleProfessionals();
        }
        window.history.replaceState(null, "", process.env.REACT_APP_CLIENT_URL + "/Landing");
        updateUserProperties("web_landing");
    }, []);

    return (
        <>
            <div
                className="modal fade getintouch"
                id={"getInTouchModal"}
                data-bs-keyboard="true"
                tabIndex={-1}
                aria-labelledby="quiz"
                aria-hidden="true"
            >
                <div className="modal-dialog">
                    <div className="modal-content">
                        <button type="button" className="btn_mod_close" data-bs-dismiss="modal" aria-label="Close">
                            <img alt="placeholder" src={closeButton} />
                        </button>
                        <div className="modal-body">
                            <GetInTouch />
                        </div>
                    </div>
                </div>
            </div>

            <section className={scroll ? "nav_wrap fixed_nav" : "nav_wrap"}>
                <div className="container main_nav">
                    <div className="row">
                        <div className="col-md-12">
                            <nav className="navbar navbar-expand-lg navbar-light">
                                <div className="container-fluid">
                                    <a className="navbar-brand" href="#">
                                        <img src={immersify_logo} alt="Logo" />
                                    </a>
                                    <button
                                        className="navbar-toggler"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#navbarTogglerDemo03"
                                        aria-controls="navbarTogglerDemo03"
                                        aria-expanded="false"
                                        aria-label="Toggle navigation"
                                    >
                                        <span className="navbar-toggler-icon"></span>
                                    </button>

                                    <div className="collapse navbar-collapse" id="navbarTogglerDemo03">
                                        <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                                            <li className="nav-item">
                                                <a
                                                    className={"nav-link " + educatorsButtonStyle}
                                                    aria-current="page"
                                                    onClick={() => {
                                                        handleEducators();
                                                        handleMobileMenuClose();
                                                    }}
                                                >
                                                    Institutions
                                                </a>
                                            </li>
                                            <li className="nav-item">
                                                <a
                                                    className={"nav-link " + studentButtonStyle}
                                                    onClick={() => {
                                                        handleStudents();
                                                        handleMobileMenuClose();
                                                    }}
                                                >
                                                    Students
                                                </a>
                                            </li>

                                            <li className="nav-item">
                                                <a
                                                    className={"nav-link " + professionalButtonStyle}
                                                    onClick={() => {
                                                        handleProfessionals();
                                                        handleMobileMenuClose();
                                                    }}
                                                >
                                                    Professionals
                                                </a>
                                            </li>
                                            <li className="nav-item">
                                                <a
                                                    className={"nav-link"}
                                                    aria-current="page"
                                                    onClick={() => {
                                                        handleAboutus();
                                                    }}
                                                >
                                                    About us
                                                </a>
                                            </li>
                                            <li className="nav-item">
                                                <a
                                                    className={"nav-link"}
                                                    aria-current="page"
                                                    onClick={() => {
                                                        handleBlog();
                                                    }}
                                                >
                                                    Blog
                                                </a>
                                            </li>

                                            {/* <li className="nav-item">
                                                <a
                                                    href="https://immersifyeducation.com/blog"
                                                    target="_blank"
                                                    rel="noreferrer"
                                                    className=
                                                        "nav-link"
                                                   
                                                >
                                                    Blog
                                                </a>
                                            </li> */}

                                            {/* <li className="nav-item">
                                                    <a className="nav-link" href="#">Blog</a>
                                                    </li> */}

                                            <li className="nav-item free_trial">
                                                <a className="nav-link" href={process.env.REACT_APP_CLIENT_URL + "/Profile"}>
                                                    My Account
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </nav>
                        </div>
                    </div>
                </div>
            </section>

            <section className="top_sec">
                <section className="hero_slider">
                    <div className="bg_immersify_video d-none d-lg-block ">
                        <video autoPlay muted loop id="myVideo">
                            <source src={immersify_cover} type="video/mp4" />
                        </video>
                    </div>
                    <div className="bg_immersify_video d-none d-md-block d-lg-none">
                        <video autoPlay muted loop id="myVideo">
                            <source src={immersify_cover_tab} type="video/mp4" />
                        </video>
                    </div>

                    <div className="bg_immersify_video d-sm-block d-md-none">
                        <video autoPlay muted loop id="myVideo">
                            <source src={immersify_cover_mobile} type="video/mp4" />
                        </video>
                    </div>
                    <div className="hero_txt_block">
                        <div className="hero_txt">
                            <div className="l_main_heading animtext">
                                <div className="text_items">
                                    <h2>Immersify, the ultimate education platform</h2>
                                    {/* <h2>
                                Transforming nursing and dental education around the world through 
                                immersive experiences, Augmented Reality and engaging visual 
                                and practical resources on-the-go.
                                </h2> */}
                                    <h1>Transforming educational resources with</h1>
                                    <div className="scrolling-words-container">
                                        <div className="scrolling-words-box">
                                            <ul>
                                                <li>Immersive simulations</li>
                                                <li>Engaging visuals</li>
                                                <li>Performance analytics</li>
                                                <li>Interactive lessons</li>
                                                <li>Expert content</li>
                                                <li>Immersive simulations</li>
                                            </ul>
                                        </div>
                                    </div>

                                    <div className="button_grp">
                                        <button
                                            className="btn_common"
                                            data-bs-toggle="modal"
                                            data-bs-target="#getInTouchModal"
                                            onClick={() => firebaseCall("contact_form_opened", {})}
                                        >
                                            Get in touch
                                        </button>
                                    </div>

                                    {/* <a
                                        href="https://immersifydental.page.link/?link=https://immersifyeducation.com/deeplink?dl=%5Bimmersifyeducation%3A%2F%2Fimmersifydental%3Firclickid%3DLANDING%5D%26apn%3Dcom.immersifyeducation.immersifydental%26isi%3D1509735183%26ibi%3Dcom.immersifyeducation.immersifydental"
                                        target="_blank"
                                        rel="noreferrer"
                                        className="btn_outline d-none d-md-flex"
                                    >
                                        Download Immersify app
                                    </a>
                                    <a
                                        href="https://immersifydental.page.link/?link=https://immersifyeducation.com/deeplink?dl=%5Bimmersifyeducation%3A%2F%2Fimmersifydental%3Firclickid%3DLANDING%5D%26apn%3Dcom.immersifyeducation.immersifydental%26isi%3D1509735183%26ibi%3Dcom.immersifyeducation.immersifydental "
                                        className="btn_outline d-flex d-md-none"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        Download Immersify app
                                    </a> */}
                                </div>
                            </div>
                            <div
                                className="down_pointer bounce2"
                                onClick={() => {
                                    handleArrow();
                                }}
                            >
                                <i className="fa fa-angle-down"></i>
                            </div>
                        </div>

                        {/* <div className="col-md-12 order-1 col-lg-4 order-lg-2">
                            <div
                                className="video_sec"
                                onClick={modelClicked}
                                onMouseDown={modelClicked}
                            >
                                <div
                                    className={
                                        tooltipActive
                                            ? "tip_model"
                                            : "tip_model d-none"
                                    }
                                >
                                    <div className="tip_img d-none d-md-block"></div>
                                    <div className="tip_img2 d-block d-md-none"></div>
                                    <div className="tip_txt">
                                        <h3>It&#39;s a 3D model!</h3>
                                        <h5>You can pan  the model.</h5>
                                    </div>
                                </div>
                             
                                {landingModel}
                            </div>
                        </div> */}

                        {/* <div className="col-md-12 order-1 col-lg-5 order-lg-2">
                            <div className="first_banner_img text-center">
                        <Slider {...settings2}>

                                <div className="mob_item">
                                   <div className="mob_img">
                                   <video width="100%" height="100%" autoPlay muted loop>
                                        <source src={vmcq} type="video/mp4"></source>
                                        </video>
                                   </div>
                                   <h4>Visual MCQs</h4>
                                </div>
                                <div className="mob_item">
                                   <div className="mob_img">
                                   <video width="100%" height="100%" autoPlay muted loop>
                                        <source src={sim} type="video/mp4"></source>
                                        </video>
                                   </div>
                                   <h4>Real life simulations</h4>
                                </div>
                                <div className="mob_item">
                                   <div className="mob_img c_sim">
                                   <video width="100%" height="100%" autoPlay muted loop>
                                        <source src={lessons} type="video/mp4"></source>
                                        </video>
                                   </div>
                                   <h4>Interactive lessons</h4>
                                </div>
                                <div className="mob_item">
                                   <div className="mob_img c_sim">
                                   <video width="100%" height="100%" autoPlay muted loop>
                                        <source src={threedmodels} type="video/mp4"></source>
                                        </video>
                                   </div>
                                   <h4>3D models</h4>
                                </div>
                                <div className="mob_item">
                                   <div className="mob_img c_sim">
                                   <video width="100%" height="100%" autoPlay muted loop>
                                        <source src={ar} type="video/mp4"></source>
                                        </video>
                                   </div>
                                   <h4>Augmented reality</h4>
                                </div>
                            </Slider>

                           
                        </div>
                        </div> */}
                    </div>
                </section>

                {/* not entirely sure if this does anything ?? */}
                <section className="bottom_navs d-none">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 p-0">
                                <div className="btm_nav">
                                    <div className={"tab_mover " + studentTabStyle}></div>
                                    <div className={"btm_nav_links " + studentButtonStyle} onClick={() => handleStudents()}>
                                        <div className="ins_navtab">
                                            <i className="icon-learners"></i>
                                            <h3>Students</h3>
                                            <p>What are you studying? </p>
                                        </div>
                                    </div>
                                    <div className={"btm_nav_links " + educatorsButtonStyle} onClick={() => handleEducators()}>
                                        <div className="ins_navtab">
                                            <i className="icon-educator"></i>
                                            <h3>Institutions</h3>
                                            <p>Are you an educator?</p>
                                        </div>
                                    </div>
                                    <div className={"btm_nav_links " + professionalButtonStyle} onClick={() => handleProfessionals()}>
                                        <div className="ins_navtab">
                                            <i className="icon-professional"></i>
                                            <h3>Professionals</h3>
                                            <p>Looking for a different approach?</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </section>

            <div className="awards_wrap">
                <div id="landingScrollTo" ref={downArrowRef}></div>
                <div className="container">
                    <div className="row awards_top">
                        <div className="col-4 col-lg-2">
                            <div className="p_img">
                                <img src={media1} alt="Media image" />
                            </div>
                        </div>
                        <div className="col-4 col-lg-2 d-none d-md-block">
                            <div className="p_img">
                                <a
                                    href="https://www.the-dentist.co.uk/content/news/immersify-dental-targets-100-000-users-as-it-launches-new-subscription-service-for-dentistry-students%22%20/o%20%22https://www.the-dentist.co.uk/content/news/immersify-dental-targets-100-000-users-as-it-launches-new-subscription-service-for-dentistry-students%22%20/t%20%22_blank"
                                    rel="noreferrer"
                                    target="_blank"
                                >
                                    <img src={media2} alt="Media image" />
                                </a>
                            </div>
                        </div>
                        <div className="col-4 col-lg-2">
                            <div className="p_img">
                                <a
                                    href="https://edtechnology.co.uk/comments/enhancing-the-way-we-learn-in-a-technology-driven-world/"
                                    rel="noreferrer"
                                    target="_blank"
                                >
                                    <img src={edtech} alt="Media image" />
                                </a>
                            </div>
                        </div>
                        <div className="col-4 col-lg-2">
                            <div className="p_img">
                                <img src={gobal_finalist} alt="Media image" />
                            </div>
                        </div>
                        <div className="col-4 col-lg-2 d-none d-md-block">
                            <div className="p_img">
                                <a
                                    href="https://www.thebusinessdesk.com/northwest/news/2097255-start-up-aims-to-become-netflix-for-educational-content"
                                    rel="noreferrer"
                                    target="_blank"
                                >
                                    <img src={media5} alt="Media image" />
                                </a>
                            </div>
                        </div>
                        <div className="col-4 col-lg-2 d-none d-md-block">
                            <div className="p_img">
                                <a href="https://dentistry.co.uk/awards/dental-industry-awards/" rel="noreferrer" target="_blank">
                                    <img src={dental_industry} alt="Media image" />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <section className="video_part">
                <div className="fix_parallax">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-5">
                                <div className="video_in_txt">
                                    <h4>
                                        Step into a world of boundless possibilities with <span>Immersify</span>
                                    </h4>

                                    <h6>Want to learn more about us? </h6>
                                    <button
                                        className="btn_common mt-3"
                                        data-bs-toggle="modal"
                                        data-bs-target="#getInTouchModal"
                                        onClick={() => firebaseCall("contact_form_opened", {})}
                                    >
                                        Find out more
                                    </button>
                                </div>
                            </div>

                            <div className="col-md-7">
                                <div className="video_in">
                                    <iframe
                                        src="https://www.youtube-nocookie.com/embed/3QsuHE28E0k?rel=0&fs=0&color=white"
                                        width="100%"
                                        height="100%"
                                        title="Experience Immersify: The Ultimate Education Platform"
                                        frameBorder="0"
                                    ></iframe>
                                </div>
                            </div>
                        </div>
                        <div id="landingScrollTo" ref={landingScrollRef}></div>
                    </div>
                </div>
            </section>

            <section className="main_cont_wrap">
                <div className="switch_blocks">{pageToShow}</div>
            </section>

            <section className="container  wow animate__animated animate__fadeInUp">
                <div className="row">
                    <div className="col-md-12">
                        <div className="partners">
                            <h2 className="p_head">Our Partners</h2>
                            <div className="row">
                            <div className="col-md-4 col-6">
                                    <div className="p_img">
                                        <a href="https://www.adea.org/" target="_blank" rel="noreferrer">
                                            <img src={adea} alt="ADEA" />
                                        </a>
                                    </div>
                                </div>
                                <div className="col-md-4 col-6">
                                    <div className="p_img">
                                        <a href="https://adee.org" target="_blank" rel="noreferrer">
                                            <img src={partner1} alt="Partner image" />
                                        </a>
                                    </div>
                                </div>

                                <div className="col-md-4 col-6">
                                    <div className="p_img">
                                        <a href="https://www.septodont.co.uk" target="_blank" rel="noreferrer">
                                            <img src={partner3} alt="Media image" />
                                        </a>
                                    </div>
                                </div>
                                <div className="col-md-4 col-6">
                                    <div className="p_img">
                                        <a href="https://www.cityandguilds.com" target="_blank" rel="noreferrer">
                                            <img src={partner2} alt="Partner image" />
                                        </a>
                                    </div>
                                </div>
                                <div className="col-md-4 col-6">
                                    <div className="p_img">
                                        <a href="https://www.tepe.com" target="_blank" rel="noreferrer">
                                            <img src={tepe} alt="Partner image" />
                                        </a>
                                    </div>
                                </div>
                                <div className="col-md-4 col-6">
                                    <div className="p_img">
                                        <a href="https://global.oup.com/?cc=gb" target="_blank" rel="noreferrer">
                                            <img src={partner4} alt="Partner image" />
                                        </a>
                                    </div>
                                </div>
                                <div className="col-md-4 col-6">
                                    <div className="p_img">
                                        <a href="https://www.ems-dental.com/en" target="_blank" rel="noreferrer">
                                            <img src={partner5} alt="Partner image" />
                                        </a>
                                    </div>
                                </div>
                                <div className="col-md-4 col-6">
                                    <div className="p_img">
                                        <a href="https://www.mouthcancerfoundation.org" target="_blank" rel="noreferrer">
                                            <img src={partner6} alt="Partner image" />
                                        </a>
                                    </div>
                                </div>

                                <div className="col-md-3 col-6">
                                    <div className="p_img">
                                        <a href="https://www.densura.com" target="_blank" rel="noreferrer">
                                            <img src={partner7} alt="Partner image" />
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* <div
                            className="partners media_fea  wow animate__animated animate__fadeInUp"
                            id="downloadapp"
                        >
                            <h2 className="p_head"><span>Media features and awards</span></h2>
                            <div className="row">
                                <div className="col-md-4 col-6">
                                    <div className="p_img">
                                        <img src={media1} alt="Media image" />
                                    </div>
                                </div>
                                <div className="col-md-4 col-6">
                                    <div className="p_img">
                                        <a
                                            href="https://www.the-dentist.co.uk/content/news/immersify-dental-targets-100-000-users-as-it-launches-new-subscription-service-for-dentistry-students%22%20/o%20%22https://www.the-dentist.co.uk/content/news/immersify-dental-targets-100-000-users-as-it-launches-new-subscription-service-for-dentistry-students%22%20/t%20%22_blank"
                                            rel="noreferrer"
                                            target="_blank"
                                        >
                                            <img
                                                src={media2}
                                                alt="Media image"
                                            />
                                        </a>
                                    </div>
                                </div>
                                <div className="col-md-4 col-6">
                                    <div className="p_img">
                                        <a
                                            href="https://edtechnology.co.uk/comments/enhancing-the-way-we-learn-in-a-technology-driven-world/"
                                            rel="noreferrer"
                                            target="_blank"
                                        >
                                            <img
                                                src={edtech}
                                                alt="Media image"
                                            />
                                        </a>
                                    </div>
                                </div>
                                <div className="col-md-4 col-6">
                                    <div className="p_img">
                                        <img src={gobal_finalist} alt="Media image" />
                                    </div>
                                </div>
                                <div className="col-md-4 col-6">
                                    <div className="p_img">
                                        <a
                                            href="https://www.thebusinessdesk.com/northwest/news/2097255-start-up-aims-to-become-netflix-for-educational-content"
                                            rel="noreferrer"
                                            target="_blank"
                                        >
                                            <img
                                                src={media5}
                                                alt="Media image"
                                            />
                                        </a>
                                    </div>
                                </div>
                                <div className="col-md-4 col-6">
                                    <div className="p_img">
                                        <a
                                            href="https://dentistry.co.uk/awards/dental-industry-awards/"
                                            rel="noreferrer"
                                            target="_blank"
                                        >
                                            <img
                                                src={dental_industry}
                                                alt="Media image"
                                            />
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>
            </section>

            <section className="download_app  wow animate__animated animate__fadeInUp">
                <div className="dwld_txt">
                    <h2>Download Immersify</h2>
                    <div className="qr_block">
                        <img src={qrcode} alt="QR Code" />
                        <div className="qr_txt">
                            <h5>Immersify is available in both Android and iOS</h5>
                            <div className="download_play">
                                <a
                                    href="https://immersifydental.page.link/?link=https://immersifyeducation.com/deeplink?dl=%5Bimmersifyeducation%3A%2F%2Fimmersifydental%3Firclickid%3DFOOTER%5D%26apn%3Dcom.immersifyeducation.immersifydental%26isi%3D1509735183%26ibi%3Dcom.immersifyeducation.immersifydental"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <img
                                        src={appstore}
                                        alt="App store"
                                        //onClick={handleStoreLink}
                                    />
                                </a>
                                <a
                                    href="https://immersifydental.page.link/?link=https://immersifyeducation.com/deeplink?dl=%5Bimmersifyeducation%3A%2F%2Fimmersifydental%3Firclickid%3DFOOTER%5D%26apn%3Dcom.immersifyeducation.immersifydental%26isi%3D1509735183%26ibi%3Dcom.immersifyeducation.immersifydental"
                                    rel="noreferrer"
                                    target="_blank"
                                >
                                    <img
                                        src={playstore}
                                        alt="Play store"
                                        //onClick={handleStoreLink}
                                    />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="dwnld_img">
                    <img src={downloadapp} alt="Phone image" />
                </div>
            </section>

            <section className="container-fluid get_in_bg">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 ">
                            <div className="get_in_touch">
                                <div className="get-50">
                                    <h3>Want to know more?</h3>
                                    <h5>Got a question, a suggestion or wanna chat with our team?</h5>
                                </div>
                                <button
                                    onClick={() => firebaseCall("contact_form_opened", {})}
                                    className="btn_whiteoutline"
                                    data-bs-toggle="modal"
                                    data-bs-target="#getInTouchModal"
                                >
                                    Get in touch
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Footer
                setPageToShow={setPageToShow}
                setStudentButtonStyle={setStudentButtonStyle}
                setEducatorsButtonStyle={setEducatorsButtonStyle}
                setProfessionalButtonStyle={setProfessionalButtonStyle}
                setStudentTabStyle={setStudentTabStyle}
            />

            {/* <div> <h1>Heading</h1>
            <button
                style={{ background: studentButtonStyle }}
                onClick={handleStudentsClicked}
            >
                Students
            </button>
            <button 
                style={{ background: educatorsButtonStyle }}
                onClick={handleEducatorsClicked}>Educators</button>
            {pageToShow}
            </div> */}
        </>
    );
};

Landing.propTypes = {
    activeLink: PropTypes.string,
};
