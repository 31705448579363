import React, { useState } from "react";
import Slider from "react-slick";
import { Header } from "../../Landing/Header/Header";
import { Footer } from "../../Landing/Footer/Footer";
import unlock_img from "../../images/landing/dentistry/unlock_img.svg";
import cr1 from "../../images/landing/dentistry/cr_1.png";
import cr2 from "../../images/landing/dentistry/cr_2.png";
import cr3 from "../../images/landing/dentistry/cr_3.png";
import cr4 from "../../images/landing/dentistry/cr_4.png";
import anna from "../../images/landing/dentistry/anna.png";
import roshini from "../../images/landing/dentistry/roshini.png";
import AcademicAreasData from "../../ContentDeliverySystem/InfoJSONs/AcademicAreasData.json";
import { TopicCard } from "../../ContentDeliverySystem/Experience/DisplayContent/DisplayTopics/TopicCard/TopicCard";
import { GetModuleTopics } from "../../ContentDeliverySystem/Experience/DisplayContent/DisplayTopics/GetModuleTopics/GetModuleTopics";
import { LandingModel } from "../../Landing/LandingModel/LandingModel";

import { RelatedLinks } from "../RelatedLinks";
export const Dentistry = () => {

    const settings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToScroll: 1,
        arrows: false
    };
    function modelLoaded () {
        setTooltipActive(true)
   }
   function modelClicked () {
    setTooltipActive(false)
}
    const [landingModel] = useState(
        <LandingModel
            modelLoaded={modelLoaded}
            modelScale={"2.1 2.1 2.1"}
            modelPosition={"0 1.8 -8"}
            modelRotation={"0 0 0"}
            modelURL={
                "https://dsmhsp9m0ds1d.cloudfront.net/ModelData/LandingPage/LandingPageModelNoNormal.glb"
            }
        />
    );
    const [tooltipActive, setTooltipActive] = useState(false)



    function handleTryWebPlatform () {
        window.location.href = process.env.REACT_APP_CLIENT_URL + "/Experience"
    }

    var topics = []
    AcademicAreasData.academicAreas.forEach(academicArea => {
        if (academicArea.id === 'dentistry') {
            academicArea.modules.forEach(module => {
                GetModuleTopics(module).forEach(topic => {
                    //only want these topics
                    if ((topic.id == "localAnalgesia_topic") || (topic.id =="medicinerelatedToDentistry_topic") || (topic.id =="skullCranium_topic") || (topic.id =="charting_topic") || (topic.id =="instrument_topic") || (topic.id =="cariology_topic")) {
                        topics.push(topic);
                    }
                });
            });
        }
    });
    function updateState () {
        console.log("update state")
    }
    const allTopics = topics.map((topic, index) => {
        return <TopicCard key={"topicCard" + index} id={topic.id} displayContentCallback={updateState} premium={false} outsidePlatform={true}/>;
    });

    return (

        <>
         <Header link={true} />

         <section className="dentistry_main">
            <div className="container hero_slider">
                        <div className="row">
                            <div className="col-md-12 col-lg-7 order-2 order-lg-1">
                                <div
                                    className="l_main_heading"
                                >
                                    <h1>
                                    Immersify, our dentistry education platform designed for both students and professionals<span className="end_not"></span>
                                    </h1>

                                    <div className="button_grp">
                                        <button
                                            className="btn_common"
                                            onClick={handleTryWebPlatform}
                                        >
                                            Try Immersify Platform
                                        </button>

                                        <a
                                            href="https://immersifydental.page.link/?link=https://immersifyeducation.com/deeplink?dl=%5Bimmersifyeducation%3A%2F%2Fimmersifydental%3Firclickid%3DLANDING%5D%26apn%3Dcom.immersifyeducation.immersifydental%26isi%3D1509735183%26ibi%3Dcom.immersifyeducation.immersifydental"
                                            target="_blank"
                                            rel="noreferrer"
                                            className="btn_outline d-none d-md-flex"
                                        >
                                            Download Immersify app
                                        </a>
                                        <a
                                            href="https://immersifydental.page.link/?link=https://immersifyeducation.com/deeplink?dl=%5Bimmersifyeducation%3A%2F%2Fimmersifydental%3Firclickid%3DLANDING%5D%26apn%3Dcom.immersifyeducation.immersifydental%26isi%3D1509735183%26ibi%3Dcom.immersifyeducation.immersifydental "
                                            className="btn_outline d-flex d-md-none"
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            Download Immersify app
                                        </a>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-12 order-1 col-lg-5 order-lg-2">
                                <div 
                                    className="video_sec"
                                    onClick={modelClicked}
                                    onMouseDown={modelClicked}
                                >
                                <div
                                    className={
                                        tooltipActive
                                            ? "tip_model"
                                            : "tip_model d-none"
                                    }
                                >
                                    <div className="tip_img d-none d-md-block"></div>
                                    <div className="tip_img2 d-block d-md-none"></div>
                                    <div className="tip_txt">
                                        <h3>It&#39;s a 3D model!</h3>
                                        <h5>You can pan and zoom the model.</h5>
                                    </div>
                                </div>
                                {landingModel}
                                </div>
                            </div>
                        </div>
            </div>
         </section>

         <section className="dentistry_modules">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                     <div className="heading_wrap text-center">
                        <h2 className="landing_main_head">
                        What dentistry modules does Immersify cover?
                        </h2>
                        <p  className="landing_main_para text-center">Our dentistry content covers beginner learnings through to advanced modules. Click the tiles to go straight into this content.</p>
                    </div>

                    <div className="topic_listings main_topics row">
                        {allTopics}
                    </div>
                    <div className="text-center mt-4"><button className="btn_common" onClick={handleTryWebPlatform}>View all</button></div>
                    </div>
                </div>
            </div>
         </section>

         

         <section className="use_content mt_pro_1">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="heading_wrap text-center">
                                <h2 className="landing_main_head">
                                    How you could use our content<span className="end_not"></span>
                                </h2>

                            </div>
                        </div>
                    </div>

                        <div className="row case_blocks">
                            <div className="col-lg-3 col-md-6 col-12">
                                <div className="use_cnt_block">
                                    <i className="icon-master_terminology"></i>

                                    <h4>Master Terminology</h4>
                                    <p>
                                    Get started on your dental terminology or better understand your dental instruments and their uses.
                                    </p>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 col-12">
                                <div className="use_cnt_block">
                                <i className="icon-study_patient"></i>

                                    <h4>Study Patient Case Scenarios</h4>
                                    <p>
                                    Undergo scenario-based learning on Radiography and identify what is needed for your patient&#39;s dental health to get back to its optimum.
                                    </p>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 col-12">
                                <div className="use_cnt_block">
                                <i className="icon-tech_procedure"></i>

                                    <h4>Understand Technical Procedures</h4>
                                    <p>
                                    Freshen up on Restorative Dentistry and understand the different prosthodontics and when to use them.
                                    </p>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 col-12">
                                <div className="use_cnt_block">
                                <i className="icon-add_content"></i>

                                    <h4>Explore Add-on Content</h4>
                                    <p>
                                    Maybe you want insight into Implantology?
                                    </p>
                                </div>
                            </div>

                        </div>
                </div>
            </section>

            <section className="unlock_pot">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="unlock_pot_wrap">
                                <div className="unlock_txt">
                                    <h2>Ready to unlock your potential</h2>
                                    <button className="btn_outline" onClick={handleTryWebPlatform}>Try Immersify Platform</button>
                                </div>
                                <div className="unlock_img">
                                    <img src={unlock_img} alt="unlock potential" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <div className="sec_sep"></div>


            <section className="content_rely">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                        <div className="heading_wrap text-center">
                                <h2 className="landing_main_head">
                                Content you can rely on<span className="end_not"></span>
                                </h2>
                                <p className="landing_main_para">
                                Our dentistry content is created through collaboration with 
                                industry experts and dentistry professionals. Meet a selection of our experts here:
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className="row mt-5">
                        <div className="col-md-6 col-lg-3 col-12">
                            <div className="content_people">
                                <div className="card">
                                    <div className="content">
                                        <div className="front">
                                            <img src={cr1} alt="Dr. Barry Berkovitz" />
                                            <h4>Dr. Barry Berkovitz</h4>
                                            <h6>Expert in Dental Anatomy</h6>
                                        </div>
                                        <div className="back">
                                            <p>
                                                Dr. Berkovitz qualified at the Royal Dental Hospital of
                                                London in 1962. At Royal Holloway College, Kings College
                                                London and University of Bristol he taught Anatomy and
                                                Dental Histology at the Anatomy Department. He is still
                                                involved in academic pursuits and is the author of numerous
                                                books and scientific articles.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-3 col-12">
                            <div className="content_people">
                                <div className="card">
                                    <div className="content">
                                        <div className="front">
                                            <img src={cr2} alt="Dr. Barry Berkovitz" />
                                            <h4>Emeritus Bernard Moxham</h4>
                                            <h6>Former Professor at Cardiff University</h6>
                                        </div>
                                        <div className="back">
                                            <p>
                                                After his time as a Professor of Anatomy for 23 years at
                                                Cardiff University, he founded the journal Aging Cell,
                                                which is the journal for reporting on the biology of ageing
                                                that now has the highest scientific impact for this line of research.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-3 col-12">
                            <div className="content_people">
                                <div className="card">
                                    <div className="content">
                                        <div className="front">
                                            <img src={cr3} alt="Dr. Barry Berkovitz" />
                                            <h4>James Darcey</h4>
                                            <h6>Consultant at University Hospital Manchester</h6>
                                        </div>
                                        <div className="back">
                                            <p>
                                                After completing his Masters degree in Oral and Maxillofacial
                                                Surgery with distinction from the University of Manchester
                                                in 2008. James has also attained Membership in Endodontics
                                                from the Royal College of Surgeons of Edinburgh and now holds
                                                a consultant post in Restorative Dentistry at the University
                                                Dental Hospital of Manchester
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-3 col-12">
                            <div className="content_people">
                                <div className="card">
                                    <div className="content">
                                        <div className="front">
                                            <img src={cr4} alt="Dr. Barry Berkovitz" />
                                            <h4>Jonathan Rees</h4>
                                            <h6>Specialist Oral Surgeon</h6>
                                        </div>
                                        <div className="back">
                                            <p>
                                            As a specialist oral surgeon and general dental surgeon, Jonathan’s experience is
                                             dedicated to the placement, restoration and maintenance of dental 
                                             implants together with the extraction of teeth and surgical management of infection.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <div className="sec_sep inv_shape"></div>


            <section className="container mt_pro_1">
                <div className="row">
                    <div className="testimonials">


                        <div className="col-md-12 p-rel">
                            <div className="testimonial_sld">
                                <Slider {...settings}>
                                    <div className="testimonial_item">
                                        <p>
                                            &#34;During my transition from student to a qualified dentist,
                                             I could stay engaged with dentistry in an incredibly realistic
                                              and visual way. I felt that this was invaluable considering 
                                              the hands-on nature of the job! &#34;
                                        </p>

                                        <div className="testimonial_person">
                                            <div className="testm_img">
                                                <img src={roshini} alt="testimonials" />
                                            </div>
                                            <div className="testm_det">
                                                <h2>Dr. Roshni Ruparelia </h2>
                                                <h5>Dentist</h5>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="testimonial_item">
                                        <p>
                                            &#34;The Immersify Dental app has given me everything I need in terms of practical and 
                                            theoretical dental knowledge and it’s amazing that I have all the dental knowledge
                                             I need to progress in my career/studies, at the touch of a finger. It’s a game changer.&#34;
                                        </p>

                                        <div className="testimonial_person">
                                            <div className="testm_img">
                                                <img src={anna} alt="testimonials" />
                                            </div>
                                            <div className="testm_det">
                                                <h2>Anna Johnson</h2>
                                                <h5>Dental student</h5>
                                            </div>
                                        </div>
                                    </div>


                                </Slider>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

         <section className="faq container mt_pro_1">
                <div className="row">
                    <div className="col">
                        
                        <div className="box_head">
                            <h2 className="landing_main_head">
                            Frequently asked questions<span className="end_not"></span>
                            </h2>
                        </div>

                        <div className="faq_content">
                            <div className="faq_questions">
                                <button className="faq_que" type="button" data-bs-toggle="collapse" data-bs-target="#faq_1" aria-expanded="false" aria-controls="faq_1">
                                What devices are Immersify available on? <span className="arrow_change"><i className="fa fa-angle-right"></i></span>
                                </button>
                                <div className="collapse" id="faq_1">
                                    <div className="faq_answ">
                                    <ul>
                                        <li>You can access Immersify through smartphone, tablet, laptop and desktop.</li>
                                        <li>
                                            On laptop and desktop, access our content through the Immersify Platform above. 
                                        </li>
                                        <li>For smartphones and tablets, your device is compatible if Immersify appears within
                                    the Apple app Store or Google Play Store; this is usually 11+ for iOS and 12+ for Android. </li>
                                    <li>
                                    
                                    Why not check through these links here: <br/>

                                    Immersify - <a href='https://immersifydental.page.link/?link=https://immersifyeducation.com/deeplink?dl=%5Bimmersifyeducation%3A%2F%2Fimmersifydental%3Firclickid%3DFAQL%5D%26apn%3Dcom.immersifyeducation.immersifydental%26isi%3D1509735183%26ibi%3Dcom.immersifyeducation.immersifydental ' target="_blank" rel="noreferrer">Apple App Store</a> <br/>

                                    Immersify - <a href="https://immersifydental.page.link/?link=https://immersifyeducation.com/deeplink?dl=%5Bimmersifyeducation%3A%2F%2Fimmersifydental%3Firclickid%3DFAQL%5D%26apn%3Dcom.immersifyeducation.immersifydental%26isi%3D1509735183%26ibi%3Dcom.immersifyeducation.immersifydental " rel="noreferrer" target="_blank">Google Play Store</a> <br/>
                                    </li>
                                    </ul>
                                     

                                    </div>
                                </div>
                            </div>

                            <div className="faq_questions">
                                <button className="faq_que" type="button" data-bs-toggle="collapse" data-bs-target="#faq_2" aria-expanded="false" aria-controls="faq_2">
                                What academic level of dentistry does Immersify cover? <span className="arrow_change"><i className="fa fa-angle-right"></i></span>
                                </button>
                                <div className="collapse" id="faq_2">
                                    <div className="faq_answ">
                                    From Tooth Morphology to Cranial Nerves and Endodontics to Paediatric Dentistry, our dentistry content covers beginner learnings 
                                    from first year to advanced modules in fifth year and is suitable for young professionals who want a refresh on topics.
                                    </div>
                                </div>
                            </div>

                            <div className="faq_questions">
                                <button className="faq_que" type="button" data-bs-toggle="collapse" data-bs-target="#faq_3" aria-expanded="false" aria-controls="faq_3">
                                How transferrable is the content in Immersify to real-life?   <span className="arrow_change"><i className="fa fa-angle-right"></i></span>
                                </button>
                                <div className="collapse" id="faq_3">
                                    <div className="faq_answ">
                                    <ul>
                                        <li>The lessons within Immersify cover scenario-based learning, with some being held within simulated clinical settings. 
                                            This provides you with an understanding of how a real-life situation could occur and best ways to treat; 
                                            you are also given feedback on how you performed and what you could have done better. 
                                        </li>
                                        <li>
                                            <a href="https://immersifyeducation.org/education-platforms-help-qualified-professional" rel="noreferrer" target="_blank">Check out our blog post</a> on how Immersify’s content is transferrable to real-life.  
                                        </li>
                                    </ul>   
                                      
                                    </div>
                                </div>
                            </div>

                            <div className="faq_questions">
                                <button className="faq_que" type="button" data-bs-toggle="collapse" data-bs-target="#faq_4" aria-expanded="false" aria-controls="faq_4">
                                I don’t go to university / college, can I get access to Immersify?  <span className="arrow_change"><i className="fa fa-angle-right"></i></span>
                                </button>
                                <div className="collapse" id="faq_4">
                                    <div className="faq_answ">
                                        <ul>
                                            <li>
                                            Yes – anyone can access our dentistry content; you can create an account or continue as a guest.
                                            </li>
                                            <li>
                                            Get immediate access to our dentistry modules through the Immersify Platform above. 
                                            </li>
                                            <li>
                                            Or, download Immersify for free and get stuck in to our content. <br/>
                                            Immersify - <a href='https://immersifydental.page.link/?link=https://immersifyeducation.com/deeplink?dl=%5Bimmersifyeducation%3A%2F%2Fimmersifydental%3Firclickid%3DFAQL%5D%26apn%3Dcom.immersifyeducation.immersifydental%26isi%3D1509735183%26ibi%3Dcom.immersifyeducation.immersifydental ' target="_blank" rel="noreferrer">Apple App Store</a> <br/>

                                            Immersify - <a href="https://immersifydental.page.link/?link=https://immersifyeducation.com/deeplink?dl=%5Bimmersifyeducation%3A%2F%2Fimmersifydental%3Firclickid%3DFAQL%5D%26apn%3Dcom.immersifyeducation.immersifydental%26isi%3D1509735183%26ibi%3Dcom.immersifyeducation.immersifydental " rel="noreferrer" target="_blank">Google Play Store</a> <br/>

                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <div className="faq_questions">
                                <button className="faq_que" type="button" data-bs-toggle="collapse" data-bs-target="#faq_5" aria-expanded="false" aria-controls="faq_5">
                                How much does Immersify cost?<span className="arrow_change"><i className="fa fa-angle-right"></i></span>
                                </button>
                                <div className="collapse" id="faq_5">
                                    <div className="faq_answ">
                                        <ul>
                                            <li>Accessing our content through the Immersify Platform and downloading Immersify is free. </li>
                                            <li>Our free tier gives you immediate access to a range of content such as Dental Charting, Skull Anatomy and Local Analgesia. </li>
                                            <li>We also have our premium subscription, what we call Immersify Premium, this enables you to have unlimited access to all our dentistry content.</li>
                                            <li>
                                            You can check out our <a href="https://immersifyeducation.com/deeplink?dl=%5Bimmersifyeducation%3A%2F%2Fimmersifydental%3Fsection%3DShop%5D" rel="noreferrer" target="_blank">subscription models here.</a>
                                            </li>
                                            <li>PSA: we currently have a 14-day FREE trial of Immersify Premium. </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <RelatedLinks />

            <section className="container-fluid get_in_bg">
                <div className="col-md-12 ">
                    <div className="get_in_touch">
                        <h3>Want to know more?</h3>
                        <h5>
                            Got a question, a suggestion or wanna chat with our
                            team?
                        </h5>
                        <button
                            className="btn_white_out"
                            data-bs-toggle="modal"
                            data-bs-target="#getInTouchModal"
                        >
                            Get in touch
                        </button>
                    </div>
                </div>
            </section>

         <Footer link={true} />
        </>
        );
    };
    