import React from "react";

import { AFramePlayer } from "./AFramePlayerFeedback";
// import { ImagePlayer } from "../../../../../../AFrame/ImagePlayer";

export function GetPointData(standardLessonMan) {
    // const textContent = standardLessonMan.modelsJSON.textData.contentText;
    // const subheadingText = standardLessonMan.modelsJSON.textData.subheadingText;

    const modelData = standardLessonMan.modelsJSON;
    // const imageData = standardLessonMan.modelsJSON.imageData.images;

    let content = [];

    for (let index = 0; index < modelData.length; index++) {
        // switch (textContent[index].type) {
        //     case "3DModel":
                if (modelData === undefined) {
                    console.log("no model data");
                    break;
                }
                content[index] = (
                    <AFramePlayer
                        key={"AframePlayer" + index}
                        id={index}
                        modelData={modelData}
                    />
                );
                // break;
            // case "2DImage":
            //     if (imageData === undefined) {
            //         console.log("no image data");
            //         break;
            //     }
            //     content[index] = (
            //         <ImagePlayer
            //             key={"ImagePlayer" + index}
            //             id={index}
            //             imageData={imageData}
            //         />
            //     );
            //     break;
        // }
    }

    return {
        // textContent: textContent,
        // subheadingText: subheadingText,
        content: content,
    };
}
