import React from "react";

export const ReportsDoc = () => {
   

    return (
        <>
            <div className="profile_edit_sec qr_c">
                <h3>Reports</h3>
                <p>Download and retrieve detailed reports.</p>
                <div className="reports_sec">
                    <iframe src="https://im-sales-portal-8acc2a83f73b.herokuapp.com/reporting/UserReports.html"></iframe>
                </div>
                
            </div>
        </>
    );
};

