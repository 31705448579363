import React from 'react'
import PropTypes from "prop-types";

import { GetInfoFromID } from '../../../../../Utils/getInfoFromID';
import { PracticalPopup } from '../../DisplayExperiences/ExperienceCard/PracticalPopup/PracticalPopup';

export const SideCard = (props) => {

    const id = props.id
    const experienceInfo = GetInfoFromID(id)

    var iconLocation
    var title
    var type = 'lesson'
    if (experienceInfo !== undefined) {
        title = experienceInfo.info.title
        type = experienceInfo.type

        if (type === 'topic') {
            iconLocation = experienceInfo.info.roundedIconLocation
        }
        else {
            iconLocation = experienceInfo.info.iconLocation
        }
    }
    else return (<></>)

    function loadItem (id) {
        if (type !== 'practical') {
            window.location.href = (process.env.REACT_APP_API_URL + "/Experience/" + id)
        }
    }

    return (
        <>
            <div
                className="video_item"
                key={id}
                onClick={() => loadItem(id)}
                data-bs-toggle={type === "practical" ? "modal" : ""}
                data-bs-target={
                    type === "practical" ? "#premium_content" + id : ""
                }
            >
                <span
                    className={
                        "link_type " +
                        (type === "lesson"
                            ? "lesson"
                            : type === "practical"
                            ? "exp"
                            : "topic")
                    }
                >
                    {type === "practical" ? "experience" : type}
                </span>

                <div className="act_icon">
                    <img
                        src={
                            "https://d1476tmm9qfop0.cloudfront.net/TestFiles/Icons/webp/px-" +
                            iconLocation.slice(6).slice(0, -4) +
                            ".webp"
                        }
                        className="img-fluid"
                        alt="Activity - Icon"
                    />
                </div>

                <h5 className="act_title">{title}</h5>
            </div>

            {type === "practical" ? <PracticalPopup id={id} /> : <></>}
        </>
    );
}

SideCard.propTypes = {
    id: PropTypes.string,
    displayContentCallback: PropTypes.func,
}
