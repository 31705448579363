// React form for the landing page

import React, { useState } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import ReactFlagsSelect from "react-flags-select";

// export const GetInTouchA = () => {
//     const [firstName, setFirstName] = useState("");
//     const [lastName, setLastName] = useState("");
//     const [email, setEmail] = useState("");
//     const [country, setCountry] = useState("");
//     const [organisation, setOrganisation] = useState("");
//     const [occupation, setOccupation] = useState("");
//     const [phone, setPhone] = useState("");
//     const [message, setMessage] = useState("");
//     const [sent, setSent] = useState(false);

//     const handleSubmit = (e) => {
//         e.preventDefault();

//         setSent(true);

//         // concatinate data

//         if (message.length > 500) {
//             setMessage(message.substring(0, 500));
//         }
//         var data = {
//             firstName: firstName,
//             lastName: lastName,
//             email: email,
//             country: country,
//             organisation: organisation,
//             phone: phone,
//             occupation: occupation,
//             message: message,
//         };

//         // send email with sendMail api call
//         var myHeaders = new Headers();
//         myHeaders.append("Content-Type", "application/json");

//         var raw = JSON.stringify({
//             to: "info@immersifyeducation.com",
//             subject: "Someone wants to get in touch",
//             text: JSON.stringify(data),
//         });

//         var requestOptions = {
//             method: "POST",
//             headers: myHeaders,
//             body: raw,
//             redirect: "follow",
//         };

//         fetch(process.env.REACT_APP_API_URL + "/api/sendMail", requestOptions)
//             .then((response) => response.text())
//             .then((result) => console.log(result))
//             .catch((error) => console.log("error", error));
//     };

//     return (
//         <div className="cntct_wrap">
//             {sent === false ? (
//                 <div className="col-md-12">
//                     <h2>Get in Touch</h2>
//                     <p>Find out how Immersify can support you:</p>
//                     <form onSubmit={handleSubmit}>
//                         <div className="row">
//                             <div className="col-md-6">
//                                 <div className="form-group">
//                                     <label htmlFor="firstName">
//                                         First Name <span className="formRequiredField">*</span>
//                                     </label>
//                                     <input
//                                         type="text"
//                                         className="form-control"
//                                         id="firstName"
//                                         value={firstName}
//                                         required
//                                         onChange={(e) => setFirstName(e.target.value)}
//                                     />
//                                 </div>
//                             </div>
//                             <div className="col-md-6">
//                                 <div className="form-group">
//                                     <label htmlFor="lastName">
//                                         Last Name <span className="formRequiredField">*</span>
//                                     </label>
//                                     <input
//                                         type="text"
//                                         className="form-control"
//                                         id="lastName"
//                                         value={lastName}
//                                         required
//                                         onChange={(e) => setLastName(e.target.value)}
//                                     />
//                                 </div>
//                             </div>
//                         </div>
//                         <div className="row">
//                             <div className="col-md-6">
//                                 <div className="form-group">
//                                     <label htmlFor="email">
//                                         Email <span className="formRequiredField">*</span>
//                                     </label>
//                                     <input
//                                         type="email"
//                                         className="form-control"
//                                         id="email"
//                                         value={email}
//                                         required
//                                         onChange={(e) => setEmail(e.target.value)}
//                                     />
//                                 </div>
//                             </div>
//                             <div className="col-md-6">
//                                 <div className="form-group">
//                                     <label htmlFor="phone">Phone</label>
//                                     <PhoneInput
//                                         className="contactPhoneInput"
//                                         country={"us"}
//                                         value={phone}
//                                         onChange={setPhone}
//                                     />
//                                 </div>
//                             </div>
//                             <div className="col-md-4">
//                                 <div className="form-group">
//                                     <label htmlFor="country">
//                                         Country <span className="formRequiredField">*</span>
//                                     </label>
//                                     <ReactFlagsSelect
//                                         className="countrySelect"
//                                         selected={country}
//                                         onSelect={(country) => setCountry(country)}
//                                         searchable
//                                     />
//                                 </div>
//                             </div>
//                             <div className="col-md-4">
//                                 <div className="form-group">
//                                     <label htmlFor="organisation">
//                                         Organisation <span className="formRequiredField">*</span>
//                                     </label>
//                                     <input
//                                         type="organisation"
//                                         className="form-control"
//                                         id="organisation"
//                                         value={organisation}
//                                         required
//                                         onChange={(e) => setOrganisation(e.target.value)}
//                                     />
//                                 </div>
//                             </div>
//                             <div className="col-md-4">
//                                 <div className="form-group">
//                                     <label htmlFor="occupation">
//                                         Occupation <span className="formRequiredField">*</span>
//                                     </label>
//                                     <input
//                                         type="text"
//                                         className="form-control"
//                                         id="occupation"
//                                         value={occupation}
//                                         required
//                                         onChange={(e) => setOccupation(e.target.value)}
//                                     />
//                                 </div>
//                             </div>
//                             <div className="col-md-12">
//                                 <div className="form-group message_sec">
//                                     <label htmlFor="message">
//                                         Message <span className="formRequiredField">*</span>
//                                     </label>
//                                     <textarea
//                                         className="form-control"
//                                         id="message"
//                                         rows="3"
//                                         value={message}
//                                         required
//                                         onChange={(e) => setMessage(e.target.value)}
//                                     />
//                                 </div>
//                             </div>
//                         </div>

//                         <div>
//                             By clicking send, you agree to our <a href="/website-privacy-policy">privacy policy</a>.
//                         </div>
//                         <div className="btn_areas">
//                             <button className="btn_outline" data-bs-dismiss="modal" aria-label="Close">
//                                 Cancel
//                             </button>
//                             <button type="submit" className="btn_common">
//                                 Send
//                             </button>
//                         </div>
//                     </form>
//                 </div>
//             ) : (
//                 <div>
//                     <h2>Thank you for your message</h2>
//                     <p>We will get back to you soon.</p>
//                 </div>
//             )}
//         </div>
//     );
// };

export const GetInTouch = () => {
    const [formState, setFormState] = useState({
        oid: "00D8d000009pLRX",
        retURL: "https://",
        recordType: "0128d0000009e4H",
        debug: 1,
        debugEmail: "projects@alicorn.digital",
        first_name: "",
        last_name: "",
        email: "",
        mobile: "",
        country_code: "",
        company: "",
        title: "",
        scopingDetails: "",
    });
    const [sent, setSent] = useState(false);

    const handleChange = (event) => {
        setFormState({
            ...formState,
            [event.target.name]: event.target.value,
        });
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        setSent(true);

        //salesforce throwing an error, think this is the solution not got time to fix rn
        // https://trailhead.salesforce.com/trailblazer-community/feed/0D54V00007T4UCQSA3
        // var myHeaders = new Headers();
        // myHeaders.append("Content-Type", "application/json");

        // var raw = JSON.stringify(formData);

        // var requestOptions = {
        //     method: "POST",
        //     headers: myHeaders,
        //     body: raw,
        //     redirect: "follow",
        // };

        // fetch("https://webto.salesforce.com/servlet/servlet.WebToLead?encoding=UTF-8", requestOptions)
        //     .then((response) => response.text())
        //     .then((result) => console.log(result))
        //     .catch((error) => console.log("error", error));

        // send email with sendMail api call
        var myHeaders2 = new Headers();
        myHeaders2.append("Content-Type", "application/json");

        var raw2 = JSON.stringify({
            to: "info@immersifyeducation.com",
            subject: "Someone wants to get in touch",
            text: JSON.stringify(formState),
        });

        var requestOptions2 = {
            method: "POST",
            headers: myHeaders2,
            body: raw2,
            redirect: "follow",
        };

        fetch(process.env.REACT_APP_API_URL + "/api/sendMail", requestOptions2)
            .then((response) => response.text())
            .then((result) => console.log(result))
            .catch((error) => console.log("error", error));

        //GTAG
        if (window && window.gtag) {
            window.gtag("event", "contact_form_submission", {});
        }
    };

    return (
        <div className="cntct_wrap">
            {!sent ? (
                <div className="col-md-12">
                    <h2>Get in Touch</h2>
                    <p>Find out how Immersify can support you:</p>
                    <form onSubmit={handleSubmit}>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label htmlFor="first_name">First Name</label>
                                    <input
                                        id="first_name"
                                        maxLength="40"
                                        name="first_name"
                                        size="20"
                                        type="text"
                                        value={formState.first_name}
                                        onChange={handleChange}
                                        className="form-control"
                                        required
                                    />
                                    <br />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label htmlFor="last_name">Last Name</label>
                                    <input
                                        id="last_name"
                                        maxLength="80"
                                        name="last_name"
                                        size="20"
                                        type="text"
                                        value={formState.last_name}
                                        onChange={handleChange}
                                        className="form-control"
                                        required
                                    />
                                    <br />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label htmlFor="email">Email</label>
                                    <input
                                        id="email"
                                        maxLength="80"
                                        name="email"
                                        size="20"
                                        type="text"
                                        value={formState.email}
                                        onChange={handleChange}
                                        className="form-control"
                                        required
                                    />
                                    <br />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label htmlFor="mobile">Mobile</label>
                                    <PhoneInput
                                        className="contactPhoneInput"
                                        country={"us"}
                                        value={formState.mobile}
                                        onChange={(mobile) => setFormState({ ...formState, mobile })}
                                    />
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group">
                                    <label htmlFor="country_code">Country (for timezone)</label>
                                    <ReactFlagsSelect
                                        className="countrySelect"
                                        selected={formState.country_code}
                                        onSelect={(country_code) => setFormState({ ...formState, country_code })}
                                        searchable
                                    />
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group">
                                    <label htmlFor="company">Company</label>
                                    <input
                                        id="company"
                                        maxLength="40"
                                        name="company"
                                        size="20"
                                        type="text"
                                        value={formState.company}
                                        onChange={handleChange}
                                        className="form-control"
                                        required
                                    />
                                    <br />
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group">
                                    <label htmlFor="title">Title</label>
                                    <input
                                        id="title"
                                        maxLength="40"
                                        name="title"
                                        size="20"
                                        type="text"
                                        value={formState.title}
                                        onChange={handleChange}
                                        className="form-control"
                                        required
                                    />
                                    <br />
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="form-group message_sec">
                                    <label htmlFor="scopingDetails">Message:</label>
                                    <textarea
                                        id="scopingDetails"
                                        name="scopingDetails"
                                        type="text"
                                        wrap="soft"
                                        value={formState.scopingDetails}
                                        onChange={handleChange}
                                        className="form-control"
                                        required
                                    ></textarea>
                                    <br />
                                </div>
                            </div>
                        </div>
                        <div>
                            By clicking send, you agree to our <a href="/website-privacy-policy">privacy policy</a>.
                        </div>
                        <div className="btn_areas">
                            <button className="btn_outline" data-bs-dismiss="modal" aria-label="Close">
                                Cancel
                            </button>
                            <button type="submit" className="btn_common">
                                Send
                            </button>
                        </div>
                    </form>
                </div>
            ) : (
                <div>
                    <h2>Thank you for your message</h2>
                    <p>We will get back to you soon.</p>
                </div>
            )}
        </div>
    );
};
