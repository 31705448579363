import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import { Entity, Scene } from "aframe-react";
import { setupLesson, vmcqFeedbackModelMan } from "./vmcq-feedback-models";

// import { getLessonUserData } from "../GetLessonUserData/GetLs
import { GetPointData } from "./GetPointData";
import { CreateAssets } from "./CreateAssets";
import { SetPoint } from "./SetPoint";

var pointData;

export const InitialiseSceneFeedback = (props) => {
    const setSliderPoints = props.setSliderPoints
    const setPointData = props.setPointData

    
    const [feedbackStarted, setFeedbackStarted] = useState(false)

    const [isFeedbackDataLoaded, setIsFeedbackDataLoaded] = useState(false)

    const [assetTags, setAssetTags] = useState(undefined)

    useEffect(() => {

        if (feedbackStarted === false) {
            beginFeedback();
            setFeedbackStarted(true);
        }

        if (isFeedbackDataLoaded === true && assetTags === undefined) {
            
            pointData = GetPointData(vmcqFeedbackModelMan)
            // console.log("got point data", pointData)
            setPointData(pointData)
            SetPoint(pointData, setSliderPoints, 0, true, true)
            setAssetTags(<CreateAssets vmcqFeedbackModelMan={vmcqFeedbackModelMan} />)
        }
        
    }, [isFeedbackDataLoaded]);

    const beginFeedback = () => {
        setupLesson(props.id, "loaderTOP", props.incorrectAnswerData);
    };

    const feedbackDataLoaded = () => {
        // console.log("Model data loaded")
        setIsFeedbackDataLoaded(true);
    };
    
    
    return (
        <>
            <div className="d-none">
                {/* this first scene is only needed to tigger this loading event*/}
                <Scene embedded vr-mode-ui="enabled: false" light="defaultLightsEnabled: false">
                    <a-assets>
                        {isFeedbackDataLoaded ? <>{assetTags}</> : null}
                    </a-assets>
                    <Entity
                        id="loaderTOP"
                        events={{
                            onVMCQModelsLoaded: () => feedbackDataLoaded(),
                        }}
                    />
                </Scene>
            </div>
        </>
    );
}

InitialiseSceneFeedback.propTypes = {
    setSliderPoints: PropTypes.func,
    setContentLength: PropTypes.func,
    setPointData: PropTypes.func,
    id: PropTypes.string,
    incorrectAnswerData: PropTypes.array,
}