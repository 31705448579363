import {Encrypt} from ".././../Utils/Encrypt";
import {Decrypt} from "../../Utils/Decrypt";
import {GetUserData} from "../../Utils/getUserData";

export function GetLoginData(email, password, callback = () => {
}, errorsCallback = () => {
}) {
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");

  var raw = JSON.stringify({
    Email: email,
    Password: password,
    TitleId: process.env.REACT_APP_TITLE_ID,
  });

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  fetch("https://29001.playfabapi.com/Client/LoginWithEmailAddress", requestOptions)
    .then((response) => response.text())
    .then((result) => {
      if (JSON.parse(result).code == 200) {
        GetAccountInfo(result, "playfab", callback);
      } else {
        let errors = {};
        errors.playfabLogin = "Incorrect email or password";
        errorsCallback(errors);
      }
    })
    .catch((error) => {
      console.log(error);
    });
}

export function GetAccountInfo(response, loginType, callback = () => {
}) {
  const sessionTicket = JSON.parse(response).data.SessionTicket;
  localStorage.setItem(process.env.REACT_APP_SESSION_TICKET_CODE_NAME, Encrypt(sessionTicket));
  localStorage.setItem("loginTime", new Date());
  localStorage.setItem("loginType", loginType);

  var myHeaders = new Headers();
  myHeaders.append("X-Authorization", sessionTicket);
  myHeaders.append("Content-Type", "application/json");
  var raw = JSON.stringify({
    PlayFabId: localStorage.getItem("playfabID"),
    ProfileConstraints: {ShowContactEmailAddresses: true},
  });
  var PlayerProfileRequestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };
  var contactEmail = "";
  fetch("https://29001.playfabapi.com/Client/GetPlayerProfile", PlayerProfileRequestOptions)
    .then((response) => response.text())
    .then((result) => {
      const contactEmails = JSON.parse(result).data.PlayerProfile.ContactEmailAddresses;

      if (contactEmails.length !== 0 && contactEmails) {
        contactEmail = contactEmails[0].EmailAddress;
        localStorage.setItem("contactEmail", contactEmail);
      }
    });

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    redirect: "follow",
  };

  fetch("https://29001.playfabapi.com/Client/GetAccountInfo", requestOptions)
    .then((response) => response.text())
    .then((result) => {
      const loginEmail = JSON.parse(result).data.AccountInfo.PrivateInfo.Email;
      const accountInfo = JSON.parse(result).data.AccountInfo;
      const playfabID = JSON.parse(result).data.AccountInfo.PlayFabId;
      localStorage.setItem("loginEmail", loginEmail);
      localStorage.setItem("playfabID", playfabID);

      var displayName = "";
      switch (loginType) {
        case "playfab":
          displayName = accountInfo.TitleInfo.DisplayName;
          break;
        case "facebook":
          if (accountInfo.TitleInfo.DisplayName !== undefined) {
            displayName = accountInfo.TitleInfo.DisplayName;
          } else {
            window.location.href = process.env.REACT_APP_API_URL + "/DisplayName";
          }
          break;
        case "apple":
          if (accountInfo.TitleInfo.DisplayName !== undefined) {
            displayName = accountInfo.TitleInfo.DisplayName;
          } else {
            window.location.href = process.env.REACT_APP_API_URL + "/DisplayName";
          }
          break;
        case "oidc":
          break;
        case "google":
          if (accountInfo.TitleInfo.DisplayName !== undefined) {
            displayName = accountInfo.TitleInfo.DisplayName;
          } else {
            window.location.href = process.env.REACT_APP_API_URL + "/DisplayName";
          }
          break;

        case "uniSSO":
          if (accountInfo.TitleInfo.DisplayName !== undefined) {
            displayName = accountInfo.TitleInfo.DisplayName;
          } else {
            window.location.href = process.env.REACT_APP_API_URL + "/DisplayName";
          }
          break;
        default:
          break;
      }

      localStorage.setItem("displayName", displayName);

      //set academic arae in local storage here
      GetUserData(["AcademicArea"], gotAcademicArea);

      function gotAcademicArea(data) {
        data = JSON.parse(data);
        if (data.AcademicArea.Value !== undefined) {
          localStorage.setItem("AcademicArea", data.AcademicArea.Value);
        }
      }

      if (loginType === "uniSSO") {
        callback(displayName);
      } else {
        CheckIfSubscriber(callback);
      }
    })
    .catch((error) => console.log("error", error));
}

function CheckIfSubscriber(callback) {
  var myHeaders = new Headers();
  myHeaders.append("X-Authorization", Decrypt(localStorage.getItem(process.env.REACT_APP_SESSION_TICKET_CODE_NAME)));
  myHeaders.append("Content-Type", "application/json");

  var raw = JSON.stringify({
    PlayFabId: localStorage.getItem("playfabID"),
    Keys: ["ReceiptStripeActiveUntilDate"],
  });

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  fetch("https://29001.playfabapi.com/Client/GetUserData", requestOptions)
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .then((result) => {
      if (result.data.Data.ReceiptStripeActiveUntilDate !== undefined) {
        localStorage.setItem("platform", "stripe");
        localStorage.setItem("activeUntil", result.data.Data.ReceiptStripeActiveUntilDate.Value);

        const today = new Date();

        if (result.data.Data.ReceiptStripeActiveUntilDate.Value > today) {
          callback(true);
        } else {
          callback(false);
        }
      } else {
        RestoreSubscription(callback);
      }
    })
    .catch((error) => console.log("error", error));
}

function RestoreSubscription(callback) {
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");

  var raw = JSON.stringify({
    PlayFabId: localStorage.getItem("playfabID"),
  });

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  fetch(process.env.REACT_APP_SERVER_URL + "/api/verifyReceipt", requestOptions)
    .then((response) => response.text())
    .then((result) => {
      localStorage.setItem("interval", JSON.parse(result).interval);
      localStorage.setItem("platform", JSON.parse(result).platform);
      localStorage.setItem("activeUntil", JSON.parse(result).activeUntil);
      callback(JSON.parse(result).valid);
    })
    .catch((error) => console.log("error", error));
}
