import ModuleStructure from "../../../../InfoJSONs/ModuleStructure.json"

import { GetExperienceTopic } from "../../../../../Utils/getExperienceTopic";
import { GetTopicStructure } from "../../DisplayTopics/TopicCard/GetTopicInfo/GetTopicStructure";

export function GetNextComing(id) {
    
    var nextComing = {"nextComing": [], "experienceFound": false}

    const topicId = GetExperienceTopic(id)[0]

    const topicStructure = GetTopicStructure(topicId)
    
    nextComing = AddNextExperiences(id, topicStructure.Lessons, nextComing)
    nextComing = AddNextExperiences(id, topicStructure.Practicals, nextComing)

    if (nextComing.nextComing.length < 3) {
        nextComing = AddNextTopic(topicId, nextComing)
    }

    return nextComing.nextComing
}

function AddNextExperiences (id, inputArray, nextComing) {
    inputArray.forEach((experienceId) => {
        if (experienceId === id) {
            nextComing.experienceFound = true;
        } else if (nextComing.experienceFound === true) {
            nextComing.nextComing.push(experienceId);
        }
    });

    return nextComing
}

function AddNextTopic (topicId, nextComing) {

    var topicFound = false

    ModuleStructure.modules.forEach(module => {
        module.topics.forEach(topic => {
            if (topic.topicID === topicId) {
                topicFound = true
            }
            else if (topicFound === true && nextComing.nextComing.length < 3) {
                nextComing.nextComing.push(topic.topicID)
            }
        });
    });

    return nextComing
}
