export function shuffle(array) {
    let currentIndex = array.length,  randomIndex;
  
    // While there remain elements to shuffle.
    while (currentIndex != 0) {
  
      // Pick a remaining element.
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;
  
      // And swap it with the current element
      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex], array[currentIndex]];
    }
    //if answer is 'all of the above' or 'none of the above' move to the bottom position
    array.forEach((name, index) => {
      if (name.answerText == 'All of the above' || name.answerText == 'None of the above') {
        array.push(array.splice(index, 1)[0]);
      }
    });
    return array;
  }

export function chunkArray(inputArray, chunkSize){
  let chunks = [];
  for (let i = 0; i < inputArray.length; i += chunkSize) {
      let chunk = inputArray.slice(i, i + chunkSize);
      chunks.push(chunk);
  }
  console.log(chunks);
  return chunks;
}