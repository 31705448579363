import AFRAME from 'aframe';


AFRAME.registerComponent("feedbackmodelswapper", {
  schema:{
    message: {type: 'string', default: '0'}
  },
  init:function(){
      console.log("Feedback swapper", this.data.message);
      this.loadModel();

      this.el.addEventListener("model-loaded", this.modelLoaded);
  },

  loadModel:function(){ // this should be called when the feedback is changed
    console.log("fb model load called");
    let gltfComp = document.getElementById('SwapModel'+this.data.message);
    if(gltfComp !== undefined){
      gltfComp.removeAttribute("gltf-model");
      gltfComp.setAttribute('gltf-model', '#visibleModel'+this.data.message);
    }
  },
  modelLoaded:function(){
    console.log("model loaded in feedback");
  }
})