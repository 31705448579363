import 'aframe';
import 'aframe-extras';

import { Entity, Scene } from "aframe-react";
import React,{useEffect,useState} from "react";
import PropTypes from "prop-types";


//import { arShadow } from '../../Scripts/aframe/ar-shadows'
import { standardLessonMan } from '../../Scripts/aframe/StandardLesson/standard-lesson-data-manager'

import '../../Scripts/aframe/model-entity-maker'
import '../../Scripts/aframe/Utils/camera-manager.component'
import '../../Scripts/aframe/Utils/center-locator.component'
import '../../Scripts/aframe/im-orbit-controls'
import '../../Scripts/aframe/light-manager.component'

export const StandardLessonTemplate = (props) => {

    var initialised = false

    document.addEventListener("initialised", () => {
        initialised = true
    });
    
    //console.log(props);
    //const [isModelLoaded,setIsModelLoaded] = useState(false);
    const [isAllModelsLoaded,setIsAllModelsLoaded] = useState(false);
    const [isLessonDataLoaded,setIsLessonDataLoaded] = useState(false);

    useEffect(() => {
        handleMakeModelEntitiesClick();
    }, []);

    {/* LOAD HANDLERS*/}
    const allModelsLoaded = () => {
        setIsAllModelsLoaded(true);
        if(isLessonDataLoaded){
            setIsLoadingComplete(true);
        } 
    };
    const lessonDataLoaded = () => {
        console.log("lesson data loaded");
        setIsLessonDataLoaded(true);       
        if(isAllModelsLoaded){
            setIsLoadingComplete(true);
        }       
    };

    const [isLoadingComplete, setIsLoadingComplete] = useState(false);
    // Event that sets up the animation & slider vars
    // Can be used to trigger anything that needs to happen once lesson & model are loaded
    const [doneLoadingComplete, setDoneLoadingCompelte] = useState(false);
    // eslint-disable-next-line
    const onAllLoadingComplete = () => {
        if(!isLoadingComplete || doneLoadingComplete){return;}
        //console.log("all loading complete, performing final setup");

        setDoneLoadingCompelte(true);
    }

    const handleMakeModelEntitiesClick = () => {

        if (initialised === true) {
            // trigger the make entities event
            let modelMakerID = "modelEntityMaker"+props.id;
            let modelMakerDOM = document.getElementById(modelMakerID);
            
            let modelMaker = modelMakerDOM.components['model-entity-maker'];
            //let modelEntList = [];
            modelMaker.makeModelEntities(props.modelsForThisPoint, props.id);
        }
        else {
            setTimeout(() => handleMakeModelEntitiesClick(), 100);
        }
         
         
        //  props.modelsForThisPoint.forEach(model =>{
        //     let modelEntObj = { modelID:model.id }; // make a temp obj that stores relevant data from the json
        //     modelEntList.push( modelEntObj );
        //  });
        //  modelMaker.makeModelEntities(modelEntList);
    }

    const handleFullscreen = () => {
        let elem = document.getElementById('AFrameScene'+props.id);
        if (elem.requestFullscreen) {
            elem.requestFullscreen();
          } else if (elem.webkitRequestFullscreen) { /* Safari */
            elem.webkitRequestFullscreen();
          } else if (elem.msRequestFullscreen) { /* IE11 */
            elem.msRequestFullscreen();
          }
    }

    return (
      <>
        {/*SCREEN SPACE UI */}

        {/*ENDSCREEN SPACE UI */}

        {/* LOADING */}
        {/* {isLoadingComplete ? onAllLoadingComplete() : <ContentLoader /> } */}
        <button onClick={handleFullscreen}>
          <i className="icon-fullscreen"></i>
        </button>

        <div id={"AFrameSceneSection" + props.id}>
          <Scene
            id={"AFrameScene" + props.id}
            embedded
            device-orientation-permission-ui="enabled: false"
            light="defaultLightsEnabled: false"
            //webxr="optionalFeatures: {hit-test, local-floor}"
            class="ModelViewerScene"
            renderer="colorManagement: true; antialias: true"
            vr-mode-ui="enabled: false"
            light-manager
          >
            <a-entity
              id="directionalLight"
              light="type: directional; color: #FFF; intensity: 0.3"
              position="-0.5 1 1"
            ></a-entity>
            <Entity
              id="ambientLight"
              light="type: ambient; color: #FFF; intensity: 0.3"
            ></Entity>

            <Entity
              id={"sceneCam" + props.id}
              class="sceneCam"
              camera="fov:36"
              camera-manager={
                "distance:" +
                standardLessonMan.lessonJSON.textData.contentText[props.id]
                  .distance +"; pointID: " + props.id
              }
              im-orbit-controls={
                "target: #camTarget" + props.id + "; rotateTo:0 0 5;"
              }
              light="type:spot; color: #FFF; intensity: 1"
            />

            <Entity id="WorldCenter" position="0 0 0" rotation="0 0 0" />

            {/* Target Cube */}
            {/* Position is overwritten by find center code */}
            {/* Can also be changed by lesson json file (camPos) */}
            <a-box
              id={"camTarget" + props.id}
              class="camTarget"
              position="0 2 1.175"
              rotation="0 0 0"
              scale="0 0 0"
              color="green"
            />

            {/* MODELS */}
            <Entity
              id={"modelEntityMaker" + props.id}
              model-entity-maker={"loaderID:loader" + props.id}
            />

            {/* SKY */}
            {/* <a-sky color="#ECECEC" /> */}
            <a-sky color="#f7f8fa" />
            {/* LOADING EVENTS */}
            <Entity
              id={"loader" + props.id}
              events={{
                onAllModelsLoaded: () => allModelsLoaded(),
                onLessonDataLoaded: () => lessonDataLoaded(),
              }}
            />
            {/* END LOADING EVENTS */}
          </Scene>
        </div>
      </>
    );
};

StandardLessonTemplate.propTypes = {
    id: PropTypes.number,
    modelsForThisPoint: PropTypes.any
};
