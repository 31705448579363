import React from "react";
import PropTypes from "prop-types";
import { Entity, Scene } from "aframe-react";
import "aframe";
import "aframe-extras";
import "./AFrameComponents/ModelLoadCheck"

export const LandingModel = (props) => {

    const modelLoaded = props.modelLoaded
    let modelScale = "2 2 2";
    let modelPosition = "0 1.5 -8";
    let modelRotation = "0 0 0";
    let modelURL =
        "https://dsmhsp9m0ds1d.cloudfront.net/ModelData/LandingPage/SkullStatic.glb";
    if (props.modelURL !== undefined) {
        modelURL = props.modelURL;
    }
    if (props.modelScale !== undefined) {
        modelScale = props.modelScale;
    }
    if (props.modelPosition !== undefined) {
        modelPosition = props.modelPosition;
    }
    if (props.modelRotation !== undefined) {
        modelRotation = props.modelRotation;
    }
    
    return (
        <>
            <div className="landing_model">
                <div id="AFrameScene">
                    <Scene
                        embedded
                        device-orientation-permission-ui="enabled: false"
                        class="ModelViewerScene"
                        renderer="colorManagement: true; antialias: true"
                        vr-mode-ui="enabled: false"
                        loading-screen="dotsColor: blue; backgroundColor: white"
                        shadow="type: pcfsoft"
                    >
                        <Entity
                            id="loadCheckEntity"
                            events={{
                                onModelLoaded: () => modelLoaded(),
                            }}
                        />
                        <a-assets>
                            <a-asset-item
                                id="landingModel"
                                src={modelURL}
                            ></a-asset-item>
                        </a-assets>
                        <Entity
                            id="landingModelEntity"
                            gltf-model="#landingModel"
                            scale={modelScale}
                            position={modelPosition}
                            rotation={modelRotation}
                            animation-mixer="clip: *"
                            shadow="cast: true; receive: false"
                            model-load-check
                        />
                        <Entity
                            primitive="a-plane"
                            id="floor"
                            position="0 0.5 0"
                            rotation="-90 0 0"
                            width="1400"
                            height="1400"
                            color="#FFFFFF"
                            shadow="cast: false; receive: true"
                            material="shader: shadow"
                        />
                        <Entity
                            id={"sceneCam"}
                            class="sceneCam"
                            camera="fov:36"
                            im-orbit-controls={
                                "target: #camTarget" + "; rotateTo:0 0 5; minDistance: 12; maxDistance: 12"
                            }
                            light="type: point; intensity: 0.8; distance: 0; decay: 2; color: #FFFFFF"
                        />
                        <a-entity
                            light="type:directional; color: #FFF; intensity: 0.3; castShadow:true; shadowMapHeight: 2048;shadowMapWidth:2048;"
                            position="1 4 1"
                        ></a-entity>
                        <a-box
                            id={"camTarget"}
                            class="camTarget"
                            position="0 3 -8"
                            rotation="0 0 0"
                            scale="0 0 0"
                            color="green"
                        />
                    </Scene>
                </div>
            </div>
        </>
    );      
}

LandingModel.propTypes = {
    modelLoaded: PropTypes.func,
    modelScale: PropTypes.string,
    modelPosition: PropTypes.string,
    modelRotation: PropTypes.string,
    modelURL: PropTypes.string,
}