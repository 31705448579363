import { GetInfoFromID } from "../../../../../Utils/getInfoFromID";

export function GetPracticalType (id) {
    const info = GetInfoFromID(id).info
    if (info !== undefined) {
        if (info.sceneName === 'VMCQGeneric') {
            return 'vmcq'
        } else if (info.sceneName === 'Spotter'){
            return 'spotter'
        }
    }
    else {
        return undefined
    }
}