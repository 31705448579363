import { GetInfoFromID } from '../../../../../../../../Utils/getInfoFromID';

export function GetLessonData (id) {

    var returnInfo

    const sceneParams = GetInfoFromID(id).info.sceneParams;
    console.log(sceneParams);
    var lessonFileName;
    var quizFileName;
    var modelDataFileName = false;
    sceneParams.forEach((element) => {
        if (element.includes("LessonFileName")) {
            lessonFileName = element.split(":")[1];
            const filePath = lessonFileName.split("/");
            lessonFileName = filePath[filePath.length - 1];
        } else if (element.includes("QuizFileName")) {
            quizFileName = element.split(":")[1];
        } else if (element.includes("ModelDataFile")) {
            modelDataFileName = element.split(":")[1];
        }
    });

    returnInfo = {"lessonFileName": lessonFileName, "quizFileName": quizFileName, "modelDataFileName": modelDataFileName}

    return returnInfo
}