import { GA4React } from "ga-4-react";

const ga4react = new GA4React("G-MFH9NZT2R6").initialize();

export const trackPathForAnalytics = (data) => {
    
    const { path, title } = data;
    ga4react
        .then((ga) => {
            ga.pageview(path, title);
        })
        .catch((err) => console.error(`Analytics failed: ${err}`));
};
