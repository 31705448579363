import React, { useEffect } from "react";

import immersify_logo from "../images/immersify_logo.svg";
import shape_1 from "../images/shape_1.svg";
import loader from "../images/loader3.gif";

export const TikTokTest = () => {

    useEffect(() => {
        window.location.href =
            "https://immersifydental.page.link/?link=https://immersifyeducation.com/deeplink%3Fdl%3D[immersifyeducation://immersifydental%3Firclickid%3DHOLIDAYAD4]%26apn%3Dcom.immersifyeducation.immersifydental%26isi%3D1509735183%26ibi%3Dcom.immersifyeducation.immersifydental&utm_source=TikTok+AD&utm_medium=AD&utm_campaign=Implantology";
    }, []);

    function goToDeeplink() {
        window.location.href =
            "https://immersifydental.page.link/?link=https://immersifyeducation.com/deeplink%3Fdl%3D[immersifyeducation://immersifydental%3Firclickid%3DHOLIDAYAD4]%26apn%3Dcom.immersifyeducation.immersifydental%26isi%3D1509735183%26ibi%3Dcom.immersifyeducation.immersifydental&utm_source=TikTok+AD&utm_medium=AD&utm_campaign=Implantology";
    }

    return (
        <section className="container-fluid">
            <div className="row">
                <div className="col-xl-7 col-md-6 left_block d-none d-md-block">
                    <div className="signup_image">
                        <div className="shape_block">
                            <div className="shape_1">
                                <img alt="placeholder" src={shape_1} />
                            </div>
                            <div className="shape_2">
                                <img alt="placeholder" src={shape_1} />
                            </div>
                        </div>
                        <div className="login_text_block">
                            <h1>LEARN.</h1>
                            <h1>EXPERIENCE.</h1>
                            <h1>CONNECT.</h1>
                        </div>
                    </div>
                </div>
                <div className="col-xl-5 col-md-6">
                    <div className="logo_wrap">
                        <img alt="placeholder" src={immersify_logo} />
                    </div>
                    <div className="signup_form_area redirect_sec">
                        <div className="loader_dir">
                            <img src={loader} alt="loader" />
                        </div>
                        <h2>Redirecting...</h2>
                        <h3>Please do not refresh </h3>
                        <p>
                            If you have been waiting longer than a few seconds
                            please
                            <button
                                className="btn_underline"
                                onClick={goToDeeplink}
                            >
                                Click here
                            </button>
                        </p>
                    </div>
                </div>
            </div>
        </section>
    );
};
