/* global AFRAME */
AFRAME.registerComponent('create-entities', {
    init: function () {
      console.log("create entities init");
        const model = this.el;
        model.id = "ModelLoadEntity";
  
        this.el.addEventListener('model-loaded', () => {
            console.log("model loaded");
            this.scene = model.object3D.children[0];
            const entities = this.createEntities(this.scene);
            this.el.append(...entities);
            
            // once we have copied our meshes to entities,
            // remove the original
            model.removeAttribute('gltf-model');            
        });
     },
    
    createEntities: function (obj) {
        //console.log("creating entities for " + obj);
          const entities = [];
  
          if (obj instanceof AFRAME.THREE.Mesh) {
              entities.push(this.createEntity(obj));
          } else if (obj instanceof AFRAME.THREE.Object3D) {
              const entity = this.createEntity(obj);
              entities.push(entity);
  
              for (let i = 0; i < obj.children.length; i++) {
                  const child = obj.children[i];
                  const childEntities = this.createEntities(child);
  
                  if (childEntities.length > 0) {
                      entity.append(...childEntities);
                  }
              }
          }
  
          return entities;
      },
  
      createEntity: function (obj) {
        //console.log("creating entity for:");
        //console.log(obj);
        const entity = document.createElement('a-entity');

        if (obj instanceof AFRAME.THREE.Mesh) {
            const geometry = obj.geometry.clone();
            const material = obj.material.clone();
            const mesh = new AFRAME.THREE.Mesh(geometry, material);

            entity.setObject3D('mesh', mesh);
        }

        entity.object3D.name = obj.name;

        if (obj.name === this.scene.name) {
            entity.setAttribute('id', obj.name);
            entity.setAttribute('position', this.el.getAttribute('position'));
            entity.setAttribute('rotation', this.el.getAttribute('rotation'));
            entity.setAttribute('scale', this.el.getAttribute('scale'));
        }
        else 
        {
            entity.setAttribute('id', obj.name);
            entity.object3D.position.copy(obj.position);
            entity.object3D.quaternion.copy(obj.quaternion);
            entity.object3D.scale.copy(obj.scale);

            // if(obj.name.includes("H_")){
            //     entity.setAttribute('highlighter', '');
            // }
        }

        return entity;
    }
  });