import React from "react";
import Slider from "react-slick";
import { useEffect } from "react";
import WOW from "wowjs";
// import attract_talent from "../../images/landing/professionals/attract_talent.svg";
// import transfer_skills from "../../images/landing/professionals/skills.svg";
// import upskill_trainees from "../../images/landing/professionals/upskill_trainess.svg";
import brand from "../../images/landing/professionals/brand.svg";
import value from "../../images/landing/professionals/add_value.svg";
import ahead from "../../images/landing/professionals/get_ahead.svg";
import partner7 from "../../images/landing/partners/densura.png";
import demo_1 from "../../images/landing/demo_1.svg";
import partner3 from "../../images/landing/partners/p3.png";
// import professional from "../../images/landing/professional.png";
// import dentistry from "../../images/landing/dentistry.svg";
// import nursing from "../../images/landing/nursing.svg";
// import engineering from "../../images/landing/engineering.svg";
// import instagram from "../../images/landing/instag.png";
export const Professionals = () => {

    
    useEffect(() => {
        new WOW.WOW({
            live: false,
        }).init();
    }, []);


    const settings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToScroll: 1,
        arrows: true,
        responsive: [
            {
              breakpoint: 767,
              settings: {
                slidesToShow: 1,
              },
            },
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 2,
              },
            },
            {
              breakpoint: 1200,
              settings: {
                slidesToShow: 3,
              },
            },
            {
                breakpoint: 8000,
                settings: {
                  slidesToShow: 3,
                },
                
              }]
    };

    function handleDentistryPage() {
        window.location.href = process.env.REACT_APP_CLIENT_URL + "/dentistry";
    }

    // function handleTryWebPlatform () {
    //     window.location.href = process.env.REACT_APP_CLIENT_URL + "/Experience"
    // }

    return (
        <>
            {/* <section className="professionals_wrap mt-5 wow animate__animated animate__fadeInUp">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="m_head">
                                <h2 className="landing_main_head">
                                    Achieve your objectives with Immersify
                                    <span className="end_not"></span>
                                </h2>
                            </div>
                        </div>

                        <div className="row mt_pro">
                            <div className="col-lg-4 col-md-12">
                                <div className="prof_q_box">
                                    <img
                                        src={attract_talent}
                                        alt="Attract talent"
                                    />
                                    <h4>Attract talent</h4>
                                    <p>
                                        Attract and retain the right talent with
                                        an all-in-one educational resource
                                        platform.
                                    </p>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-12">
                                <div className="prof_q_box">
                                    <img
                                        src={transfer_skills}
                                        alt="Attract talent"
                                    />
                                    <h4>Provide transferrable skills</h4>
                                    <p>
                                        Give your trainees a chance to practise
                                        more and strengthen their skills with
                                        experiences that simulate real-life
                                        scenarios.
                                    </p>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-12">
                                <div className="prof_q_box">
                                    <img
                                        src={upskill_trainees}
                                        alt="Attract talent"
                                    />
                                    <h4>Upskill Trainees</h4>
                                    <p>
                                        Upskill your trainees with relevant
                                        educational resources and propel their
                                        career progression.
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-12">
                            <div className="button_sec">
                                <a
                                    href="https://calendly.com/thomasclarke-1/1hr?month=2023-03"
                                    className="btn_outline"
                                    rel="noreferrer"
                                    target="_blank"
                                >
                                    Book Discovery Session
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}

            <section className="professionals_wrap mt-5 wow animate__animated animate__fadeInUp">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="m_head">
                                <h2 className="landing_main_head">
                                    Are you a business in the health or dental
                                    care sector?
                                </h2>
                            </div>
                        </div>

                        <div className="row mt_pro">
                            <div className="col-lg-4 col-md-12">
                                <div className="prof_q_box">
                                    <img src={brand} alt="Attract talent" />
                                    <h4>Advance Your Brand</h4>
                                    <p>
                                        Grow and reinforce your brand reputation
                                        to a highly targeted audience through
                                        collaboration opportunities.
                                    </p>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-12">
                                <div className="prof_q_box">
                                    <img src={value} alt="Attract talent" />
                                    <h4>Add value</h4>
                                    <p>
                                        Create value beyond your product through
                                        unique channels and continue to inspire,
                                        educate, and entertain your audience.
                                    </p>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-12">
                                <div className="prof_q_box">
                                    <img src={ahead} alt="Attract talent" />
                                    <h4>Get Ahead</h4>
                                    <p>
                                        Attract the right talent by early
                                        adopting innovative technology and being
                                        forward-thinking.
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-12 wow animate__animated animate__fadeInUp">
                            <div className="button_sec">
                                <a
                                    href="https://calendly.com/d/2r2-wwr-z6p/immersify-demo"
                                    className="btn_outline"
                                    rel="noreferrer"
                                    target="_blank"
                                >
                                    Book Scoping Session
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <div className="sec_sep inv_shape"></div>

            <section className="stats_section rev wow animate__animated animate__fadeInUp mt_pro_1">
                <div className=" container ">
                <div className="row">
                <div className="col-lg-12">
                        <div className="box_head">
                            <h2 className="landing_main_head">
                                Suitability of the Immersify Platform
                                <span className="end_not"></span>
                            </h2>
                            <p className="landing_main_para">
                                Statistics taken from our recent survey of
                                students and young professionals.
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-12">
                        <div className="stats_wrap">
                            <div className="row">
                                <div className="col-md-4">
                                    <div className="stat_block mb-80">
                                        <div className="stat_num">
                                            92%
                                        </div>
                                        <p>
                                            See the transferability to real-life
                                            experiences.
                                        </p>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="stat_block mb-80">
                                        <div className="stat_num">
                                            97%
                                        </div>
                                        <p>
                                            Agree the platform is a reliable
                                            tool for professional development.
                                        </p>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="stat_block l_child">
                                        <div className="stat_num">
                                            100%
                                        </div>
                                        <p>
                                            Say the platform uses professional
                                            terminology.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                   
                </div>
                </div>
            </section>

            <section className="testimonials wow animate__animated animate__fadeInUp">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                       <div className="testimonial_head">
                       <h2>What people say about Immersify<span className="end_not"></span></h2>
                        </div> 
                    </div>
                    <div className="col-md-12 p-rel">
                        <div className="testimonial_sld">
                            <Slider {...settings}>
                                <div className="testimonial_item">
                                    <p>
                                        &#34;This partnership with Immersify
                                        unites technology and dentistry with one
                                        simple aim: educating our members to
                                        help them excel in their careers. This
                                        is at the very heart of what Immersify
                                        do. I couldn’t ask for a better fit and
                                        can’t wait to see the benefits of what
                                        promises to be a fruitful
                                        collaboration.&#34;
                                    </p>

                                    <div className="testimonial_person">
                                        <div className="testm_img">
                                            <img
                                                src={partner7}
                                                alt="testimonials"
                                            />
                                        </div>
                                        <div className="testm_det">
                                            <h2>Tom Hester</h2>
                                            <h5>
                                                Senior Vice President LLP at
                                                Lockton Companies LLP
                                            </h5>
                                        </div>
                                    </div>
                                </div>

                                <div className="testimonial_item">
                                    <p>
                                        &#34;Our partnership with Immersify
                                        Education has allowed us to continue
                                        fostering close relationships with
                                        students and professionals by
                                        collaborating on technology driven
                                        experiences within the Immersify
                                        Platform. Not only this, but the
                                        exposure throughout Immersify&#39;s
                                        digital channels, has provided us with
                                        an extra level of brand awareness from
                                        the start of their professional
                                        journey.&#34;
                                    </p>

                                    <div className="testimonial_person">
                                        <div className="testm_img">
                                            <img
                                                src={partner3}
                                                alt="testimonials"
                                            />
                                        </div>
                                        <div className="testm_det">
                                            <h2>Mike G Cann</h2>
                                            <h5>
                                                Managing Director at Septodont
                                                Ltd{" "}
                                            </h5>
                                        </div>
                                    </div>
                                </div>
                            </Slider>
                        </div>
                    </div>
                </div>
                </div>
            </section>

            <section className="container book_demo mt_pro_1 wow animate__animated animate__fadeInUp">
                <div className="row">
                    <div className="col-md-6 order-2 order-md-1">
                        <div className="demo_txt">
                            <div className="m2_head">
                                <h4 className="small_head">
                                    Want to find out more?{" "}
                                </h4>
                                <h2 className="landing_main_head text_left">
                                    Understanding the Immersify Platform
                                    <span className="end_not"></span>
                                </h2>

                                <p className="landing_main_para">
                                    Enhance your education delivery <br />{" "}
                                    through advanced technology today.
                                </p>
                            </div>
                            <div className="button_sec me-auto ms-0 mt-3">
                                <a
                                    href="https://calendly.com/d/2r2-wwr-z6p/immersify-demo"
                                    target="_blank"
                                    rel="noreferrer"
                                    className="btn_common"
                                >
                                    Book Discovery Session
                                </a>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-6 order-1 order-md-2">
                        <div className="demo_img">
                            <img src={demo_1} alt="Demo image" />
                        </div>
                    </div>
                </div>
            </section>

            <section className="faq wow animate__animated animate__fadeInUp mt_pro_1">
                <div className="row">
                    <div className="col">
                        <div className="box_head">
                            <h2 className="landing_main_head">
                                Frequently asked questions
                                <span className="end_not"></span>
                            </h2>
                        </div>

                        <div className="faq_content">
                            <div className="faq_questions">
                                <button
                                    className="faq_que"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#faq_1"
                                    aria-expanded="false"
                                    aria-controls="faq_1"
                                >
                                    What industry standards does your content
                                    adhere too?{" "}
                                    <span className="arrow_change">
                                        <i className="fa fa-angle-right"></i>
                                    </span>
                                </button>
                                <div className="collapse" id="faq_1">
                                    <div className="faq_answ">
                                        <ul>
                                            <li>
                                                We currently work with several
                                                industry experts who are based
                                                in the UK, as well as reputable,
                                                global partners such as{" "}
                                                <a
                                                    href="https://global.oup.com/about/oup_history/?cc=gb"
                                                    rel="noreferrer"
                                                    target="_blank"
                                                >
                                                    Oxford University Press
                                                </a>
                                                ,
                                                <a
                                                    href="https://www.cityandguilds.com/international"
                                                    rel="noreferrer"
                                                    target="_blank"
                                                >
                                                    {" "}
                                                    City & Guilds
                                                </a>{" "}
                                                and{" "}
                                                <a
                                                    href="https://www.ems-dental.com/en/about-ems"
                                                    rel="noreferrer"
                                                    target="_blank"
                                                >
                                                    EMS
                                                </a>{" "}
                                                to ensure exceptional quality of
                                                our educational content.
                                            </li>
                                            <li>
                                                You can meet a selection of our
                                                industry experts
                                                <button
                                                    className="nm_link"
                                                    onClick={
                                                        handleDentistryPage
                                                    }
                                                >
                                                    here.
                                                </button>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <div className="faq_questions">
                                <button
                                    className="faq_que"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#faq_2"
                                    aria-expanded="false"
                                    aria-controls="faq_2"
                                >
                                    Does your educational content provide
                                    qualifications?{" "}
                                    <span className="arrow_change">
                                        <i className="fa fa-angle-right"></i>
                                    </span>
                                </button>
                                <div className="collapse" id="faq_2">
                                    <div className="faq_answ">
                                        <ul>
                                            <li>
                                                Not yet – our goal is to have
                                                CPD hours attached to completing
                                                modules. But for now, our
                                                platform is the perfect
                                                additional resource to upskill
                                                trainees and equip them with the
                                                relevant competencies they need
                                                to progress throughout their
                                                professional career.
                                            </li>
                                            <li>
                                                The Immersify Platform can also
                                                be used as an assessment tool to
                                                better understand your new
                                                trainee&#39;s knowledge level
                                                and identify areas for
                                                improvement; allowing you to
                                                effectively map out their
                                                professional development plan
                                                (PDP).
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <div className="faq_questions">
                                <button
                                    className="faq_que"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#faq_3"
                                    aria-expanded="false"
                                    aria-controls="faq_3"
                                >
                                    How transferrable is your content to
                                    real-life experiences for our trainees?{" "}
                                    <span className="arrow_change">
                                        <i className="fa fa-angle-right"></i>
                                    </span>
                                </button>
                                <div className="collapse" id="faq_3">
                                    <div className="faq_answ">
                                        <ul>
                                            <li>
                                                Our content can be used for
                                                upskilling qualified
                                                professionals through teaching
                                                methods and techniques that are
                                                renowned within the education
                                                industry with some areas being:
                                                scenario-based learning,
                                                multiple-choice questions,
                                                gamification and utilising
                                                credible sources of information.
                                            </li>
                                            <li>
                                                We have this{" "}
                                                <a
                                                    href="https://immersifyeducation.org/education-platforms-help-qualified-professional"
                                                    rel="noreferrer"
                                                    target="_blank"
                                                >
                                                    blog post
                                                </a>{" "}
                                                that goes into more detail as to
                                                how our education platform
                                                supports qualified professionals
                                                in continuing their professional
                                                development.
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <div className="faq_questions">
                                <button
                                    className="faq_que"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#faq_4"
                                    aria-expanded="false"
                                    aria-controls="faq_4"
                                >
                                    What can you tell me about your audience?
                                    <span className="arrow_change">
                                        <i className="fa fa-angle-right"></i>
                                    </span>
                                </button>
                                <div className="collapse" id="faq_4">
                                    <div className="faq_answ">
                                        <ul>
                                            <li>
                                                Tertiary education and recently
                                                qualified professionals.
                                            </li>
                                            <li>
                                                However, with our content
                                                covering beginner learnings
                                                through to advanced modules, our
                                                educational resources support
                                                trainees looking to achieve CPD
                                                hours.
                                            </li>
                                            <li>
                                                Right now, we have comprehensive
                                                coverage for Dentistry
                                                professionals, early 2023 we
                                                will be releasing our Nursing
                                                content and the next academic
                                                area we plan to provide content
                                                for will be Engineering.
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <div className="faq_questions">
                                <button
                                    className="faq_que"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#faq_5"
                                    aria-expanded="false"
                                    aria-controls="faq_5"
                                >
                                    How easy is your education platform to
                                    rollout in both domestic and international
                                    countries?{" "}
                                    <span className="arrow_change">
                                        <i className="fa fa-angle-right"></i>
                                    </span>
                                </button>
                                <div className="collapse" id="faq_5">
                                    <div className="faq_answ">
                                        <ul>
                                            <li>
                                                Despite our headquarters being
                                                based in the UK, our education
                                                platform is readily accessible
                                                and currently used in over 150
                                                countries.
                                            </li>
                                            <li>
                                                Our expert development team will
                                                support entirely during the
                                                integration of the platform,
                                                providing an agile, seamless and
                                                frictionless experience.
                                            </li>
                                            <li>
                                                With easy to reach customer and
                                                tech support teams, you can rely
                                                on our team throughout your
                                                digital transformation phase.
                                            </li>
                                            <li>
                                                If you want more information,
                                                meet virtually with our
                                                Education team and they would be
                                                happy to answer any questions
                                                you may have.
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* <ContentSection textContent={"students text content"} /> */}
        </>
    );
};
