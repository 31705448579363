import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import { StandardLessonHeader } from "./StandardLessonHeader/StandardLessonHeader";
import Slider from "react-slick";
import { InitialiseScene } from "./InitialiseScene/InitialiseScene";
import { SetPoint } from "./SetPoint/SetPoint";
import { firebaseCall } from "../../../../../../Utils/firebaseAnalytics";

var moving = false;
let sliderValue = 0;
let currentPoint = 0;

export const StandardLessonContent = (props) => {
    useEffect(() => {
        sliderValue = 0;
        currentPoint = 0;
    }, []);

    const [currentPointState, setCurrentPointState] = useState(0);

    const settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToScroll: 1,
        margin: 15,
        swipe: false,
        afterChange: (current) => handleSlideChange(current),
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        accessibility: false,
    };

    const [firebaseFirstClick, setFirebaseFirstClick] = useState(true);

    function SampleNextArrow(props) {
        // eslint-disable-next-line
        const { className, style, onClick } = props;
        return (
            <div
                className={className}
                style={{ ...style, display: "block" }}
                onClick={function () {
                    if (onClick !== null) {
                        onClick();
                        nextPoint();
                        if (firebaseFirstClick) {
                            setFirebaseFirstClick(false);
                            firebaseCall("first_interaction");
                        }
                    }
                }}
            />
        );
    }

    function SamplePrevArrow(props) {
        // eslint-disable-next-line
        const { className, style, onClick } = props;
        return (
            <div
                className={className}
                style={{ ...style, display: "block" }}
                onClick={function () {
                    if (onClick !== null) {
                        onClick();
                        prevPoint();
                    }
                }}
            />
        );
    }

    const id = props.id;

    //const [lessonData, setLessonData] = useState({"lessonFileName": undefined, "quizFileName": undefined, "modelDataFileName": undefined})

    const [quizJSX, setQuizJSX] = useState(<></>);

    const [isQuizDataLoaded, setIsQuizDataLoaded] = useState(false);

    const [pointData, setPointData] = useState();

    // eslint-disable-next-line
    // const [sliderValue, setSliderValue] = useState(0);

    const [contentLength, setContentLength] = useState(0);

    const [gotUserData, setGotUserData] = useState(false);

    const [sliderPoints, setSliderPoints] = useState(
        <>
            <article key={"sliderPoint"} className="lesson_slide">
                <div className="std_lessons">
                    <div className="std_img_block">
                        <div className="skeleton-box bx1"></div>
                    </div>
                    <div className="std_text_block">
                        <div className="skel_box">
                            <div className="skeleton-box bx2"></div>
                            <div className="skeleton-box bx3"></div>
                        </div>
                    </div>
                </div>
            </article>
        </>
    );

    function handleSlideChange() {
        SetPoint(pointData, setSliderPoints, currentPoint);
        moving = false;
    }

    function nextPoint() {
        if (moving === false) {
            currentPoint = currentPoint + 1;
            setCurrentPointState(currentPoint);
            sliderValue = currentPoint;
            moving = true;
        }
    }

    function prevPoint() {
        if (moving === false) {
            currentPoint = currentPoint - 1;
            setCurrentPointState(currentPoint);
            sliderValue = currentPoint;
            moving = true;
        }
    }

    //for just click
    function handleOnChange(index) {
        sliderValue = index;
        currentPoint = index;
        setCurrentPointState(index);
        // SetPoint(pointData, setSliderPoints, currentPoint);
        // document.getElementsByClassName('bar')[0].style.width = (sliderValue / (contentLength - 1)) * 100 + "%";
    }

    // for on click and drag
    function handleMouseUp() {
        moving = true;
        slider.slickGoTo(currentPoint);
        SetPoint(pointData, setSliderPoints, currentPoint);
        moving = false;
    }

    // eslint-disable-next-line
    const [slider, setSlider] = useState();

    return (
        <>
            <StandardLessonHeader id={id} isQuizDataLoaded={isQuizDataLoaded} />

            <div className="std_wrap">
                <Slider {...settings} ref={(slider) => setSlider(slider)}>
                    {sliderPoints}
                </Slider>
                <div className="std_lesson_progress">
                    <div className="progress">
                        <div
                            id="progressBarSlider"
                            className="bar"
                            style={{
                                width:
                                    (100 * (sliderValue + 1)) / contentLength +
                                    "%",
                            }}
                        >
                            <p className="percent"></p>
                        </div>
                        <input
                            value={sliderValue}
                            onChange={(e) =>
                                handleOnChange(parseFloat(e.target.value))
                            }
                            onMouseUp={() => handleMouseUp()}
                            type="range"
                            min={0}
                            max={contentLength - 1}
                        />
                    </div>
                </div>

                <div className="slider_num">
                    <h3>
                        <span>{parseInt(currentPointState) + 1} </span>/{" "}
                        <span>{contentLength > 0 ? contentLength : "..."}</span>
                    </h3>
                </div>
            </div>

            {quizJSX}

            <InitialiseScene
                //standardLessonMan={standardLessonMan}
                gotUserData={gotUserData}
                setGotUserData={setGotUserData}
                setSliderPoints={setSliderPoints}
                id={id}
                setContentLength={setContentLength}
                setPointData={setPointData}
                setIsQuizDataLoaded={setIsQuizDataLoaded}
                isQuizDataLoaded={isQuizDataLoaded}
                //setLessonData={setLessonData}
                handleSlideChange={handleSlideChange}
                setQuizJSX={setQuizJSX}
            />
        </>
    );
};

StandardLessonContent.propTypes = {
    id: PropTypes.string,
};
