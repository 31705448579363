import { DownloadS3File } from "./downloadS3File";

export function GetSubbedUnis (getSubbedUnisCallback) {

        DownloadS3File(
            "TestFiles/EnterpriseData/suffix_list.json",
            gotSuffixListCallback,
            "com.rubenwood.unityaatesting"
        );

        function gotSuffixListCallback(data) {
            const suffixList = JSON.parse(data).SuffixAccessList;
            const emailList = JSON.parse(data).EmailVerifyList;

            getSubbedUnisCallback({"suffixAccessList":suffixList, "emailVerifyList":emailList})
        }
}